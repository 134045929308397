.q--flex-xxxs {
  flex: 0;
}

.q--flex-xxs {
  flex: 0.25;
}

.q--flex-xs {
  flex: 0.5;
}

.q--flex-s {
  flex: 0.75;
}

.q--flex-m {
  flex: 1.5;
}

.q--flex-l {
  flex: 2;
}

.q--flex-xl {
  flex: 2.5;
}

.q--flex-xxl {
  flex: 3;
}

.q--flex-xxxl {
  flex: 4;
}

.q--mr-xxxs {
  margin-right: 0.25rem;
}

.q--ml-xxxs {
  margin-left: 0.25rem;
}

.q--mb-xxxs {
  margin-bottom: 0.25rem;
}

.q--mt-xxxs {
  margin-top: 0.25rem;
}

.q--mx-xxxs {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.q--my-xxxs {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.q--m-xxxs {
  margin: 0.25rem;
}

.q--pr-xxxs {
  padding-right: 0.25rem;
}

.q--pl-xxxs {
  padding-left: 0.25rem;
}

.q--pb-xxxs {
  padding-bottom: 0.25rem;
}

.q--pt-xxxs {
  padding-top: 0.25rem;
}

.q--px-xxxs {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.q--py-xxxs {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.q--p-xxxs {
  padding: 0.25rem;
}

.q--mr-xxs {
  margin-right: 0.5rem;
}

.q--ml-xxs {
  margin-left: 0.5rem;
}

.q--mb-xxs {
  margin-bottom: 0.5rem;
}

.q--mt-xxs {
  margin-top: 0.5rem;
}

.q--mx-xxs {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.q--my-xxs {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.q--m-xxs {
  margin: 0.5rem;
}

.q--pr-xxs {
  padding-right: 0.5rem;
}

.q--pl-xxs {
  padding-left: 0.5rem;
}

.q--pb-xxs {
  padding-bottom: 0.5rem;
}

.q--pt-xxs {
  padding-top: 0.5rem;
}

.q--px-xxs {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.q--py-xxs {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.q--p-xxs {
  padding: 0.5rem;
}

.q--mr-xs {
  margin-right: 1rem;
}

.q--ml-xs {
  margin-left: 1rem;
}

.q--mb-xs {
  margin-bottom: 1rem;
}

.q--mt-xs {
  margin-top: 1rem;
}

.q--mx-xs {
  margin-right: 1rem;
  margin-left: 1rem;
}

.q--my-xs {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.q--m-xs {
  margin: 1rem;
}

.q--pr-xs {
  padding-right: 1rem;
}

.q--pl-xs {
  padding-left: 1rem;
}

.q--pb-xs {
  padding-bottom: 1rem;
}

.q--pt-xs {
  padding-top: 1rem;
}

.q--px-xs {
  padding-right: 1rem;
  padding-left: 1rem;
}

.q--py-xs {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.q--p-xs {
  padding: 1rem;
}

.q--mr-s {
  margin-right: 1.5rem;
}

.q--ml-s {
  margin-left: 1.5rem;
}

.q--mb-s {
  margin-bottom: 1.5rem;
}

.q--mt-s {
  margin-top: 1.5rem;
}

.q--mx-s {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.q--my-s {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.q--m-s {
  margin: 1.5rem;
}

.q--pr-s {
  padding-right: 1.5rem;
}

.q--pl-s {
  padding-left: 1.5rem;
}

.q--pb-s {
  padding-bottom: 1.5rem;
}

.q--pt-s {
  padding-top: 1.5rem;
}

.q--px-s {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.q--py-s {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.q--p-s {
  padding: 1.5rem;
}

.q--mr-m {
  margin-right: 2rem;
}

.q--ml-m {
  margin-left: 2rem;
}

.q--mb-m {
  margin-bottom: 2rem;
}

.q--mt-m {
  margin-top: 2rem;
}

.q--mx-m {
  margin-right: 2rem;
  margin-left: 2rem;
}

.q--my-m {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.q--m-m {
  margin: 2rem;
}

.q--pr-m {
  padding-right: 2rem;
}

.q--pl-m {
  padding-left: 2rem;
}

.q--pb-m {
  padding-bottom: 2rem;
}

.q--pt-m {
  padding-top: 2rem;
}

.q--px-m {
  padding-right: 2rem;
  padding-left: 2rem;
}

.q--py-m {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.q--p-m {
  padding: 2rem;
}

.q--mr-l {
  margin-right: 2.5rem;
}

.q--ml-l {
  margin-left: 2.5rem;
}

.q--mb-l {
  margin-bottom: 2.5rem;
}

.q--mt-l {
  margin-top: 2.5rem;
}

.q--mx-l {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.q--my-l {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.q--m-l {
  margin: 2.5rem;
}

.q--pr-l {
  padding-right: 2.5rem;
}

.q--pl-l {
  padding-left: 2.5rem;
}

.q--pb-l {
  padding-bottom: 2.5rem;
}

.q--pt-l {
  padding-top: 2.5rem;
}

.q--px-l {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.q--py-l {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.q--p-l {
  padding: 2.5rem;
}

.q--mr-xl {
  margin-right: 3rem;
}

.q--ml-xl {
  margin-left: 3rem;
}

.q--mb-xl {
  margin-bottom: 3rem;
}

.q--mt-xl {
  margin-top: 3rem;
}

.q--mx-xl {
  margin-right: 3rem;
  margin-left: 3rem;
}

.q--my-xl {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.q--m-xl {
  margin: 3rem;
}

.q--pr-xl {
  padding-right: 3rem;
}

.q--pl-xl {
  padding-left: 3rem;
}

.q--pb-xl {
  padding-bottom: 3rem;
}

.q--pt-xl {
  padding-top: 3rem;
}

.q--px-xl {
  padding-right: 3rem;
  padding-left: 3rem;
}

.q--py-xl {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.q--p-xl {
  padding: 3rem;
}

.q--mr-xxl {
  margin-right: 3.5rem;
}

.q--ml-xxl {
  margin-left: 3.5rem;
}

.q--mb-xxl {
  margin-bottom: 3.5rem;
}

.q--mt-xxl {
  margin-top: 3.5rem;
}

.q--mx-xxl {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.q--my-xxl {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.q--m-xxl {
  margin: 3.5rem;
}

.q--pr-xxl {
  padding-right: 3.5rem;
}

.q--pl-xxl {
  padding-left: 3.5rem;
}

.q--pb-xxl {
  padding-bottom: 3.5rem;
}

.q--pt-xxl {
  padding-top: 3.5rem;
}

.q--px-xxl {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.q--py-xxl {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.q--p-xxl {
  padding: 3.5rem;
}

.q--mr-xxxl {
  margin-right: 4rem;
}

.q--ml-xxxl {
  margin-left: 4rem;
}

.q--mb-xxxl {
  margin-bottom: 4rem;
}

.q--mt-xxxl {
  margin-top: 4rem;
}

.q--mx-xxxl {
  margin-right: 4rem;
  margin-left: 4rem;
}

.q--my-xxxl {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.q--m-xxxl {
  margin: 4rem;
}

.q--pr-xxxl {
  padding-right: 4rem;
}

.q--pl-xxxl {
  padding-left: 4rem;
}

.q--pb-xxxl {
  padding-bottom: 4rem;
}

.q--pt-xxxl {
  padding-top: 4rem;
}

.q--px-xxxl {
  padding-right: 4rem;
  padding-left: 4rem;
}

.q--py-xxxl {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.q--p-xxxl {
  padding: 4rem;
}

.mat-button-toggle-checked {
  background-color: #e1f5e6 !important;
}

.mat-button-toggle-appearance-standard:hover {
  background-color: #e8edec;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}
html {
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: rgb(224.4, 224.4, 224.4);
}

html {
  --mat-standard-button-toggle-height: 36px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
  color: #444;
}

.q--btn-toogle--sm {
  --mat-standard-button-toggle-height: 24px;
}
.q--btn-toogle--sm .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.q--btn-toogle--sm .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
  color: #444;
}

.q--btn-toogle--md {
  --mat-standard-button-toggle-height: 36px;
}
.q--btn-toogle--md .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.q--btn-toogle--md .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
  color: #444;
}

.q--btn-toogle--lg .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.q--btn-toogle--lg .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
  color: #444;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d5f41;
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #0d5f41;
  --mat-text-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e8edec;
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #e8edec;
  --mat-text-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #cb3935;
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #cb3935;
  --mat-text-button-ripple-color: rgba(203, 57, 53, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d5f41;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e8edec;
  --mdc-filled-button-label-text-color: #444444;
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: #444444;
  --mat-filled-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #cb3935;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d5f41;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e8edec;
  --mdc-protected-button-label-text-color: #444444;
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: #444444;
  --mat-protected-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #cb3935;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d5f41;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #0d5f41;
  --mat-outlined-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e8edec;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #e8edec;
  --mat-outlined-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #cb3935;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #cb3935;
  --mat-outlined-button-ripple-color: rgba(203, 57, 53, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
}
html {
  --mdc-filled-button-container-height: 36px;
}
html {
  --mdc-protected-button-container-height: 36px;
}
html {
  --mdc-outlined-button-container-height: 36px;
}
html {
  --mat-text-button-touch-target-display: block;
}
html {
  --mat-filled-button-touch-target-display: block;
}
html {
  --mat-protected-button-touch-target-display: block;
}
html {
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Open Sans;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.4px;
  --mdc-text-button-label-text-weight: 600;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Open Sans;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.4px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Open Sans;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.4px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Open Sans;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.4px;
  --mdc-outlined-button-label-text-weight: 600;
  --mdc-outlined-button-label-text-transform: none;
}
.mdc-button--outlined:not(:disabled)[color=primary] {
  --mdc-outlined-button-outline-color: map.get(palettes.$palette-primary, 600);
}

.mdc-button--outlined:not(:disabled)[color=accent] {
  --mdc-outlined-button-outline-color: map.get(palettes.$palette-secondary, 600);
}

.mdc-button--outlined:not(:disabled)[color=warn] {
  --mdc-outlined-button-outline-color: map.get(palettes.$palette-red, 600);
}

.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: #e0e0e0;
  --mdc-filled-button-disabled-label-text-color: #b6b6b6;
}

.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: #b6b6b6;
}

.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-disabled-outline-color: #e0e0e0;
  --mdc-outlined-button-label-text-color: #b6b6b6;
}

.q--btn--sm {
  --mdc-outlined-button-container-height: 30px;
  --mdc-filled-button-container-height: 30px;
  --mdc-text-button-container-height: 30px;
}
.q--btn--sm {
  --mdc-text-button-label-text-font: Open Sans;
  --mdc-text-button-label-text-size: 13px;
  --mdc-text-button-label-text-tracking: 0.4px;
  --mdc-text-button-label-text-weight: 600;
  --mdc-text-button-label-text-transform: none;
}
.q--btn--sm {
  --mdc-filled-button-label-text-font: Open Sans;
  --mdc-filled-button-label-text-size: 13px;
  --mdc-filled-button-label-text-tracking: 0.4px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-transform: none;
}
.q--btn--sm {
  --mdc-protected-button-label-text-font: Open Sans;
  --mdc-protected-button-label-text-size: 13px;
  --mdc-protected-button-label-text-tracking: 0.4px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-label-text-transform: none;
}
.q--btn--sm {
  --mdc-outlined-button-label-text-font: Open Sans;
  --mdc-outlined-button-label-text-size: 13px;
  --mdc-outlined-button-label-text-tracking: 0.4px;
  --mdc-outlined-button-label-text-weight: 600;
  --mdc-outlined-button-label-text-transform: none;
}
.q--btn--sm {
  --mdc-text-button-container-height: 28px;
}
.q--btn--sm {
  --mdc-filled-button-container-height: 28px;
}
.q--btn--sm {
  --mdc-protected-button-container-height: 28px;
}
.q--btn--sm {
  --mdc-outlined-button-container-height: 28px;
}
.q--btn--sm {
  --mat-text-button-touch-target-display: none;
}
.q--btn--sm {
  --mat-filled-button-touch-target-display: none;
}
.q--btn--sm {
  --mat-protected-button-touch-target-display: none;
}
.q--btn--sm {
  --mat-outlined-button-touch-target-display: none;
}

.q--btn--md {
  --mdc-text-button-label-text-font: Open Sans;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.4px;
  --mdc-text-button-label-text-weight: 600;
  --mdc-text-button-label-text-transform: none;
}
.q--btn--md {
  --mdc-filled-button-label-text-font: Open Sans;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.4px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-transform: none;
}
.q--btn--md {
  --mdc-protected-button-label-text-font: Open Sans;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.4px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-label-text-transform: none;
}
.q--btn--md {
  --mdc-outlined-button-label-text-font: Open Sans;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.4px;
  --mdc-outlined-button-label-text-weight: 600;
  --mdc-outlined-button-label-text-transform: none;
}
.q--btn--md {
  --mdc-text-button-container-height: 36px;
}
.q--btn--md {
  --mdc-filled-button-container-height: 36px;
}
.q--btn--md {
  --mdc-protected-button-container-height: 36px;
}
.q--btn--md {
  --mdc-outlined-button-container-height: 36px;
}
.q--btn--md {
  --mat-text-button-touch-target-display: block;
}
.q--btn--md {
  --mat-filled-button-touch-target-display: block;
}
.q--btn--md {
  --mat-protected-button-touch-target-display: block;
}
.q--btn--md {
  --mat-outlined-button-touch-target-display: block;
}

.q--btn--lg {
  --mdc-outlined-button-container-height: 42px;
  --mdc-filled-button-container-height: 42px;
  --mdc-text-button-container-height: 42px;
}
.q--btn--lg {
  --mdc-text-button-label-text-font: Open Sans;
  --mdc-text-button-label-text-size: 15px;
  --mdc-text-button-label-text-tracking: 0.4px;
  --mdc-text-button-label-text-weight: 600;
  --mdc-text-button-label-text-transform: none;
}
.q--btn--lg {
  --mdc-filled-button-label-text-font: Open Sans;
  --mdc-filled-button-label-text-size: 15px;
  --mdc-filled-button-label-text-tracking: 0.4px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-transform: none;
}
.q--btn--lg {
  --mdc-protected-button-label-text-font: Open Sans;
  --mdc-protected-button-label-text-size: 15px;
  --mdc-protected-button-label-text-tracking: 0.4px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-label-text-transform: none;
}
.q--btn--lg {
  --mdc-outlined-button-label-text-font: Open Sans;
  --mdc-outlined-button-label-text-size: 15px;
  --mdc-outlined-button-label-text-tracking: 0.4px;
  --mdc-outlined-button-label-text-weight: 600;
  --mdc-outlined-button-label-text-transform: none;
}
.q--btn--primary {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--primary {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--primary {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--primary {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--primary {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--primary {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--primary {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--primary {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--primary .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d5f41;
}
.q--btn--primary .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #0d5f41;
  --mat-text-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.q--btn--primary .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #0d5f41;
}
.q--btn--primary .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #0d5f41;
  --mat-text-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.q--btn--primary .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--primary .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--primary .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d5f41;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #0d5f41;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d5f41;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #0d5f41;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--primary .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--primary .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d5f41;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--primary .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #0d5f41;
  --mat-outlined-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.q--btn--primary .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #0d5f41;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--primary .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #0d5f41;
  --mat-outlined-button-ripple-color: rgba(13, 95, 65, 0.1);
}
.q--btn--primary .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--primary .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--primary .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--accent {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--accent {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--accent {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--accent {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--accent {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--accent {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--accent .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #e8edec;
}
.q--btn--accent .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #e8edec;
  --mat-text-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.q--btn--accent .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e8edec;
}
.q--btn--accent .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #e8edec;
  --mat-text-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.q--btn--accent .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--accent .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--accent .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #e8edec;
  --mdc-filled-button-label-text-color: #444444;
}
.q--btn--accent .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: #444444;
  --mat-filled-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--accent .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e8edec;
  --mdc-filled-button-label-text-color: #444444;
}
.q--btn--accent .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: #444444;
  --mat-filled-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--accent .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--accent .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--accent .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #e8edec;
  --mdc-protected-button-label-text-color: #444444;
}
.q--btn--accent .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: #444444;
  --mat-protected-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--accent .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e8edec;
  --mdc-protected-button-label-text-color: #444444;
}
.q--btn--accent .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: #444444;
  --mat-protected-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--accent .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--accent .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--accent .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #e8edec;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--accent .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #e8edec;
  --mat-outlined-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.q--btn--accent .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e8edec;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--accent .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #e8edec;
  --mat-outlined-button-ripple-color: rgba(232, 237, 236, 0.1);
}
.q--btn--accent .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--accent .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--accent .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--error {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--error {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--error {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--error {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--error {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--error {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--error {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--error {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--error .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #cb3935;
}
.q--btn--error .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #cb3935;
  --mat-text-button-ripple-color: rgba(203, 57, 53, 0.1);
}
.q--btn--error .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #cb3935;
}
.q--btn--error .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #cb3935;
  --mat-text-button-ripple-color: rgba(203, 57, 53, 0.1);
}
.q--btn--error .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--error .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--error .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #cb3935;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--error .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #cb3935;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--error .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--error .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #cb3935;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--error .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #cb3935;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--error .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--error .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--error .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #cb3935;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--error .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #cb3935;
  --mat-outlined-button-ripple-color: rgba(203, 57, 53, 0.1);
}
.q--btn--error .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #cb3935;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--error .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #cb3935;
  --mat-outlined-button-ripple-color: rgba(203, 57, 53, 0.1);
}
.q--btn--error .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--error .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--error .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--warning {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--warning {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--warning {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--warning {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--warning {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--warning {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--warning {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--warning {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--warning .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ed6c02;
}
.q--btn--warning .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #ed6c02;
  --mat-text-button-ripple-color: rgba(237, 108, 2, 0.1);
}
.q--btn--warning .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ed6c02;
}
.q--btn--warning .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #ed6c02;
  --mat-text-button-ripple-color: rgba(237, 108, 2, 0.1);
}
.q--btn--warning .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--warning .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--warning .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ed6c02;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ed6c02;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ed6c02;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ed6c02;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--warning .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--warning .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ed6c02;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--warning .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #ed6c02;
  --mat-outlined-button-ripple-color: rgba(237, 108, 2, 0.1);
}
.q--btn--warning .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ed6c02;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--warning .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #ed6c02;
  --mat-outlined-button-ripple-color: rgba(237, 108, 2, 0.1);
}
.q--btn--warning .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--warning .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--warning .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--info {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--info {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--info {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--info {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--info {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--info {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--info {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--info {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--info .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3b7de0;
}
.q--btn--info .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #3b7de0;
  --mat-text-button-ripple-color: rgba(59, 125, 224, 0.1);
}
.q--btn--info .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3b7de0;
}
.q--btn--info .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #3b7de0;
  --mat-text-button-ripple-color: rgba(59, 125, 224, 0.1);
}
.q--btn--info .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--info .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--info .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3b7de0;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--info .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3b7de0;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--info .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--info .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3b7de0;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--info .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3b7de0;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--info .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--info .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--info .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3b7de0;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--info .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #3b7de0;
  --mat-outlined-button-ripple-color: rgba(59, 125, 224, 0.1);
}
.q--btn--info .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3b7de0;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--info .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #3b7de0;
  --mat-outlined-button-ripple-color: rgba(59, 125, 224, 0.1);
}
.q--btn--info .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--info .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--info .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--success {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--success {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--success {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--success {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--success {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--success {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--success {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--success {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--success .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #39a858;
}
.q--btn--success .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #39a858;
  --mat-text-button-ripple-color: rgba(57, 168, 88, 0.1);
}
.q--btn--success .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #39a858;
}
.q--btn--success .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #39a858;
  --mat-text-button-ripple-color: rgba(57, 168, 88, 0.1);
}
.q--btn--success .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--success .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--success .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #39a858;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--success .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #39a858;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--success .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--success .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #39a858;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--success .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #39a858;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--success .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--success .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--success .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #39a858;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--success .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #39a858;
  --mat-outlined-button-ripple-color: rgba(57, 168, 88, 0.1);
}
.q--btn--success .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #39a858;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--success .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #39a858;
  --mat-outlined-button-ripple-color: rgba(57, 168, 88, 0.1);
}
.q--btn--success .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--success .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--success .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--basic-light {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-light {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-light {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-light {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-light {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-light {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-light {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-light {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #eaeaea;
}
.q--btn--basic-light .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #eaeaea;
  --mat-text-button-ripple-color: rgba(234, 234, 234, 0.1);
}
.q--btn--basic-light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #eaeaea;
}
.q--btn--basic-light .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #eaeaea;
  --mat-text-button-ripple-color: rgba(234, 234, 234, 0.1);
}
.q--btn--basic-light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--basic-light .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #eaeaea;
  --mdc-filled-button-label-text-color: #323232;
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: #323232;
  --mat-filled-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #eaeaea;
  --mdc-filled-button-label-text-color: #323232;
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: #323232;
  --mat-filled-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--basic-light .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #eaeaea;
  --mdc-protected-button-label-text-color: #323232;
}
.q--btn--basic-light .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: #323232;
  --mat-protected-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.q--btn--basic-light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #eaeaea;
  --mdc-protected-button-label-text-color: #323232;
}
.q--btn--basic-light .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: #323232;
  --mat-protected-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.q--btn--basic-light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--basic-light .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #eaeaea;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #eaeaea;
  --mat-outlined-button-ripple-color: rgba(234, 234, 234, 0.1);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #eaeaea;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #eaeaea;
  --mat-outlined-button-ripple-color: rgba(234, 234, 234, 0.1);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-light .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--basic-light .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

.q--btn--basic-dark {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-dark {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-dark {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-dark {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-dark {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
.q--btn--basic-dark {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-dark {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-dark {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.q--btn--basic-dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #444444;
}
.q--btn--basic-dark .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #444444;
  --mat-text-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--basic-dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #444444;
}
.q--btn--basic-dark .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #444444;
  --mat-text-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--basic-dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.q--btn--basic-dark .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #444444;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #444444;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #444444;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #444444;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.q--btn--basic-dark .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #444444;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #444444;
  --mat-outlined-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #444444;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #444444;
  --mat-outlined-button-ripple-color: rgba(68, 68, 68, 0.1);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.q--btn--basic-dark .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.q--btn--basic-dark .mdc-button--outlined:not(:disabled) {
  --mdc-outlined-button-outline-color: map.get($primary, calc($shade - 100));
}

a.mat-mdc-button:hover,
a.mat-mdc-button:focus {
  text-decoration: none;
}

.mdc-card--outlined {
  --mdc-outlined-card-container-shape: 8px;
  --mdc-outlined-card-container-color: #fff;
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mdc-checkbox {
  --mdc-checkbox-state-layer-size: 36px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #444444;
  --mdc-checkbox-selected-focus-icon-color: #698681;
  --mdc-checkbox-selected-hover-icon-color: #698681;
  --mdc-checkbox-selected-icon-color: #698681;
  --mdc-checkbox-selected-pressed-icon-color: #698681;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #698681;
  --mdc-checkbox-selected-hover-state-layer-color: #698681;
  --mdc-checkbox-selected-pressed-state-layer-color: #698681;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
html {
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0d5f41;
  --mdc-checkbox-selected-hover-icon-color: #0d5f41;
  --mdc-checkbox-selected-icon-color: #0d5f41;
  --mdc-checkbox-selected-pressed-icon-color: #0d5f41;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d5f41;
  --mdc-checkbox-selected-hover-state-layer-color: #0d5f41;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d5f41;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Open Sans;
  --mat-checkbox-label-text-line-height: 21px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.15px;
  --mat-checkbox-label-text-weight: 400;
}

.q--checkbox--sm {
  --mdc-checkbox-state-layer-size: 32px;
}
.q--checkbox--sm {
  --mat-checkbox-touch-target-display: none;
}
.q--checkbox--sm .mdc-checkbox {
  --mdc-checkbox-state-layer-size: 32px;
}

.q--chips-status--sm .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}

.q--chips-status--basic .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #f6f6f6;
  border: 1px solid #5b5b5b;
}
.q--chips-status--basic .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #2d2d2d;
}
.q--chips-status--basic .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: transparent;
  border: 1px solid #727272;
}
.q--chips-status--basic .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  --mdc-chip-disabled-label-text-color: #727272;
}
.q--chips-status--basic .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.q--chips-status--basic .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2d2d2d;
  --mdc-chip-elevated-selected-container-color: #2d2d2d;
  --mdc-chip-elevated-disabled-container-color: #2d2d2d;
  --mdc-chip-flat-disabled-selected-container-color: #2d2d2d;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2d2d2d;
  --mdc-chip-elevated-selected-container-color: #2d2d2d;
  --mdc-chip-elevated-disabled-container-color: #2d2d2d;
  --mdc-chip-flat-disabled-selected-container-color: #2d2d2d;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--basic .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--basic .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #f6f6f6;
  border: 1px solid #a1a1a1;
}

.q--chips-status--red .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #fdf1f1;
  border: 1px solid #eb7677;
}
.q--chips-status--red .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #cb3935;
}
.q--chips-status--red .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: transparent;
  border: 1px solid #f2a0a2;
}
.q--chips-status--red .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  --mdc-chip-disabled-label-text-color: #f2a0a2;
}
.q--chips-status--red .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.q--chips-status--red .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #cb3935;
  --mdc-chip-elevated-selected-container-color: #cb3935;
  --mdc-chip-elevated-disabled-container-color: #cb3935;
  --mdc-chip-flat-disabled-selected-container-color: #cb3935;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #cb3935;
  --mdc-chip-elevated-selected-container-color: #cb3935;
  --mdc-chip-elevated-disabled-container-color: #cb3935;
  --mdc-chip-flat-disabled-selected-container-color: #cb3935;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--red .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.q--chips-status--yellow .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #fefaed;
  border: 1px solid #f6b580;
}
.q--chips-status--yellow .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #ed6c02;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: transparent;
  border: 1px solid #fad5aa;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  --mdc-chip-disabled-label-text-color: #fad5aa;
}
.q--chips-status--yellow .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.q--chips-status--yellow .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ed6c02;
  --mdc-chip-elevated-selected-container-color: #ed6c02;
  --mdc-chip-elevated-disabled-container-color: #ed6c02;
  --mdc-chip-flat-disabled-selected-container-color: #ed6c02;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ed6c02;
  --mdc-chip-elevated-selected-container-color: #ed6c02;
  --mdc-chip-elevated-disabled-container-color: #ed6c02;
  --mdc-chip-flat-disabled-selected-container-color: #ed6c02;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--yellow .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.q--chips-status--blue .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #dce7f8;
  border: 1px solid #70a0e8;
}
.q--chips-status--blue .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #3b7de0;
}
.q--chips-status--blue .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: transparent;
  border: 1px solid #8bb2ec;
}
.q--chips-status--blue .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  --mdc-chip-disabled-label-text-color: #8bb2ec;
}
.q--chips-status--blue .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.q--chips-status--blue .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3b7de0;
  --mdc-chip-elevated-selected-container-color: #3b7de0;
  --mdc-chip-elevated-disabled-container-color: #3b7de0;
  --mdc-chip-flat-disabled-selected-container-color: #3b7de0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3b7de0;
  --mdc-chip-elevated-selected-container-color: #3b7de0;
  --mdc-chip-elevated-disabled-container-color: #3b7de0;
  --mdc-chip-flat-disabled-selected-container-color: #3b7de0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--blue .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.q--chips-status--green .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) {
  --mdc-chip-elevated-container-color: #e1f5e6;
  border: 1px solid #67cb83;
}
.q--chips-status--green .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-option) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #39a858;
}
.q--chips-status--green .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: transparent;
  border: 1px solid #85d69c;
}
.q--chips-status--green .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  --mdc-chip-disabled-label-text-color: #85d69c;
}
.q--chips-status--green .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.q--chips-status--green .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #39a858;
  --mdc-chip-elevated-selected-container-color: #39a858;
  --mdc-chip-elevated-disabled-container-color: #39a858;
  --mdc-chip-flat-disabled-selected-container-color: #39a858;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #39a858;
  --mdc-chip-elevated-selected-container-color: #39a858;
  --mdc-chip-elevated-disabled-container-color: #39a858;
  --mdc-chip-flat-disabled-selected-container-color: #39a858;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .q--chips-status--green .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0d5f41;
  --mdc-chip-elevated-selected-container-color: #0d5f41;
  --mdc-chip-elevated-disabled-container-color: #0d5f41;
  --mdc-chip-flat-disabled-selected-container-color: #0d5f41;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #444444;
  --mdc-chip-elevated-container-color: #698681;
  --mdc-chip-elevated-selected-container-color: #698681;
  --mdc-chip-elevated-disabled-container-color: #698681;
  --mdc-chip-flat-disabled-selected-container-color: #698681;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #444444;
  --mdc-chip-selected-label-text-color: #444444;
  --mdc-chip-with-icon-icon-color: #444444;
  --mdc-chip-with-icon-disabled-icon-color: #444444;
  --mdc-chip-with-icon-selected-icon-color: #444444;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #444444;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #444444;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: #444444;
  --mat-chip-selected-trailing-icon-color: #444444;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans;
  --mdc-chip-label-text-line-height: 26px;
  --mdc-chip-label-text-size: 13px;
  --mdc-chip-label-text-tracking: 0.16px;
  --mdc-chip-label-text-weight: 400;
}
.q--chips--md .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.q--chips--sm .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.q--chips--secondary .mdc-evolution-chip__text-label, .q--chips--primary .mdc-evolution-chip__text-label, .q--chips--basic .mdc-evolution-chip__text-label {
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.q--chips--basic .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  --mdc-chip-label-text-color: #444;
  --mdc-chip-elevated-container-color: #eaeaea;
}
.q--chips--basic .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: #f3f3f3;
  --mdc-chip-disabled-label-text-color: #444;
}

.q--chips--primary .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  --mdc-chip-label-text-color: #fff;
  --mdc-chip-elevated-container-color: #0d5f41;
}
.q--chips--primary .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: #3b9373;
  --mdc-chip-disabled-label-text-color: #fff;
}

.q--chips--secondary .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  --mdc-chip-label-text-color: #444;
  --mdc-chip-elevated-container-color: #e8edec;
}
.q--chips--secondary .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  --mdc-chip-elevated-disabled-container-color: #f0f5f4;
  --mdc-chip-disabled-label-text-color: #444;
}

.q--dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  --mdc-dialog-subhead-color: #444;
  --mdc-dialog-subhead-line-height: 28px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: 0.15px;
  --mat-dialog-headline-padding: 0;
  margin: unset !important;
}
.q--dialog .mat-mdc-dialog-container .mat-mdc-dialog-title::before {
  content: unset !important;
}
.q--dialog .mat-mdc-dialog-content {
  --mat-dialog-with-actions-content-padding: 0;
}
.q--dialog .mat-mdc-dialog-actions {
  --mat-dialog-actions-padding: 0;
  min-height: unset !important;
}
.q--dialog .mat-mdc-dialog-component-host > * {
  padding: 2rem;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.cdk-drag-preview {
  overflow: hidden;
  box-shadow: none !important;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #0d5f41;
  --mdc-filled-text-field-focus-active-indicator-color: #0d5f41;
  --mdc-filled-text-field-focus-label-text-color: rgba(13, 95, 65, 0.87);
  --mdc-filled-text-field-container-color: rgb(244.8, 244.8, 244.8);
  --mdc-filled-text-field-disabled-container-color: rgb(249.9, 249.9, 249.9);
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
}
html {
  --mdc-outlined-text-field-caret-color: #0d5f41;
  --mdc-outlined-text-field-focus-outline-color: #0d5f41;
  --mdc-outlined-text-field-focus-label-text-color: rgba(13, 95, 65, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
}
html {
  --mat-form-field-focus-select-arrow-color: rgba(13, 95, 65, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #0d5f41;
  --mdc-filled-text-field-focus-active-indicator-color: #0d5f41;
  --mdc-filled-text-field-focus-label-text-color: rgba(13, 95, 65, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #0d5f41;
  --mdc-outlined-text-field-focus-outline-color: #0d5f41;
  --mdc-outlined-text-field-focus-label-text-color: rgba(13, 95, 65, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(13, 95, 65, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 44px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 10px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.15px;
  --mdc-filled-text-field-label-text-weight: 400;
}
html {
  --mdc-outlined-text-field-label-text-font: Open Sans;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.15px;
  --mdc-outlined-text-field-label-text-weight: 400;
}
html {
  --mat-form-field-container-text-font: Open Sans;
  --mat-form-field-container-text-line-height: 21px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.15px;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Open Sans;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(13, 95, 65, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(13, 95, 65, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: none;
}

html {
  --mat-select-trigger-text-font: Open Sans;
  --mat-select-trigger-text-line-height: 21px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.15px;
  --mat-select-trigger-text-weight: 400;
}

.mat-mdc-form-field input {
  background: none !important;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d5f41;
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #0d5f41;
  --mat-icon-button-ripple-color: rgba(13, 95, 65, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #698681;
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #698681;
  --mat-icon-button-ripple-color: rgba(105, 134, 129, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}

.mat-mdc-icon-button fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--qit-text-secondary);
}
.mat-mdc-icon-button fa-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.mat-mdc-icon-button fa-layers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.q--icon-btn--lg {
  --mat-icon-button-touch-target-display: none;
}
.q--icon-btn--lg .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: ;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: -24px/2;
}
.q--icon-btn--lg fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--qit-text-secondary);
}
.q--icon-btn--lg fa-icon svg {
  width: 1.6875rem;
  height: 1.6875rem;
}
.q--icon-btn--lg fa-layers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.q--icon-btn--md {
  --mat-icon-button-touch-target-display: none;
}
.q--icon-btn--md .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}
.q--icon-btn--md fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--qit-text-secondary);
}
.q--icon-btn--md fa-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}
.q--icon-btn--md fa-layers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.q--icon-btn--sm {
  --mat-icon-button-touch-target-display: none;
}
.q--icon-btn--sm .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: ;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: -24px/2;
}
.q--icon-btn--sm fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--qit-text-secondary);
}
.q--icon-btn--sm fa-icon svg {
  width: 1.125rem;
  height: 1.125rem;
}
.q--icon-btn--sm fa-layers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.q--icon-btn--xs {
  --mat-icon-button-touch-target-display: none;
}
.q--icon-btn--xs .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 28px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 2px;
}
.q--icon-btn--xs fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--qit-text-secondary);
}
.q--icon-btn--xs fa-icon svg {
  width: 0.9375rem;
  height: 0.9375rem;
}
.q--icon-btn--xs fa-layers {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-font: "Open Sans";
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-label-text-line-height: 21px;
  --mdc-list-list-item-label-text-tracking: 0.15px;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d5f41;
  --mdc-radio-selected-hover-icon-color: #0d5f41;
  --mdc-radio-selected-icon-color: #0d5f41;
  --mdc-radio-selected-pressed-icon-color: #0d5f41;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #698681;
  --mdc-radio-selected-hover-icon-color: #698681;
  --mdc-radio-selected-icon-color: #698681;
  --mdc-radio-selected-pressed-icon-color: #698681;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0d5f41;
  --mdc-checkbox-selected-hover-icon-color: #0d5f41;
  --mdc-checkbox-selected-icon-color: #0d5f41;
  --mdc-checkbox-selected-pressed-icon-color: #0d5f41;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d5f41;
  --mdc-checkbox-selected-hover-state-layer-color: #0d5f41;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d5f41;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #444444;
  --mdc-checkbox-selected-focus-icon-color: #698681;
  --mdc-checkbox-selected-hover-icon-color: #698681;
  --mdc-checkbox-selected-icon-color: #698681;
  --mdc-checkbox-selected-pressed-icon-color: #698681;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #698681;
  --mdc-checkbox-selected-hover-state-layer-color: #698681;
  --mdc-checkbox-selected-pressed-state-layer-color: #698681;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #0d5f41;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}

.q--list-item {
  display: flex;
  gap: 0.75rem;
}

.mat-mdc-menu-item .mdc-list-item__primary-text {
  --mdc-theme-text-primary-on-background: var(--qit-text-primary);
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 40px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: none;
}

html {
  --mat-paginator-container-text-font: Open Sans;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.4px;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

mat-paginator {
  --mdc-outlined-text-field-outline-color: transparent;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d5f41;
  --mdc-radio-selected-hover-icon-color: #0d5f41;
  --mdc-radio-selected-icon-color: #0d5f41;
  --mdc-radio-selected-pressed-icon-color: #0d5f41;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0d5f41;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #698681;
  --mdc-radio-selected-hover-icon-color: #698681;
  --mdc-radio-selected-icon-color: #698681;
  --mdc-radio-selected-pressed-icon-color: #698681;
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #698681;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
}
html {
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Open Sans;
  --mat-radio-label-text-line-height: 21px;
  --mat-radio-label-text-size: 16px;
  --mat-radio-label-text-tracking: 0.15px;
  --mat-radio-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 42px;
}
.mat-mdc-radio-button .mdc-form-field {
  height: 42px;
}
.mat-mdc-radio-button .mat-ripple,
.mat-mdc-radio-button .mdc-radio__background {
  scale: 1;
}
.mat-mdc-radio-button .mdc-form-field label {
  padding-right: 2px;
}

.q--radio--sm .mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 38px;
}
.q--radio--sm .mat-mdc-radio-button .mdc-form-field {
  height: 38px;
}
.q--radio--sm .mat-mdc-radio-button .mat-ripple,
.q--radio--sm .mat-mdc-radio-button .mdc-radio__background {
  scale: 0.9047619048;
}
.q--radio--sm .mat-mdc-radio-button .mdc-form-field label {
  padding-right: 2px;
}
.q--radio--sm .mat-mdc-radio-button .mdc-form-field label {
  padding-right: 0;
}

.q--radio--md .mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 42px;
}
.q--radio--md .mat-mdc-radio-button .mdc-form-field {
  height: 42px;
}
.q--radio--md .mat-mdc-radio-button .mat-ripple,
.q--radio--md .mat-mdc-radio-button .mdc-radio__background {
  scale: 1;
}
.q--radio--md .mat-mdc-radio-button .mdc-form-field label {
  padding-right: 2px;
}

.q--radio--lg .mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 46px;
}
.q--radio--lg .mat-mdc-radio-button .mdc-form-field {
  height: 46px;
}
.q--radio--lg .mat-mdc-radio-button .mat-ripple,
.q--radio--lg .mat-mdc-radio-button .mdc-radio__background {
  scale: 1.0952380952;
}
.q--radio--lg .mat-mdc-radio-button .mdc-form-field label {
  padding-right: 2px;
}
.q--radio--lg .mat-mdc-radio-button .mdc-form-field label {
  padding-right: 4px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #0b6947;
  --mdc-switch-selected-handle-color: #0b6947;
  --mdc-switch-selected-hover-state-layer-color: #0b6947;
  --mdc-switch-selected-pressed-state-layer-color: #0b6947;
  --mdc-switch-selected-focus-handle-color: #09402c;
  --mdc-switch-selected-hover-handle-color: #09402c;
  --mdc-switch-selected-pressed-handle-color: #09402c;
  --mdc-switch-selected-focus-track-color: #69ac94;
  --mdc-switch-selected-hover-track-color: #69ac94;
  --mdc-switch-selected-pressed-track-color: #69ac94;
  --mdc-switch-selected-track-color: #69ac94;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #819c97;
  --mdc-switch-selected-handle-color: #819c97;
  --mdc-switch-selected-hover-state-layer-color: #819c97;
  --mdc-switch-selected-pressed-state-layer-color: #819c97;
  --mdc-switch-selected-focus-handle-color: #41534f;
  --mdc-switch-selected-hover-handle-color: #41534f;
  --mdc-switch-selected-pressed-handle-color: #41534f;
  --mdc-switch-selected-focus-track-color: #ced9d7;
  --mdc-switch-selected-hover-track-color: #ced9d7;
  --mdc-switch-selected-pressed-track-color: #ced9d7;
  --mdc-switch-selected-track-color: #ced9d7;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Open Sans;
  --mat-switch-label-text-line-height: 21px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.15px;
  --mat-switch-label-text-weight: 400;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  --mdc-switch-selected-icon-color: transparent;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  --mdc-switch-unselected-icon-color: transparent;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #0d5f41;
  --mdc-slider-focus-handle-color: #0d5f41;
  --mdc-slider-hover-handle-color: #0d5f41;
  --mdc-slider-active-track-color: #0d5f41;
  --mdc-slider-inactive-track-color: #0d5f41;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d5f41;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
html {
  --mat-slider-ripple-color: #0d5f41;
  --mat-slider-hover-state-layer-color: rgba(13, 95, 65, 0.05);
  --mat-slider-focus-state-layer-color: rgba(13, 95, 65, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #698681;
  --mdc-slider-focus-handle-color: #698681;
  --mdc-slider-hover-handle-color: #698681;
  --mdc-slider-active-track-color: #698681;
  --mdc-slider-inactive-track-color: #698681;
  --mdc-slider-with-tick-marks-inactive-container-color: #698681;
  --mdc-slider-with-tick-marks-active-container-color: #444444;
}
html .mat-accent {
  --mat-slider-ripple-color: #698681;
  --mat-slider-hover-state-layer-color: rgba(105, 134, 129, 0.05);
  --mat-slider-focus-state-layer-color: rgba(105, 134, 129, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Open Sans;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.q--slider--sm .mat-mdc-slider .mdc-slider__track--inactive {
  --mdc-slider-inactive-track-height: 2px;
}
.q--slider--sm .mat-mdc-slider .mdc-slider__track--active {
  --mdc-slider-active-track-height: 2px;
}
.q--slider--sm .mat-mdc-slider .mdc-slider__thumb-knob {
  --mdc-slider-handle-width: 12px;
  --mdc-slider-handle-height: 12px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  --mdc-snackbar-supporting-text-font: "Open Sans";
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 600;
  --mdc-snackbar-supporting-text-line-height: 24px;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
html {
  --mat-snack-bar-button-color: #698681;
}

.q--snack-bar--success {
  --mdc-snackbar-container-color: #39a858;
  --mdc-snackbar-supporting-text-color: #fff;
}

.q--snack-bar--warning {
  --mdc-snackbar-container-color: #ed6c02;
  --mdc-snackbar-supporting-text-color: #fff;
}

.q--snack-bar--error {
  --mdc-snackbar-container-color: #cb3935;
  --mdc-snackbar-supporting-text-color: #fff;
}

.q--snack-bar--info {
  --mdc-snackbar-container-color: #3b7de0;
  --mdc-snackbar-supporting-text-color: #fff;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Open Sans;
  --mat-table-header-headline-line-height: 21px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: 0.15px;
  --mat-table-row-item-label-text-font: Open Sans;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Open Sans;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-mdc-header-row {
  height: 32px !important;
}

.mat-mdc-header-cell {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0.15px !important;
}

.mat-mdc-row {
  height: 56px !important;
}

.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-header-row {
  min-height: 32px !important;
}

.mat-mdc-cell:first-of-type,
.mat-mdc-header-cell:first-of-type,
.mat-mdc-footer-cell:first-of-type {
  padding-left: 1rem !important;
}

.mat-mdc-cell:last-of-type,
.mat-mdc-header-cell:last-of-type,
.mat-mdc-footer-cell:last-of-type {
  padding-right: 1rem !important;
}

.q--table--sm {
  --mat-table-header-container-height: 40px;
  --mat-table-footer-container-height: 36px;
  --mat-table-row-item-container-height: 36px;
}
.q--table--sm .mat-mdc-row {
  height: 32px !important;
}

.q--table--hoverable .mat-mdc-row:hover {
  background-color: #f0f0f0 !important;
}

.q--table-pagination {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
.q--table-pagination {
  --mat-icon-button-touch-target-display: none;
}
.q--table-pagination .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: ;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: -24px/2;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #0d5f41;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #698681;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #ed6c02;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #698681;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #698681;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0d5f41;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0d5f41;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #698681;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #698681;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #ed6c02;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ed6c02;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Open Sans;
  --mat-option-label-text-line-height: 21px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.15px;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Open Sans;
  --mat-optgroup-label-text-line-height: 21px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.15px;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #0d5f41;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #698681;
  --mat-badge-text-color: #444444;
}

.mat-badge-warn {
  --mat-badge-background-color: #ed6c02;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Open Sans;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0d5f41;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(13, 95, 65, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(13, 95, 65, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(13, 95, 65, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0d5f41;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(13, 95, 65, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #444444;
  --mat-datepicker-calendar-date-selected-state-background-color: #698681;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(105, 134, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #444444;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(105, 134, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(105, 134, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(105, 134, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ed6c02;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(237, 108, 2, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(237, 108, 2, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(237, 108, 2, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(237, 108, 2, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #698681;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ed6c02;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Open Sans;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans;
  --mat-expansion-container-text-line-height: 21px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.15px;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #0d5f41;
}
.mat-icon.mat-accent {
  --mat-icon-color: #698681;
}
.mat-icon.mat-warn {
  --mat-icon-color: #ed6c02;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Open Sans;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.15px;
  --mat-menu-item-label-text-line-height: 21px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d5f41;
  --mdc-linear-progress-track-color: rgba(13, 95, 65, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #698681;
  --mdc-linear-progress-track-color: rgba(105, 134, 129, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ed6c02;
  --mdc-linear-progress-track-color: rgba(237, 108, 2, 0.25);
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #0d5f41;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #698681;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #ed6c02;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-sort-arrow-color: rgb(117.3, 117.3, 117.3);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0d5f41;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0d5f41;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0d5f41;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #ed6c02;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #ed6c02;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #444444;
  --mat-stepper-header-selected-state-icon-background-color: #698681;
  --mat-stepper-header-selected-state-icon-foreground-color: #444444;
  --mat-stepper-header-done-state-icon-background-color: #698681;
  --mat-stepper-header-done-state-icon-foreground-color: #444444;
  --mat-stepper-header-edit-state-icon-background-color: #698681;
  --mat-stepper-header-edit-state-icon-foreground-color: #444444;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ed6c02;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ed6c02;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ed6c02;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Open Sans;
  --mat-stepper-header-label-text-font: Open Sans;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0d5f41;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0d5f41;
  --mat-tab-header-active-ripple-color: #0d5f41;
  --mat-tab-header-inactive-ripple-color: #0d5f41;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0d5f41;
  --mat-tab-header-active-hover-label-text-color: #0d5f41;
  --mat-tab-header-active-focus-indicator-color: #0d5f41;
  --mat-tab-header-active-hover-indicator-color: #0d5f41;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #698681;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #698681;
  --mat-tab-header-active-ripple-color: #698681;
  --mat-tab-header-inactive-ripple-color: #698681;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #698681;
  --mat-tab-header-active-hover-label-text-color: #698681;
  --mat-tab-header-active-focus-indicator-color: #698681;
  --mat-tab-header-active-hover-indicator-color: #698681;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ed6c02;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ed6c02;
  --mat-tab-header-active-ripple-color: #ed6c02;
  --mat-tab-header-inactive-ripple-color: #ed6c02;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ed6c02;
  --mat-tab-header-active-hover-label-text-color: #ed6c02;
  --mat-tab-header-active-focus-indicator-color: #ed6c02;
  --mat-tab-header-active-hover-indicator-color: #ed6c02;
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0d5f41;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #698681;
  --mat-tab-header-with-background-foreground-color: #444444;
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ed6c02;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.4px;
  --mat-tab-header-label-text-line-height: 24px;
  --mat-tab-header-label-text-weight: 600;
}
html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0d5f41;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #698681;
  --mat-toolbar-container-text-color: #444444;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ed6c02;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Open Sans;
  --mat-toolbar-title-text-line-height: 22px;
  --mat-toolbar-title-text-size: 14px;
  --mat-toolbar-title-text-tracking: 0.15px;
  --mat-toolbar-title-text-weight: 600;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.4px;
}

.qit--dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme {
  --mat-option-selected-state-label-text-color: #fcfcfc;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #fcfcfc;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme {
  --mat-optgroup-label-text-color: white;
}
.qit--dark-theme {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme {
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme {
  --mat-option-selected-state-label-text-color: #fcfcfc;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #fcfcfc;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.qit--dark-theme {
  --mat-optgroup-label-text-color: white;
}
.qit--dark-theme {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #fcfcfc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fcfcfc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.qit--dark-theme .mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.qit--dark-theme {
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-elevated-card-container-shape: 4px;
}
.qit--dark-theme {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
.qit--dark-theme {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.qit--dark-theme {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
.qit--dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #fcfcfc;
  --mdc-linear-progress-track-color: rgba(252, 252, 252, 0.25);
}
.qit--dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #fcfcfc;
  --mdc-linear-progress-track-color: rgba(252, 252, 252, 0.25);
}
.qit--dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
.qit--dark-theme {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}
.qit--dark-theme {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.qit--dark-theme {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
.qit--dark-theme {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
.qit--dark-theme {
  --mdc-filled-text-field-caret-color: #fcfcfc;
  --mdc-filled-text-field-focus-active-indicator-color: #fcfcfc;
  --mdc-filled-text-field-focus-label-text-color: rgba(252, 252, 252, 0.87);
  --mdc-filled-text-field-container-color: rgb(73.56, 73.56, 73.56);
  --mdc-filled-text-field-disabled-container-color: rgb(69.78, 69.78, 69.78);
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
}
.qit--dark-theme {
  --mdc-outlined-text-field-caret-color: #fcfcfc;
  --mdc-outlined-text-field-focus-outline-color: #fcfcfc;
  --mdc-outlined-text-field-focus-label-text-color: rgba(252, 252, 252, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
}
.qit--dark-theme {
  --mat-form-field-focus-select-arrow-color: rgba(252, 252, 252, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
}
.qit--dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #fcfcfc;
  --mdc-filled-text-field-focus-active-indicator-color: #fcfcfc;
  --mdc-filled-text-field-focus-label-text-color: rgba(252, 252, 252, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #fcfcfc;
  --mdc-outlined-text-field-focus-outline-color: #fcfcfc;
  --mdc-outlined-text-field-focus-label-text-color: rgba(252, 252, 252, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(252, 252, 252, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(252, 252, 252, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(252, 252, 252, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.qit--dark-theme {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-autocomplete-background-color: #424242;
}
.qit--dark-theme {
  --mdc-dialog-container-shape: 4px;
}
.qit--dark-theme {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}
.qit--dark-theme {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.qit--dark-theme .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.qit--dark-theme .mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.qit--dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-disabled-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-flat-disabled-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.qit--dark-theme .mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #323232;
  --mdc-chip-elevated-container-color: #fcfcfc;
  --mdc-chip-elevated-selected-container-color: #fcfcfc;
  --mdc-chip-elevated-disabled-container-color: #fcfcfc;
  --mdc-chip-flat-disabled-selected-container-color: #fcfcfc;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #323232;
  --mdc-chip-selected-label-text-color: #323232;
  --mdc-chip-with-icon-icon-color: #323232;
  --mdc-chip-with-icon-disabled-icon-color: #323232;
  --mdc-chip-with-icon-selected-icon-color: #323232;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #323232;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #323232;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: #323232;
  --mat-chip-selected-trailing-icon-color: #323232;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #323232;
  --mdc-chip-elevated-container-color: #fcfcfc;
  --mdc-chip-elevated-selected-container-color: #fcfcfc;
  --mdc-chip-elevated-disabled-container-color: #fcfcfc;
  --mdc-chip-flat-disabled-selected-container-color: #fcfcfc;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #323232;
  --mdc-chip-selected-label-text-color: #323232;
  --mdc-chip-with-icon-icon-color: #323232;
  --mdc-chip-with-icon-disabled-icon-color: #323232;
  --mdc-chip-with-icon-selected-icon-color: #323232;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #323232;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #323232;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: #323232;
  --mat-chip-selected-trailing-icon-color: #323232;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .qit--dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.qit--dark-theme {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
.qit--dark-theme .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.qit--dark-theme {
  --mdc-switch-selected-focus-state-layer-color: #888888;
  --mdc-switch-selected-handle-color: #888888;
  --mdc-switch-selected-hover-state-layer-color: #888888;
  --mdc-switch-selected-pressed-state-layer-color: #888888;
  --mdc-switch-selected-focus-handle-color: #eaeaea;
  --mdc-switch-selected-hover-handle-color: #eaeaea;
  --mdc-switch-selected-pressed-handle-color: #eaeaea;
  --mdc-switch-selected-focus-track-color: #444444;
  --mdc-switch-selected-hover-track-color: #444444;
  --mdc-switch-selected-pressed-track-color: #444444;
  --mdc-switch-selected-track-color: #444444;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
.qit--dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #888888;
  --mdc-switch-selected-handle-color: #888888;
  --mdc-switch-selected-hover-state-layer-color: #888888;
  --mdc-switch-selected-pressed-state-layer-color: #888888;
  --mdc-switch-selected-focus-handle-color: #eaeaea;
  --mdc-switch-selected-hover-handle-color: #eaeaea;
  --mdc-switch-selected-pressed-handle-color: #eaeaea;
  --mdc-switch-selected-focus-track-color: #444444;
  --mdc-switch-selected-hover-track-color: #444444;
  --mdc-switch-selected-pressed-track-color: #444444;
  --mdc-switch-selected-track-color: #444444;
}
.qit--dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.qit--dark-theme {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.qit--dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fcfcfc;
  --mdc-radio-selected-hover-icon-color: #fcfcfc;
  --mdc-radio-selected-icon-color: #fcfcfc;
  --mdc-radio-selected-pressed-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #fcfcfc;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.qit--dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fcfcfc;
  --mdc-radio-selected-hover-icon-color: #fcfcfc;
  --mdc-radio-selected-icon-color: #fcfcfc;
  --mdc-radio-selected-pressed-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #fcfcfc;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.qit--dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.qit--dark-theme .mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.qit--dark-theme {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}
.qit--dark-theme {
  --mdc-slider-handle-color: #fcfcfc;
  --mdc-slider-focus-handle-color: #fcfcfc;
  --mdc-slider-hover-handle-color: #fcfcfc;
  --mdc-slider-active-track-color: #fcfcfc;
  --mdc-slider-inactive-track-color: #fcfcfc;
  --mdc-slider-with-tick-marks-inactive-container-color: #fcfcfc;
  --mdc-slider-with-tick-marks-active-container-color: #323232;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
}
.qit--dark-theme {
  --mat-slider-ripple-color: #fcfcfc;
  --mat-slider-hover-state-layer-color: rgba(252, 252, 252, 0.05);
  --mat-slider-focus-state-layer-color: rgba(252, 252, 252, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
}
.qit--dark-theme .mat-accent {
  --mdc-slider-handle-color: #fcfcfc;
  --mdc-slider-focus-handle-color: #fcfcfc;
  --mdc-slider-hover-handle-color: #fcfcfc;
  --mdc-slider-active-track-color: #fcfcfc;
  --mdc-slider-inactive-track-color: #fcfcfc;
  --mdc-slider-with-tick-marks-inactive-container-color: #fcfcfc;
  --mdc-slider-with-tick-marks-active-container-color: #323232;
}
.qit--dark-theme .mat-accent {
  --mat-slider-ripple-color: #fcfcfc;
  --mat-slider-hover-state-layer-color: rgba(252, 252, 252, 0.05);
  --mat-slider-focus-state-layer-color: rgba(252, 252, 252, 0.2);
}
.qit--dark-theme .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.qit--dark-theme .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}
.qit--dark-theme {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
.qit--dark-theme {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}
.qit--dark-theme {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.qit--dark-theme .mdc-list-item__start,
.qit--dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fcfcfc;
  --mdc-radio-selected-hover-icon-color: #fcfcfc;
  --mdc-radio-selected-icon-color: #fcfcfc;
  --mdc-radio-selected-pressed-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-accent .mdc-list-item__start,
.qit--dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #fcfcfc;
  --mdc-radio-selected-hover-icon-color: #fcfcfc;
  --mdc-radio-selected-icon-color: #fcfcfc;
  --mdc-radio-selected-pressed-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-warn .mdc-list-item__start,
.qit--dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.qit--dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #323232;
  --mdc-checkbox-selected-focus-icon-color: #fcfcfc;
  --mdc-checkbox-selected-hover-icon-color: #fcfcfc;
  --mdc-checkbox-selected-icon-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-icon-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-hover-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-state-layer-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #323232;
  --mdc-checkbox-selected-focus-icon-color: #fcfcfc;
  --mdc-checkbox-selected-hover-icon-color: #fcfcfc;
  --mdc-checkbox-selected-icon-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-icon-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-hover-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-state-layer-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.qit--dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.qit--dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.qit--dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.qit--dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.qit--dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.qit--dark-theme {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.qit--dark-theme {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
.qit--dark-theme {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.qit--dark-theme .mat-mdc-tab-group,
.qit--dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-tab-group,
.qit--dark-theme .mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #fcfcfc;
  --mat-tab-header-active-ripple-color: #fcfcfc;
  --mat-tab-header-inactive-ripple-color: #fcfcfc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fcfcfc;
  --mat-tab-header-active-hover-label-text-color: #fcfcfc;
  --mat-tab-header-active-focus-indicator-color: #fcfcfc;
  --mat-tab-header-active-hover-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-tab-group.mat-accent,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-tab-group.mat-accent,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #fcfcfc;
  --mat-tab-header-active-ripple-color: #fcfcfc;
  --mat-tab-header-inactive-ripple-color: #fcfcfc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fcfcfc;
  --mat-tab-header-active-hover-label-text-color: #fcfcfc;
  --mat-tab-header-active-focus-indicator-color: #fcfcfc;
  --mat-tab-header-active-hover-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-tab-group.mat-warn,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
}
.qit--dark-theme .mat-mdc-tab-group.mat-warn,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.qit--dark-theme .mat-mdc-tab-group.mat-background-primary,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #fcfcfc;
  --mat-tab-header-with-background-foreground-color: #323232;
}
.qit--dark-theme .mat-mdc-tab-group.mat-background-accent,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #fcfcfc;
  --mat-tab-header-with-background-foreground-color: #323232;
}
.qit--dark-theme .mat-mdc-tab-group.mat-background-warn,
.qit--dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.qit--dark-theme {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
.qit--dark-theme {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #323232;
  --mdc-checkbox-selected-focus-icon-color: #fcfcfc;
  --mdc-checkbox-selected-hover-icon-color: #fcfcfc;
  --mdc-checkbox-selected-icon-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-icon-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-hover-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-state-layer-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme {
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
}
.qit--dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #323232;
  --mdc-checkbox-selected-focus-icon-color: #fcfcfc;
  --mdc-checkbox-selected-hover-icon-color: #fcfcfc;
  --mdc-checkbox-selected-icon-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-icon-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-hover-state-layer-color: #fcfcfc;
  --mdc-checkbox-selected-pressed-state-layer-color: #fcfcfc;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.qit--dark-theme {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
.qit--dark-theme {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
.qit--dark-theme {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
.qit--dark-theme {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
.qit--dark-theme {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
.qit--dark-theme {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
.qit--dark-theme {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}
.qit--dark-theme {
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme {
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
}
.qit--dark-theme {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
}
.qit--dark-theme {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
}
.qit--dark-theme {
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme {
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
}
.qit--dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #fcfcfc;
  --mat-text-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #fcfcfc;
  --mat-text-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.qit--dark-theme .mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #fcfcfc;
  --mdc-filled-button-label-text-color: #323232;
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: #323232;
  --mat-filled-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #fcfcfc;
  --mdc-filled-button-label-text-color: #323232;
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: #323232;
  --mat-filled-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.qit--dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #fcfcfc;
  --mdc-protected-button-label-text-color: #323232;
}
.qit--dark-theme .mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: #323232;
  --mat-protected-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #fcfcfc;
  --mdc-protected-button-label-text-color: #323232;
}
.qit--dark-theme .mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: #323232;
  --mat-protected-button-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.qit--dark-theme .mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #fcfcfc;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #fcfcfc;
  --mat-outlined-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #fcfcfc;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #fcfcfc;
  --mat-outlined-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.qit--dark-theme {
  --mdc-icon-button-icon-size: 24px;
}
.qit--dark-theme {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme {
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
}
.qit--dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #fcfcfc;
  --mat-icon-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #fcfcfc;
  --mat-icon-button-ripple-color: rgba(252, 252, 252, 0.1);
}
.qit--dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.qit--dark-theme .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.qit--dark-theme {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mdc-fab-container-color: #424242;
}
.qit--dark-theme {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme {
  --mdc-fab-small-container-color: #424242;
}
.qit--dark-theme {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: #323232;
  --mat-fab-state-layer-color: #323232;
  --mat-fab-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: #323232;
  --mat-fab-state-layer-color: #323232;
  --mat-fab-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
}
.qit--dark-theme .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: #323232;
  --mat-fab-small-state-layer-color: #323232;
  --mat-fab-small-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #fcfcfc;
}
.qit--dark-theme .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: #323232;
  --mat-fab-small-state-layer-color: #323232;
  --mat-fab-small-ripple-color: rgba(50, 50, 50, 0.1);
}
.qit--dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
}
.qit--dark-theme .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.qit--dark-theme {
  --mdc-snackbar-container-shape: 4px;
}
.qit--dark-theme {
  --mdc-snackbar-container-color: rgb(217.2, 217.2, 217.2);
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
.qit--dark-theme {
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.qit--dark-theme {
  --mat-table-row-item-outline-width: 1px;
}
.qit--dark-theme {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
.qit--dark-theme {
  --mdc-circular-progress-active-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-accent {
  --mdc-circular-progress-active-indicator-color: #fcfcfc;
}
.qit--dark-theme .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.qit--dark-theme {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}
.qit--dark-theme {
  --mat-badge-background-color: #fcfcfc;
  --mat-badge-text-color: #323232;
  --mat-badge-disabled-state-background-color: rgb(110.1, 110.1, 110.1);
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}
.qit--dark-theme .mat-badge-accent {
  --mat-badge-background-color: #fcfcfc;
  --mat-badge-text-color: #323232;
}
.qit--dark-theme .mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}
.qit--dark-theme {
  --mat-bottom-sheet-container-shape: 4px;
}
.qit--dark-theme {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}
.qit--dark-theme {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
.qit--dark-theme {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}
.qit--dark-theme {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
}
.qit--dark-theme {
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: rgb(88.68, 88.68, 88.68);
}
.qit--dark-theme {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-datepicker-calendar-date-selected-state-text-color: #323232;
  --mat-datepicker-calendar-date-selected-state-background-color: #fcfcfc;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(252, 252, 252, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #323232;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(252, 252, 252, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(252, 252, 252, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #fcfcfc;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(252, 252, 252, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}
.qit--dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #323232;
  --mat-datepicker-calendar-date-selected-state-background-color: #fcfcfc;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(252, 252, 252, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #323232;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(252, 252, 252, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(252, 252, 252, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(252, 252, 252, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.qit--dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.qit--dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.qit--dark-theme {
  --mat-divider-width: 1px;
}
.qit--dark-theme {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}
.qit--dark-theme {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}
.qit--dark-theme {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}
.qit--dark-theme {
  --mat-icon-color: inherit;
}
.qit--dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-icon.mat-accent {
  --mat-icon-color: #fcfcfc;
}
.qit--dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.qit--dark-theme {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}
.qit--dark-theme {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}
.qit--dark-theme {
  --mat-stepper-header-icon-foreground-color: #323232;
  --mat-stepper-header-selected-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-selected-state-icon-foreground-color: #323232;
  --mat-stepper-header-done-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-done-state-icon-foreground-color: #323232;
  --mat-stepper-header-edit-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-edit-state-icon-foreground-color: #323232;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.qit--dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #323232;
  --mat-stepper-header-selected-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-selected-state-icon-foreground-color: #323232;
  --mat-stepper-header-done-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-done-state-icon-foreground-color: #323232;
  --mat-stepper-header-edit-state-icon-background-color: #fcfcfc;
  --mat-stepper-header-edit-state-icon-foreground-color: #323232;
}
.qit--dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.qit--dark-theme {
  --mat-sort-arrow-color: rgb(198.3, 198.3, 198.3);
}
.qit--dark-theme {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}
.qit--dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #fcfcfc;
  --mat-toolbar-container-text-color: #323232;
}
.qit--dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #fcfcfc;
  --mat-toolbar-container-text-color: #323232;
}
.qit--dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}
.qit--dark-theme {
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
.qit--dark-theme {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.qit--dark-theme {
  --mat-timepicker-container-background-color: #424242;
}

:not(:root):fullscreen::backdrop {
  color: var(--qit-secondary-color);
  background-color: var(--qit-primary-color);
}

/**
 * @license Highcharts
 *
 * (c) 2009-2022 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
:root,
.highcharts-light {
  /* Colors for data series and points */
  --highcharts-color-0: #2caffe;
  --highcharts-color-1: #544fc5;
  --highcharts-color-2: #00e272;
  --highcharts-color-3: #fe6a35;
  --highcharts-color-4: #6b8abc;
  --highcharts-color-5: #d568fb;
  --highcharts-color-6: #2ee0ca;
  --highcharts-color-7: #fa4b42;
  --highcharts-color-8: #feb56a;
  --highcharts-color-9: #91e8e1;
  /* Chart background, point stroke for markers and columns etc */
  --highcharts-background-color: #ffffff;
  /*
  Neutral colors, grayscale by default. The default colors are defined by
  mixing the background-color with neutral, with a weight corresponding to
  the number in the name.

  https://www.highcharts.com/samples/highcharts/css/palette-helper
  */
  /* Strong text. */
  --highcharts-neutral-color-100: #000000;
  /* Main text, axis labels and some strokes. */
  --highcharts-neutral-color-80: #333333;
  /* Axis title, connector fallback. */
  --highcharts-neutral-color-60: #666666;
  /* Credits text, export menu stroke. */
  --highcharts-neutral-color-40: #999999;
  /* Disabled texts, button strokes, crosshair etc. */
  --highcharts-neutral-color-20: #cccccc;
  /* Grid lines etc. */
  --highcharts-neutral-color-10: #e6e6e6;
  /* Minor grid lines etc. */
  --highcharts-neutral-color-5: #f2f2f2;
  /* Tooltip backgroud, button fills, map null points. */
  --highcharts-neutral-color-3: #f7f7f7;
  /*
  Highlights, shades of blue by default
  */
  /* Drilldown clickable labels, color axis max color. */
  --highcharts-highlight-color-100: #0022ff;
  /* Selection marker, menu hover, button hover, chart border, navigator
  series. */
  --highcharts-highlight-color-80: #334eff;
  /* Navigator mask fill. */
  --highcharts-highlight-color-60: #667aff;
  /* Ticks and axis line. */
  --highcharts-highlight-color-20: #ccd3ff;
  /* Pressed button, color axis min color. */
  --highcharts-highlight-color-10: #e6e9ff;
  /* Indicators */
  --highcharts-positive-color: #06b535;
  --highcharts-negative-color: #f21313;
  /* Transparent colors for annotations */
  --highcharts-annotation-color-0: rgba(130, 170, 255, 0.4);
  --highcharts-annotation-color-1: rgba(139, 191, 216, 0.4);
  --highcharts-annotation-color-2: rgba(150, 216, 192, 0.4);
  --highcharts-annotation-color-3: rgba(156, 229, 161, 0.4);
  --highcharts-annotation-color-4: rgba(162, 241, 130, 0.4);
  --highcharts-annotation-color-5: rgba(169, 255, 101, 0.4);
}

@media (prefers-color-scheme: dark) {
  :root {
    /* UI colors */
    --highcharts-background-color: rgb(48, 48, 48);
    /*
        Neutral color variations
        https://www.highcharts.com/samples/highcharts/css/palette-helper
    */
    --highcharts-neutral-color-100: rgb(255, 255, 255);
    --highcharts-neutral-color-80: rgb(214, 214, 214);
    --highcharts-neutral-color-60: rgb(173, 173, 173);
    --highcharts-neutral-color-40: rgb(133, 133, 133);
    --highcharts-neutral-color-20: rgb(92, 92, 92);
    --highcharts-neutral-color-10: rgb(71, 71, 71);
    --highcharts-neutral-color-5: rgb(61, 61, 61);
    --highcharts-neutral-color-3: rgb(57, 57, 57);
    /* Highlight color variations */
    --highcharts-highlight-color-100: rgb(122, 167, 255);
    --highcharts-highlight-color-80: rgb(108, 144, 214);
    --highcharts-highlight-color-60: rgb(94, 121, 173);
    --highcharts-highlight-color-20: rgb(65, 74, 92);
    --highcharts-highlight-color-10: rgb(58, 63, 71);
  }
}
.highcharts-dark {
  /* UI colors */
  --highcharts-background-color: rgb(48, 48, 48);
  /* Neutral color variations */
  --highcharts-neutral-color-100: rgb(255, 255, 255);
  --highcharts-neutral-color-80: rgb(214, 214, 214);
  --highcharts-neutral-color-60: rgb(173, 173, 173);
  --highcharts-neutral-color-40: rgb(133, 133, 133);
  --highcharts-neutral-color-20: rgb(92, 92, 92);
  --highcharts-neutral-color-10: rgb(71, 71, 71);
  --highcharts-neutral-color-5: rgb(61, 61, 61);
  --highcharts-neutral-color-3: rgb(57, 57, 57);
  /* Highlight color variations */
  --highcharts-highlight-color-100: rgb(122, 167, 255);
  --highcharts-highlight-color-80: rgb(108, 144, 214);
  --highcharts-highlight-color-60: rgb(94, 121, 173);
  --highcharts-highlight-color-20: rgb(65, 74, 92);
  --highcharts-highlight-color-10: rgb(58, 63, 71);
}

.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0; /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 1rem;
  user-select: none;
  touch-action: manipulation;
  outline: none;
}

.highcharts-no-touch-action {
  touch-action: none;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: bold;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: var(--highcharts-background-color);
}

.highcharts-plot-border,
.highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-label text {
  fill: var(--highcharts-neutral-color-80);
  font-size: 0.8em;
}

.highcharts-button-box {
  fill: inherit;
}

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}

.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}

/* Titles */
.highcharts-title {
  fill: var(--highcharts-neutral-color-80);
  font-size: 1.2em;
  font-weight: bold;
}

.highcharts-subtitle {
  fill: var(--highcharts-neutral-color-60);
  font-size: 0.8em;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: var(--highcharts-neutral-color-80);
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: var(--highcharts-neutral-color-60);
  font-size: 0.8em;
}

.highcharts-axis-labels {
  fill: var(--highcharts-neutral-color-80);
  cursor: default;
  font-size: 0.8em;
}

.highcharts-grid-line {
  fill: none;
  stroke: var(--highcharts-neutral-color-10);
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-tick {
  stroke: var(--highcharts-neutral-color-80);
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: var(--highcharts-neutral-color-5);
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-20);
}

.highcharts-crosshair-category {
  stroke: var(--highcharts-highlight-color-20);
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: var(--highcharts-neutral-color-40);
  font-size: 0.6em;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: var(--highcharts-neutral-color-100);
  font-size: 0.7em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}

.highcharts-tooltip .highcharts-tracker {
  fill: none;
  stroke: none;
}

.highcharts-tooltip text {
  fill: var(--highcharts-neutral-color-80);
  font-size: 0.8em;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.8em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: var(--highcharts-background-color);
}

.highcharts-tooltip-box {
  stroke-width: 0;
  fill: var(--highcharts-background-color);
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: var(--highcharts-background-color);
}

div.highcharts-tooltip {
  filter: none;
  font-size: 0.8em;
}

.highcharts-selection-marker {
  fill: var(--highcharts-highlight-color-80);
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-empty-series {
  stroke-width: 1px;
  fill: none;
  stroke: var(--highcharts-neutral-color-20);
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-point-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-series-inactive {
  opacity: 0.2;
  transition: opacity 50ms; /* quick in */
}

.highcharts-state-hover path {
  transition: stroke-width 50ms; /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms; /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover, .highcharts-point-select),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: var(--highcharts-color-0);
  stroke: var(--highcharts-color-0);
}

.highcharts-color-1 {
  fill: var(--highcharts-color-1);
  stroke: var(--highcharts-color-1);
}

.highcharts-color-2 {
  fill: var(--highcharts-color-2);
  stroke: var(--highcharts-color-2);
}

.highcharts-color-3 {
  fill: var(--highcharts-color-3);
  stroke: var(--highcharts-color-3);
}

.highcharts-color-4 {
  fill: var(--highcharts-color-4);
  stroke: var(--highcharts-color-4);
}

.highcharts-color-5 {
  fill: var(--highcharts-color-5);
  stroke: var(--highcharts-color-5);
}

.highcharts-color-6 {
  fill: var(--highcharts-color-6);
  stroke: var(--highcharts-color-6);
}

.highcharts-color-7 {
  fill: var(--highcharts-color-7);
  stroke: var(--highcharts-color-7);
}

.highcharts-color-8 {
  fill: var(--highcharts-color-8);
  stroke: var(--highcharts-color-8);
}

.highcharts-color-9 {
  fill: var(--highcharts-color-9);
  stroke: var(--highcharts-color-9);
}

/* Various series-specific */
.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  stroke: var(--highcharts-background-color);
}

.highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover, .highcharts-a11y-marker-visible),
.highcharts-a11y-marker-hidden {
  opacity: 0;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
  font-size: 0.7em;
  font-weight: bold;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
  fill: var(--highcharts-neutral-color-80);
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-data-label-hidden {
  pointer-events: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series-label text {
  fill: inherit;
  font-weight: bold;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: var(--highcharts-neutral-color-20);
  stroke: var(--highcharts-neutral-color-100);
}

.highcharts-column-series path.highcharts-point,
.highcharts-bar-series path.highcharts-point {
  /* path to prevent stroke on 3D columns and bars */
  stroke: var(--highcharts-background-color);
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--highcharts-background-color);
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--highcharts-background-color);
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: var(--highcharts-background-color);
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-10);
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: var(--highcharts-neutral-color-40);
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}

.highcharts-lollipop-stem {
  stroke: var(--highcharts-neutral-color-100);
}

.highcharts-focus-border {
  fill: none;
  stroke-width: 2px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item > text {
  fill: var(--highcharts-neutral-color-80);
  font-weight: bold;
  font-size: 0.8em;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: var(--highcharts-neutral-color-100);
}

.highcharts-legend-item-hidden * {
  fill: var(--highcharts-neutral-color-60) !important;
  stroke: var(--highcharts-neutral-color-60) !important;
  transition: fill 250ms;
  text-decoration: line-through;
}

.highcharts-legend-nav-active {
  fill: var(--highcharts-highlight-color-100);
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: var(--highcharts-neutral-color-20);
}

circle.highcharts-legend-nav-active,
circle.highcharts-legend-nav-inactive { /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}

.highcharts-bubble-legend-labels {
  fill: var(--highcharts-neutral-color-80);
  font-size: 0.7em;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: var(--highcharts-background-color);
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band,
.highcharts-pane {
  fill: var(--highcharts-neutral-color-100);
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: var(--highcharts-neutral-color-40);
  stroke-width: 1px;
}

.highcharts-plot-line-label {
  font-size: 0.8em;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: var(--highcharts-background-color);
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: var(--highcharts-neutral-color-100);
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: var(--highcharts-neutral-color-100);
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: var(--highcharts-neutral-color-80);
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: var(--highcharts-neutral-color-20);
  transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: var(--highcharts-neutral-color-20);
}

.highcharts-timeline-series .highcharts-graph {
  stroke: var(--highcharts-neutral-color-20);
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: var(--highcharts-highlight-color-60); /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: var(--highcharts-neutral-color-40);
  fill: none;
}

.highcharts-navigator-handle {
  stroke: var(--highcharts-neutral-color-40);
  fill: var(--highcharts-neutral-color-5);
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: var(--highcharts-highlight-color-80);
  stroke: var(--highcharts-highlight-color-80);
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-10);
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: var(--highcharts-neutral-color-100);
  font-size: 0.7em;
  opacity: 0.6;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: var(--highcharts-neutral-color-20);
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 0;
}

.highcharts-scrollbar-button {
  fill: var(--highcharts-neutral-color-10);
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: var(--highcharts-neutral-color-60);
}

.highcharts-scrollbar-rifles {
  stroke: none;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: rgba(255, 255, 255, 0.001);
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
}

.highcharts-button {
  fill: var(--highcharts-neutral-color-3);
  stroke: var(--highcharts-neutral-color-20);
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: var(--highcharts-neutral-color-80);
  font-size: 0.8em;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: var(--highcharts-neutral-color-10);
  stroke: var(--highcharts-neutral-color-20);
}

.highcharts-button-hover text {
  fill: var(--highcharts-neutral-color-80);
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: var(--highcharts-highlight-color-10);
  stroke: var(--highcharts-neutral-color-20);
}

.highcharts-button-pressed text {
  fill: var(--highcharts-neutral-color-80);
  font-weight: bold;
}

.highcharts-button-disabled text {
  fill: var(--highcharts-neutral-color-80);
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: var(--highcharts-neutral-color-60);
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: var(--highcharts-neutral-color-80);
  font-size: 0.8em;
}

.highcharts-range-input {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-20);
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px; /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em; /* #4798 */
}

.highcharts-crosshair-label text {
  fill: var(--highcharts-background-color);
  font-size: 1.7em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: var(--highcharts-neutral-color-100);
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: var(--highcharts-background-color);
}

.highcharts-renko-series .highcharts-point-down,
.highcharts-hollowcandlestick-series .highcharts-point-down {
  fill: var(--highcharts-negative-color);
  stroke: var(--highcharts-negative-color);
}

.highcharts-renko-series .highcharts-point-up,
.highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
  fill: var(--highcharts-positive-color);
  stroke: var(--highcharts-positive-color);
}

.highcharts-hollowcandlestick-series .highcharts-point-up {
  fill: transparent;
  stroke: var(--highcharts-positive-color);
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: var(--highcharts-neutral-color-40);
  fill: var(--highcharts-background-color);
  transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: var(--highcharts-neutral-color-100);
  fill: var(--highcharts-highlight-color-20);
}

.highcharts-flags-series .highcharts-point text {
  fill: var(--highcharts-neutral-color-100);
  font-size: 0.9em;
  font-weight: bold;
}

/* Highcharts Maps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: inherit;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.highcharts-map-navigation.highcharts-button {
  fill: var(--highcharts-background-color);
  stroke: var(--highcharts-neutral-color-10);
}

.highcharts-map-navigation.highcharts-button:hover {
  fill: var(--highcharts-neutral-color-10);
}

.highcharts-map-navigation.highcharts-button .highcharts-button-symbol {
  stroke-width: 2px;
}

.highcharts-mapview-inset-border {
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
  fill: none;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: var(--highcharts-neutral-color-40);
}

.highcharts-null-point {
  fill: var(--highcharts-neutral-color-3);
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  /* Fill is needed to capture hover */
  fill: var(--highcharts-background-color);
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: var(--highcharts-neutral-color-10);
  stroke: var(--highcharts-neutral-color-10);
}

.highcharts-button-symbol {
  stroke: var(--highcharts-neutral-color-60);
  stroke-width: 3px;
}

.highcharts-menu {
  border: none;
  background: var(--highcharts-background-color);
  border-radius: 3px;
  padding: 0.5em;
  box-shadow: 3px 3px 10px #888888;
}

.highcharts-menu-item {
  background: none;
  border-radius: 3px;
  color: var(--highcharts-neutral-color-80);
  cursor: pointer;
  font-size: 0.8em;
  list-style-type: none;
  padding: 0.5em;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: var(--highcharts-neutral-color-5);
}

/* Breadcrumbs */
.highcharts-breadcrumbs-button {
  fill: none;
  stroke-width: 0;
  cursor: pointer;
}

.highcharts-breadcrumbs-separator {
  fill: var(--highcharts-neutral-color-60);
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: var(--highcharts-highlight-color-100);
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 0.8em;
  fill: var(--highcharts-neutral-color-60);
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: var(--highcharts-neutral-color-100);
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-80);
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-80);
}

/* Advanced annotations module */
.highcharts-fibonacci-background-0,
.highcharts-pitchfork-inner-background,
.highcharts-measure-background {
  fill: var(--highcharts-annotation-color-0);
}

.highcharts-fibonacci-background-1 {
  fill: var(--highcharts-annotation-color-1);
}

.highcharts-fibonacci-background-2 {
  fill: var(--highcharts-annotation-color-2);
}

.highcharts-fibonacci-background-3,
.highcharts-pitchfork-outer-background {
  fill: var(--highcharts-annotation-color-3);
}

.highcharts-fibonacci-background-4 {
  fill: var(--highcharts-annotation-color-4);
}

.highcharts-fibonacci-background-5 {
  fill: var(--highcharts-annotation-color-5);
}

.highcharts-fibonacci-line {
  stroke: var(--highcharts-neutral-color-40);
}

.highcharts-crooked-lines,
.highcharts-tunnel-lines,
.highcharts-infinity-lines,
.highcharts-timecycles-lines,
.highcharts-fibonacci-timezones-lines,
.highcharts-pitchfork-lines,
.highcharts-vertical-line,
.highcharts-measure-crosshair-x,
.highcharts-measure-crosshair-y {
  stroke: var(--highcharts-neutral-color-100);
  stroke-opacity: 0.75;
  fill: none;
}

.highcharts-measure-crosshair-x,
.highcharts-measure-crosshair-y {
  stroke-dasharray: 1, 3;
}

.highcharts-tunnel-background {
  fill: var(--highcharts-color-0);
}

.highcharts-annotation-shapes {
  cursor: move;
}

.highcharts-basic-shape {
  fill: var(--highcharts-neutral-color-100);
  stroke: var(--highcharts-neutral-color-100);
  opacity: 0.74;
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: var(--highcharts-neutral-color-100);
  fill: var(--highcharts-neutral-color-100);
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: var(--highcharts-neutral-color-10);
  font-size: 0.8em;
}

/* A11y module */
.highcharts-a11y-proxy-element {
  border-width: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  opacity: 0.001;
  z-index: 999;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
}

.highcharts-a11y-proxy-group li {
  list-style: none;
}

.highcharts-visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px, 1px, 1px, 1px);
  margin-top: -3px;
  opacity: 0.01;
}

.highcharts-a11y-invisible {
  visibility: hidden;
}

.highcharts-a11y-proxy-container,
.highcharts-a11y-proxy-container-before,
.highcharts-a11y-proxy-container-after {
  position: absolute;
  white-space: nowrap;
}

g.highcharts-series,
.highcharts-markers,
.highcharts-point {
  outline: none;
}

/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
  cursor: pointer;
}

.highcharts-point-connecting-path {
  fill: none;
}

.highcharts-grid-axis .highcharts-tick {
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
  stroke: var(--highcharts-neutral-color-20);
  stroke-width: 1px;
}

:root,
.qit--light-theme {
  --highcharts-background-color: #fff;
  /* Neutral color variations */
  --highcharts-neutral-color-100: #000;
  --highcharts-neutral-color-80: #333;
  --highcharts-neutral-color-60: #666;
  --highcharts-neutral-color-40: #999;
  --highcharts-neutral-color-20: #ccc;
  --highcharts-neutral-color-10: #e6e6e6;
  --highcharts-neutral-color-5: #f2f2f2;
  --highcharts-neutral-color-3: #f7f7f7;
  /* Highlight color variations */
  --highcharts-highlight-color-100: #02f;
  --highcharts-highlight-color-80: #334eff;
  --highcharts-highlight-color-60: #667aff;
  --highcharts-highlight-color-20: #ccd3ff;
  --highcharts-highlight-color-10: #e6e9ff;
  --highcharts-color-0: #19be81;
  --highcharts-color-1: #ffbb54;
  --highcharts-color-2: #0d5f41;
  --highcharts-color-3: #e84d56;
  --highcharts-color-4: #2f65b0;
  --highcharts-color-5: #82a3d0;
  --highcharts-color-6: #731804;
  --highcharts-color-7: #249c86;
  --highcharts-color-8: #fc9d5d;
  --highcharts-color-9: #857c45;
  --highcharts-color-10: #4f175e;
  --highcharts-color-11: #bf225e;
  --highcharts-color-12: #41e8ca;
  --highcharts-color-13: #b06838;
  --highcharts-color-14: #9882d0;
  --highcharts-color-15: #b0a32f;
  --highcharts-color-16: #1d5135;
  --highcharts-color-17: #c27f29;
  --highcharts-color-18: #59bb86;
  --highcharts-color-19: #f3dec8;
  --highcharts-color-20: #5abb86;
  --highcharts-color-21: #d3913b;
  --highcharts-color-22: #bd4d50;
  --highcharts-color-23: #4aa372;
  --highcharts-color-24: #f1d7bc;
  --highcharts-color-25: #7693bf;
  --highcharts-color-26: #88a3cc;
  --highcharts-color-27: #2b5e43;
  --highcharts-color-28: #eea649;
  --highcharts-color-29: #70bf94;
  --highcharts-color-30: #d7585b;
  --highcharts-color-31: #f3ddc8;
  --highcharts-color-32: #ddba99;
  --highcharts-color-33: #f5e5d4;
}

.qit--dark-theme {
  --highcharts-background-color: #323232;
  /* Neutral color variations */
  --highcharts-neutral-color-100: rgb(255 255 255);
  --highcharts-neutral-color-80: rgb(214 214 214);
  --highcharts-neutral-color-60: rgb(173 173 173);
  --highcharts-neutral-color-40: rgb(133 133 133);
  --highcharts-neutral-color-20: rgb(92 92 92);
  --highcharts-neutral-color-10: rgb(71 71 71);
  --highcharts-neutral-color-5: rgb(61 61 61);
  --highcharts-neutral-color-3: rgb(57 57 57);
  /* Highlight color variations */
  --highcharts-highlight-color-100: rgb(122 167 255);
  --highcharts-highlight-color-80: rgb(108 144 214);
  --highcharts-highlight-color-60: rgb(94 121 173);
  --highcharts-highlight-color-20: rgb(65 74 92);
  --highcharts-highlight-color-10: rgb(58 63 71);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 2px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 0.4vw;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #8a8b8f;
  border-radius: 3px;
}

* {
  scrollbar-color: inherit;
  scrollbar-width: inherit;
}

:root {
  --qit-primary-color: #fff;
  --qit-secondary-color: #444;
  --qit-text-primary: #444;
  --qit-text-secondary: #727272;
  --qit-text-contrast: #fff;
}
:root .qit--dark-theme {
  --qit-primary-color: #323232;
  --qit-secondary-color: #fff;
  --qit-text-primary: #fff;
  --qit-text-secondary: #cdcdcd;
  --qit-text-contrast: #323232;
}

h1,
.q--typography-h1 {
  font-size: 34px !important;
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--secondary,
.q--typography-h1--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--main,
.q--typography-h1--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--disabled,
.q--typography-h1--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--error,
.q--typography-h1--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--warning,
.q--typography-h1--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--info,
.q--typography-h1--info {
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: #132846;
}
h1--success,
.q--typography-h1--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}
h1--contrast,
.q--typography-h1--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.25px;
}

h2,
.q--typography-h2 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--secondary,
.q--typography-h2--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--main,
.q--typography-h2--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--disabled,
.q--typography-h2--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--error,
.q--typography-h2--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--warning,
.q--typography-h2--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--info,
.q--typography-h2--info {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  color: #132846;
}
h2--success,
.q--typography-h2--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}
h2--contrast,
.q--typography-h2--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}

h3,
.q--typography-h3 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--secondary,
.q--typography-h3--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--main,
.q--typography-h3--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--disabled,
.q--typography-h3--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--error,
.q--typography-h3--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--warning,
.q--typography-h3--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--info,
.q--typography-h3--info {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #132846;
}
h3--success,
.q--typography-h3--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}
h3--contrast,
.q--typography-h3--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15px;
}

h4,
.q--typography-h4 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--secondary,
.q--typography-h4--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--main,
.q--typography-h4--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--disabled,
.q--typography-h4--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--error,
.q--typography-h4--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--warning,
.q--typography-h4--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--info,
.q--typography-h4--info {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #132846;
}
h4--success,
.q--typography-h4--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
h4--contrast,
.q--typography-h4--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}

h5,
.q--typography-h5 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--secondary,
.q--typography-h5--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--main,
.q--typography-h5--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--disabled,
.q--typography-h5--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--error,
.q--typography-h5--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--warning,
.q--typography-h5--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--info,
.q--typography-h5--info {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #132846;
}
h5--success,
.q--typography-h5--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h5--contrast,
.q--typography-h5--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}

h6,
.q--typography-h6 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--secondary,
.q--typography-h6--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--main,
.q--typography-h6--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--disabled,
.q--typography-h6--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--error,
.q--typography-h6--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--warning,
.q--typography-h6--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--info,
.q--typography-h6--info {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #132846;
}
h6--success,
.q--typography-h6--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}
h6--contrast,
.q--typography-h6--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15px;
}

.q--typography-avatar {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--info {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #132846;
}
.q--typography-avatar--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}
.q--typography-avatar--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.q--typography-badge {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--info {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
  color: #132846;
}
.q--typography-badge--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}
.q--typography-badge--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
}

.q--typography-body1 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--info {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #132846;
}
.q--typography-body1--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body1--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}

.q--typography-body2 {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--info {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #132846;
}
.q--typography-body2--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.q--typography-body2--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
}

.q--typography-button-l {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--info {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
  color: #132846;
}
.q--typography-button-l--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}
.q--typography-button-l--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.4px;
}

.q--typography-button-m {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--info {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #132846;
}
.q--typography-button-m--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.q--typography-button-m--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.q--typography-button-s {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--info {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #132846;
}
.q--typography-button-s--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.q--typography-button-s--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.q--typography-caption {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--info {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #132846;
}
.q--typography-caption--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.q--typography-caption--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.q--typography-chip {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--info {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #132846;
}
.q--typography-chip--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}
.q--typography-chip--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
}

.q--typography-helper-text {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--info {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #132846;
}
.q--typography-helper-text--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}
.q--typography-helper-text--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.q--typography-input-label {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--info {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: #132846;
}
.q--typography-input-label--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}
.q--typography-input-label--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
}

.q--typography-input-text {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--info {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #132846;
}
.q--typography-input-text--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-input-text--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.q--typography-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--info {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #132846;
}
.q--typography-link--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.q--typography-link--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.q--typography-overline {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--info {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #132846;
}
.q--typography-overline--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}
.q--typography-overline--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}

.q--typography-tooltip {
  color: var(--qit-text-primary);
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--secondary {
  color: var(--qit-text-secondary);
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--main {
  color: #0d5f41;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--disabled {
  color: #b6b6b6;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--error {
  color: #511715;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--warning {
  color: #64572b;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--info {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
  color: #132846;
}
.q--typography-tooltip--success {
  color: #265443;
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}
.q--typography-tooltip--contrast {
  color: var(--qit-text-contrast);
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fas,
.fass,
.far,
.fasr,
.fal,
.fasl,
.fat,
.fast,
.fad,
.fadr,
.fadl,
.fadt,
.fasds,
.fasdr,
.fasdl,
.fasdt,
.fab,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas::before,
.fass::before,
.far::before,
.fasr::before,
.fal::before,
.fasl::before,
.fat::before,
.fast::before,
.fad::before,
.fadr::before,
.fadl::before,
.fadt::before,
.fasds::before,
.fasdr::before,
.fasdl::before,
.fasdt::before,
.fab::before,
.fa-solid::before,
.fa-regular::before,
.fa-light::before,
.fa-thin::before,
.fa-brands::before,
.fa-classic::before,
.fa-duotone::before,
.fa-sharp::before,
.fa-sharp-duotone::before,
.fa::before {
  content: var(--fa);
}

.fad::after,
.fa-duotone.fa-solid::after,
.fa-duotone::after,
.fadr::after,
.fa-duotone.fa-regular::after,
.fadl::after,
.fa-duotone.fa-light::after,
.fadt::after,
.fa-duotone.fa-thin::after,
.fasds::after,
.fa-sharp-duotone.fa-solid::after,
.fa-sharp-duotone::after,
.fasdr::after,
.fa-sharp-duotone.fa-regular::after,
.fasdl::after,
.fa-sharp-duotone.fa-light::after,
.fasdt::after,
.fa-sharp-duotone.fa-thin::after {
  content: var(--fa--fa);
}

.fa-classic.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fa-sharp {
  font-weight: 900;
}

.fad,
.fa-duotone {
  font-weight: 900;
}

.fasds,
.fa-sharp-duotone {
  font-weight: 900;
}

.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fa-duotone,
.fad,
.fadr,
.fadl,
.fadt {
  font-family: "Font Awesome 6 Duotone";
}

.fa-brands,
.fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-sharp,
.fass,
.fasr,
.fasl,
.fast {
  font-family: "Font Awesome 6 Sharp";
}

.fa-sharp-duotone,
.fasds,
.fasdr,
.fasdl,
.fasdt {
  font-family: "Font Awesome 6 Sharp Duotone";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0 {
  --fa: "\30 ";
  --fa--fa: "\30 \30 ";
}

.fa-1 {
  --fa: "\31 ";
  --fa--fa: "\31 \31 ";
}

.fa-2 {
  --fa: "\32 ";
  --fa--fa: "\32 \32 ";
}

.fa-3 {
  --fa: "\33 ";
  --fa--fa: "\33 \33 ";
}

.fa-4 {
  --fa: "\34 ";
  --fa--fa: "\34 \34 ";
}

.fa-5 {
  --fa: "\35 ";
  --fa--fa: "\35 \35 ";
}

.fa-6 {
  --fa: "\36 ";
  --fa--fa: "\36 \36 ";
}

.fa-7 {
  --fa: "\37 ";
  --fa--fa: "\37 \37 ";
}

.fa-8 {
  --fa: "\38 ";
  --fa--fa: "\38 \38 ";
}

.fa-9 {
  --fa: "\39 ";
  --fa--fa: "\39 \39 ";
}

.fa-fill-drip {
  --fa: "\f576";
  --fa--fa: "\f576\f576";
}

.fa-arrows-to-circle {
  --fa: "\e4bd";
  --fa--fa: "\e4bd\e4bd";
}

.fa-circle-chevron-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138";
}

.fa-chevron-circle-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138";
}

.fa-wagon-covered {
  --fa: "\f8ee";
  --fa--fa: "\f8ee\f8ee";
}

.fa-line-height {
  --fa: "\f871";
  --fa--fa: "\f871\f871";
}

.fa-bagel {
  --fa: "\e3d7";
  --fa--fa: "\e3d7\e3d7";
}

.fa-transporter-7 {
  --fa: "\e2a8";
  --fa--fa: "\e2a8\e2a8";
}

.fa-at {
  --fa: "\@";
  --fa--fa: "\@\@";
}

.fa-rectangles-mixed {
  --fa: "\e323";
  --fa--fa: "\e323\e323";
}

.fa-phone-arrow-up-right {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-phone-arrow-up {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-phone-outgoing {
  --fa: "\e224";
  --fa--fa: "\e224\e224";
}

.fa-trash-can {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed";
}

.fa-trash-alt {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed";
}

.fa-circle-l {
  --fa: "\e114";
  --fa--fa: "\e114\e114";
}

.fa-head-side-goggles {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea";
}

.fa-head-vr {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea";
}

.fa-text-height {
  --fa: "\f034";
  --fa--fa: "\f034\f034";
}

.fa-user-xmark {
  --fa: "\f235";
  --fa--fa: "\f235\f235";
}

.fa-user-times {
  --fa: "\f235";
  --fa--fa: "\f235\f235";
}

.fa-face-hand-yawn {
  --fa: "\e379";
  --fa--fa: "\e379\e379";
}

.fa-gauge-simple-min {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d";
}

.fa-tachometer-slowest {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d";
}

.fa-stethoscope {
  --fa: "\f0f1";
  --fa--fa: "\f0f1\f0f1";
}

.fa-coffin {
  --fa: "\f6c6";
  --fa--fa: "\f6c6\f6c6";
}

.fa-message {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a";
}

.fa-comment-alt {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a";
}

.fa-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e";
}

.fa-bowl-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e";
}

.fa-info {
  --fa: "\f129";
  --fa--fa: "\f129\f129";
}

.fa-robot-astromech {
  --fa: "\e2d2";
  --fa--fa: "\e2d2\e2d2";
}

.fa-ring-diamond {
  --fa: "\e5ab";
  --fa--fa: "\e5ab\e5ab";
}

.fa-fondue-pot {
  --fa: "\e40d";
  --fa--fa: "\e40d\e40d";
}

.fa-theta {
  --fa: "\f69e";
  --fa--fa: "\f69e\f69e";
}

.fa-face-hand-peeking {
  --fa: "\e481";
  --fa--fa: "\e481\e481";
}

.fa-square-user {
  --fa: "\e283";
  --fa--fa: "\e283\e283";
}

.fa-down-left-and-up-right-to-center {
  --fa: "\f422";
  --fa--fa: "\f422\f422";
}

.fa-compress-alt {
  --fa: "\f422";
  --fa--fa: "\f422\f422";
}

.fa-explosion {
  --fa: "\e4e9";
  --fa--fa: "\e4e9\e4e9";
}

.fa-file-lines {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-file-alt {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-file-text {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c";
}

.fa-wave-square {
  --fa: "\f83e";
  --fa--fa: "\f83e\f83e";
}

.fa-ring {
  --fa: "\f70b";
  --fa--fa: "\f70b\f70b";
}

.fa-building-un {
  --fa: "\e4d9";
  --fa--fa: "\e4d9\e4d9";
}

.fa-dice-three {
  --fa: "\f527";
  --fa--fa: "\f527\f527";
}

.fa-tire-pressure-warning {
  --fa: "\f633";
  --fa--fa: "\f633\f633";
}

.fa-wifi-fair {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab";
}

.fa-wifi-2 {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab";
}

.fa-calendar-days {
  --fa: "\f073";
  --fa--fa: "\f073\f073";
}

.fa-calendar-alt {
  --fa: "\f073";
  --fa--fa: "\f073\f073";
}

.fa-mp3-player {
  --fa: "\f8ce";
  --fa--fa: "\f8ce\f8ce";
}

.fa-anchor-circle-check {
  --fa: "\e4aa";
  --fa--fa: "\e4aa\e4aa";
}

.fa-tally-4 {
  --fa: "\e297";
  --fa--fa: "\e297\e297";
}

.fa-rectangle-history {
  --fa: "\e4a2";
  --fa--fa: "\e4a2\e4a2";
}

.fa-building-circle-arrow-right {
  --fa: "\e4d1";
  --fa--fa: "\e4d1\e4d1";
}

.fa-volleyball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f";
}

.fa-volleyball-ball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f";
}

.fa-sun-haze {
  --fa: "\f765";
  --fa--fa: "\f765\f765";
}

.fa-text-size {
  --fa: "\f894";
  --fa--fa: "\f894\f894";
}

.fa-ufo {
  --fa: "\e047";
  --fa--fa: "\e047\e047";
}

.fa-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3";
}

.fa-utensil-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3";
}

.fa-arrows-up-to-line {
  --fa: "\e4c2";
  --fa--fa: "\e4c2\e4c2";
}

.fa-mobile-signal {
  --fa: "\e1ef";
  --fa--fa: "\e1ef\e1ef";
}

.fa-barcode-scan {
  --fa: "\f465";
  --fa--fa: "\f465\f465";
}

.fa-sort-down {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd";
}

.fa-sort-desc {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd";
}

.fa-folder-arrow-down {
  --fa: "\e053";
  --fa--fa: "\e053\e053";
}

.fa-folder-download {
  --fa: "\e053";
  --fa--fa: "\e053\e053";
}

.fa-circle-minus {
  --fa: "\f056";
  --fa--fa: "\f056\f056";
}

.fa-minus-circle {
  --fa: "\f056";
  --fa--fa: "\f056\f056";
}

.fa-face-icicles {
  --fa: "\e37c";
  --fa--fa: "\e37c\e37c";
}

.fa-shovel {
  --fa: "\f713";
  --fa--fa: "\f713\f713";
}

.fa-door-open {
  --fa: "\f52b";
  --fa--fa: "\f52b\f52b";
}

.fa-films {
  --fa: "\e17a";
  --fa--fa: "\e17a\e17a";
}

.fa-right-from-bracket {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5";
}

.fa-sign-out-alt {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5";
}

.fa-face-glasses {
  --fa: "\e377";
  --fa--fa: "\e377\e377";
}

.fa-nfc {
  --fa: "\e1f7";
  --fa--fa: "\e1f7\e1f7";
}

.fa-atom {
  --fa: "\f5d2";
  --fa--fa: "\f5d2\f5d2";
}

.fa-soap {
  --fa: "\e06e";
  --fa--fa: "\e06e\e06e";
}

.fa-icons {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d";
}

.fa-heart-music-camera-bolt {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d";
}

.fa-microphone-lines-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539";
}

.fa-microphone-alt-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539";
}

.fa-closed-captioning-slash {
  --fa: "\e135";
  --fa--fa: "\e135\e135";
}

.fa-calculator-simple {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c";
}

.fa-calculator-alt {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c";
}

.fa-bridge-circle-check {
  --fa: "\e4c9";
  --fa--fa: "\e4c9\e4c9";
}

.fa-sliders-up {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1";
}

.fa-sliders-v {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1";
}

.fa-location-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609";
}

.fa-map-marker-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609";
}

.fa-pump-medical {
  --fa: "\e06a";
  --fa--fa: "\e06a\e06a";
}

.fa-fingerprint {
  --fa: "\f577";
  --fa--fa: "\f577\f577";
}

.fa-ski-boot {
  --fa: "\e3cc";
  --fa--fa: "\e3cc\e3cc";
}

.fa-standard-definition {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a";
}

.fa-rectangle-sd {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a";
}

.fa-h1 {
  --fa: "\f313";
  --fa--fa: "\f313\f313";
}

.fa-hand-point-right {
  --fa: "\f0a4";
  --fa--fa: "\f0a4\f0a4";
}

.fa-magnifying-glass-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689";
}

.fa-search-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689";
}

.fa-message-bot {
  --fa: "\e3b8";
  --fa--fa: "\e3b8\e3b8";
}

.fa-forward-step {
  --fa: "\f051";
  --fa--fa: "\f051\f051";
}

.fa-step-forward {
  --fa: "\f051";
  --fa--fa: "\f051\f051";
}

.fa-face-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8";
}

.fa-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8";
}

.fa-light-ceiling {
  --fa: "\e016";
  --fa--fa: "\e016\e016";
}

.fa-message-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5";
}

.fa-comment-alt-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5";
}

.fa-bowl-scoop {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de";
}

.fa-bowl-shaved-ice {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de";
}

.fa-square-x {
  --fa: "\e286";
  --fa--fa: "\e286\e286";
}

.fa-building-memo {
  --fa: "\e61e";
  --fa--fa: "\e61e\e61e";
}

.fa-utility-pole-double {
  --fa: "\e2c4";
  --fa--fa: "\e2c4\e2c4";
}

.fa-flag-checkered {
  --fa: "\f11e";
  --fa--fa: "\f11e\f11e";
}

.fa-chevrons-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325";
}

.fa-chevron-double-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325";
}

.fa-football {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e";
}

.fa-football-ball {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e";
}

.fa-user-vneck {
  --fa: "\e461";
  --fa--fa: "\e461\e461";
}

.fa-school-circle-exclamation {
  --fa: "\e56c";
  --fa--fa: "\e56c\e56c";
}

.fa-crop {
  --fa: "\f125";
  --fa--fa: "\f125\f125";
}

.fa-angles-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103";
}

.fa-angle-double-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103";
}

.fa-users-rectangle {
  --fa: "\e594";
  --fa--fa: "\e594\e594";
}

.fa-people-roof {
  --fa: "\e537";
  --fa--fa: "\e537\e537";
}

.fa-square-arrow-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b";
}

.fa-arrow-square-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b";
}

.fa-location-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a";
}

.fa-map-marker-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a";
}

.fa-lightbulb-exclamation-on {
  --fa: "\e1ca";
  --fa--fa: "\e1ca\e1ca";
}

.fa-people-line {
  --fa: "\e534";
  --fa--fa: "\e534\e534";
}

.fa-beer-mug-empty {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc";
}

.fa-beer {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc";
}

.fa-carpool {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c";
}

.fa-car-people {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c";
}

.fa-crate-empty {
  --fa: "\e151";
  --fa--fa: "\e151\e151";
}

.fa-diagram-predecessor {
  --fa: "\e477";
  --fa--fa: "\e477\e477";
}

.fa-transporter {
  --fa: "\e042";
  --fa--fa: "\e042\e042";
}

.fa-calendar-circle-user {
  --fa: "\e471";
  --fa--fa: "\e471\e471";
}

.fa-arrow-up-long {
  --fa: "\f176";
  --fa--fa: "\f176\f176";
}

.fa-long-arrow-up {
  --fa: "\f176";
  --fa--fa: "\f176\f176";
}

.fa-person-carry-box {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf";
}

.fa-person-carry {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf";
}

.fa-fire-flame-simple {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a";
}

.fa-burn {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a";
}

.fa-person {
  --fa: "\f183";
  --fa--fa: "\f183\f183";
}

.fa-male {
  --fa: "\f183";
  --fa--fa: "\f183\f183";
}

.fa-laptop {
  --fa: "\f109";
  --fa--fa: "\f109\f109";
}

.fa-file-csv {
  --fa: "\f6dd";
  --fa--fa: "\f6dd\f6dd";
}

.fa-menorah {
  --fa: "\f676";
  --fa--fa: "\f676\f676";
}

.fa-union {
  --fa: "\f6a2";
  --fa--fa: "\f6a2\f6a2";
}

.fa-chevrons-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323";
}

.fa-chevron-double-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323";
}

.fa-circle-heart {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7";
}

.fa-heart-circle {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7";
}

.fa-truck-plane {
  --fa: "\e58f";
  --fa--fa: "\e58f\e58f";
}

.fa-record-vinyl {
  --fa: "\f8d9";
  --fa--fa: "\f8d9\f8d9";
}

.fa-bring-forward {
  --fa: "\f856";
  --fa--fa: "\f856\f856";
}

.fa-square-p {
  --fa: "\e279";
  --fa--fa: "\e279\e279";
}

.fa-face-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587";
}

.fa-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587";
}

.fa-sigma {
  --fa: "\f68b";
  --fa--fa: "\f68b\f68b";
}

.fa-camera-movie {
  --fa: "\f8a9";
  --fa--fa: "\f8a9\f8a9";
}

.fa-bong {
  --fa: "\f55c";
  --fa--fa: "\f55c\f55c";
}

.fa-clarinet {
  --fa: "\f8ad";
  --fa--fa: "\f8ad\f8ad";
}

.fa-truck-flatbed {
  --fa: "\e2b6";
  --fa--fa: "\e2b6\e2b6";
}

.fa-spaghetti-monster-flying {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b";
}

.fa-pastafarianism {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b";
}

.fa-arrow-down-up-across-line {
  --fa: "\e4af";
  --fa--fa: "\e4af\e4af";
}

.fa-arrows-rotate-reverse {
  --fa: "\e630";
  --fa--fa: "\e630\e630";
}

.fa-leaf-heart {
  --fa: "\f4cb";
  --fa--fa: "\f4cb\f4cb";
}

.fa-house-building {
  --fa: "\e1b1";
  --fa--fa: "\e1b1\e1b1";
}

.fa-cheese-swiss {
  --fa: "\f7f0";
  --fa--fa: "\f7f0\f7f0";
}

.fa-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5";
}

.fa-utensil-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5";
}

.fa-jar-wheat {
  --fa: "\e517";
  --fa--fa: "\e517\e517";
}

.fa-envelopes-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674";
}

.fa-mail-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674";
}

.fa-file-circle-exclamation {
  --fa: "\e4eb";
  --fa--fa: "\e4eb\e4eb";
}

.fa-bow-arrow {
  --fa: "\f6b9";
  --fa--fa: "\f6b9\f6b9";
}

.fa-cart-xmark {
  --fa: "\e0dd";
  --fa--fa: "\e0dd\e0dd";
}

.fa-hexagon-xmark {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-times-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-xmark-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee";
}

.fa-circle-h {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e";
}

.fa-hospital-symbol {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e";
}

.fa-merge {
  --fa: "\e526";
  --fa--fa: "\e526\e526";
}

.fa-pager {
  --fa: "\f815";
  --fa--fa: "\f815\f815";
}

.fa-cart-minus {
  --fa: "\e0db";
  --fa--fa: "\e0db\e0db";
}

.fa-address-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9";
}

.fa-contact-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9";
}

.fa-pan-frying {
  --fa: "\e42c";
  --fa--fa: "\e42c\e42c";
}

.fa-grid {
  --fa: "\e195";
  --fa--fa: "\e195\e195";
}

.fa-grid-3 {
  --fa: "\e195";
  --fa--fa: "\e195\e195";
}

.fa-football-helmet {
  --fa: "\f44f";
  --fa--fa: "\f44f\f44f";
}

.fa-hand-love {
  --fa: "\e1a5";
  --fa--fa: "\e1a5\e1a5";
}

.fa-trees {
  --fa: "\f724";
  --fa--fa: "\f724\f724";
}

.fa-strikethrough {
  --fa: "\f0cc";
  --fa--fa: "\f0cc\f0cc";
}

.fa-page {
  --fa: "\e428";
  --fa--fa: "\e428\e428";
}

.fa-k {
  --fa: "K";
  --fa--fa: "KK";
}

.fa-diagram-previous {
  --fa: "\e478";
  --fa--fa: "\e478\e478";
}

.fa-gauge-min {
  --fa: "\f628";
  --fa--fa: "\f628\f628";
}

.fa-tachometer-alt-slowest {
  --fa: "\f628";
  --fa--fa: "\f628\f628";
}

.fa-folder-grid {
  --fa: "\e188";
  --fa--fa: "\e188\e188";
}

.fa-eggplant {
  --fa: "\e16c";
  --fa--fa: "\e16c\e16c";
}

.fa-excavator {
  --fa: "\e656";
  --fa--fa: "\e656\e656";
}

.fa-ram {
  --fa: "\f70a";
  --fa--fa: "\f70a\f70a";
}

.fa-landmark-flag {
  --fa: "\e51c";
  --fa--fa: "\e51c\e51c";
}

.fa-lips {
  --fa: "\f600";
  --fa--fa: "\f600\f600";
}

.fa-pencil {
  --fa: "\f303";
  --fa--fa: "\f303\f303";
}

.fa-pencil-alt {
  --fa: "\f303";
  --fa--fa: "\f303\f303";
}

.fa-backward {
  --fa: "\f04a";
  --fa--fa: "\f04a\f04a";
}

.fa-caret-right {
  --fa: "\f0da";
  --fa--fa: "\f0da\f0da";
}

.fa-comments {
  --fa: "\f086";
  --fa--fa: "\f086\f086";
}

.fa-paste {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea";
}

.fa-file-clipboard {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea";
}

.fa-desktop-arrow-down {
  --fa: "\e155";
  --fa--fa: "\e155\e155";
}

.fa-code-pull-request {
  --fa: "\e13c";
  --fa--fa: "\e13c\e13c";
}

.fa-pumpkin {
  --fa: "\f707";
  --fa--fa: "\f707\f707";
}

.fa-clipboard-list {
  --fa: "\f46d";
  --fa--fa: "\f46d\f46d";
}

.fa-pen-field {
  --fa: "\e211";
  --fa--fa: "\e211\e211";
}

.fa-chart-sine {
  --fa: "\e69d";
  --fa--fa: "\e69d\e69d";
}

.fa-blueberries {
  --fa: "\e2e8";
  --fa--fa: "\e2e8\e2e8";
}

.fa-truck-ramp-box {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de";
}

.fa-truck-loading {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de";
}

.fa-note {
  --fa: "\e1ff";
  --fa--fa: "\e1ff\e1ff";
}

.fa-arrow-down-to-square {
  --fa: "\e096";
  --fa--fa: "\e096\e096";
}

.fa-user-check {
  --fa: "\f4fc";
  --fa--fa: "\f4fc\f4fc";
}

.fa-cloud-xmark {
  --fa: "\e35f";
  --fa--fa: "\e35f\e35f";
}

.fa-vial-virus {
  --fa: "\e597";
  --fa--fa: "\e597\e597";
}

.fa-book-blank {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9";
}

.fa-book-alt {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9";
}

.fa-golf-flag-hole {
  --fa: "\e3ac";
  --fa--fa: "\e3ac\e3ac";
}

.fa-message-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db";
}

.fa-comment-alt-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db";
}

.fa-face-unamused {
  --fa: "\e39f";
  --fa--fa: "\e39f\e39f";
}

.fa-sheet-plastic {
  --fa: "\e571";
  --fa--fa: "\e571\e571";
}

.fa-circle-9 {
  --fa: "\e0f6";
  --fa--fa: "\e0f6\e0f6";
}

.fa-blog {
  --fa: "\f781";
  --fa--fa: "\f781\f781";
}

.fa-user-ninja {
  --fa: "\f504";
  --fa--fa: "\f504\f504";
}

.fa-pencil-slash {
  --fa: "\e215";
  --fa--fa: "\e215\e215";
}

.fa-bowling-pins {
  --fa: "\f437";
  --fa--fa: "\f437\f437";
}

.fa-person-arrow-up-from-line {
  --fa: "\e539";
  --fa--fa: "\e539\e539";
}

.fa-down-right {
  --fa: "\e16b";
  --fa--fa: "\e16b\e16b";
}

.fa-scroll-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0";
}

.fa-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0";
}

.fa-webhook {
  --fa: "\e5d5";
  --fa--fa: "\e5d5\e5d5";
}

.fa-blinds-open {
  --fa: "\f8fc";
  --fa--fa: "\f8fc\f8fc";
}

.fa-fence {
  --fa: "\e303";
  --fa--fa: "\e303\e303";
}

.fa-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357";
}

.fa-arrow-alt-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357";
}

.fa-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-quidditch {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-quidditch-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458";
}

.fa-drumstick {
  --fa: "\f6d6";
  --fa--fa: "\f6d6\f6d6";
}

.fa-square-v {
  --fa: "\e284";
  --fa--fa: "\e284\e284";
}

.fa-face-awesome {
  --fa: "\e409";
  --fa--fa: "\e409\e409";
}

.fa-gave-dandy {
  --fa: "\e409";
  --fa--fa: "\e409\e409";
}

.fa-dial-off {
  --fa: "\e162";
  --fa--fa: "\e162\e162";
}

.fa-toggle-off {
  --fa: "\f204";
  --fa--fa: "\f204\f204";
}

.fa-face-smile-horns {
  --fa: "\e391";
  --fa--fa: "\e391\e391";
}

.fa-box-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187";
}

.fa-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187";
}

.fa-grapes {
  --fa: "\e306";
  --fa--fa: "\e306\e306";
}

.fa-person-drowning {
  --fa: "\e545";
  --fa--fa: "\e545\e545";
}

.fa-dial-max {
  --fa: "\e15e";
  --fa--fa: "\e15e\e15e";
}

.fa-circle-m {
  --fa: "\e115";
  --fa--fa: "\e115\e115";
}

.fa-calendar-image {
  --fa: "\e0d4";
  --fa--fa: "\e0d4\e0d4";
}

.fa-circle-caret-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d";
}

.fa-caret-circle-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d";
}

.fa-arrow-down-9-1 {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-sort-numeric-desc {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-sort-numeric-down-alt {
  --fa: "\f886";
  --fa--fa: "\f886\f886";
}

.fa-face-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a";
}

.fa-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a";
}

.fa-shish-kebab {
  --fa: "\f821";
  --fa--fa: "\f821\f821";
}

.fa-spray-can {
  --fa: "\f5bd";
  --fa--fa: "\f5bd\f5bd";
}

.fa-alarm-snooze {
  --fa: "\f845";
  --fa--fa: "\f845\f845";
}

.fa-scarecrow {
  --fa: "\f70d";
  --fa--fa: "\f70d\f70d";
}

.fa-truck-monster {
  --fa: "\f63b";
  --fa--fa: "\f63b\f63b";
}

.fa-gift-card {
  --fa: "\f663";
  --fa--fa: "\f663\f663";
}

.fa-w {
  --fa: "W";
  --fa--fa: "WW";
}

.fa-code-pull-request-draft {
  --fa: "\e3fa";
  --fa--fa: "\e3fa\e3fa";
}

.fa-square-b {
  --fa: "\e264";
  --fa--fa: "\e264\e264";
}

.fa-elephant {
  --fa: "\f6da";
  --fa--fa: "\f6da\f6da";
}

.fa-earth-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c";
}

.fa-globe-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c";
}

.fa-rainbow {
  --fa: "\f75b";
  --fa--fa: "\f75b\f75b";
}

.fa-circle-notch {
  --fa: "\f1ce";
  --fa--fa: "\f1ce\f1ce";
}

.fa-tablet-screen-button {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa";
}

.fa-tablet-alt {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa";
}

.fa-paw {
  --fa: "\f1b0";
  --fa--fa: "\f1b0\f1b0";
}

.fa-message-question {
  --fa: "\e1e3";
  --fa--fa: "\e1e3\e1e3";
}

.fa-cloud {
  --fa: "\f0c2";
  --fa--fa: "\f0c2\f0c2";
}

.fa-trowel-bricks {
  --fa: "\e58a";
  --fa--fa: "\e58a\e58a";
}

.fa-square-3 {
  --fa: "\e258";
  --fa--fa: "\e258\e258";
}

.fa-face-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579";
}

.fa-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579";
}

.fa-hospital-user {
  --fa: "\f80d";
  --fa--fa: "\f80d\f80d";
}

.fa-microwave {
  --fa: "\e01b";
  --fa--fa: "\e01b\e01b";
}

.fa-chf-sign {
  --fa: "\e602";
  --fa--fa: "\e602\e602";
}

.fa-tent-arrow-left-right {
  --fa: "\e57f";
  --fa--fa: "\e57f\e57f";
}

.fa-cart-circle-arrow-up {
  --fa: "\e3f0";
  --fa--fa: "\e3f0\e3f0";
}

.fa-trash-clock {
  --fa: "\e2b0";
  --fa--fa: "\e2b0\e2b0";
}

.fa-reflect-both {
  --fa: "\e66f";
  --fa--fa: "\e66f\e66f";
}

.fa-gavel {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3";
}

.fa-legal {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3";
}

.fa-sprinkler-ceiling {
  --fa: "\e44c";
  --fa--fa: "\e44c\e44c";
}

.fa-browsers {
  --fa: "\e0cb";
  --fa--fa: "\e0cb\e0cb";
}

.fa-trillium {
  --fa: "\e588";
  --fa--fa: "\e588\e588";
}

.fa-table-cells-unlock {
  --fa: "\e692";
  --fa--fa: "\e692\e692";
}

.fa-music-slash {
  --fa: "\f8d1";
  --fa--fa: "\f8d1\f8d1";
}

.fa-truck-ramp {
  --fa: "\f4e0";
  --fa--fa: "\f4e0\f4e0";
}

.fa-binoculars {
  --fa: "\f1e5";
  --fa--fa: "\f1e5\f1e5";
}

.fa-microphone-slash {
  --fa: "\f131";
  --fa--fa: "\f131\f131";
}

.fa-box-tissue {
  --fa: "\e05b";
  --fa--fa: "\e05b\e05b";
}

.fa-circle-c {
  --fa: "\e101";
  --fa--fa: "\e101\e101";
}

.fa-star-christmas {
  --fa: "\f7d4";
  --fa--fa: "\f7d4\f7d4";
}

.fa-chart-bullet {
  --fa: "\e0e1";
  --fa--fa: "\e0e1\e0e1";
}

.fa-motorcycle {
  --fa: "\f21c";
  --fa--fa: "\f21c\f21c";
}

.fa-tree-christmas {
  --fa: "\f7db";
  --fa--fa: "\f7db\f7db";
}

.fa-tire-flat {
  --fa: "\f632";
  --fa--fa: "\f632\f632";
}

.fa-sunglasses {
  --fa: "\f892";
  --fa--fa: "\f892\f892";
}

.fa-badge {
  --fa: "\f335";
  --fa--fa: "\f335\f335";
}

.fa-message-pen {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-comment-alt-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-message-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4";
}

.fa-bell-concierge {
  --fa: "\f562";
  --fa--fa: "\f562\f562";
}

.fa-concierge-bell {
  --fa: "\f562";
  --fa--fa: "\f562\f562";
}

.fa-pen-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae";
}

.fa-pencil-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae";
}

.fa-file-mp3 {
  --fa: "\e648";
  --fa--fa: "\e648\e648";
}

.fa-arrow-progress {
  --fa: "\e5df";
  --fa--fa: "\e5df\e5df";
}

.fa-chess-rook-piece {
  --fa: "\f448";
  --fa--fa: "\f448\f448";
}

.fa-chess-rook-alt {
  --fa: "\f448";
  --fa--fa: "\f448\f448";
}

.fa-square-root {
  --fa: "\f697";
  --fa--fa: "\f697\f697";
}

.fa-album-collection-circle-plus {
  --fa: "\e48e";
  --fa--fa: "\e48e\e48e";
}

.fa-people-arrows {
  --fa: "\e068";
  --fa--fa: "\e068\e068";
}

.fa-people-arrows-left-right {
  --fa: "\e068";
  --fa--fa: "\e068\e068";
}

.fa-sign-post {
  --fa: "\e624";
  --fa--fa: "\e624\e624";
}

.fa-face-angry-horns {
  --fa: "\e368";
  --fa--fa: "\e368\e368";
}

.fa-mars-and-venus-burst {
  --fa: "\e523";
  --fa--fa: "\e523\e523";
}

.fa-tombstone {
  --fa: "\f720";
  --fa--fa: "\f720\f720";
}

.fa-square-caret-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152";
}

.fa-caret-square-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152";
}

.fa-scissors {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4";
}

.fa-cut {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4";
}

.fa-list-music {
  --fa: "\f8c9";
  --fa--fa: "\f8c9\f8c9";
}

.fa-sun-plant-wilt {
  --fa: "\e57a";
  --fa--fa: "\e57a\e57a";
}

.fa-toilets-portable {
  --fa: "\e584";
  --fa--fa: "\e584\e584";
}

.fa-hockey-puck {
  --fa: "\f453";
  --fa--fa: "\f453\f453";
}

.fa-mustache {
  --fa: "\e5bc";
  --fa--fa: "\e5bc\e5bc";
}

.fa-hyphen {
  --fa: "\-";
  --fa--fa: "\-\-";
}

.fa-table {
  --fa: "\f0ce";
  --fa--fa: "\f0ce\f0ce";
}

.fa-user-chef {
  --fa: "\e3d2";
  --fa--fa: "\e3d2\e3d2";
}

.fa-message-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0";
}

.fa-comment-alt-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0";
}

.fa-users-medical {
  --fa: "\f830";
  --fa--fa: "\f830\f830";
}

.fa-sensor-triangle-exclamation {
  --fa: "\e029";
  --fa--fa: "\e029\e029";
}

.fa-sensor-alert {
  --fa: "\e029";
  --fa--fa: "\e029\e029";
}

.fa-magnifying-glass-arrow-right {
  --fa: "\e521";
  --fa--fa: "\e521\e521";
}

.fa-tachograph-digital {
  --fa: "\f566";
  --fa--fa: "\f566\f566";
}

.fa-digital-tachograph {
  --fa: "\f566";
  --fa--fa: "\f566\f566";
}

.fa-face-mask {
  --fa: "\e37f";
  --fa--fa: "\e37f\e37f";
}

.fa-pickleball {
  --fa: "\e435";
  --fa--fa: "\e435\e435";
}

.fa-star-sharp-half {
  --fa: "\e28c";
  --fa--fa: "\e28c\e28c";
}

.fa-users-slash {
  --fa: "\e073";
  --fa--fa: "\e073\e073";
}

.fa-clover {
  --fa: "\e139";
  --fa--fa: "\e139\e139";
}

.fa-meat {
  --fa: "\f814";
  --fa--fa: "\f814\f814";
}

.fa-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5";
}

.fa-mail-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5";
}

.fa-star-and-crescent {
  --fa: "\f699";
  --fa--fa: "\f699\f699";
}

.fa-empty-set {
  --fa: "\f656";
  --fa--fa: "\f656\f656";
}

.fa-house-fire {
  --fa: "\e50c";
  --fa--fa: "\e50c\e50c";
}

.fa-square-minus {
  --fa: "\f146";
  --fa--fa: "\f146\f146";
}

.fa-minus-square {
  --fa: "\f146";
  --fa--fa: "\f146\f146";
}

.fa-helicopter {
  --fa: "\f533";
  --fa--fa: "\f533\f533";
}

.fa-bird {
  --fa: "\e469";
  --fa--fa: "\e469\e469";
}

.fa-compass {
  --fa: "\f14e";
  --fa--fa: "\f14e\f14e";
}

.fa-square-caret-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150";
}

.fa-caret-square-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150";
}

.fa-heart-half-stroke {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac";
}

.fa-heart-half-alt {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac";
}

.fa-file-circle-question {
  --fa: "\e4ef";
  --fa--fa: "\e4ef\e4ef";
}

.fa-truck-utensils {
  --fa: "\e628";
  --fa--fa: "\e628\e628";
}

.fa-laptop-code {
  --fa: "\f5fc";
  --fa--fa: "\f5fc\f5fc";
}

.fa-joystick {
  --fa: "\f8c5";
  --fa--fa: "\f8c5\f8c5";
}

.fa-grill-fire {
  --fa: "\e5a4";
  --fa--fa: "\e5a4\e5a4";
}

.fa-rectangle-vertical-history {
  --fa: "\e237";
  --fa--fa: "\e237\e237";
}

.fa-swatchbook {
  --fa: "\f5c3";
  --fa--fa: "\f5c3\f5c3";
}

.fa-prescription-bottle {
  --fa: "\f485";
  --fa--fa: "\f485\f485";
}

.fa-bars {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9";
}

.fa-navicon {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9";
}

.fa-keyboard-left {
  --fa: "\e1c3";
  --fa--fa: "\e1c3\e1c3";
}

.fa-people-group {
  --fa: "\e533";
  --fa--fa: "\e533\e533";
}

.fa-hourglass-end {
  --fa: "\f253";
  --fa--fa: "\f253\f253";
}

.fa-hourglass-3 {
  --fa: "\f253";
  --fa--fa: "\f253\f253";
}

.fa-heart-crack {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9";
}

.fa-heart-broken {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9";
}

.fa-face-beam-hand-over-mouth {
  --fa: "\e47c";
  --fa--fa: "\e47c\e47c";
}

.fa-droplet-percent {
  --fa: "\f750";
  --fa--fa: "\f750\f750";
}

.fa-humidity {
  --fa: "\f750";
  --fa--fa: "\f750\f750";
}

.fa-square-up-right {
  --fa: "\f360";
  --fa--fa: "\f360\f360";
}

.fa-external-link-square-alt {
  --fa: "\f360";
  --fa--fa: "\f360\f360";
}

.fa-face-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597";
}

.fa-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597";
}

.fa-corn {
  --fa: "\f6c7";
  --fa--fa: "\f6c7\f6c7";
}

.fa-roller-coaster {
  --fa: "\e324";
  --fa--fa: "\e324\e324";
}

.fa-photo-film-music {
  --fa: "\e228";
  --fa--fa: "\e228\e228";
}

.fa-radar {
  --fa: "\e024";
  --fa--fa: "\e024\e024";
}

.fa-sickle {
  --fa: "\f822";
  --fa--fa: "\f822\f822";
}

.fa-film {
  --fa: "\f008";
  --fa--fa: "\f008\f008";
}

.fa-coconut {
  --fa: "\e2f6";
  --fa--fa: "\e2f6\e2f6";
}

.fa-ruler-horizontal {
  --fa: "\f547";
  --fa--fa: "\f547\f547";
}

.fa-shield-cross {
  --fa: "\f712";
  --fa--fa: "\f712\f712";
}

.fa-cassette-tape {
  --fa: "\f8ab";
  --fa--fa: "\f8ab\f8ab";
}

.fa-square-terminal {
  --fa: "\e32a";
  --fa--fa: "\e32a\e32a";
}

.fa-people-robbery {
  --fa: "\e536";
  --fa--fa: "\e536\e536";
}

.fa-lightbulb {
  --fa: "\f0eb";
  --fa--fa: "\f0eb\f0eb";
}

.fa-caret-left {
  --fa: "\f0d9";
  --fa--fa: "\f0d9\f0d9";
}

.fa-comment-middle {
  --fa: "\e149";
  --fa--fa: "\e149\e149";
}

.fa-trash-can-list {
  --fa: "\e2ab";
  --fa--fa: "\e2ab\e2ab";
}

.fa-block {
  --fa: "\e46a";
  --fa--fa: "\e46a\e46a";
}

.fa-circle-exclamation {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a";
}

.fa-exclamation-circle {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a";
}

.fa-school-circle-xmark {
  --fa: "\e56d";
  --fa--fa: "\e56d\e56d";
}

.fa-arrow-right-from-bracket {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b";
}

.fa-sign-out {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b";
}

.fa-face-frown-slight {
  --fa: "\e376";
  --fa--fa: "\e376\e376";
}

.fa-circle-chevron-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a";
}

.fa-chevron-circle-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a";
}

.fa-sidebar-flip {
  --fa: "\e24f";
  --fa--fa: "\e24f\e24f";
}

.fa-unlock-keyhole {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e";
}

.fa-unlock-alt {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e";
}

.fa-temperature-list {
  --fa: "\e299";
  --fa--fa: "\e299\e299";
}

.fa-cloud-showers-heavy {
  --fa: "\f740";
  --fa--fa: "\f740\f740";
}

.fa-headphones-simple {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f";
}

.fa-headphones-alt {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f";
}

.fa-sitemap {
  --fa: "\f0e8";
  --fa--fa: "\f0e8\f0e8";
}

.fa-pipe-section {
  --fa: "\e438";
  --fa--fa: "\e438\e438";
}

.fa-space-station-moon-construction {
  --fa: "\e034";
  --fa--fa: "\e034\e034";
}

.fa-space-station-moon-alt {
  --fa: "\e034";
  --fa--fa: "\e034\e034";
}

.fa-circle-dollar-to-slot {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9";
}

.fa-donate {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9";
}

.fa-memory {
  --fa: "\f538";
  --fa--fa: "\f538\f538";
}

.fa-face-sleeping {
  --fa: "\e38d";
  --fa--fa: "\e38d\e38d";
}

.fa-road-spikes {
  --fa: "\e568";
  --fa--fa: "\e568\e568";
}

.fa-fire-burner {
  --fa: "\e4f1";
  --fa--fa: "\e4f1\e4f1";
}

.fa-squirrel {
  --fa: "\f71a";
  --fa--fa: "\f71a\f71a";
}

.fa-arrow-up-to-line {
  --fa: "\f341";
  --fa--fa: "\f341\f341";
}

.fa-arrow-to-top {
  --fa: "\f341";
  --fa--fa: "\f341\f341";
}

.fa-flag {
  --fa: "\f024";
  --fa--fa: "\f024\f024";
}

.fa-face-cowboy-hat {
  --fa: "\e36e";
  --fa--fa: "\e36e\e36e";
}

.fa-hanukiah {
  --fa: "\f6e6";
  --fa--fa: "\f6e6\f6e6";
}

.fa-chart-scatter-3d {
  --fa: "\e0e8";
  --fa--fa: "\e0e8\e0e8";
}

.fa-display-chart-up {
  --fa: "\e5e3";
  --fa--fa: "\e5e3\e5e3";
}

.fa-square-code {
  --fa: "\e267";
  --fa--fa: "\e267\e267";
}

.fa-feather {
  --fa: "\f52d";
  --fa--fa: "\f52d\f52d";
}

.fa-volume-low {
  --fa: "\f027";
  --fa--fa: "\f027\f027";
}

.fa-volume-down {
  --fa: "\f027";
  --fa--fa: "\f027\f027";
}

.fa-xmark-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-times-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-vote-nay {
  --fa: "\f771";
  --fa--fa: "\f771\f771";
}

.fa-box-taped {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a";
}

.fa-box-alt {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a";
}

.fa-comment-slash {
  --fa: "\f4b3";
  --fa--fa: "\f4b3\f4b3";
}

.fa-swords {
  --fa: "\f71d";
  --fa--fa: "\f71d\f71d";
}

.fa-cloud-sun-rain {
  --fa: "\f743";
  --fa--fa: "\f743\f743";
}

.fa-album {
  --fa: "\f89f";
  --fa--fa: "\f89f\f89f";
}

.fa-circle-n {
  --fa: "\e118";
  --fa--fa: "\e118\e118";
}

.fa-compress {
  --fa: "\f066";
  --fa--fa: "\f066\f066";
}

.fa-wheat-awn {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd";
}

.fa-wheat-alt {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd";
}

.fa-ankh {
  --fa: "\f644";
  --fa--fa: "\f644\f644";
}

.fa-hands-holding-child {
  --fa: "\e4fa";
  --fa--fa: "\e4fa\e4fa";
}

.fa-asterisk {
  --fa: "\*";
  --fa--fa: "\*\*";
}

.fa-key-skeleton-left-right {
  --fa: "\e3b4";
  --fa--fa: "\e3b4\e3b4";
}

.fa-comment-lines {
  --fa: "\f4b0";
  --fa--fa: "\f4b0\f4b0";
}

.fa-luchador-mask {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-mask-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455";
}

.fa-square-check {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a";
}

.fa-check-square {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a";
}

.fa-shredder {
  --fa: "\f68a";
  --fa--fa: "\f68a\f68a";
}

.fa-book-open-cover {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0";
}

.fa-book-open-alt {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0";
}

.fa-sandwich {
  --fa: "\f81f";
  --fa--fa: "\f81f\f81f";
}

.fa-peseta-sign {
  --fa: "\e221";
  --fa--fa: "\e221\e221";
}

.fa-square-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617";
}

.fa-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617";
}

.fa-train-tunnel {
  --fa: "\e454";
  --fa--fa: "\e454\e454";
}

.fa-heading {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc";
}

.fa-header {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc";
}

.fa-ghost {
  --fa: "\f6e2";
  --fa--fa: "\f6e2\f6e2";
}

.fa-face-anguished {
  --fa: "\e369";
  --fa--fa: "\e369\e369";
}

.fa-hockey-sticks {
  --fa: "\f454";
  --fa--fa: "\f454\f454";
}

.fa-abacus {
  --fa: "\f640";
  --fa--fa: "\f640\f640";
}

.fa-film-simple {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0";
}

.fa-film-alt {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0";
}

.fa-list {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a";
}

.fa-list-squares {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a";
}

.fa-tree-palm {
  --fa: "\f82b";
  --fa--fa: "\f82b\f82b";
}

.fa-square-phone-flip {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b";
}

.fa-phone-square-alt {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b";
}

.fa-user-beard-bolt {
  --fa: "\e689";
  --fa--fa: "\e689\e689";
}

.fa-cart-plus {
  --fa: "\f217";
  --fa--fa: "\f217\f217";
}

.fa-gamepad {
  --fa: "\f11b";
  --fa--fa: "\f11b\f11b";
}

.fa-border-center-v {
  --fa: "\f89d";
  --fa--fa: "\f89d\f89d";
}

.fa-circle-dot {
  --fa: "\f192";
  --fa--fa: "\f192\f192";
}

.fa-dot-circle {
  --fa: "\f192";
  --fa--fa: "\f192\f192";
}

.fa-clipboard-medical {
  --fa: "\e133";
  --fa--fa: "\e133\e133";
}

.fa-face-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567";
}

.fa-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567";
}

.fa-egg {
  --fa: "\f7fb";
  --fa--fa: "\f7fb\f7fb";
}

.fa-up-to-line {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d";
}

.fa-arrow-alt-to-top {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d";
}

.fa-house-medical-circle-xmark {
  --fa: "\e513";
  --fa--fa: "\e513\e513";
}

.fa-watch-fitness {
  --fa: "\f63e";
  --fa--fa: "\f63e\f63e";
}

.fa-clock-nine-thirty {
  --fa: "\e34d";
  --fa--fa: "\e34d\e34d";
}

.fa-campground {
  --fa: "\f6bb";
  --fa--fa: "\f6bb\f6bb";
}

.fa-folder-plus {
  --fa: "\f65e";
  --fa--fa: "\f65e\f65e";
}

.fa-jug {
  --fa: "\f8c6";
  --fa--fa: "\f8c6\f8c6";
}

.fa-futbol {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-futbol-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-soccer-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3";
}

.fa-snow-blowing {
  --fa: "\f761";
  --fa--fa: "\f761\f761";
}

.fa-paintbrush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc";
}

.fa-paint-brush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc";
}

.fa-lock {
  --fa: "\f023";
  --fa--fa: "\f023\f023";
}

.fa-arrow-down-from-line {
  --fa: "\f345";
  --fa--fa: "\f345\f345";
}

.fa-arrow-from-top {
  --fa: "\f345";
  --fa--fa: "\f345\f345";
}

.fa-gas-pump {
  --fa: "\f52f";
  --fa--fa: "\f52f\f52f";
}

.fa-signal-bars-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694";
}

.fa-signal-alt-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694";
}

.fa-monkey {
  --fa: "\f6fb";
  --fa--fa: "\f6fb\f6fb";
}

.fa-rectangle-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235";
}

.fa-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235";
}

.fa-house-night {
  --fa: "\e010";
  --fa--fa: "\e010\e010";
}

.fa-hot-tub-person {
  --fa: "\f593";
  --fa--fa: "\f593\f593";
}

.fa-hot-tub {
  --fa: "\f593";
  --fa--fa: "\f593\f593";
}

.fa-globe-pointer {
  --fa: "\e60e";
  --fa--fa: "\e60e\e60e";
}

.fa-blanket {
  --fa: "\f498";
  --fa--fa: "\f498\f498";
}

.fa-map-location {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f";
}

.fa-map-marked {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f";
}

.fa-house-flood-water {
  --fa: "\e50e";
  --fa--fa: "\e50e\e50e";
}

.fa-comments-question-check {
  --fa: "\e14f";
  --fa--fa: "\e14f\e14f";
}

.fa-tree {
  --fa: "\f1bb";
  --fa--fa: "\f1bb\f1bb";
}

.fa-arrows-cross {
  --fa: "\e0a2";
  --fa--fa: "\e0a2\e0a2";
}

.fa-backpack {
  --fa: "\f5d4";
  --fa--fa: "\f5d4\f5d4";
}

.fa-square-small {
  --fa: "\e27e";
  --fa--fa: "\e27e\e27e";
}

.fa-folder-arrow-up {
  --fa: "\e054";
  --fa--fa: "\e054\e054";
}

.fa-folder-upload {
  --fa: "\e054";
  --fa--fa: "\e054\e054";
}

.fa-bridge-lock {
  --fa: "\e4cc";
  --fa--fa: "\e4cc\e4cc";
}

.fa-crosshairs-simple {
  --fa: "\e59f";
  --fa--fa: "\e59f\e59f";
}

.fa-sack-dollar {
  --fa: "\f81d";
  --fa--fa: "\f81d\f81d";
}

.fa-pen-to-square {
  --fa: "\f044";
  --fa--fa: "\f044\f044";
}

.fa-edit {
  --fa: "\f044";
  --fa--fa: "\f044\f044";
}

.fa-square-sliders {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0";
}

.fa-sliders-h-square {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0";
}

.fa-car-side {
  --fa: "\f5e4";
  --fa--fa: "\f5e4\f5e4";
}

.fa-message-middle-top {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2";
}

.fa-comment-middle-top-alt {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2";
}

.fa-lightbulb-on {
  --fa: "\f672";
  --fa--fa: "\f672\f672";
}

.fa-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4";
}

.fa-utensil-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4";
}

.fa-share-nodes {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0";
}

.fa-share-alt {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0";
}

.fa-display-chart-up-circle-dollar {
  --fa: "\e5e6";
  --fa--fa: "\e5e6\e5e6";
}

.fa-wave-sine {
  --fa: "\f899";
  --fa--fa: "\f899\f899";
}

.fa-heart-circle-minus {
  --fa: "\e4ff";
  --fa--fa: "\e4ff\e4ff";
}

.fa-circle-w {
  --fa: "\e12c";
  --fa--fa: "\e12c\e12c";
}

.fa-circle-calendar {
  --fa: "\e102";
  --fa--fa: "\e102\e102";
}

.fa-calendar-circle {
  --fa: "\e102";
  --fa--fa: "\e102\e102";
}

.fa-hourglass-half {
  --fa: "\f252";
  --fa--fa: "\f252\f252";
}

.fa-hourglass-2 {
  --fa: "\f252";
  --fa--fa: "\f252\f252";
}

.fa-microscope {
  --fa: "\f610";
  --fa--fa: "\f610\f610";
}

.fa-sunset {
  --fa: "\f767";
  --fa--fa: "\f767\f767";
}

.fa-sink {
  --fa: "\e06d";
  --fa--fa: "\e06d\e06d";
}

.fa-calendar-exclamation {
  --fa: "\f334";
  --fa--fa: "\f334\f334";
}

.fa-truck-container-empty {
  --fa: "\e2b5";
  --fa--fa: "\e2b5\e2b5";
}

.fa-hand-heart {
  --fa: "\f4bc";
  --fa--fa: "\f4bc\f4bc";
}

.fa-bag-shopping {
  --fa: "\f290";
  --fa--fa: "\f290\f290";
}

.fa-shopping-bag {
  --fa: "\f290";
  --fa--fa: "\f290\f290";
}

.fa-arrow-down-z-a {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-sort-alpha-desc {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-sort-alpha-down-alt {
  --fa: "\f881";
  --fa--fa: "\f881\f881";
}

.fa-mitten {
  --fa: "\f7b5";
  --fa--fa: "\f7b5\f7b5";
}

.fa-reply-clock {
  --fa: "\e239";
  --fa--fa: "\e239\e239";
}

.fa-reply-time {
  --fa: "\e239";
  --fa--fa: "\e239\e239";
}

.fa-person-rays {
  --fa: "\e54d";
  --fa--fa: "\e54d\e54d";
}

.fa-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356";
}

.fa-arrow-alt-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356";
}

.fa-circle-f {
  --fa: "\e10e";
  --fa--fa: "\e10e\e10e";
}

.fa-users {
  --fa: "\f0c0";
  --fa--fa: "\f0c0\f0c0";
}

.fa-face-pleading {
  --fa: "\e386";
  --fa--fa: "\e386\e386";
}

.fa-eye-slash {
  --fa: "\f070";
  --fa--fa: "\f070\f070";
}

.fa-flask-vial {
  --fa: "\e4f3";
  --fa--fa: "\e4f3\e4f3";
}

.fa-police-box {
  --fa: "\e021";
  --fa--fa: "\e021\e021";
}

.fa-cucumber {
  --fa: "\e401";
  --fa--fa: "\e401\e401";
}

.fa-head-side-brain {
  --fa: "\f808";
  --fa--fa: "\f808\f808";
}

.fa-hand {
  --fa: "\f256";
  --fa--fa: "\f256\f256";
}

.fa-hand-paper {
  --fa: "\f256";
  --fa--fa: "\f256\f256";
}

.fa-person-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b";
}

.fa-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b";
}

.fa-utensils-slash {
  --fa: "\e464";
  --fa--fa: "\e464\e464";
}

.fa-print-magnifying-glass {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a";
}

.fa-print-search {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a";
}

.fa-turn-right {
  --fa: "\e639";
  --fa--fa: "\e639\e639";
}

.fa-folder-bookmark {
  --fa: "\e186";
  --fa--fa: "\e186\e186";
}

.fa-arrow-turn-left-down {
  --fa: "\e633";
  --fa--fa: "\e633\e633";
}

.fa-om {
  --fa: "\f679";
  --fa--fa: "\f679\f679";
}

.fa-pi {
  --fa: "\f67e";
  --fa--fa: "\f67e\f67e";
}

.fa-flask-round-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1";
}

.fa-flask-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1";
}

.fa-face-shush {
  --fa: "\e38c";
  --fa--fa: "\e38c\e38c";
}

.fa-worm {
  --fa: "\e599";
  --fa--fa: "\e599\e599";
}

.fa-house-circle-xmark {
  --fa: "\e50b";
  --fa--fa: "\e50b\e50b";
}

.fa-plug {
  --fa: "\f1e6";
  --fa--fa: "\f1e6\f1e6";
}

.fa-calendar-circle-exclamation {
  --fa: "\e46e";
  --fa--fa: "\e46e\e46e";
}

.fa-square-i {
  --fa: "\e272";
  --fa--fa: "\e272\e272";
}

.fa-chevron-up {
  --fa: "\f077";
  --fa--fa: "\f077\f077";
}

.fa-face-saluting {
  --fa: "\e484";
  --fa--fa: "\e484\e484";
}

.fa-gauge-simple-low {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c";
}

.fa-tachometer-slow {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c";
}

.fa-face-persevering {
  --fa: "\e385";
  --fa--fa: "\e385\e385";
}

.fa-circle-camera {
  --fa: "\e103";
  --fa--fa: "\e103\e103";
}

.fa-camera-circle {
  --fa: "\e103";
  --fa--fa: "\e103\e103";
}

.fa-hand-spock {
  --fa: "\f259";
  --fa--fa: "\f259\f259";
}

.fa-spider-web {
  --fa: "\f719";
  --fa--fa: "\f719\f719";
}

.fa-circle-microphone {
  --fa: "\e116";
  --fa--fa: "\e116\e116";
}

.fa-microphone-circle {
  --fa: "\e116";
  --fa--fa: "\e116\e116";
}

.fa-book-arrow-up {
  --fa: "\e0ba";
  --fa--fa: "\e0ba\e0ba";
}

.fa-popsicle {
  --fa: "\e43e";
  --fa--fa: "\e43e\e43e";
}

.fa-command {
  --fa: "\e142";
  --fa--fa: "\e142\e142";
}

.fa-blinds {
  --fa: "\f8fb";
  --fa--fa: "\f8fb\f8fb";
}

.fa-stopwatch {
  --fa: "\f2f2";
  --fa--fa: "\f2f2\f2f2";
}

.fa-saxophone {
  --fa: "\f8dc";
  --fa--fa: "\f8dc\f8dc";
}

.fa-square-2 {
  --fa: "\e257";
  --fa--fa: "\e257\e257";
}

.fa-field-hockey-stick-ball {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c";
}

.fa-field-hockey {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c";
}

.fa-arrow-up-square-triangle {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b";
}

.fa-sort-shapes-up-alt {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b";
}

.fa-face-scream {
  --fa: "\e38b";
  --fa--fa: "\e38b\e38b";
}

.fa-square-m {
  --fa: "\e276";
  --fa--fa: "\e276\e276";
}

.fa-camera-web {
  --fa: "\f832";
  --fa--fa: "\f832\f832";
}

.fa-webcam {
  --fa: "\f832";
  --fa--fa: "\f832\f832";
}

.fa-comment-arrow-down {
  --fa: "\e143";
  --fa--fa: "\e143\e143";
}

.fa-lightbulb-cfl {
  --fa: "\e5a6";
  --fa--fa: "\e5a6\e5a6";
}

.fa-window-frame-open {
  --fa: "\e050";
  --fa--fa: "\e050\e050";
}

.fa-face-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596";
}

.fa-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596";
}

.fa-bridge-circle-xmark {
  --fa: "\e4cb";
  --fa--fa: "\e4cb\e4cb";
}

.fa-period {
  --fa: "\.";
  --fa--fa: "\.\.";
}

.fa-face-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589";
}

.fa-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589";
}

.fa-up-to-dotted-line {
  --fa: "\e457";
  --fa--fa: "\e457\e457";
}

.fa-thought-bubble {
  --fa: "\e32e";
  --fa--fa: "\e32e\e32e";
}

.fa-skeleton-ribs {
  --fa: "\e5cb";
  --fa--fa: "\e5cb\e5cb";
}

.fa-raygun {
  --fa: "\e025";
  --fa--fa: "\e025\e025";
}

.fa-flute {
  --fa: "\f8b9";
  --fa--fa: "\f8b9\f8b9";
}

.fa-acorn {
  --fa: "\f6ae";
  --fa--fa: "\f6ae\f6ae";
}

.fa-video-arrow-up-right {
  --fa: "\e2c9";
  --fa--fa: "\e2c9\e2c9";
}

.fa-grate-droplet {
  --fa: "\e194";
  --fa--fa: "\e194\e194";
}

.fa-seal-exclamation {
  --fa: "\e242";
  --fa--fa: "\e242\e242";
}

.fa-chess-bishop {
  --fa: "\f43a";
  --fa--fa: "\f43a\f43a";
}

.fa-message-sms {
  --fa: "\e1e5";
  --fa--fa: "\e1e5\e1e5";
}

.fa-coffee-beans {
  --fa: "\e13f";
  --fa--fa: "\e13f\e13f";
}

.fa-hat-witch {
  --fa: "\f6e7";
  --fa--fa: "\f6e7\f6e7";
}

.fa-face-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c";
}

.fa-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c";
}

.fa-clock-three-thirty {
  --fa: "\e357";
  --fa--fa: "\e357\e357";
}

.fa-ear-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-deafness {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-hard-of-hearing {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4";
}

.fa-alarm-clock {
  --fa: "\f34e";
  --fa--fa: "\f34e\f34e";
}

.fa-eclipse {
  --fa: "\f749";
  --fa--fa: "\f749\f749";
}

.fa-face-relieved {
  --fa: "\e389";
  --fa--fa: "\e389\e389";
}

.fa-road-circle-check {
  --fa: "\e564";
  --fa--fa: "\e564\e564";
}

.fa-dice-five {
  --fa: "\f523";
  --fa--fa: "\f523\f523";
}

.fa-octagon-minus {
  --fa: "\f308";
  --fa--fa: "\f308\f308";
}

.fa-minus-octagon {
  --fa: "\f308";
  --fa--fa: "\f308\f308";
}

.fa-square-rss {
  --fa: "\f143";
  --fa--fa: "\f143\f143";
}

.fa-rss-square {
  --fa: "\f143";
  --fa--fa: "\f143\f143";
}

.fa-face-zany {
  --fa: "\e3a4";
  --fa--fa: "\e3a4\e3a4";
}

.fa-tricycle {
  --fa: "\e5c3";
  --fa--fa: "\e5c3\e5c3";
}

.fa-land-mine-on {
  --fa: "\e51b";
  --fa--fa: "\e51b\e51b";
}

.fa-square-arrow-up-left {
  --fa: "\e263";
  --fa--fa: "\e263\e263";
}

.fa-i-cursor {
  --fa: "\f246";
  --fa--fa: "\f246\f246";
}

.fa-chart-mixed-up-circle-dollar {
  --fa: "\e5d9";
  --fa--fa: "\e5d9\e5d9";
}

.fa-salt-shaker {
  --fa: "\e446";
  --fa--fa: "\e446\e446";
}

.fa-stamp {
  --fa: "\f5bf";
  --fa--fa: "\f5bf\f5bf";
}

.fa-file-plus {
  --fa: "\f319";
  --fa--fa: "\f319\f319";
}

.fa-draw-square {
  --fa: "\f5ef";
  --fa--fa: "\f5ef\f5ef";
}

.fa-toilet-paper-under-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1";
}

.fa-toilet-paper-reverse-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1";
}

.fa-stairs {
  --fa: "\e289";
  --fa--fa: "\e289\e289";
}

.fa-drone-front {
  --fa: "\f860";
  --fa--fa: "\f860\f860";
}

.fa-drone-alt {
  --fa: "\f860";
  --fa--fa: "\f860\f860";
}

.fa-glass-empty {
  --fa: "\e191";
  --fa--fa: "\e191\e191";
}

.fa-dial-high {
  --fa: "\e15c";
  --fa--fa: "\e15c\e15c";
}

.fa-user-helmet-safety {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-user-construction {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-user-hard-hat {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c";
}

.fa-i {
  --fa: "I";
  --fa--fa: "II";
}

.fa-hryvnia-sign {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2";
}

.fa-hryvnia {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "\e092";
  --fa--fa: "\e092\e092";
}

.fa-pills {
  --fa: "\f484";
  --fa--fa: "\f484\f484";
}

.fa-face-grin-wide {
  --fa: "\f581";
  --fa--fa: "\f581\f581";
}

.fa-grin-alt {
  --fa: "\f581";
  --fa--fa: "\f581\f581";
}

.fa-tooth {
  --fa: "\f5c9";
  --fa--fa: "\f5c9\f5c9";
}

.fa-basketball-hoop {
  --fa: "\f435";
  --fa--fa: "\f435\f435";
}

.fa-objects-align-bottom {
  --fa: "\e3bb";
  --fa--fa: "\e3bb\e3bb";
}

.fa-v {
  --fa: "V";
  --fa--fa: "VV";
}

.fa-sparkles {
  --fa: "\f890";
  --fa--fa: "\f890\f890";
}

.fa-squid {
  --fa: "\e450";
  --fa--fa: "\e450\e450";
}

.fa-leafy-green {
  --fa: "\e41d";
  --fa--fa: "\e41d\e41d";
}

.fa-circle-arrow-up-right {
  --fa: "\e0fc";
  --fa--fa: "\e0fc\e0fc";
}

.fa-calendars {
  --fa: "\e0d7";
  --fa--fa: "\e0d7\e0d7";
}

.fa-bangladeshi-taka-sign {
  --fa: "\e2e6";
  --fa--fa: "\e2e6\e2e6";
}

.fa-bicycle {
  --fa: "\f206";
  --fa--fa: "\f206\f206";
}

.fa-hammer-war {
  --fa: "\f6e4";
  --fa--fa: "\f6e4\f6e4";
}

.fa-circle-d {
  --fa: "\e104";
  --fa--fa: "\e104\e104";
}

.fa-spider-black-widow {
  --fa: "\f718";
  --fa--fa: "\f718\f718";
}

.fa-staff-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-rod-asclepius {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-rod-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-staff-aesculapius {
  --fa: "\e579";
  --fa--fa: "\e579\e579";
}

.fa-pear {
  --fa: "\e20c";
  --fa--fa: "\e20c\e20c";
}

.fa-head-side-cough-slash {
  --fa: "\e062";
  --fa--fa: "\e062\e062";
}

.fa-file-mov {
  --fa: "\e647";
  --fa--fa: "\e647\e647";
}

.fa-triangle {
  --fa: "\f2ec";
  --fa--fa: "\f2ec\f2ec";
}

.fa-apartment {
  --fa: "\e468";
  --fa--fa: "\e468\e468";
}

.fa-truck-medical {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9";
}

.fa-ambulance {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9";
}

.fa-pepper {
  --fa: "\e432";
  --fa--fa: "\e432\e432";
}

.fa-piano {
  --fa: "\f8d4";
  --fa--fa: "\f8d4\f8d4";
}

.fa-gun-squirt {
  --fa: "\e19d";
  --fa--fa: "\e19d\e19d";
}

.fa-wheat-awn-circle-exclamation {
  --fa: "\e598";
  --fa--fa: "\e598\e598";
}

.fa-snowman {
  --fa: "\f7d0";
  --fa--fa: "\f7d0\f7d0";
}

.fa-user-alien {
  --fa: "\e04a";
  --fa--fa: "\e04a\e04a";
}

.fa-shield-check {
  --fa: "\f2f7";
  --fa--fa: "\f2f7\f2f7";
}

.fa-mortar-pestle {
  --fa: "\f5a7";
  --fa--fa: "\f5a7\f5a7";
}

.fa-road-barrier {
  --fa: "\e562";
  --fa--fa: "\e562\e562";
}

.fa-chart-candlestick {
  --fa: "\e0e2";
  --fa--fa: "\e0e2\e0e2";
}

.fa-briefcase-blank {
  --fa: "\e0c8";
  --fa--fa: "\e0c8\e0c8";
}

.fa-school {
  --fa: "\f549";
  --fa--fa: "\f549\f549";
}

.fa-igloo {
  --fa: "\f7ae";
  --fa--fa: "\f7ae\f7ae";
}

.fa-bracket-round {
  --fa: "\(";
  --fa--fa: "\(\(";
}

.fa-parenthesis {
  --fa: "\(";
  --fa--fa: "\(\(";
}

.fa-joint {
  --fa: "\f595";
  --fa--fa: "\f595\f595";
}

.fa-horse-saddle {
  --fa: "\f8c3";
  --fa--fa: "\f8c3\f8c3";
}

.fa-mug-marshmallows {
  --fa: "\f7b7";
  --fa--fa: "\f7b7\f7b7";
}

.fa-filters {
  --fa: "\e17e";
  --fa--fa: "\e17e\e17e";
}

.fa-bell-on {
  --fa: "\f8fa";
  --fa--fa: "\f8fa\f8fa";
}

.fa-angle-right {
  --fa: "\f105";
  --fa--fa: "\f105\f105";
}

.fa-dial-med {
  --fa: "\e15f";
  --fa--fa: "\e15f\e15f";
}

.fa-horse {
  --fa: "\f6f0";
  --fa--fa: "\f6f0\f6f0";
}

.fa-q {
  --fa: "Q";
  --fa--fa: "QQ";
}

.fa-monitor-waveform {
  --fa: "\f611";
  --fa--fa: "\f611\f611";
}

.fa-monitor-heart-rate {
  --fa: "\f611";
  --fa--fa: "\f611\f611";
}

.fa-link-simple {
  --fa: "\e1cd";
  --fa--fa: "\e1cd\e1cd";
}

.fa-whistle {
  --fa: "\f460";
  --fa--fa: "\f460\f460";
}

.fa-g {
  --fa: "G";
  --fa--fa: "GG";
}

.fa-wine-glass-crack {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb";
}

.fa-fragile {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb";
}

.fa-slot-machine {
  --fa: "\e3ce";
  --fa--fa: "\e3ce\e3ce";
}

.fa-notes-medical {
  --fa: "\f481";
  --fa--fa: "\f481\f481";
}

.fa-car-wash {
  --fa: "\f5e6";
  --fa--fa: "\f5e6\f5e6";
}

.fa-escalator {
  --fa: "\e171";
  --fa--fa: "\e171\e171";
}

.fa-comment-image {
  --fa: "\e148";
  --fa--fa: "\e148\e148";
}

.fa-temperature-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-temperature-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-thermometer-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-thermometer-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9";
}

.fa-dong-sign {
  --fa: "\e169";
  --fa--fa: "\e169\e169";
}

.fa-donut {
  --fa: "\e406";
  --fa--fa: "\e406\e406";
}

.fa-doughnut {
  --fa: "\e406";
  --fa--fa: "\e406\e406";
}

.fa-capsules {
  --fa: "\f46b";
  --fa--fa: "\f46b\f46b";
}

.fa-poo-storm {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a";
}

.fa-poo-bolt {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a";
}

.fa-tally-1 {
  --fa: "\e294";
  --fa--fa: "\e294\e294";
}

.fa-file-vector {
  --fa: "\e64c";
  --fa--fa: "\e64c\e64c";
}

.fa-face-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a";
}

.fa-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a";
}

.fa-square-dashed {
  --fa: "\e269";
  --fa--fa: "\e269\e269";
}

.fa-bag-shopping-plus {
  --fa: "\e651";
  --fa--fa: "\e651\e651";
}

.fa-square-j {
  --fa: "\e273";
  --fa--fa: "\e273\e273";
}

.fa-hand-point-up {
  --fa: "\f0a6";
  --fa--fa: "\f0a6\f0a6";
}

.fa-money-bill {
  --fa: "\f0d6";
  --fa--fa: "\f0d6\f0d6";
}

.fa-arrow-up-big-small {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e";
}

.fa-sort-size-up {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e";
}

.fa-barcode-read {
  --fa: "\f464";
  --fa--fa: "\f464\f464";
}

.fa-baguette {
  --fa: "\e3d8";
  --fa--fa: "\e3d8\e3d8";
}

.fa-bowl-soft-serve {
  --fa: "\e46b";
  --fa--fa: "\e46b\e46b";
}

.fa-face-holding-back-tears {
  --fa: "\e482";
  --fa--fa: "\e482\e482";
}

.fa-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353";
}

.fa-arrow-alt-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353";
}

.fa-train-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3";
}

.fa-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3";
}

.fa-square-exclamation {
  --fa: "\f321";
  --fa--fa: "\f321\f321";
}

.fa-exclamation-square {
  --fa: "\f321";
  --fa--fa: "\f321\f321";
}

.fa-semicolon {
  --fa: "\;";
  --fa--fa: "\;\;";
}

.fa-bookmark {
  --fa: "\f02e";
  --fa--fa: "\f02e\f02e";
}

.fa-fan-table {
  --fa: "\e004";
  --fa--fa: "\e004\e004";
}

.fa-align-justify {
  --fa: "\f039";
  --fa--fa: "\f039\f039";
}

.fa-battery-low {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1";
}

.fa-battery-1 {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1";
}

.fa-credit-card-front {
  --fa: "\f38a";
  --fa--fa: "\f38a\f38a";
}

.fa-brain-arrow-curved-right {
  --fa: "\f677";
  --fa--fa: "\f677\f677";
}

.fa-mind-share {
  --fa: "\f677";
  --fa--fa: "\f677\f677";
}

.fa-umbrella-beach {
  --fa: "\f5ca";
  --fa--fa: "\f5ca\f5ca";
}

.fa-helmet-un {
  --fa: "\e503";
  --fa--fa: "\e503\e503";
}

.fa-location-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d";
}

.fa-map-marker-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d";
}

.fa-arrow-left-to-line {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e";
}

.fa-arrow-to-left {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e";
}

.fa-bullseye {
  --fa: "\f140";
  --fa--fa: "\f140\f140";
}

.fa-sushi {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a";
}

.fa-nigiri {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a";
}

.fa-message-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de";
}

.fa-comment-alt-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de";
}

.fa-trash-list {
  --fa: "\e2b1";
  --fa--fa: "\e2b1\e2b1";
}

.fa-bacon {
  --fa: "\f7e5";
  --fa--fa: "\f7e5\f7e5";
}

.fa-option {
  --fa: "\e318";
  --fa--fa: "\e318\e318";
}

.fa-raccoon {
  --fa: "\e613";
  --fa--fa: "\e613\e613";
}

.fa-hand-point-down {
  --fa: "\f0a7";
  --fa--fa: "\f0a7\f0a7";
}

.fa-arrow-up-from-bracket {
  --fa: "\e09a";
  --fa--fa: "\e09a\e09a";
}

.fa-head-side-gear {
  --fa: "\e611";
  --fa--fa: "\e611\e611";
}

.fa-trash-plus {
  --fa: "\e2b2";
  --fa--fa: "\e2b2\e2b2";
}

.fa-file-cad {
  --fa: "\e672";
  --fa--fa: "\e672\e672";
}

.fa-objects-align-top {
  --fa: "\e3c0";
  --fa--fa: "\e3c0\e3c0";
}

.fa-folder {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b";
}

.fa-folder-blank {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b";
}

.fa-face-anxious-sweat {
  --fa: "\e36a";
  --fa--fa: "\e36a\e36a";
}

.fa-credit-card-blank {
  --fa: "\f389";
  --fa--fa: "\f389\f389";
}

.fa-file-waveform {
  --fa: "\f478";
  --fa--fa: "\f478\f478";
}

.fa-file-medical-alt {
  --fa: "\f478";
  --fa--fa: "\f478\f478";
}

.fa-microchip-ai {
  --fa: "\e1ec";
  --fa--fa: "\e1ec\e1ec";
}

.fa-mug {
  --fa: "\f874";
  --fa--fa: "\f874\f874";
}

.fa-plane-up-slash {
  --fa: "\e22e";
  --fa--fa: "\e22e\e22e";
}

.fa-radiation {
  --fa: "\f7b9";
  --fa--fa: "\f7b9\f7b9";
}

.fa-pen-circle {
  --fa: "\e20e";
  --fa--fa: "\e20e\e20e";
}

.fa-bag-seedling {
  --fa: "\e5f2";
  --fa--fa: "\e5f2\e5f2";
}

.fa-chart-simple {
  --fa: "\e473";
  --fa--fa: "\e473\e473";
}

.fa-crutches {
  --fa: "\f7f8";
  --fa--fa: "\f7f8\f7f8";
}

.fa-circle-parking {
  --fa: "\f615";
  --fa--fa: "\f615\f615";
}

.fa-parking-circle {
  --fa: "\f615";
  --fa--fa: "\f615\f615";
}

.fa-mars-stroke {
  --fa: "\f229";
  --fa--fa: "\f229\f229";
}

.fa-leaf-oak {
  --fa: "\f6f7";
  --fa--fa: "\f6f7\f6f7";
}

.fa-square-bolt {
  --fa: "\e265";
  --fa--fa: "\e265\e265";
}

.fa-vial {
  --fa: "\f492";
  --fa--fa: "\f492\f492";
}

.fa-gauge {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-dashboard {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-gauge-med {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-tachometer-alt-average {
  --fa: "\f624";
  --fa--fa: "\f624\f624";
}

.fa-wand-magic-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca";
}

.fa-magic-wand-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca";
}

.fa-lambda {
  --fa: "\f66e";
  --fa--fa: "\f66e\f66e";
}

.fa-e {
  --fa: "E";
  --fa--fa: "EE";
}

.fa-pizza {
  --fa: "\f817";
  --fa--fa: "\f817\f817";
}

.fa-bowl-chopsticks-noodles {
  --fa: "\e2ea";
  --fa--fa: "\e2ea\e2ea";
}

.fa-h3 {
  --fa: "\f315";
  --fa--fa: "\f315\f315";
}

.fa-pen-clip {
  --fa: "\f305";
  --fa--fa: "\f305\f305";
}

.fa-pen-alt {
  --fa: "\f305";
  --fa--fa: "\f305\f305";
}

.fa-bridge-circle-exclamation {
  --fa: "\e4ca";
  --fa--fa: "\e4ca\e4ca";
}

.fa-badge-percent {
  --fa: "\f646";
  --fa--fa: "\f646\f646";
}

.fa-rotate-reverse {
  --fa: "\e631";
  --fa--fa: "\e631\e631";
}

.fa-user {
  --fa: "\f007";
  --fa--fa: "\f007\f007";
}

.fa-sensor {
  --fa: "\e028";
  --fa--fa: "\e028\e028";
}

.fa-comma {
  --fa: "\,";
  --fa--fa: "\,\,";
}

.fa-school-circle-check {
  --fa: "\e56b";
  --fa--fa: "\e56b\e56b";
}

.fa-toilet-paper-under {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0";
}

.fa-toilet-paper-reverse {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0";
}

.fa-light-emergency {
  --fa: "\e41f";
  --fa--fa: "\e41f\e41f";
}

.fa-arrow-down-to-arc {
  --fa: "\e4ae";
  --fa--fa: "\e4ae\e4ae";
}

.fa-dumpster {
  --fa: "\f793";
  --fa--fa: "\f793\f793";
}

.fa-van-shuttle {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6";
}

.fa-shuttle-van {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6";
}

.fa-building-user {
  --fa: "\e4da";
  --fa--fa: "\e4da\e4da";
}

.fa-light-switch {
  --fa: "\e017";
  --fa--fa: "\e017\e017";
}

.fa-square-caret-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191";
}

.fa-caret-square-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191";
}

.fa-highlighter {
  --fa: "\f591";
  --fa--fa: "\f591\f591";
}

.fa-wave-pulse {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8";
}

.fa-heart-rate {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8";
}

.fa-key {
  --fa: "\f084";
  --fa--fa: "\f084\f084";
}

.fa-arrow-left-to-bracket {
  --fa: "\e669";
  --fa--fa: "\e669\e669";
}

.fa-hat-santa {
  --fa: "\f7a7";
  --fa--fa: "\f7a7\f7a7";
}

.fa-tamale {
  --fa: "\e451";
  --fa--fa: "\e451\e451";
}

.fa-box-check {
  --fa: "\f467";
  --fa--fa: "\f467\f467";
}

.fa-bullhorn {
  --fa: "\f0a1";
  --fa--fa: "\f0a1\f0a1";
}

.fa-steak {
  --fa: "\f824";
  --fa--fa: "\f824\f824";
}

.fa-location-crosshairs-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603";
}

.fa-location-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603";
}

.fa-person-dolly {
  --fa: "\f4d0";
  --fa--fa: "\f4d0\f4d0";
}

.fa-globe {
  --fa: "\f0ac";
  --fa--fa: "\f0ac\f0ac";
}

.fa-synagogue {
  --fa: "\f69b";
  --fa--fa: "\f69b\f69b";
}

.fa-file-chart-column {
  --fa: "\f659";
  --fa--fa: "\f659\f659";
}

.fa-file-chart-line {
  --fa: "\f659";
  --fa--fa: "\f659\f659";
}

.fa-person-half-dress {
  --fa: "\e548";
  --fa--fa: "\e548\e548";
}

.fa-folder-image {
  --fa: "\e18a";
  --fa--fa: "\e18a\e18a";
}

.fa-calendar-pen {
  --fa: "\f333";
  --fa--fa: "\f333\f333";
}

.fa-calendar-edit {
  --fa: "\f333";
  --fa--fa: "\f333\f333";
}

.fa-road-bridge {
  --fa: "\e563";
  --fa--fa: "\e563\e563";
}

.fa-face-smile-tear {
  --fa: "\e393";
  --fa--fa: "\e393\e393";
}

.fa-message-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8";
}

.fa-comment-alt-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8";
}

.fa-location-arrow {
  --fa: "\f124";
  --fa--fa: "\f124\f124";
}

.fa-c {
  --fa: "C";
  --fa--fa: "CC";
}

.fa-tablet-button {
  --fa: "\f10a";
  --fa--fa: "\f10a\f10a";
}

.fa-person-dress-fairy {
  --fa: "\e607";
  --fa--fa: "\e607\e607";
}

.fa-rectangle-history-circle-user {
  --fa: "\e4a4";
  --fa--fa: "\e4a4\e4a4";
}

.fa-building-lock {
  --fa: "\e4d6";
  --fa--fa: "\e4d6\e4d6";
}

.fa-chart-line-up {
  --fa: "\e0e5";
  --fa--fa: "\e0e5\e0e5";
}

.fa-mailbox {
  --fa: "\f813";
  --fa--fa: "\f813\f813";
}

.fa-sign-posts {
  --fa: "\e625";
  --fa--fa: "\e625\e625";
}

.fa-truck-bolt {
  --fa: "\e3d0";
  --fa--fa: "\e3d0\e3d0";
}

.fa-pizza-slice {
  --fa: "\f818";
  --fa--fa: "\f818\f818";
}

.fa-money-bill-wave {
  --fa: "\f53a";
  --fa--fa: "\f53a\f53a";
}

.fa-chart-area {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe";
}

.fa-area-chart {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe";
}

.fa-house-flag {
  --fa: "\e50d";
  --fa--fa: "\e50d\e50d";
}

.fa-circle-three-quarters-stroke {
  --fa: "\e5d4";
  --fa--fa: "\e5d4\e5d4";
}

.fa-person-circle-minus {
  --fa: "\e540";
  --fa--fa: "\e540\e540";
}

.fa-scalpel {
  --fa: "\f61d";
  --fa--fa: "\f61d\f61d";
}

.fa-ban {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e";
}

.fa-cancel {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e";
}

.fa-bell-exclamation {
  --fa: "\f848";
  --fa--fa: "\f848\f848";
}

.fa-circle-bookmark {
  --fa: "\e100";
  --fa--fa: "\e100\e100";
}

.fa-bookmark-circle {
  --fa: "\e100";
  --fa--fa: "\e100\e100";
}

.fa-egg-fried {
  --fa: "\f7fc";
  --fa--fa: "\f7fc\f7fc";
}

.fa-face-weary {
  --fa: "\e3a1";
  --fa--fa: "\e3a1\e3a1";
}

.fa-uniform-martial-arts {
  --fa: "\e3d1";
  --fa--fa: "\e3d1\e3d1";
}

.fa-camera-rotate {
  --fa: "\e0d8";
  --fa--fa: "\e0d8\e0d8";
}

.fa-sun-dust {
  --fa: "\f764";
  --fa--fa: "\f764\f764";
}

.fa-comment-text {
  --fa: "\e14d";
  --fa--fa: "\e14d\e14d";
}

.fa-spray-can-sparkles {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0";
}

.fa-air-freshener {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0";
}

.fa-signal-bars {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-alt {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-alt-4 {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-signal-bars-strong {
  --fa: "\f690";
  --fa--fa: "\f690\f690";
}

.fa-diamond-exclamation {
  --fa: "\e405";
  --fa--fa: "\e405\e405";
}

.fa-star {
  --fa: "\f005";
  --fa--fa: "\f005\f005";
}

.fa-dial-min {
  --fa: "\e161";
  --fa--fa: "\e161\e161";
}

.fa-repeat {
  --fa: "\f363";
  --fa--fa: "\f363\f363";
}

.fa-cross {
  --fa: "\f654";
  --fa--fa: "\f654\f654";
}

.fa-page-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429";
}

.fa-file-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429";
}

.fa-box {
  --fa: "\f466";
  --fa--fa: "\f466\f466";
}

.fa-venus-mars {
  --fa: "\f228";
  --fa--fa: "\f228\f228";
}

.fa-clock-seven-thirty {
  --fa: "\e351";
  --fa--fa: "\e351\e351";
}

.fa-arrow-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245";
}

.fa-mouse-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245";
}

.fa-clock-four-thirty {
  --fa: "\e34b";
  --fa--fa: "\e34b\e34b";
}

.fa-signal-bars-good {
  --fa: "\f693";
  --fa--fa: "\f693\f693";
}

.fa-signal-alt-3 {
  --fa: "\f693";
  --fa--fa: "\f693\f693";
}

.fa-cactus {
  --fa: "\f8a7";
  --fa--fa: "\f8a7\f8a7";
}

.fa-lightbulb-gear {
  --fa: "\e5fd";
  --fa--fa: "\e5fd\e5fd";
}

.fa-maximize {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e";
}

.fa-expand-arrows-alt {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e";
}

.fa-charging-station {
  --fa: "\f5e7";
  --fa--fa: "\f5e7\f5e7";
}

.fa-shapes {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f";
}

.fa-triangle-circle-square {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f";
}

.fa-plane-tail {
  --fa: "\e22c";
  --fa--fa: "\e22c\e22c";
}

.fa-gauge-simple-max {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b";
}

.fa-tachometer-fastest {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b";
}

.fa-circle-u {
  --fa: "\e127";
  --fa--fa: "\e127\e127";
}

.fa-shield-slash {
  --fa: "\e24b";
  --fa--fa: "\e24b\e24b";
}

.fa-square-phone-hangup {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a";
}

.fa-phone-square-down {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a";
}

.fa-arrow-up-left {
  --fa: "\e09d";
  --fa--fa: "\e09d\e09d";
}

.fa-transporter-1 {
  --fa: "\e043";
  --fa--fa: "\e043\e043";
}

.fa-peanuts {
  --fa: "\e431";
  --fa--fa: "\e431\e431";
}

.fa-shuffle {
  --fa: "\f074";
  --fa--fa: "\f074\f074";
}

.fa-random {
  --fa: "\f074";
  --fa--fa: "\f074\f074";
}

.fa-person-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c";
}

.fa-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c";
}

.fa-mobile-retro {
  --fa: "\e527";
  --fa--fa: "\e527\e527";
}

.fa-grip-lines-vertical {
  --fa: "\f7a5";
  --fa--fa: "\f7a5\f7a5";
}

.fa-bin-bottles-recycle {
  --fa: "\e5f6";
  --fa--fa: "\e5f6\e5f6";
}

.fa-arrow-up-from-square {
  --fa: "\e09c";
  --fa--fa: "\e09c\e09c";
}

.fa-file-dashed-line {
  --fa: "\f877";
  --fa--fa: "\f877\f877";
}

.fa-page-break {
  --fa: "\f877";
  --fa--fa: "\f877\f877";
}

.fa-bracket-curly-right {
  --fa: "\}";
  --fa--fa: "\}\}";
}

.fa-spider {
  --fa: "\f717";
  --fa--fa: "\f717\f717";
}

.fa-clock-three {
  --fa: "\e356";
  --fa--fa: "\e356\e356";
}

.fa-hands-bound {
  --fa: "\e4f9";
  --fa--fa: "\e4f9\e4f9";
}

.fa-scalpel-line-dashed {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e";
}

.fa-scalpel-path {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e";
}

.fa-file-invoice-dollar {
  --fa: "\f571";
  --fa--fa: "\f571\f571";
}

.fa-pipe-smoking {
  --fa: "\e3c4";
  --fa--fa: "\e3c4\e3c4";
}

.fa-face-astonished {
  --fa: "\e36b";
  --fa--fa: "\e36b\e36b";
}

.fa-window {
  --fa: "\f40e";
  --fa--fa: "\f40e\f40e";
}

.fa-plane-circle-exclamation {
  --fa: "\e556";
  --fa--fa: "\e556\e556";
}

.fa-ear {
  --fa: "\f5f0";
  --fa--fa: "\f5f0\f5f0";
}

.fa-file-lock {
  --fa: "\e3a6";
  --fa--fa: "\e3a6\e3a6";
}

.fa-diagram-venn {
  --fa: "\e15a";
  --fa--fa: "\e15a\e15a";
}

.fa-arrow-down-from-bracket {
  --fa: "\e667";
  --fa--fa: "\e667\e667";
}

.fa-x-ray {
  --fa: "\f497";
  --fa--fa: "\f497\f497";
}

.fa-goal-net {
  --fa: "\e3ab";
  --fa--fa: "\e3ab\e3ab";
}

.fa-coffin-cross {
  --fa: "\e051";
  --fa--fa: "\e051\e051";
}

.fa-octopus {
  --fa: "\e688";
  --fa--fa: "\e688\e688";
}

.fa-spell-check {
  --fa: "\f891";
  --fa--fa: "\f891\f891";
}

.fa-location-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-map-marker-times {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-map-marker-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e";
}

.fa-circle-quarter-stroke {
  --fa: "\e5d3";
  --fa--fa: "\e5d3\e5d3";
}

.fa-lasso {
  --fa: "\f8c8";
  --fa--fa: "\f8c8\f8c8";
}

.fa-slash {
  --fa: "\f715";
  --fa--fa: "\f715\f715";
}

.fa-person-to-portal {
  --fa: "\e022";
  --fa--fa: "\e022\e022";
}

.fa-portal-enter {
  --fa: "\e022";
  --fa--fa: "\e022\e022";
}

.fa-calendar-star {
  --fa: "\f736";
  --fa--fa: "\f736\f736";
}

.fa-computer-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc";
}

.fa-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc";
}

.fa-arrow-right-to-bracket {
  --fa: "\f090";
  --fa--fa: "\f090\f090";
}

.fa-sign-in {
  --fa: "\f090";
  --fa--fa: "\f090\f090";
}

.fa-pegasus {
  --fa: "\f703";
  --fa--fa: "\f703\f703";
}

.fa-files-medical {
  --fa: "\f7fd";
  --fa--fa: "\f7fd\f7fd";
}

.fa-cannon {
  --fa: "\e642";
  --fa--fa: "\e642\e642";
}

.fa-nfc-lock {
  --fa: "\e1f8";
  --fa--fa: "\e1f8\e1f8";
}

.fa-person-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8";
}

.fa-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8";
}

.fa-square-6 {
  --fa: "\e25b";
  --fa--fa: "\e25b\e25b";
}

.fa-shop-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070";
}

.fa-store-alt-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070";
}

.fa-wind-turbine {
  --fa: "\f89b";
  --fa--fa: "\f89b\f89b";
}

.fa-sliders-simple {
  --fa: "\e253";
  --fa--fa: "\e253\e253";
}

.fa-grid-round {
  --fa: "\e5da";
  --fa--fa: "\e5da\e5da";
}

.fa-badge-sheriff {
  --fa: "\f8a2";
  --fa--fa: "\f8a2\f8a2";
}

.fa-server {
  --fa: "\f233";
  --fa--fa: "\f233\f233";
}

.fa-virus-covid-slash {
  --fa: "\e4a9";
  --fa--fa: "\e4a9\e4a9";
}

.fa-intersection {
  --fa: "\f668";
  --fa--fa: "\f668\f668";
}

.fa-shop-lock {
  --fa: "\e4a5";
  --fa--fa: "\e4a5\e4a5";
}

.fa-family {
  --fa: "\e300";
  --fa--fa: "\e300\e300";
}

.fa-hourglass-start {
  --fa: "\f251";
  --fa--fa: "\f251\f251";
}

.fa-hourglass-1 {
  --fa: "\f251";
  --fa--fa: "\f251\f251";
}

.fa-user-hair-buns {
  --fa: "\e3d3";
  --fa--fa: "\e3d3\e3d3";
}

.fa-blender-phone {
  --fa: "\f6b6";
  --fa--fa: "\f6b6\f6b6";
}

.fa-hourglass-clock {
  --fa: "\e41b";
  --fa--fa: "\e41b\e41b";
}

.fa-person-seat-reclined {
  --fa: "\e21f";
  --fa--fa: "\e21f\e21f";
}

.fa-paper-plane-top {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-paper-plane-alt {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-send {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a";
}

.fa-message-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc";
}

.fa-comment-alt-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc";
}

.fa-lightbulb-exclamation {
  --fa: "\f671";
  --fa--fa: "\f671\f671";
}

.fa-layer-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe";
}

.fa-layer-group-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe";
}

.fa-chart-pie-simple-circle-currency {
  --fa: "\e604";
  --fa--fa: "\e604\e604";
}

.fa-circle-e {
  --fa: "\e109";
  --fa--fa: "\e109\e109";
}

.fa-building-wheat {
  --fa: "\e4db";
  --fa--fa: "\e4db\e4db";
}

.fa-gauge-max {
  --fa: "\f626";
  --fa--fa: "\f626\f626";
}

.fa-tachometer-alt-fastest {
  --fa: "\f626";
  --fa--fa: "\f626\f626";
}

.fa-person-breastfeeding {
  --fa: "\e53a";
  --fa--fa: "\e53a\e53a";
}

.fa-apostrophe {
  --fa: "\'";
  --fa--fa: "\'\'";
}

.fa-file-png {
  --fa: "\e666";
  --fa--fa: "\e666\e666";
}

.fa-fire-hydrant {
  --fa: "\e17f";
  --fa--fa: "\e17f\e17f";
}

.fa-right-to-bracket {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6";
}

.fa-sign-in-alt {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6";
}

.fa-video-plus {
  --fa: "\f4e1";
  --fa--fa: "\f4e1\f4e1";
}

.fa-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352";
}

.fa-arrow-alt-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352";
}

.fa-comment-smile {
  --fa: "\f4b4";
  --fa--fa: "\f4b4\f4b4";
}

.fa-venus {
  --fa: "\f221";
  --fa--fa: "\f221\f221";
}

.fa-passport {
  --fa: "\f5ab";
  --fa--fa: "\f5ab\f5ab";
}

.fa-thumbtack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f";
}

.fa-thumb-tack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f";
}

.fa-inbox-in {
  --fa: "\f310";
  --fa--fa: "\f310\f310";
}

.fa-inbox-arrow-down {
  --fa: "\f310";
  --fa--fa: "\f310\f310";
}

.fa-heart-pulse {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e";
}

.fa-heartbeat {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e";
}

.fa-circle-8 {
  --fa: "\e0f5";
  --fa--fa: "\e0f5\e0f5";
}

.fa-clouds-moon {
  --fa: "\f745";
  --fa--fa: "\f745\f745";
}

.fa-clock-ten-thirty {
  --fa: "\e355";
  --fa--fa: "\e355\e355";
}

.fa-people-carry-box {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce";
}

.fa-people-carry {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce";
}

.fa-folder-user {
  --fa: "\e18e";
  --fa--fa: "\e18e\e18e";
}

.fa-trash-can-xmark {
  --fa: "\e2ae";
  --fa--fa: "\e2ae\e2ae";
}

.fa-temperature-high {
  --fa: "\f769";
  --fa--fa: "\f769\f769";
}

.fa-microchip {
  --fa: "\f2db";
  --fa--fa: "\f2db\f2db";
}

.fa-left-long-to-line {
  --fa: "\e41e";
  --fa--fa: "\e41e\e41e";
}

.fa-crown {
  --fa: "\f521";
  --fa--fa: "\f521\f521";
}

.fa-weight-hanging {
  --fa: "\f5cd";
  --fa--fa: "\f5cd\f5cd";
}

.fa-xmarks-lines {
  --fa: "\e59a";
  --fa--fa: "\e59a\e59a";
}

.fa-file-prescription {
  --fa: "\f572";
  --fa--fa: "\f572\f572";
}

.fa-table-cells-lock {
  --fa: "\e679";
  --fa--fa: "\e679\e679";
}

.fa-calendar-range {
  --fa: "\e0d6";
  --fa--fa: "\e0d6\e0d6";
}

.fa-flower-daffodil {
  --fa: "\f800";
  --fa--fa: "\f800\f800";
}

.fa-hand-back-point-up {
  --fa: "\e1a2";
  --fa--fa: "\e1a2\e1a2";
}

.fa-weight-scale {
  --fa: "\f496";
  --fa--fa: "\f496\f496";
}

.fa-weight {
  --fa: "\f496";
  --fa--fa: "\f496\f496";
}

.fa-arrow-up-to-arc {
  --fa: "\e617";
  --fa--fa: "\e617\e617";
}

.fa-star-exclamation {
  --fa: "\f2f3";
  --fa--fa: "\f2f3\f2f3";
}

.fa-books {
  --fa: "\f5db";
  --fa--fa: "\f5db\f5db";
}

.fa-user-group {
  --fa: "\f500";
  --fa--fa: "\f500\f500";
}

.fa-user-friends {
  --fa: "\f500";
  --fa--fa: "\f500\f500";
}

.fa-arrow-up-a-z {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e";
}

.fa-sort-alpha-up {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e";
}

.fa-layer-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff";
}

.fa-layer-group-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff";
}

.fa-play-pause {
  --fa: "\e22f";
  --fa--fa: "\e22f\e22f";
}

.fa-block-question {
  --fa: "\e3dd";
  --fa--fa: "\e3dd\e3dd";
}

.fa-snooze {
  --fa: "\f880";
  --fa--fa: "\f880\f880";
}

.fa-zzz {
  --fa: "\f880";
  --fa--fa: "\f880\f880";
}

.fa-scanner-image {
  --fa: "\f8f3";
  --fa--fa: "\f8f3\f8f3";
}

.fa-tv-retro {
  --fa: "\f401";
  --fa--fa: "\f401\f401";
}

.fa-square-t {
  --fa: "\e280";
  --fa--fa: "\e280\e280";
}

.fa-farm {
  --fa: "\f864";
  --fa--fa: "\f864\f864";
}

.fa-barn-silo {
  --fa: "\f864";
  --fa--fa: "\f864\f864";
}

.fa-chess-knight {
  --fa: "\f441";
  --fa--fa: "\f441\f441";
}

.fa-bars-sort {
  --fa: "\e0ae";
  --fa--fa: "\e0ae\e0ae";
}

.fa-pallet-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-palette-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-pallet-alt {
  --fa: "\f483";
  --fa--fa: "\f483\f483";
}

.fa-face-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b";
}

.fa-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b";
}

.fa-code-simple {
  --fa: "\e13d";
  --fa--fa: "\e13d\e13d";
}

.fa-bolt-slash {
  --fa: "\e0b8";
  --fa--fa: "\e0b8\e0b8";
}

.fa-panel-fire {
  --fa: "\e42f";
  --fa--fa: "\e42f\e42f";
}

.fa-binary-circle-check {
  --fa: "\e33c";
  --fa--fa: "\e33c\e33c";
}

.fa-comment-minus {
  --fa: "\f4b1";
  --fa--fa: "\f4b1\f4b1";
}

.fa-burrito {
  --fa: "\f7ed";
  --fa--fa: "\f7ed\f7ed";
}

.fa-violin {
  --fa: "\f8ed";
  --fa--fa: "\f8ed\f8ed";
}

.fa-objects-column {
  --fa: "\e3c1";
  --fa--fa: "\e3c1\e3c1";
}

.fa-square-chevron-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329";
}

.fa-chevron-square-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329";
}

.fa-comment-plus {
  --fa: "\f4b2";
  --fa--fa: "\f4b2\f4b2";
}

.fa-triangle-instrument {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2";
}

.fa-triangle-music {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2";
}

.fa-wheelchair {
  --fa: "\f193";
  --fa--fa: "\f193\f193";
}

.fa-user-pilot-tie {
  --fa: "\e2c1";
  --fa--fa: "\e2c1\e2c1";
}

.fa-piano-keyboard {
  --fa: "\f8d5";
  --fa--fa: "\f8d5\f8d5";
}

.fa-bed-empty {
  --fa: "\f8f9";
  --fa--fa: "\f8f9\f8f9";
}

.fa-circle-arrow-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa";
}

.fa-arrow-circle-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa";
}

.fa-toggle-on {
  --fa: "\f205";
  --fa--fa: "\f205\f205";
}

.fa-rectangle-vertical {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb";
}

.fa-rectangle-portrait {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb";
}

.fa-person-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554";
}

.fa-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554";
}

.fa-l {
  --fa: "L";
  --fa--fa: "LL";
}

.fa-signal-stream {
  --fa: "\f8dd";
  --fa--fa: "\f8dd\f8dd";
}

.fa-down-to-bracket {
  --fa: "\e4e7";
  --fa--fa: "\e4e7\e4e7";
}

.fa-circle-z {
  --fa: "\e130";
  --fa--fa: "\e130\e130";
}

.fa-stars {
  --fa: "\f762";
  --fa--fa: "\f762\f762";
}

.fa-fire {
  --fa: "\f06d";
  --fa--fa: "\f06d\f06d";
}

.fa-bed-pulse {
  --fa: "\f487";
  --fa--fa: "\f487\f487";
}

.fa-procedures {
  --fa: "\f487";
  --fa--fa: "\f487\f487";
}

.fa-house-day {
  --fa: "\e00e";
  --fa--fa: "\e00e\e00e";
}

.fa-shuttle-space {
  --fa: "\f197";
  --fa--fa: "\f197\f197";
}

.fa-space-shuttle {
  --fa: "\f197";
  --fa--fa: "\f197\f197";
}

.fa-shirt-long-sleeve {
  --fa: "\e3c7";
  --fa--fa: "\e3c7\e3c7";
}

.fa-chart-pie-simple {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e";
}

.fa-chart-pie-alt {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e";
}

.fa-face-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599";
}

.fa-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599";
}

.fa-folder-open {
  --fa: "\f07c";
  --fa--fa: "\f07c\f07c";
}

.fa-album-collection-circle-user {
  --fa: "\e48f";
  --fa--fa: "\e48f\e48f";
}

.fa-candy {
  --fa: "\e3e7";
  --fa--fa: "\e3e7\e3e7";
}

.fa-bowl-hot {
  --fa: "\f823";
  --fa--fa: "\f823\f823";
}

.fa-soup {
  --fa: "\f823";
  --fa--fa: "\f823\f823";
}

.fa-flatbread {
  --fa: "\e40b";
  --fa--fa: "\e40b\e40b";
}

.fa-heart-circle-plus {
  --fa: "\e500";
  --fa--fa: "\e500\e500";
}

.fa-code-fork {
  --fa: "\e13b";
  --fa--fa: "\e13b\e13b";
}

.fa-city {
  --fa: "\f64f";
  --fa--fa: "\f64f\f64f";
}

.fa-signal-bars-weak {
  --fa: "\f691";
  --fa--fa: "\f691\f691";
}

.fa-signal-alt-1 {
  --fa: "\f691";
  --fa--fa: "\f691\f691";
}

.fa-microphone-lines {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9";
}

.fa-microphone-alt {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9";
}

.fa-clock-twelve {
  --fa: "\e358";
  --fa--fa: "\e358\e358";
}

.fa-pepper-hot {
  --fa: "\f816";
  --fa--fa: "\f816\f816";
}

.fa-citrus-slice {
  --fa: "\e2f5";
  --fa--fa: "\e2f5\e2f5";
}

.fa-sheep {
  --fa: "\f711";
  --fa--fa: "\f711\f711";
}

.fa-unlock {
  --fa: "\f09c";
  --fa--fa: "\f09c\f09c";
}

.fa-colon-sign {
  --fa: "\e140";
  --fa--fa: "\e140\e140";
}

.fa-headset {
  --fa: "\f590";
  --fa--fa: "\f590\f590";
}

.fa-badger-honey {
  --fa: "\f6b4";
  --fa--fa: "\f6b4\f6b4";
}

.fa-h4 {
  --fa: "\f86a";
  --fa--fa: "\f86a\f86a";
}

.fa-store-slash {
  --fa: "\e071";
  --fa--fa: "\e071\e071";
}

.fa-road-circle-xmark {
  --fa: "\e566";
  --fa--fa: "\e566\e566";
}

.fa-signal-slash {
  --fa: "\f695";
  --fa--fa: "\f695\f695";
}

.fa-user-minus {
  --fa: "\f503";
  --fa--fa: "\f503\f503";
}

.fa-mars-stroke-up {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a";
}

.fa-mars-stroke-v {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a";
}

.fa-champagne-glasses {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f";
}

.fa-glass-cheers {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f";
}

.fa-taco {
  --fa: "\f826";
  --fa--fa: "\f826\f826";
}

.fa-hexagon-plus {
  --fa: "\f300";
  --fa--fa: "\f300\f300";
}

.fa-plus-hexagon {
  --fa: "\f300";
  --fa--fa: "\f300\f300";
}

.fa-clipboard {
  --fa: "\f328";
  --fa--fa: "\f328\f328";
}

.fa-house-circle-exclamation {
  --fa: "\e50a";
  --fa--fa: "\e50a\e50a";
}

.fa-file-arrow-up {
  --fa: "\f574";
  --fa--fa: "\f574\f574";
}

.fa-file-upload {
  --fa: "\f574";
  --fa--fa: "\f574\f574";
}

.fa-wifi {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-wifi-3 {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-wifi-strong {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb";
}

.fa-messages {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6";
}

.fa-comments-alt {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6";
}

.fa-bath {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd";
}

.fa-bathtub {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd";
}

.fa-umbrella-simple {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc";
}

.fa-umbrella-alt {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc";
}

.fa-rectangle-history-circle-plus {
  --fa: "\e4a3";
  --fa--fa: "\e4a3\e4a3";
}

.fa-underline {
  --fa: "\f0cd";
  --fa--fa: "\f0cd\f0cd";
}

.fa-prescription-bottle-pill {
  --fa: "\e5c0";
  --fa--fa: "\e5c0\e5c0";
}

.fa-user-pen {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff";
}

.fa-user-edit {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff";
}

.fa-binary-slash {
  --fa: "\e33e";
  --fa--fa: "\e33e\e33e";
}

.fa-square-o {
  --fa: "\e278";
  --fa--fa: "\e278\e278";
}

.fa-caduceus {
  --fa: "\e681";
  --fa--fa: "\e681\e681";
}

.fa-signature {
  --fa: "\f5b7";
  --fa--fa: "\f5b7\f5b7";
}

.fa-stroopwafel {
  --fa: "\f551";
  --fa--fa: "\f551\f551";
}

.fa-bold {
  --fa: "\f032";
  --fa--fa: "\f032\f032";
}

.fa-anchor-lock {
  --fa: "\e4ad";
  --fa--fa: "\e4ad\e4ad";
}

.fa-building-ngo {
  --fa: "\e4d7";
  --fa--fa: "\e4d7\e4d7";
}

.fa-transporter-3 {
  --fa: "\e045";
  --fa--fa: "\e045\e045";
}

.fa-engine-warning {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2";
}

.fa-engine-exclamation {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2";
}

.fa-circle-down-right {
  --fa: "\e108";
  --fa--fa: "\e108\e108";
}

.fa-square-k {
  --fa: "\e274";
  --fa--fa: "\e274\e274";
}

.fa-manat-sign {
  --fa: "\e1d5";
  --fa--fa: "\e1d5\e1d5";
}

.fa-money-check-pen {
  --fa: "\f872";
  --fa--fa: "\f872\f872";
}

.fa-money-check-edit {
  --fa: "\f872";
  --fa--fa: "\f872\f872";
}

.fa-not-equal {
  --fa: "\f53e";
  --fa--fa: "\f53e\f53e";
}

.fa-border-top-left {
  --fa: "\f853";
  --fa--fa: "\f853\f853";
}

.fa-border-style {
  --fa: "\f853";
  --fa--fa: "\f853\f853";
}

.fa-map-location-dot {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0";
}

.fa-map-marked-alt {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0";
}

.fa-tilde {
  --fa: "\~";
  --fa--fa: "\~\~";
}

.fa-jedi {
  --fa: "\f669";
  --fa--fa: "\f669\f669";
}

.fa-square-poll-vertical {
  --fa: "\f681";
  --fa--fa: "\f681\f681";
}

.fa-poll {
  --fa: "\f681";
  --fa--fa: "\f681\f681";
}

.fa-arrow-down-square-triangle {
  --fa: "\f889";
  --fa--fa: "\f889\f889";
}

.fa-sort-shapes-down-alt {
  --fa: "\f889";
  --fa--fa: "\f889\f889";
}

.fa-mug-hot {
  --fa: "\f7b6";
  --fa--fa: "\f7b6\f7b6";
}

.fa-dog-leashed {
  --fa: "\f6d4";
  --fa--fa: "\f6d4\f6d4";
}

.fa-car-battery {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df";
}

.fa-battery-car {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df";
}

.fa-face-downcast-sweat {
  --fa: "\e371";
  --fa--fa: "\e371\e371";
}

.fa-mailbox-flag-up {
  --fa: "\e5bb";
  --fa--fa: "\e5bb\e5bb";
}

.fa-memo-circle-info {
  --fa: "\e49a";
  --fa--fa: "\e49a\e49a";
}

.fa-gift {
  --fa: "\f06b";
  --fa--fa: "\f06b\f06b";
}

.fa-dice-two {
  --fa: "\f528";
  --fa--fa: "\f528\f528";
}

.fa-volume {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8";
}

.fa-volume-medium {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8";
}

.fa-transporter-5 {
  --fa: "\e2a6";
  --fa--fa: "\e2a6\e2a6";
}

.fa-gauge-circle-bolt {
  --fa: "\e496";
  --fa--fa: "\e496\e496";
}

.fa-coin-front {
  --fa: "\e3fc";
  --fa--fa: "\e3fc\e3fc";
}

.fa-file-slash {
  --fa: "\e3a7";
  --fa--fa: "\e3a7\e3a7";
}

.fa-message-arrow-up-right {
  --fa: "\e1dd";
  --fa--fa: "\e1dd\e1dd";
}

.fa-treasure-chest {
  --fa: "\f723";
  --fa--fa: "\f723\f723";
}

.fa-chess-queen {
  --fa: "\f445";
  --fa--fa: "\f445\f445";
}

.fa-paintbrush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paint-brush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paint-brush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-paintbrush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9";
}

.fa-glasses {
  --fa: "\f530";
  --fa--fa: "\f530\f530";
}

.fa-hood-cloak {
  --fa: "\f6ef";
  --fa--fa: "\f6ef\f6ef";
}

.fa-square-quote {
  --fa: "\e329";
  --fa--fa: "\e329\e329";
}

.fa-up-left {
  --fa: "\e2bd";
  --fa--fa: "\e2bd\e2bd";
}

.fa-bring-front {
  --fa: "\f857";
  --fa--fa: "\f857\f857";
}

.fa-chess-board {
  --fa: "\f43c";
  --fa--fa: "\f43c\f43c";
}

.fa-burger-cheese {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1";
}

.fa-cheeseburger {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1";
}

.fa-building-circle-check {
  --fa: "\e4d2";
  --fa--fa: "\e4d2\e4d2";
}

.fa-repeat-1 {
  --fa: "\f365";
  --fa--fa: "\f365\f365";
}

.fa-arrow-down-to-line {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d";
}

.fa-arrow-to-bottom {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d";
}

.fa-grid-5 {
  --fa: "\e199";
  --fa--fa: "\e199\e199";
}

.fa-swap-arrows {
  --fa: "\e60a";
  --fa--fa: "\e60a\e60a";
}

.fa-right-long-to-line {
  --fa: "\e444";
  --fa--fa: "\e444\e444";
}

.fa-person-chalkboard {
  --fa: "\e53d";
  --fa--fa: "\e53d\e53d";
}

.fa-mars-stroke-right {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b";
}

.fa-mars-stroke-h {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b";
}

.fa-hand-back-fist {
  --fa: "\f255";
  --fa--fa: "\f255\f255";
}

.fa-hand-rock {
  --fa: "\f255";
  --fa--fa: "\f255\f255";
}

.fa-grid-round-5 {
  --fa: "\e5de";
  --fa--fa: "\e5de\e5de";
}

.fa-tally {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c";
}

.fa-tally-5 {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c";
}

.fa-square-caret-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151";
}

.fa-caret-square-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151";
}

.fa-cloud-showers-water {
  --fa: "\e4e4";
  --fa--fa: "\e4e4\e4e4";
}

.fa-chart-bar {
  --fa: "\f080";
  --fa--fa: "\f080\f080";
}

.fa-bar-chart {
  --fa: "\f080";
  --fa--fa: "\f080\f080";
}

.fa-hands-bubbles {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e";
}

.fa-hands-wash {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e";
}

.fa-less-than-equal {
  --fa: "\f537";
  --fa--fa: "\f537\f537";
}

.fa-train {
  --fa: "\f238";
  --fa--fa: "\f238\f238";
}

.fa-up-from-dotted-line {
  --fa: "\e456";
  --fa--fa: "\e456\e456";
}

.fa-eye-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8";
}

.fa-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8";
}

.fa-traffic-light-go {
  --fa: "\f638";
  --fa--fa: "\f638\f638";
}

.fa-face-exhaling {
  --fa: "\e480";
  --fa--fa: "\e480\e480";
}

.fa-sensor-fire {
  --fa: "\e02a";
  --fa--fa: "\e02a\e02a";
}

.fa-user-unlock {
  --fa: "\e058";
  --fa--fa: "\e058\e058";
}

.fa-hexagon-divide {
  --fa: "\e1ad";
  --fa--fa: "\e1ad\e1ad";
}

.fa-00 {
  --fa: "\e467";
  --fa--fa: "\e467\e467";
}

.fa-crow {
  --fa: "\f520";
  --fa--fa: "\f520\f520";
}

.fa-cassette-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4";
}

.fa-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4";
}

.fa-sailboat {
  --fa: "\e445";
  --fa--fa: "\e445\e445";
}

.fa-window-restore {
  --fa: "\f2d2";
  --fa--fa: "\f2d2\f2d2";
}

.fa-nfc-magnifying-glass {
  --fa: "\e1f9";
  --fa--fa: "\e1f9\e1f9";
}

.fa-file-binary {
  --fa: "\e175";
  --fa--fa: "\e175\e175";
}

.fa-circle-v {
  --fa: "\e12a";
  --fa--fa: "\e12a\e12a";
}

.fa-square-plus {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe";
}

.fa-plus-square {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe";
}

.fa-bowl-scoops {
  --fa: "\e3df";
  --fa--fa: "\e3df\e3df";
}

.fa-mistletoe {
  --fa: "\f7b4";
  --fa--fa: "\f7b4\f7b4";
}

.fa-custard {
  --fa: "\e403";
  --fa--fa: "\e403\e403";
}

.fa-lacrosse-stick {
  --fa: "\e3b5";
  --fa--fa: "\e3b5\e3b5";
}

.fa-hockey-mask {
  --fa: "\f6ee";
  --fa--fa: "\f6ee\f6ee";
}

.fa-sunrise {
  --fa: "\f766";
  --fa--fa: "\f766\f766";
}

.fa-subtitles {
  --fa: "\e60f";
  --fa--fa: "\e60f\e60f";
}

.fa-panel-ews {
  --fa: "\e42e";
  --fa--fa: "\e42e\e42e";
}

.fa-torii-gate {
  --fa: "\f6a1";
  --fa--fa: "\f6a1\f6a1";
}

.fa-cloud-exclamation {
  --fa: "\e491";
  --fa--fa: "\e491\e491";
}

.fa-message-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6";
}

.fa-comment-alt-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6";
}

.fa-frog {
  --fa: "\f52e";
  --fa--fa: "\f52e\f52e";
}

.fa-bucket {
  --fa: "\e4cf";
  --fa--fa: "\e4cf\e4cf";
}

.fa-floppy-disk-pen {
  --fa: "\e182";
  --fa--fa: "\e182\e182";
}

.fa-image {
  --fa: "\f03e";
  --fa--fa: "\f03e\f03e";
}

.fa-window-frame {
  --fa: "\e04f";
  --fa--fa: "\e04f\e04f";
}

.fa-microphone {
  --fa: "\f130";
  --fa--fa: "\f130\f130";
}

.fa-cow {
  --fa: "\f6c8";
  --fa--fa: "\f6c8\f6c8";
}

.fa-file-zip {
  --fa: "\e5ee";
  --fa--fa: "\e5ee\e5ee";
}

.fa-square-ring {
  --fa: "\e44f";
  --fa--fa: "\e44f\e44f";
}

.fa-down-from-line {
  --fa: "\f349";
  --fa--fa: "\f349\f349";
}

.fa-arrow-alt-from-top {
  --fa: "\f349";
  --fa--fa: "\f349\f349";
}

.fa-caret-up {
  --fa: "\f0d8";
  --fa--fa: "\f0d8\f0d8";
}

.fa-shield-xmark {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c";
}

.fa-shield-times {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c";
}

.fa-screwdriver {
  --fa: "\f54a";
  --fa--fa: "\f54a\f54a";
}

.fa-circle-sort-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031";
}

.fa-sort-circle-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031";
}

.fa-folder-closed {
  --fa: "\e185";
  --fa--fa: "\e185\e185";
}

.fa-house-tsunami {
  --fa: "\e515";
  --fa--fa: "\e515\e515";
}

.fa-square-nfi {
  --fa: "\e576";
  --fa--fa: "\e576\e576";
}

.fa-forklift {
  --fa: "\f47a";
  --fa--fa: "\f47a\f47a";
}

.fa-arrow-up-from-ground-water {
  --fa: "\e4b5";
  --fa--fa: "\e4b5\e4b5";
}

.fa-bracket-square-right {
  --fa: "\]";
  --fa--fa: "\]\]";
}

.fa-martini-glass {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b";
}

.fa-glass-martini-alt {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b";
}

.fa-square-binary {
  --fa: "\e69b";
  --fa--fa: "\e69b\e69b";
}

.fa-rotate-left {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-rotate-back {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-rotate-backward {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-undo-alt {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea";
}

.fa-table-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db";
}

.fa-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db";
}

.fa-square-a {
  --fa: "\e25f";
  --fa--fa: "\e25f\e25f";
}

.fa-tick {
  --fa: "\e32f";
  --fa--fa: "\e32f\e32f";
}

.fa-lemon {
  --fa: "\f094";
  --fa--fa: "\f094\f094";
}

.fa-head-side-mask {
  --fa: "\e063";
  --fa--fa: "\e063\e063";
}

.fa-handshake {
  --fa: "\f2b5";
  --fa--fa: "\f2b5\f2b5";
}

.fa-gem {
  --fa: "\f3a5";
  --fa--fa: "\f3a5\f3a5";
}

.fa-dolly {
  --fa: "\f472";
  --fa--fa: "\f472\f472";
}

.fa-dolly-box {
  --fa: "\f472";
  --fa--fa: "\f472\f472";
}

.fa-smoking {
  --fa: "\f48d";
  --fa--fa: "\f48d\f48d";
}

.fa-minimize {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c";
}

.fa-compress-arrows-alt {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c";
}

.fa-refrigerator {
  --fa: "\e026";
  --fa--fa: "\e026\e026";
}

.fa-monument {
  --fa: "\f5a6";
  --fa--fa: "\f5a6\f5a6";
}

.fa-octagon-xmark {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-times-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-xmark-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0";
}

.fa-align-slash {
  --fa: "\f846";
  --fa--fa: "\f846\f846";
}

.fa-snowplow {
  --fa: "\f7d2";
  --fa--fa: "\f7d2\f7d2";
}

.fa-angles-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101";
}

.fa-angle-double-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101";
}

.fa-truck-ramp-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd";
}

.fa-truck-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd";
}

.fa-cannabis {
  --fa: "\f55f";
  --fa--fa: "\f55f\f55f";
}

.fa-circle-play {
  --fa: "\f144";
  --fa--fa: "\f144\f144";
}

.fa-play-circle {
  --fa: "\f144";
  --fa--fa: "\f144\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "\e0a0";
  --fa--fa: "\e0a0\e0a0";
}

.fa-location-arrow-up {
  --fa: "\e63a";
  --fa--fa: "\e63a\e63a";
}

.fa-tablets {
  --fa: "\f490";
  --fa--fa: "\f490\f490";
}

.fa-360-degrees {
  --fa: "\e2dc";
  --fa--fa: "\e2dc\e2dc";
}

.fa-ethernet {
  --fa: "\f796";
  --fa--fa: "\f796\f796";
}

.fa-euro-sign {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-eur {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-euro {
  --fa: "\f153";
  --fa--fa: "\f153\f153";
}

.fa-chair {
  --fa: "\f6c0";
  --fa--fa: "\f6c0\f6c0";
}

.fa-circle-check {
  --fa: "\f058";
  --fa--fa: "\f058\f058";
}

.fa-check-circle {
  --fa: "\f058";
  --fa--fa: "\f058\f058";
}

.fa-square-dashed-circle-plus {
  --fa: "\e5c2";
  --fa--fa: "\e5c2\e5c2";
}

.fa-hand-holding-circle-dollar {
  --fa: "\e621";
  --fa--fa: "\e621\e621";
}

.fa-money-simple-from-bracket {
  --fa: "\e313";
  --fa--fa: "\e313\e313";
}

.fa-bat {
  --fa: "\f6b5";
  --fa--fa: "\f6b5\f6b5";
}

.fa-circle-stop {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d";
}

.fa-stop-circle {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d";
}

.fa-head-side-headphones {
  --fa: "\f8c2";
  --fa--fa: "\f8c2\f8c2";
}

.fa-phone-rotary {
  --fa: "\f8d3";
  --fa--fa: "\f8d3\f8d3";
}

.fa-arrow-up-to-bracket {
  --fa: "\e66a";
  --fa--fa: "\e66a\e66a";
}

.fa-compass-drafting {
  --fa: "\f568";
  --fa--fa: "\f568\f568";
}

.fa-drafting-compass {
  --fa: "\f568";
  --fa--fa: "\f568\f568";
}

.fa-plate-wheat {
  --fa: "\e55a";
  --fa--fa: "\e55a\e55a";
}

.fa-calendar-circle-minus {
  --fa: "\e46f";
  --fa--fa: "\e46f\e46f";
}

.fa-chopsticks {
  --fa: "\e3f7";
  --fa--fa: "\e3f7\e3f7";
}

.fa-car-wrench {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3";
}

.fa-car-mechanic {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3";
}

.fa-icicles {
  --fa: "\f7ad";
  --fa--fa: "\f7ad\f7ad";
}

.fa-person-shelter {
  --fa: "\e54f";
  --fa--fa: "\e54f\e54f";
}

.fa-neuter {
  --fa: "\f22c";
  --fa--fa: "\f22c\f22c";
}

.fa-id-badge {
  --fa: "\f2c1";
  --fa--fa: "\f2c1\f2c1";
}

.fa-kazoo {
  --fa: "\f8c7";
  --fa--fa: "\f8c7\f8c7";
}

.fa-marker {
  --fa: "\f5a1";
  --fa--fa: "\f5a1\f5a1";
}

.fa-bin-bottles {
  --fa: "\e5f5";
  --fa--fa: "\e5f5\e5f5";
}

.fa-face-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a";
}

.fa-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a";
}

.fa-square-arrow-down-left {
  --fa: "\e261";
  --fa--fa: "\e261\e261";
}

.fa-battery-bolt {
  --fa: "\f376";
  --fa--fa: "\f376\f376";
}

.fa-tree-large {
  --fa: "\f7dd";
  --fa--fa: "\f7dd\f7dd";
}

.fa-helicopter-symbol {
  --fa: "\e502";
  --fa--fa: "\e502\e502";
}

.fa-aperture {
  --fa: "\e2df";
  --fa--fa: "\e2df\e2df";
}

.fa-universal-access {
  --fa: "\f29a";
  --fa--fa: "\f29a\f29a";
}

.fa-gear-complex {
  --fa: "\e5e9";
  --fa--fa: "\e5e9\e5e9";
}

.fa-file-magnifying-glass {
  --fa: "\f865";
  --fa--fa: "\f865\f865";
}

.fa-file-search {
  --fa: "\f865";
  --fa--fa: "\f865\f865";
}

.fa-up-right {
  --fa: "\e2be";
  --fa--fa: "\e2be\e2be";
}

.fa-circle-chevron-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139";
}

.fa-chevron-circle-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139";
}

.fa-user-police {
  --fa: "\e333";
  --fa--fa: "\e333\e333";
}

.fa-lari-sign {
  --fa: "\e1c8";
  --fa--fa: "\e1c8\e1c8";
}

.fa-volcano {
  --fa: "\f770";
  --fa--fa: "\f770\f770";
}

.fa-teddy-bear {
  --fa: "\e3cf";
  --fa--fa: "\e3cf\e3cf";
}

.fa-stocking {
  --fa: "\f7d5";
  --fa--fa: "\f7d5\f7d5";
}

.fa-person-walking-dashed-line-arrow-right {
  --fa: "\e553";
  --fa--fa: "\e553\e553";
}

.fa-image-slash {
  --fa: "\e1b7";
  --fa--fa: "\e1b7\e1b7";
}

.fa-mask-snorkel {
  --fa: "\e3b7";
  --fa--fa: "\e3b7\e3b7";
}

.fa-smoke {
  --fa: "\f760";
  --fa--fa: "\f760\f760";
}

.fa-sterling-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-gbp {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-pound-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154";
}

.fa-battery-exclamation {
  --fa: "\e0b0";
  --fa--fa: "\e0b0\e0b0";
}

.fa-viruses {
  --fa: "\e076";
  --fa--fa: "\e076\e076";
}

.fa-square-person-confined {
  --fa: "\e577";
  --fa--fa: "\e577\e577";
}

.fa-user-tie {
  --fa: "\f508";
  --fa--fa: "\f508\f508";
}

.fa-up-to-bracket {
  --fa: "\e66e";
  --fa--fa: "\e66e\e66e";
}

.fa-arrow-down-long {
  --fa: "\f175";
  --fa--fa: "\f175\f175";
}

.fa-long-arrow-down {
  --fa: "\f175";
  --fa--fa: "\f175\f175";
}

.fa-tent-arrow-down-to-line {
  --fa: "\e57e";
  --fa--fa: "\e57e\e57e";
}

.fa-certificate {
  --fa: "\f0a3";
  --fa--fa: "\f0a3\f0a3";
}

.fa-crystal-ball {
  --fa: "\e362";
  --fa--fa: "\e362\e362";
}

.fa-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122";
}

.fa-mail-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122";
}

.fa-suitcase {
  --fa: "\f0f2";
  --fa--fa: "\f0f2\f0f2";
}

.fa-person-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5";
}

.fa-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5";
}

.fa-star-shooting {
  --fa: "\e036";
  --fa--fa: "\e036\e036";
}

.fa-binary-lock {
  --fa: "\e33d";
  --fa--fa: "\e33d\e33d";
}

.fa-filter-circle-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662";
}

.fa-funnel-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662";
}

.fa-camera-retro {
  --fa: "\f083";
  --fa--fa: "\f083\f083";
}

.fa-circle-arrow-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab";
}

.fa-arrow-circle-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab";
}

.fa-comment-pen {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae";
}

.fa-comment-edit {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae";
}

.fa-file-import {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f";
}

.fa-arrow-right-to-file {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f";
}

.fa-banjo {
  --fa: "\f8a3";
  --fa--fa: "\f8a3\f8a3";
}

.fa-square-arrow-up-right {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c";
}

.fa-external-link-square {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c";
}

.fa-light-emergency-on {
  --fa: "\e420";
  --fa--fa: "\e420\e420";
}

.fa-kerning {
  --fa: "\f86f";
  --fa--fa: "\f86f\f86f";
}

.fa-box-open {
  --fa: "\f49e";
  --fa--fa: "\f49e\f49e";
}

.fa-square-f {
  --fa: "\e270";
  --fa--fa: "\e270\e270";
}

.fa-scroll {
  --fa: "\f70e";
  --fa--fa: "\f70e\f70e";
}

.fa-spa {
  --fa: "\f5bb";
  --fa--fa: "\f5bb\f5bb";
}

.fa-arrow-left-from-line {
  --fa: "\f344";
  --fa--fa: "\f344\f344";
}

.fa-arrow-from-right {
  --fa: "\f344";
  --fa--fa: "\f344\f344";
}

.fa-strawberry {
  --fa: "\e32b";
  --fa--fa: "\e32b\e32b";
}

.fa-location-pin-lock {
  --fa: "\e51f";
  --fa--fa: "\e51f\e51f";
}

.fa-pause {
  --fa: "\f04c";
  --fa--fa: "\f04c\f04c";
}

.fa-clock-eight-thirty {
  --fa: "\e346";
  --fa--fa: "\e346\e346";
}

.fa-plane-engines {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de";
}

.fa-plane-alt {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de";
}

.fa-hill-avalanche {
  --fa: "\e507";
  --fa--fa: "\e507\e507";
}

.fa-temperature-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-temperature-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-thermometer-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-thermometer-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb";
}

.fa-bomb {
  --fa: "\f1e2";
  --fa--fa: "\f1e2\f1e2";
}

.fa-gauge-low {
  --fa: "\f627";
  --fa--fa: "\f627\f627";
}

.fa-tachometer-alt-slow {
  --fa: "\f627";
  --fa--fa: "\f627\f627";
}

.fa-registered {
  --fa: "\f25d";
  --fa--fa: "\f25d\f25d";
}

.fa-trash-can-plus {
  --fa: "\e2ac";
  --fa--fa: "\e2ac\e2ac";
}

.fa-address-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-contact-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-vcard {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb";
}

.fa-chart-fft {
  --fa: "\e69e";
  --fa--fa: "\e69e\e69e";
}

.fa-scale-unbalanced-flip {
  --fa: "\f516";
  --fa--fa: "\f516\f516";
}

.fa-balance-scale-right {
  --fa: "\f516";
  --fa--fa: "\f516\f516";
}

.fa-globe-snow {
  --fa: "\f7a3";
  --fa--fa: "\f7a3\f7a3";
}

.fa-subscript {
  --fa: "\f12c";
  --fa--fa: "\f12c\f12c";
}

.fa-diamond-turn-right {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb";
}

.fa-directions {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb";
}

.fa-integral {
  --fa: "\f667";
  --fa--fa: "\f667\f667";
}

.fa-burst {
  --fa: "\e4dc";
  --fa--fa: "\e4dc\e4dc";
}

.fa-house-laptop {
  --fa: "\e066";
  --fa--fa: "\e066\e066";
}

.fa-laptop-house {
  --fa: "\e066";
  --fa--fa: "\e066\e066";
}

.fa-face-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8";
}

.fa-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8";
}

.fa-money-bills {
  --fa: "\e1f3";
  --fa--fa: "\e1f3\e1f3";
}

.fa-blinds-raised {
  --fa: "\f8fd";
  --fa--fa: "\f8fd\f8fd";
}

.fa-smog {
  --fa: "\f75f";
  --fa--fa: "\f75f\f75f";
}

.fa-ufo-beam {
  --fa: "\e048";
  --fa--fa: "\e048\e048";
}

.fa-hydra {
  --fa: "\e686";
  --fa--fa: "\e686\e686";
}

.fa-circle-caret-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331";
}

.fa-caret-circle-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331";
}

.fa-user-vneck-hair-long {
  --fa: "\e463";
  --fa--fa: "\e463\e463";
}

.fa-square-a-lock {
  --fa: "\e44d";
  --fa--fa: "\e44d\e44d";
}

.fa-crutch {
  --fa: "\f7f7";
  --fa--fa: "\f7f7\f7f7";
}

.fa-gas-pump-slash {
  --fa: "\f5f4";
  --fa--fa: "\f5f4\f5f4";
}

.fa-cloud-arrow-up {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-cloud-upload {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-cloud-upload-alt {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee";
}

.fa-palette {
  --fa: "\f53f";
  --fa--fa: "\f53f\f53f";
}

.fa-transporter-4 {
  --fa: "\e2a5";
  --fa--fa: "\e2a5\e2a5";
}

.fa-chart-mixed-up-circle-currency {
  --fa: "\e5d8";
  --fa--fa: "\e5d8\e5d8";
}

.fa-objects-align-right {
  --fa: "\e3bf";
  --fa--fa: "\e3bf\e3bf";
}

.fa-arrows-turn-right {
  --fa: "\e4c0";
  --fa--fa: "\e4c0\e4c0";
}

.fa-vest {
  --fa: "\e085";
  --fa--fa: "\e085\e085";
}

.fa-pig {
  --fa: "\f706";
  --fa--fa: "\f706\f706";
}

.fa-inbox-full {
  --fa: "\e1ba";
  --fa--fa: "\e1ba\e1ba";
}

.fa-circle-envelope {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c";
}

.fa-envelope-circle {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c";
}

.fa-triangle-person-digging {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d";
}

.fa-construction {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d";
}

.fa-ferry {
  --fa: "\e4ea";
  --fa--fa: "\e4ea\e4ea";
}

.fa-bullseye-arrow {
  --fa: "\f648";
  --fa--fa: "\f648\f648";
}

.fa-arrows-down-to-people {
  --fa: "\e4b9";
  --fa--fa: "\e4b9\e4b9";
}

.fa-seedling {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8";
}

.fa-sprout {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8";
}

.fa-clock-seven {
  --fa: "\e350";
  --fa--fa: "\e350\e350";
}

.fa-left-right {
  --fa: "\f337";
  --fa--fa: "\f337\f337";
}

.fa-arrows-alt-h {
  --fa: "\f337";
  --fa--fa: "\f337\f337";
}

.fa-boxes-packing {
  --fa: "\e4c7";
  --fa--fa: "\e4c7\e4c7";
}

.fa-circle-arrow-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8";
}

.fa-arrow-circle-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8";
}

.fa-flashlight {
  --fa: "\f8b8";
  --fa--fa: "\f8b8\f8b8";
}

.fa-file-jpg {
  --fa: "\e646";
  --fa--fa: "\e646\e646";
}

.fa-group-arrows-rotate {
  --fa: "\e4f6";
  --fa--fa: "\e4f6\e4f6";
}

.fa-bowl-food {
  --fa: "\e4c6";
  --fa--fa: "\e4c6\e4c6";
}

.fa-square-9 {
  --fa: "\e25e";
  --fa--fa: "\e25e\e25e";
}

.fa-candy-cane {
  --fa: "\f786";
  --fa--fa: "\f786\f786";
}

.fa-arrow-down-wide-short {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-sort-amount-asc {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-sort-amount-down {
  --fa: "\f160";
  --fa--fa: "\f160\f160";
}

.fa-square-dollar {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-dollar-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-usd-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9";
}

.fa-phone-arrow-right {
  --fa: "\e5be";
  --fa--fa: "\e5be\e5be";
}

.fa-hand-holding-seedling {
  --fa: "\f4bf";
  --fa--fa: "\f4bf\f4bf";
}

.fa-message-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2";
}

.fa-comment-alt-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2";
}

.fa-cloud-bolt {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c";
}

.fa-thunderstorm {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c";
}

.fa-chart-line-up-down {
  --fa: "\e5d7";
  --fa--fa: "\e5d7\e5d7";
}

.fa-text-slash {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d";
}

.fa-remove-format {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d";
}

.fa-watch {
  --fa: "\f2e1";
  --fa--fa: "\f2e1\f2e1";
}

.fa-circle-down-left {
  --fa: "\e107";
  --fa--fa: "\e107\e107";
}

.fa-text {
  --fa: "\f893";
  --fa--fa: "\f893\f893";
}

.fa-projector {
  --fa: "\f8d6";
  --fa--fa: "\f8d6\f8d6";
}

.fa-face-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da";
}

.fa-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da";
}

.fa-tombstone-blank {
  --fa: "\f721";
  --fa--fa: "\f721\f721";
}

.fa-tombstone-alt {
  --fa: "\f721";
  --fa--fa: "\f721\f721";
}

.fa-chess-king-piece {
  --fa: "\f440";
  --fa--fa: "\f440\f440";
}

.fa-chess-king-alt {
  --fa: "\f440";
  --fa--fa: "\f440\f440";
}

.fa-circle-6 {
  --fa: "\e0f3";
  --fa--fa: "\e0f3\e0f3";
}

.fa-waves-sine {
  --fa: "\e65d";
  --fa--fa: "\e65d\e65d";
}

.fa-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355";
}

.fa-arrow-alt-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355";
}

.fa-file-word {
  --fa: "\f1c2";
  --fa--fa: "\f1c2\f1c2";
}

.fa-file-powerpoint {
  --fa: "\f1c4";
  --fa--fa: "\f1c4\f1c4";
}

.fa-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350";
}

.fa-arrow-alt-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350";
}

.fa-objects-align-center-vertical {
  --fa: "\e3bd";
  --fa--fa: "\e3bd\e3bd";
}

.fa-arrows-left-right {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e";
}

.fa-arrows-h {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e";
}

.fa-house-lock {
  --fa: "\e510";
  --fa--fa: "\e510\e510";
}

.fa-cloud-arrow-down {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-cloud-download {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-cloud-download-alt {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed";
}

.fa-wreath {
  --fa: "\f7e2";
  --fa--fa: "\f7e2\f7e2";
}

.fa-children {
  --fa: "\e4e1";
  --fa--fa: "\e4e1\e4e1";
}

.fa-meter-droplet {
  --fa: "\e1ea";
  --fa--fa: "\e1ea\e1ea";
}

.fa-chalkboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b";
}

.fa-blackboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b";
}

.fa-user-large-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa";
}

.fa-user-alt-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa";
}

.fa-signal-strong {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f";
}

.fa-signal-4 {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f";
}

.fa-lollipop {
  --fa: "\e424";
  --fa--fa: "\e424\e424";
}

.fa-lollypop {
  --fa: "\e424";
  --fa--fa: "\e424\e424";
}

.fa-list-tree {
  --fa: "\e1d2";
  --fa--fa: "\e1d2\e1d2";
}

.fa-envelope-open {
  --fa: "\f2b6";
  --fa--fa: "\f2b6\f2b6";
}

.fa-draw-circle {
  --fa: "\f5ed";
  --fa--fa: "\f5ed\f5ed";
}

.fa-cat-space {
  --fa: "\e001";
  --fa--fa: "\e001\e001";
}

.fa-handshake-simple-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f";
}

.fa-handshake-alt-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f";
}

.fa-rabbit-running {
  --fa: "\f709";
  --fa--fa: "\f709\f709";
}

.fa-rabbit-fast {
  --fa: "\f709";
  --fa--fa: "\f709\f709";
}

.fa-memo-pad {
  --fa: "\e1da";
  --fa--fa: "\e1da\e1da";
}

.fa-mattress-pillow {
  --fa: "\e525";
  --fa--fa: "\e525\e525";
}

.fa-alarm-plus {
  --fa: "\f844";
  --fa--fa: "\f844\f844";
}

.fa-alicorn {
  --fa: "\f6b0";
  --fa--fa: "\f6b0\f6b0";
}

.fa-comment-question {
  --fa: "\e14b";
  --fa--fa: "\e14b\e14b";
}

.fa-gingerbread-man {
  --fa: "\f79d";
  --fa--fa: "\f79d\f79d";
}

.fa-guarani-sign {
  --fa: "\e19a";
  --fa--fa: "\e19a\e19a";
}

.fa-burger-fries {
  --fa: "\e0cd";
  --fa--fa: "\e0cd\e0cd";
}

.fa-mug-tea {
  --fa: "\f875";
  --fa--fa: "\f875\f875";
}

.fa-border-top {
  --fa: "\f855";
  --fa--fa: "\f855\f855";
}

.fa-arrows-rotate {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-refresh {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-sync {
  --fa: "\f021";
  --fa--fa: "\f021\f021";
}

.fa-circle-book-open {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff";
}

.fa-book-circle {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff";
}

.fa-arrows-to-dotted-line {
  --fa: "\e0a6";
  --fa--fa: "\e0a6\e0a6";
}

.fa-fire-extinguisher {
  --fa: "\f134";
  --fa--fa: "\f134\f134";
}

.fa-magnifying-glass-arrows-rotate {
  --fa: "\e65e";
  --fa--fa: "\e65e\e65e";
}

.fa-garage-open {
  --fa: "\e00b";
  --fa--fa: "\e00b\e00b";
}

.fa-shelves-empty {
  --fa: "\e246";
  --fa--fa: "\e246\e246";
}

.fa-cruzeiro-sign {
  --fa: "\e152";
  --fa--fa: "\e152\e152";
}

.fa-watch-apple {
  --fa: "\e2cb";
  --fa--fa: "\e2cb\e2cb";
}

.fa-watch-calculator {
  --fa: "\f8f0";
  --fa--fa: "\f8f0\f8f0";
}

.fa-list-dropdown {
  --fa: "\e1cf";
  --fa--fa: "\e1cf\e1cf";
}

.fa-cabinet-filing {
  --fa: "\f64b";
  --fa--fa: "\f64b\f64b";
}

.fa-burger-soda {
  --fa: "\f858";
  --fa--fa: "\f858\f858";
}

.fa-square-arrow-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c";
}

.fa-arrow-square-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c";
}

.fa-greater-than-equal {
  --fa: "\f532";
  --fa--fa: "\f532\f532";
}

.fa-pallet-box {
  --fa: "\e208";
  --fa--fa: "\e208\e208";
}

.fa-face-confounded {
  --fa: "\e36c";
  --fa--fa: "\e36c\e36c";
}

.fa-shield-halved {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed";
}

.fa-shield-alt {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed";
}

.fa-truck-plow {
  --fa: "\f7de";
  --fa--fa: "\f7de\f7de";
}

.fa-book-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558";
}

.fa-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558";
}

.fa-virus {
  --fa: "\e074";
  --fa--fa: "\e074\e074";
}

.fa-grid-round-2 {
  --fa: "\e5db";
  --fa--fa: "\e5db\e5db";
}

.fa-comment-middle-top {
  --fa: "\e14a";
  --fa--fa: "\e14a\e14a";
}

.fa-wave {
  --fa: "\e65b";
  --fa--fa: "\e65b\e65b";
}

.fa-envelope-circle-check {
  --fa: "\e4e8";
  --fa--fa: "\e4e8\e4e8";
}

.fa-layer-group {
  --fa: "\f5fd";
  --fa--fa: "\f5fd\f5fd";
}

.fa-restroom-simple {
  --fa: "\e23a";
  --fa--fa: "\e23a\e23a";
}

.fa-arrows-to-dot {
  --fa: "\e4be";
  --fa--fa: "\e4be\e4be";
}

.fa-border-outer {
  --fa: "\f851";
  --fa--fa: "\f851\f851";
}

.fa-hashtag-lock {
  --fa: "\e415";
  --fa--fa: "\e415\e415";
}

.fa-clock-two-thirty {
  --fa: "\e35b";
  --fa--fa: "\e35b\e35b";
}

.fa-archway {
  --fa: "\f557";
  --fa--fa: "\f557\f557";
}

.fa-heart-circle-check {
  --fa: "\e4fd";
  --fa--fa: "\e4fd\e4fd";
}

.fa-house-chimney-crack {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1";
}

.fa-house-damage {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1";
}

.fa-file-zipper {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6";
}

.fa-file-archive {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6";
}

.fa-ticket-perforated {
  --fa: "\e63e";
  --fa--fa: "\e63e\e63e";
}

.fa-heart-half {
  --fa: "\e1ab";
  --fa--fa: "\e1ab\e1ab";
}

.fa-comment-check {
  --fa: "\f4ac";
  --fa--fa: "\f4ac\f4ac";
}

.fa-square {
  --fa: "\f0c8";
  --fa--fa: "\f0c8\f0c8";
}

.fa-memo {
  --fa: "\e1d8";
  --fa--fa: "\e1d8\e1d8";
}

.fa-martini-glass-empty {
  --fa: "\f000";
  --fa--fa: "\f000\f000";
}

.fa-glass-martini {
  --fa: "\f000";
  --fa--fa: "\f000\f000";
}

.fa-couch {
  --fa: "\f4b8";
  --fa--fa: "\f4b8\f4b8";
}

.fa-cedi-sign {
  --fa: "\e0df";
  --fa--fa: "\e0df\e0df";
}

.fa-italic {
  --fa: "\f033";
  --fa--fa: "\f033\f033";
}

.fa-glass-citrus {
  --fa: "\f869";
  --fa--fa: "\f869\f869";
}

.fa-calendar-lines-pen {
  --fa: "\e472";
  --fa--fa: "\e472\e472";
}

.fa-table-cells-column-lock {
  --fa: "\e678";
  --fa--fa: "\e678\e678";
}

.fa-church {
  --fa: "\f51d";
  --fa--fa: "\f51d\f51d";
}

.fa-person-snowmobiling {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1";
}

.fa-snowmobile {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1";
}

.fa-face-hushed {
  --fa: "\e37b";
  --fa--fa: "\e37b\e37b";
}

.fa-comments-dollar {
  --fa: "\f653";
  --fa--fa: "\f653\f653";
}

.fa-tickets-simple {
  --fa: "\e659";
  --fa--fa: "\e659\e659";
}

.fa-pickaxe {
  --fa: "\e5bf";
  --fa--fa: "\e5bf\e5bf";
}

.fa-link-simple-slash {
  --fa: "\e1ce";
  --fa--fa: "\e1ce\e1ce";
}

.fa-democrat {
  --fa: "\f747";
  --fa--fa: "\f747\f747";
}

.fa-face-confused {
  --fa: "\e36d";
  --fa--fa: "\e36d\e36d";
}

.fa-pinball {
  --fa: "\e229";
  --fa--fa: "\e229\e229";
}

.fa-z {
  --fa: "Z";
  --fa--fa: "ZZ";
}

.fa-person-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9";
}

.fa-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9";
}

.fa-deer {
  --fa: "\f78e";
  --fa--fa: "\f78e\f78e";
}

.fa-input-pipe {
  --fa: "\e1be";
  --fa--fa: "\e1be\e1be";
}

.fa-road-lock {
  --fa: "\e567";
  --fa--fa: "\e567\e567";
}

.fa-a {
  --fa: "A";
  --fa--fa: "AA";
}

.fa-bookmark-slash {
  --fa: "\e0c2";
  --fa--fa: "\e0c2\e0c2";
}

.fa-temperature-arrow-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f";
}

.fa-temperature-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f";
}

.fa-mace {
  --fa: "\f6f8";
  --fa--fa: "\f6f8\f6f8";
}

.fa-feather-pointed {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b";
}

.fa-feather-alt {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b";
}

.fa-sausage {
  --fa: "\f820";
  --fa--fa: "\f820\f820";
}

.fa-trash-can-clock {
  --fa: "\e2aa";
  --fa--fa: "\e2aa\e2aa";
}

.fa-p {
  --fa: "P";
  --fa--fa: "PP";
}

.fa-broom-wide {
  --fa: "\e5d1";
  --fa--fa: "\e5d1\e5d1";
}

.fa-snowflake {
  --fa: "\f2dc";
  --fa--fa: "\f2dc\f2dc";
}

.fa-stomach {
  --fa: "\f623";
  --fa--fa: "\f623\f623";
}

.fa-newspaper {
  --fa: "\f1ea";
  --fa--fa: "\f1ea\f1ea";
}

.fa-rectangle-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641";
}

.fa-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641";
}

.fa-guitar-electric {
  --fa: "\f8be";
  --fa--fa: "\f8be\f8be";
}

.fa-arrow-turn-down-right {
  --fa: "\e3d6";
  --fa--fa: "\e3d6\e3d6";
}

.fa-moon-cloud {
  --fa: "\f754";
  --fa--fa: "\f754\f754";
}

.fa-bread-slice-butter {
  --fa: "\e3e1";
  --fa--fa: "\e3e1\e3e1";
}

.fa-circle-arrow-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9";
}

.fa-arrow-circle-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9";
}

.fa-user-group-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5";
}

.fa-users-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5";
}

.fa-circle-i {
  --fa: "\e111";
  --fa--fa: "\e111\e111";
}

.fa-toilet-paper-check {
  --fa: "\e5b2";
  --fa--fa: "\e5b2\e5b2";
}

.fa-filter-circle-xmark {
  --fa: "\e17b";
  --fa--fa: "\e17b\e17b";
}

.fa-locust {
  --fa: "\e520";
  --fa--fa: "\e520\e520";
}

.fa-sort {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc";
}

.fa-unsorted {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc";
}

.fa-list-ol {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-list-1-2 {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-list-numeric {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb";
}

.fa-chart-waterfall {
  --fa: "\e0eb";
  --fa--fa: "\e0eb\e0eb";
}

.fa-sparkle {
  --fa: "\e5d6";
  --fa--fa: "\e5d6\e5d6";
}

.fa-face-party {
  --fa: "\e383";
  --fa--fa: "\e383\e383";
}

.fa-kidneys {
  --fa: "\f5fb";
  --fa--fa: "\f5fb\f5fb";
}

.fa-wifi-exclamation {
  --fa: "\e2cf";
  --fa--fa: "\e2cf\e2cf";
}

.fa-chart-network {
  --fa: "\f78a";
  --fa--fa: "\f78a\f78a";
}

.fa-person-dress-burst {
  --fa: "\e544";
  --fa--fa: "\e544\e544";
}

.fa-dice-d4 {
  --fa: "\f6d0";
  --fa--fa: "\f6d0\f6d0";
}

.fa-money-check-dollar {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d";
}

.fa-money-check-alt {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d";
}

.fa-vector-square {
  --fa: "\f5cb";
  --fa--fa: "\f5cb\f5cb";
}

.fa-bread-slice {
  --fa: "\f7ec";
  --fa--fa: "\f7ec\f7ec";
}

.fa-language {
  --fa: "\f1ab";
  --fa--fa: "\f1ab\f1ab";
}

.fa-wheat-awn-slash {
  --fa: "\e338";
  --fa--fa: "\e338\e338";
}

.fa-face-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598";
}

.fa-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598";
}

.fa-dagger {
  --fa: "\f6cb";
  --fa--fa: "\f6cb\f6cb";
}

.fa-podium {
  --fa: "\f680";
  --fa--fa: "\f680\f680";
}

.fa-diamonds-4 {
  --fa: "\e68b";
  --fa--fa: "\e68b\e68b";
}

.fa-memo-circle-check {
  --fa: "\e1d9";
  --fa--fa: "\e1d9\e1d9";
}

.fa-route-highway {
  --fa: "\f61a";
  --fa--fa: "\f61a\f61a";
}

.fa-down-to-line {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a";
}

.fa-arrow-alt-to-bottom {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a";
}

.fa-filter {
  --fa: "\f0b0";
  --fa--fa: "\f0b0\f0b0";
}

.fa-square-g {
  --fa: "\e271";
  --fa--fa: "\e271\e271";
}

.fa-circle-phone {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b";
}

.fa-phone-circle {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b";
}

.fa-clipboard-prescription {
  --fa: "\f5e8";
  --fa--fa: "\f5e8\f5e8";
}

.fa-user-nurse-hair {
  --fa: "\e45d";
  --fa--fa: "\e45d\e45d";
}

.fa-question {
  --fa: "\?";
  --fa--fa: "\?\?";
}

.fa-file-signature {
  --fa: "\f573";
  --fa--fa: "\f573\f573";
}

.fa-toggle-large-on {
  --fa: "\e5b1";
  --fa--fa: "\e5b1\e5b1";
}

.fa-up-down-left-right {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2";
}

.fa-arrows-alt {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2";
}

.fa-dryer-heat {
  --fa: "\f862";
  --fa--fa: "\f862\f862";
}

.fa-dryer-alt {
  --fa: "\f862";
  --fa--fa: "\f862\f862";
}

.fa-house-chimney-user {
  --fa: "\e065";
  --fa--fa: "\e065\e065";
}

.fa-hand-holding-heart {
  --fa: "\f4be";
  --fa--fa: "\f4be\f4be";
}

.fa-arrow-up-small-big {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f";
}

.fa-sort-size-up-alt {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f";
}

.fa-train-track {
  --fa: "\e453";
  --fa--fa: "\e453\e453";
}

.fa-puzzle-piece {
  --fa: "\f12e";
  --fa--fa: "\f12e\f12e";
}

.fa-money-check {
  --fa: "\f53c";
  --fa--fa: "\f53c\f53c";
}

.fa-star-half-stroke {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0";
}

.fa-star-half-alt {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0";
}

.fa-file-exclamation {
  --fa: "\f31a";
  --fa--fa: "\f31a\f31a";
}

.fa-code {
  --fa: "\f121";
  --fa--fa: "\f121\f121";
}

.fa-whiskey-glass {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0";
}

.fa-glass-whiskey {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0";
}

.fa-moon-stars {
  --fa: "\f755";
  --fa--fa: "\f755\f755";
}

.fa-building-circle-exclamation {
  --fa: "\e4d3";
  --fa--fa: "\e4d3\e4d3";
}

.fa-clothes-hanger {
  --fa: "\e136";
  --fa--fa: "\e136\e136";
}

.fa-mobile-notch {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee";
}

.fa-mobile-iphone {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee";
}

.fa-magnifying-glass-chart {
  --fa: "\e522";
  --fa--fa: "\e522\e522";
}

.fa-arrow-up-right-from-square {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e";
}

.fa-external-link {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e";
}

.fa-cubes-stacked {
  --fa: "\e4e6";
  --fa--fa: "\e4e6\e4e6";
}

.fa-images-user {
  --fa: "\e1b9";
  --fa--fa: "\e1b9\e1b9";
}

.fa-won-sign {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-krw {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-won {
  --fa: "\f159";
  --fa--fa: "\f159\f159";
}

.fa-image-polaroid-user {
  --fa: "\e1b6";
  --fa--fa: "\e1b6\e1b6";
}

.fa-virus-covid {
  --fa: "\e4a8";
  --fa--fa: "\e4a8\e4a8";
}

.fa-square-ellipsis {
  --fa: "\e26e";
  --fa--fa: "\e26e\e26e";
}

.fa-pie {
  --fa: "\f705";
  --fa--fa: "\f705\f705";
}

.fa-chess-knight-piece {
  --fa: "\f442";
  --fa--fa: "\f442\f442";
}

.fa-chess-knight-alt {
  --fa: "\f442";
  --fa--fa: "\f442\f442";
}

.fa-austral-sign {
  --fa: "\e0a9";
  --fa--fa: "\e0a9\e0a9";
}

.fa-cloud-plus {
  --fa: "\e35e";
  --fa--fa: "\e35e\e35e";
}

.fa-f {
  --fa: "F";
  --fa--fa: "FF";
}

.fa-leaf {
  --fa: "\f06c";
  --fa--fa: "\f06c\f06c";
}

.fa-bed-bunk {
  --fa: "\f8f8";
  --fa--fa: "\f8f8\f8f8";
}

.fa-road {
  --fa: "\f018";
  --fa--fa: "\f018\f018";
}

.fa-taxi {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba";
}

.fa-cab {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba";
}

.fa-person-circle-plus {
  --fa: "\e541";
  --fa--fa: "\e541\e541";
}

.fa-chart-pie {
  --fa: "\f200";
  --fa--fa: "\f200\f200";
}

.fa-pie-chart {
  --fa: "\f200";
  --fa--fa: "\f200\f200";
}

.fa-bolt-lightning {
  --fa: "\e0b7";
  --fa--fa: "\e0b7\e0b7";
}

.fa-clock-eight {
  --fa: "\e345";
  --fa--fa: "\e345\e345";
}

.fa-sack-xmark {
  --fa: "\e56a";
  --fa--fa: "\e56a\e56a";
}

.fa-file-xls {
  --fa: "\e64d";
  --fa--fa: "\e64d\e64d";
}

.fa-file-excel {
  --fa: "\f1c3";
  --fa--fa: "\f1c3\f1c3";
}

.fa-file-contract {
  --fa: "\f56c";
  --fa--fa: "\f56c\f56c";
}

.fa-fish-fins {
  --fa: "\e4f2";
  --fa--fa: "\e4f2\e4f2";
}

.fa-circle-q {
  --fa: "\e11e";
  --fa--fa: "\e11e\e11e";
}

.fa-building-flag {
  --fa: "\e4d5";
  --fa--fa: "\e4d5\e4d5";
}

.fa-face-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582";
}

.fa-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582";
}

.fa-object-ungroup {
  --fa: "\f248";
  --fa--fa: "\f248\f248";
}

.fa-face-disguise {
  --fa: "\e370";
  --fa--fa: "\e370\e370";
}

.fa-circle-arrow-down-right {
  --fa: "\e0fa";
  --fa--fa: "\e0fa\e0fa";
}

.fa-alien-8bit {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6";
}

.fa-alien-monster {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6";
}

.fa-hand-point-ribbon {
  --fa: "\e1a6";
  --fa--fa: "\e1a6\e1a6";
}

.fa-poop {
  --fa: "\f619";
  --fa--fa: "\f619\f619";
}

.fa-object-exclude {
  --fa: "\e49c";
  --fa--fa: "\e49c\e49c";
}

.fa-telescope {
  --fa: "\e03e";
  --fa--fa: "\e03e\e03e";
}

.fa-location-pin {
  --fa: "\f041";
  --fa--fa: "\f041\f041";
}

.fa-map-marker {
  --fa: "\f041";
  --fa--fa: "\f041\f041";
}

.fa-square-list {
  --fa: "\e489";
  --fa--fa: "\e489\e489";
}

.fa-kaaba {
  --fa: "\f66b";
  --fa--fa: "\f66b\f66b";
}

.fa-toilet-paper {
  --fa: "\f71e";
  --fa--fa: "\f71e\f71e";
}

.fa-helmet-safety {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-hard-hat {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-hat-hard {
  --fa: "\f807";
  --fa--fa: "\f807\f807";
}

.fa-comment-code {
  --fa: "\e147";
  --fa--fa: "\e147\e147";
}

.fa-sim-cards {
  --fa: "\e251";
  --fa--fa: "\e251\e251";
}

.fa-starship {
  --fa: "\e039";
  --fa--fa: "\e039\e039";
}

.fa-eject {
  --fa: "\f052";
  --fa--fa: "\f052\f052";
}

.fa-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a";
}

.fa-arrow-alt-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a";
}

.fa-plane-circle-check {
  --fa: "\e555";
  --fa--fa: "\e555\e555";
}

.fa-seal {
  --fa: "\e241";
  --fa--fa: "\e241\e241";
}

.fa-user-cowboy {
  --fa: "\f8ea";
  --fa--fa: "\f8ea\f8ea";
}

.fa-hexagon-vertical-nft {
  --fa: "\e505";
  --fa--fa: "\e505\e505";
}

.fa-face-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5";
}

.fa-meh-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5";
}

.fa-bread-loaf {
  --fa: "\f7eb";
  --fa--fa: "\f7eb\f7eb";
}

.fa-rings-wedding {
  --fa: "\f81b";
  --fa--fa: "\f81b\f81b";
}

.fa-object-group {
  --fa: "\f247";
  --fa--fa: "\f247\f247";
}

.fa-french-fries {
  --fa: "\f803";
  --fa--fa: "\f803\f803";
}

.fa-chart-line {
  --fa: "\f201";
  --fa--fa: "\f201\f201";
}

.fa-line-chart {
  --fa: "\f201";
  --fa--fa: "\f201\f201";
}

.fa-calendar-arrow-down {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0";
}

.fa-calendar-download {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0";
}

.fa-send-back {
  --fa: "\f87e";
  --fa--fa: "\f87e\f87e";
}

.fa-mask-ventilator {
  --fa: "\e524";
  --fa--fa: "\e524\e524";
}

.fa-tickets {
  --fa: "\e658";
  --fa--fa: "\e658\e658";
}

.fa-signature-lock {
  --fa: "\e3ca";
  --fa--fa: "\e3ca\e3ca";
}

.fa-arrow-right {
  --fa: "\f061";
  --fa--fa: "\f061\f061";
}

.fa-signs-post {
  --fa: "\f277";
  --fa--fa: "\f277\f277";
}

.fa-map-signs {
  --fa: "\f277";
  --fa--fa: "\f277\f277";
}

.fa-octagon-plus {
  --fa: "\f301";
  --fa--fa: "\f301\f301";
}

.fa-plus-octagon {
  --fa: "\f301";
  --fa--fa: "\f301\f301";
}

.fa-cash-register {
  --fa: "\f788";
  --fa--fa: "\f788\f788";
}

.fa-person-circle-question {
  --fa: "\e542";
  --fa--fa: "\e542\e542";
}

.fa-melon-slice {
  --fa: "\e311";
  --fa--fa: "\e311\e311";
}

.fa-space-station-moon {
  --fa: "\e033";
  --fa--fa: "\e033\e033";
}

.fa-message-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa";
}

.fa-comment-alt-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa";
}

.fa-cup-straw {
  --fa: "\e363";
  --fa--fa: "\e363\e363";
}

.fa-left-from-line {
  --fa: "\f348";
  --fa--fa: "\f348\f348";
}

.fa-arrow-alt-from-right {
  --fa: "\f348";
  --fa--fa: "\f348\f348";
}

.fa-h {
  --fa: "H";
  --fa--fa: "HH";
}

.fa-basket-shopping-simple {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af";
}

.fa-shopping-basket-alt {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af";
}

.fa-hands-holding-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3";
}

.fa-hands-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3";
}

.fa-clock-nine {
  --fa: "\e34c";
  --fa--fa: "\e34c\e34c";
}

.fa-hammer-brush {
  --fa: "\e620";
  --fa--fa: "\e620\e620";
}

.fa-tarp {
  --fa: "\e57b";
  --fa--fa: "\e57b\e57b";
}

.fa-face-sleepy {
  --fa: "\e38e";
  --fa--fa: "\e38e\e38e";
}

.fa-hand-horns {
  --fa: "\e1a9";
  --fa--fa: "\e1a9\e1a9";
}

.fa-screwdriver-wrench {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9";
}

.fa-tools {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9";
}

.fa-arrows-to-eye {
  --fa: "\e4bf";
  --fa--fa: "\e4bf\e4bf";
}

.fa-circle-three-quarters {
  --fa: "\e125";
  --fa--fa: "\e125\e125";
}

.fa-trophy-star {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb";
}

.fa-trophy-alt {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb";
}

.fa-plug-circle-bolt {
  --fa: "\e55b";
  --fa--fa: "\e55b\e55b";
}

.fa-face-thermometer {
  --fa: "\e39a";
  --fa--fa: "\e39a\e39a";
}

.fa-grid-round-4 {
  --fa: "\e5dd";
  --fa--fa: "\e5dd\e5dd";
}

.fa-sign-posts-wrench {
  --fa: "\e626";
  --fa--fa: "\e626\e626";
}

.fa-shirt-running {
  --fa: "\e3c8";
  --fa--fa: "\e3c8\e3c8";
}

.fa-book-circle-arrow-up {
  --fa: "\e0bd";
  --fa--fa: "\e0bd\e0bd";
}

.fa-face-nauseated {
  --fa: "\e381";
  --fa--fa: "\e381\e381";
}

.fa-heart {
  --fa: "\f004";
  --fa--fa: "\f004\f004";
}

.fa-file-chart-pie {
  --fa: "\f65a";
  --fa--fa: "\f65a\f65a";
}

.fa-mars-and-venus {
  --fa: "\f224";
  --fa--fa: "\f224\f224";
}

.fa-house-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0";
}

.fa-home-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0";
}

.fa-circle-arrow-down-left {
  --fa: "\e0f9";
  --fa--fa: "\e0f9\e0f9";
}

.fa-dumpster-fire {
  --fa: "\f794";
  --fa--fa: "\f794\f794";
}

.fa-hexagon-minus {
  --fa: "\f307";
  --fa--fa: "\f307\f307";
}

.fa-minus-hexagon {
  --fa: "\f307";
  --fa--fa: "\f307\f307";
}

.fa-left-to-line {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b";
}

.fa-arrow-alt-to-left {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b";
}

.fa-house-crack {
  --fa: "\e3b1";
  --fa--fa: "\e3b1\e3b1";
}

.fa-paw-simple {
  --fa: "\f701";
  --fa--fa: "\f701\f701";
}

.fa-paw-alt {
  --fa: "\f701";
  --fa--fa: "\f701\f701";
}

.fa-arrow-left-long-to-line {
  --fa: "\e3d4";
  --fa--fa: "\e3d4\e3d4";
}

.fa-brackets-round {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5";
}

.fa-parentheses {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5";
}

.fa-martini-glass-citrus {
  --fa: "\f561";
  --fa--fa: "\f561\f561";
}

.fa-cocktail {
  --fa: "\f561";
  --fa--fa: "\f561\f561";
}

.fa-user-shakespeare {
  --fa: "\e2c2";
  --fa--fa: "\e2c2\e2c2";
}

.fa-arrow-right-to-arc {
  --fa: "\e4b2";
  --fa--fa: "\e4b2\e4b2";
}

.fa-face-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2";
}

.fa-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2";
}

.fa-bottle-water {
  --fa: "\e4c5";
  --fa--fa: "\e4c5\e4c5";
}

.fa-circle-pause {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b";
}

.fa-pause-circle {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b";
}

.fa-gauge-circle-plus {
  --fa: "\e498";
  --fa--fa: "\e498\e498";
}

.fa-folders {
  --fa: "\f660";
  --fa--fa: "\f660\f660";
}

.fa-angel {
  --fa: "\f779";
  --fa--fa: "\f779\f779";
}

.fa-value-absolute {
  --fa: "\f6a6";
  --fa--fa: "\f6a6\f6a6";
}

.fa-rabbit {
  --fa: "\f708";
  --fa--fa: "\f708\f708";
}

.fa-toilet-paper-slash {
  --fa: "\e072";
  --fa--fa: "\e072\e072";
}

.fa-circle-euro {
  --fa: "\e5ce";
  --fa--fa: "\e5ce\e5ce";
}

.fa-apple-whole {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1";
}

.fa-apple-alt {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1";
}

.fa-kitchen-set {
  --fa: "\e51a";
  --fa--fa: "\e51a\e51a";
}

.fa-diamond-half {
  --fa: "\e5b7";
  --fa--fa: "\e5b7\e5b7";
}

.fa-lock-keyhole {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d";
}

.fa-lock-alt {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d";
}

.fa-r {
  --fa: "R";
  --fa--fa: "RR";
}

.fa-temperature-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-temperature-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-thermometer-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-thermometer-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca";
}

.fa-square-info {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f";
}

.fa-info-square {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f";
}

.fa-wifi-slash {
  --fa: "\f6ac";
  --fa--fa: "\f6ac\f6ac";
}

.fa-toilet-paper-xmark {
  --fa: "\e5b3";
  --fa--fa: "\e5b3\e5b3";
}

.fa-hands-holding-dollar {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5";
}

.fa-hands-usd {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5";
}

.fa-cube {
  --fa: "\f1b2";
  --fa--fa: "\f1b2\f1b2";
}

.fa-arrow-down-triangle-square {
  --fa: "\f888";
  --fa--fa: "\f888\f888";
}

.fa-sort-shapes-down {
  --fa: "\f888";
  --fa--fa: "\f888\f888";
}

.fa-bitcoin-sign {
  --fa: "\e0b4";
  --fa--fa: "\e0b4\e0b4";
}

.fa-shutters {
  --fa: "\e449";
  --fa--fa: "\e449\e449";
}

.fa-shield-dog {
  --fa: "\e573";
  --fa--fa: "\e573\e573";
}

.fa-solar-panel {
  --fa: "\f5ba";
  --fa--fa: "\f5ba\f5ba";
}

.fa-lock-open {
  --fa: "\f3c1";
  --fa--fa: "\f3c1\f3c1";
}

.fa-table-tree {
  --fa: "\e293";
  --fa--fa: "\e293\e293";
}

.fa-house-chimney-heart {
  --fa: "\e1b2";
  --fa--fa: "\e1b2\e1b2";
}

.fa-tally-3 {
  --fa: "\e296";
  --fa--fa: "\e296\e296";
}

.fa-elevator {
  --fa: "\e16d";
  --fa--fa: "\e16d\e16d";
}

.fa-money-bill-transfer {
  --fa: "\e528";
  --fa--fa: "\e528\e528";
}

.fa-money-bill-trend-up {
  --fa: "\e529";
  --fa--fa: "\e529\e529";
}

.fa-house-flood-water-circle-arrow-right {
  --fa: "\e50f";
  --fa--fa: "\e50f\e50f";
}

.fa-square-poll-horizontal {
  --fa: "\f682";
  --fa--fa: "\f682\f682";
}

.fa-poll-h {
  --fa: "\f682";
  --fa--fa: "\f682\f682";
}

.fa-circle {
  --fa: "\f111";
  --fa--fa: "\f111\f111";
}

.fa-left-to-bracket {
  --fa: "\e66d";
  --fa--fa: "\e66d\e66d";
}

.fa-cart-circle-exclamation {
  --fa: "\e3f2";
  --fa--fa: "\e3f2\e3f2";
}

.fa-sword {
  --fa: "\f71c";
  --fa--fa: "\f71c\f71c";
}

.fa-backward-fast {
  --fa: "\f049";
  --fa--fa: "\f049\f049";
}

.fa-fast-backward {
  --fa: "\f049";
  --fa--fa: "\f049\f049";
}

.fa-recycle {
  --fa: "\f1b8";
  --fa--fa: "\f1b8\f1b8";
}

.fa-user-astronaut {
  --fa: "\f4fb";
  --fa--fa: "\f4fb\f4fb";
}

.fa-interrobang {
  --fa: "\e5ba";
  --fa--fa: "\e5ba\e5ba";
}

.fa-plane-slash {
  --fa: "\e069";
  --fa--fa: "\e069\e069";
}

.fa-circle-dashed {
  --fa: "\e105";
  --fa--fa: "\e105\e105";
}

.fa-trademark {
  --fa: "\f25c";
  --fa--fa: "\f25c\f25c";
}

.fa-basketball {
  --fa: "\f434";
  --fa--fa: "\f434\f434";
}

.fa-basketball-ball {
  --fa: "\f434";
  --fa--fa: "\f434\f434";
}

.fa-fork-knife {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6";
}

.fa-utensils-alt {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6";
}

.fa-satellite-dish {
  --fa: "\f7c0";
  --fa--fa: "\f7c0\f7c0";
}

.fa-badge-check {
  --fa: "\f336";
  --fa--fa: "\f336\f336";
}

.fa-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b";
}

.fa-arrow-alt-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b";
}

.fa-slider {
  --fa: "\e252";
  --fa--fa: "\e252\e252";
}

.fa-mobile-screen-button {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd";
}

.fa-mobile-alt {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd";
}

.fa-clock-one-thirty {
  --fa: "\e34f";
  --fa--fa: "\e34f\e34f";
}

.fa-inbox-out {
  --fa: "\f311";
  --fa--fa: "\f311\f311";
}

.fa-inbox-arrow-up {
  --fa: "\f311";
  --fa--fa: "\f311\f311";
}

.fa-cloud-slash {
  --fa: "\e137";
  --fa--fa: "\e137\e137";
}

.fa-volume-high {
  --fa: "\f028";
  --fa--fa: "\f028\f028";
}

.fa-volume-up {
  --fa: "\f028";
  --fa--fa: "\f028\f028";
}

.fa-users-rays {
  --fa: "\e593";
  --fa--fa: "\e593\e593";
}

.fa-wallet {
  --fa: "\f555";
  --fa--fa: "\f555\f555";
}

.fa-octagon-check {
  --fa: "\e426";
  --fa--fa: "\e426\e426";
}

.fa-flatbread-stuffed {
  --fa: "\e40c";
  --fa--fa: "\e40c\e40c";
}

.fa-clipboard-check {
  --fa: "\f46c";
  --fa--fa: "\f46c\f46c";
}

.fa-cart-circle-plus {
  --fa: "\e3f3";
  --fa--fa: "\e3f3\e3f3";
}

.fa-truck-clock {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c";
}

.fa-shipping-timed {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c";
}

.fa-pool-8-ball {
  --fa: "\e3c5";
  --fa--fa: "\e3c5\e3c5";
}

.fa-file-audio {
  --fa: "\f1c7";
  --fa--fa: "\f1c7\f1c7";
}

.fa-turn-down-left {
  --fa: "\e331";
  --fa--fa: "\e331\e331";
}

.fa-lock-hashtag {
  --fa: "\e423";
  --fa--fa: "\e423\e423";
}

.fa-chart-radar {
  --fa: "\e0e7";
  --fa--fa: "\e0e7\e0e7";
}

.fa-staff {
  --fa: "\f71b";
  --fa--fa: "\f71b\f71b";
}

.fa-burger {
  --fa: "\f805";
  --fa--fa: "\f805\f805";
}

.fa-hamburger {
  --fa: "\f805";
  --fa--fa: "\f805\f805";
}

.fa-utility-pole {
  --fa: "\e2c3";
  --fa--fa: "\e2c3\e2c3";
}

.fa-transporter-6 {
  --fa: "\e2a7";
  --fa--fa: "\e2a7\e2a7";
}

.fa-arrow-turn-left {
  --fa: "\e632";
  --fa--fa: "\e632\e632";
}

.fa-wrench {
  --fa: "\f0ad";
  --fa--fa: "\f0ad\f0ad";
}

.fa-bugs {
  --fa: "\e4d0";
  --fa--fa: "\e4d0\e4d0";
}

.fa-vector-polygon {
  --fa: "\e2c7";
  --fa--fa: "\e2c7\e2c7";
}

.fa-diagram-nested {
  --fa: "\e157";
  --fa--fa: "\e157\e157";
}

.fa-rupee-sign {
  --fa: "\f156";
  --fa--fa: "\f156\f156";
}

.fa-rupee {
  --fa: "\f156";
  --fa--fa: "\f156\f156";
}

.fa-file-image {
  --fa: "\f1c5";
  --fa--fa: "\f1c5\f1c5";
}

.fa-circle-question {
  --fa: "\f059";
  --fa--fa: "\f059\f059";
}

.fa-question-circle {
  --fa: "\f059";
  --fa--fa: "\f059\f059";
}

.fa-tickets-perforated {
  --fa: "\e63f";
  --fa--fa: "\e63f\e63f";
}

.fa-image-user {
  --fa: "\e1b8";
  --fa--fa: "\e1b8\e1b8";
}

.fa-buoy {
  --fa: "\e5b5";
  --fa--fa: "\e5b5\e5b5";
}

.fa-plane-departure {
  --fa: "\f5b0";
  --fa--fa: "\f5b0\f5b0";
}

.fa-handshake-slash {
  --fa: "\e060";
  --fa--fa: "\e060\e060";
}

.fa-book-bookmark {
  --fa: "\e0bb";
  --fa--fa: "\e0bb\e0bb";
}

.fa-border-center-h {
  --fa: "\f89c";
  --fa--fa: "\f89c\f89c";
}

.fa-can-food {
  --fa: "\e3e6";
  --fa--fa: "\e3e6\e3e6";
}

.fa-typewriter {
  --fa: "\f8e7";
  --fa--fa: "\f8e7\f8e7";
}

.fa-arrow-right-from-arc {
  --fa: "\e4b1";
  --fa--fa: "\e4b1\e4b1";
}

.fa-circle-k {
  --fa: "\e113";
  --fa--fa: "\e113\e113";
}

.fa-face-hand-over-mouth {
  --fa: "\e378";
  --fa--fa: "\e378\e378";
}

.fa-popcorn {
  --fa: "\f819";
  --fa--fa: "\f819\f819";
}

.fa-house-water {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f";
}

.fa-house-flood {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f";
}

.fa-object-subtract {
  --fa: "\e49e";
  --fa--fa: "\e49e\e49e";
}

.fa-code-branch {
  --fa: "\f126";
  --fa--fa: "\f126\f126";
}

.fa-warehouse-full {
  --fa: "\f495";
  --fa--fa: "\f495\f495";
}

.fa-warehouse-alt {
  --fa: "\f495";
  --fa--fa: "\f495\f495";
}

.fa-hat-cowboy {
  --fa: "\f8c0";
  --fa--fa: "\f8c0\f8c0";
}

.fa-bridge {
  --fa: "\e4c8";
  --fa--fa: "\e4c8\e4c8";
}

.fa-phone-flip {
  --fa: "\f879";
  --fa--fa: "\f879\f879";
}

.fa-phone-alt {
  --fa: "\f879";
  --fa--fa: "\f879\f879";
}

.fa-arrow-down-from-dotted-line {
  --fa: "\e090";
  --fa--fa: "\e090\e090";
}

.fa-file-doc {
  --fa: "\e5ed";
  --fa--fa: "\e5ed\e5ed";
}

.fa-square-quarters {
  --fa: "\e44e";
  --fa--fa: "\e44e\e44e";
}

.fa-truck-front {
  --fa: "\e2b7";
  --fa--fa: "\e2b7\e2b7";
}

.fa-cat {
  --fa: "\f6be";
  --fa--fa: "\f6be\f6be";
}

.fa-trash-xmark {
  --fa: "\e2b4";
  --fa--fa: "\e2b4\e2b4";
}

.fa-circle-caret-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e";
}

.fa-caret-circle-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e";
}

.fa-files {
  --fa: "\e178";
  --fa--fa: "\e178\e178";
}

.fa-anchor-circle-exclamation {
  --fa: "\e4ab";
  --fa--fa: "\e4ab\e4ab";
}

.fa-face-clouds {
  --fa: "\e47d";
  --fa--fa: "\e47d\e47d";
}

.fa-user-crown {
  --fa: "\f6a4";
  --fa--fa: "\f6a4\f6a4";
}

.fa-basket-shopping-plus {
  --fa: "\e653";
  --fa--fa: "\e653\e653";
}

.fa-truck-field {
  --fa: "\e58d";
  --fa--fa: "\e58d\e58d";
}

.fa-route {
  --fa: "\f4d7";
  --fa--fa: "\f4d7\f4d7";
}

.fa-cart-circle-check {
  --fa: "\e3f1";
  --fa--fa: "\e3f1\e3f1";
}

.fa-clipboard-question {
  --fa: "\e4e3";
  --fa--fa: "\e4e3\e4e3";
}

.fa-panorama {
  --fa: "\e209";
  --fa--fa: "\e209\e209";
}

.fa-comment-medical {
  --fa: "\f7f5";
  --fa--fa: "\f7f5\f7f5";
}

.fa-teeth-open {
  --fa: "\f62f";
  --fa--fa: "\f62f\f62f";
}

.fa-user-tie-hair-long {
  --fa: "\e460";
  --fa--fa: "\e460\e460";
}

.fa-file-circle-minus {
  --fa: "\e4ed";
  --fa--fa: "\e4ed\e4ed";
}

.fa-head-side-medical {
  --fa: "\f809";
  --fa--fa: "\f809\f809";
}

.fa-arrow-turn-right {
  --fa: "\e635";
  --fa--fa: "\e635\e635";
}

.fa-tags {
  --fa: "\f02c";
  --fa--fa: "\f02c\f02c";
}

.fa-wine-glass {
  --fa: "\f4e3";
  --fa--fa: "\f4e3\f4e3";
}

.fa-forward-fast {
  --fa: "\f050";
  --fa--fa: "\f050\f050";
}

.fa-fast-forward {
  --fa: "\f050";
  --fa--fa: "\f050\f050";
}

.fa-face-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4";
}

.fa-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4";
}

.fa-user-robot {
  --fa: "\e04b";
  --fa--fa: "\e04b\e04b";
}

.fa-square-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540";
}

.fa-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540";
}

.fa-card-diamond {
  --fa: "\e3ea";
  --fa--fa: "\e3ea\e3ea";
}

.fa-face-zipper {
  --fa: "\e3a5";
  --fa--fa: "\e3a5\e3a5";
}

.fa-face-raised-eyebrow {
  --fa: "\e388";
  --fa--fa: "\e388\e388";
}

.fa-house-signal {
  --fa: "\e012";
  --fa--fa: "\e012\e012";
}

.fa-square-chevron-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c";
}

.fa-chevron-square-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c";
}

.fa-bars-progress {
  --fa: "\f828";
  --fa--fa: "\f828\f828";
}

.fa-tasks-alt {
  --fa: "\f828";
  --fa--fa: "\f828\f828";
}

.fa-faucet-drip {
  --fa: "\e006";
  --fa--fa: "\e006\e006";
}

.fa-arrows-to-line {
  --fa: "\e0a7";
  --fa--fa: "\e0a7\e0a7";
}

.fa-dolphin {
  --fa: "\e168";
  --fa--fa: "\e168\e168";
}

.fa-arrow-up-right {
  --fa: "\e09f";
  --fa--fa: "\e09f\e09f";
}

.fa-circle-r {
  --fa: "\e120";
  --fa--fa: "\e120\e120";
}

.fa-cart-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474";
}

.fa-dolly-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474";
}

.fa-ban-smoking {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d";
}

.fa-smoking-ban {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d";
}

.fa-circle-sort-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032";
}

.fa-sort-circle-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032";
}

.fa-terminal {
  --fa: "\f120";
  --fa--fa: "\f120\f120";
}

.fa-mobile-button {
  --fa: "\f10b";
  --fa--fa: "\f10b\f10b";
}

.fa-house-medical-flag {
  --fa: "\e514";
  --fa--fa: "\e514\e514";
}

.fa-basket-shopping {
  --fa: "\f291";
  --fa--fa: "\f291\f291";
}

.fa-shopping-basket {
  --fa: "\f291";
  --fa--fa: "\f291\f291";
}

.fa-tape {
  --fa: "\f4db";
  --fa--fa: "\f4db\f4db";
}

.fa-chestnut {
  --fa: "\e3f6";
  --fa--fa: "\e3f6\e3f6";
}

.fa-bus-simple {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e";
}

.fa-bus-alt {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e";
}

.fa-eye {
  --fa: "\f06e";
  --fa--fa: "\f06e\f06e";
}

.fa-face-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3";
}

.fa-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3";
}

.fa-heat {
  --fa: "\e00c";
  --fa--fa: "\e00c\e00c";
}

.fa-ticket-airline {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-ticket-perforated-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-ticket-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a";
}

.fa-boot-heeled {
  --fa: "\e33f";
  --fa--fa: "\e33f\e33f";
}

.fa-arrows-minimize {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5";
}

.fa-compress-arrows {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5";
}

.fa-audio-description {
  --fa: "\f29e";
  --fa--fa: "\f29e\f29e";
}

.fa-person-military-to-person {
  --fa: "\e54c";
  --fa--fa: "\e54c\e54c";
}

.fa-file-shield {
  --fa: "\e4f0";
  --fa--fa: "\e4f0\e4f0";
}

.fa-hexagon {
  --fa: "\f312";
  --fa--fa: "\f312\f312";
}

.fa-manhole {
  --fa: "\e1d6";
  --fa--fa: "\e1d6\e1d6";
}

.fa-user-slash {
  --fa: "\f506";
  --fa--fa: "\f506\f506";
}

.fa-pen {
  --fa: "\f304";
  --fa--fa: "\f304\f304";
}

.fa-tower-observation {
  --fa: "\e586";
  --fa--fa: "\e586\e586";
}

.fa-floppy-disks {
  --fa: "\e183";
  --fa--fa: "\e183\e183";
}

.fa-toilet-paper-blank-under {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f";
}

.fa-toilet-paper-reverse-alt {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f";
}

.fa-file-code {
  --fa: "\f1c9";
  --fa--fa: "\f1c9\f1c9";
}

.fa-signal {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-signal-5 {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-signal-perfect {
  --fa: "\f012";
  --fa--fa: "\f012\f012";
}

.fa-pump {
  --fa: "\e442";
  --fa--fa: "\e442\e442";
}

.fa-bus {
  --fa: "\f207";
  --fa--fa: "\f207\f207";
}

.fa-heart-circle-xmark {
  --fa: "\e501";
  --fa--fa: "\e501\e501";
}

.fa-arrow-up-left-from-circle {
  --fa: "\e09e";
  --fa--fa: "\e09e\e09e";
}

.fa-house-chimney {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af";
}

.fa-home-lg {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af";
}

.fa-window-maximize {
  --fa: "\f2d0";
  --fa--fa: "\f2d0\f2d0";
}

.fa-dryer {
  --fa: "\f861";
  --fa--fa: "\f861\f861";
}

.fa-face-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119";
}

.fa-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119";
}

.fa-chess-bishop-piece {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b";
}

.fa-chess-bishop-alt {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b";
}

.fa-shirt-tank-top {
  --fa: "\e3c9";
  --fa--fa: "\e3c9\e3c9";
}

.fa-diploma {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea";
}

.fa-scroll-ribbon {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea";
}

.fa-screencast {
  --fa: "\e23e";
  --fa--fa: "\e23e\e23e";
}

.fa-walker {
  --fa: "\f831";
  --fa--fa: "\f831\f831";
}

.fa-prescription {
  --fa: "\f5b1";
  --fa--fa: "\f5b1\f5b1";
}

.fa-shop {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f";
}

.fa-store-alt {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f";
}

.fa-floppy-disk {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7";
}

.fa-save {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7";
}

.fa-vihara {
  --fa: "\f6a7";
  --fa--fa: "\f6a7\f6a7";
}

.fa-face-kiss-closed-eyes {
  --fa: "\e37d";
  --fa--fa: "\e37d\e37d";
}

.fa-scale-unbalanced {
  --fa: "\f515";
  --fa--fa: "\f515\f515";
}

.fa-balance-scale-left {
  --fa: "\f515";
  --fa--fa: "\f515\f515";
}

.fa-file-user {
  --fa: "\f65c";
  --fa--fa: "\f65c\f65c";
}

.fa-user-police-tie {
  --fa: "\e334";
  --fa--fa: "\e334\e334";
}

.fa-face-tongue-money {
  --fa: "\e39d";
  --fa--fa: "\e39d\e39d";
}

.fa-tennis-ball {
  --fa: "\f45e";
  --fa--fa: "\f45e\f45e";
}

.fa-square-l {
  --fa: "\e275";
  --fa--fa: "\e275\e275";
}

.fa-sort-up {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de";
}

.fa-sort-asc {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de";
}

.fa-calendar-arrow-up {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1";
}

.fa-calendar-upload {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1";
}

.fa-comment-dots {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad";
}

.fa-commenting {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad";
}

.fa-plant-wilt {
  --fa: "\e5aa";
  --fa--fa: "\e5aa\e5aa";
}

.fa-scarf {
  --fa: "\f7c1";
  --fa--fa: "\f7c1\f7c1";
}

.fa-album-circle-plus {
  --fa: "\e48c";
  --fa--fa: "\e48c\e48c";
}

.fa-user-nurse-hair-long {
  --fa: "\e45e";
  --fa--fa: "\e45e\e45e";
}

.fa-diamond {
  --fa: "\f219";
  --fa--fa: "\f219\f219";
}

.fa-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351";
}

.fa-arrow-alt-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351";
}

.fa-face-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585";
}

.fa-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585";
}

.fa-circle-ellipsis-vertical {
  --fa: "\e10b";
  --fa--fa: "\e10b\e10b";
}

.fa-hand-holding-dollar {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0";
}

.fa-hand-holding-usd {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0";
}

.fa-grid-dividers {
  --fa: "\e3ad";
  --fa--fa: "\e3ad\e3ad";
}

.fa-chart-diagram {
  --fa: "\e695";
  --fa--fa: "\e695\e695";
}

.fa-bacterium {
  --fa: "\e05a";
  --fa--fa: "\e05a\e05a";
}

.fa-hand-pointer {
  --fa: "\f25a";
  --fa--fa: "\f25a\f25a";
}

.fa-drum-steelpan {
  --fa: "\f56a";
  --fa--fa: "\f56a\f56a";
}

.fa-hand-scissors {
  --fa: "\f257";
  --fa--fa: "\f257\f257";
}

.fa-hands-praying {
  --fa: "\f684";
  --fa--fa: "\f684\f684";
}

.fa-praying-hands {
  --fa: "\f684";
  --fa--fa: "\f684\f684";
}

.fa-face-pensive {
  --fa: "\e384";
  --fa--fa: "\e384\e384";
}

.fa-user-music {
  --fa: "\f8eb";
  --fa--fa: "\f8eb\f8eb";
}

.fa-arrow-rotate-right {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-arrow-right-rotate {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-arrow-rotate-forward {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-redo {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e";
}

.fa-messages-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652";
}

.fa-comments-alt-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652";
}

.fa-sensor-on {
  --fa: "\e02b";
  --fa--fa: "\e02b\e02b";
}

.fa-balloon {
  --fa: "\e2e3";
  --fa--fa: "\e2e3\e2e3";
}

.fa-biohazard {
  --fa: "\f780";
  --fa--fa: "\f780\f780";
}

.fa-chess-queen-piece {
  --fa: "\f446";
  --fa--fa: "\f446\f446";
}

.fa-chess-queen-alt {
  --fa: "\f446";
  --fa--fa: "\f446\f446";
}

.fa-location-crosshairs {
  --fa: "\f601";
  --fa--fa: "\f601\f601";
}

.fa-location {
  --fa: "\f601";
  --fa--fa: "\f601\f601";
}

.fa-mars-double {
  --fa: "\f227";
  --fa--fa: "\f227\f227";
}

.fa-left-from-bracket {
  --fa: "\e66c";
  --fa--fa: "\e66c\e66c";
}

.fa-house-person-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-house-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-house-person-depart {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f";
}

.fa-ruler-triangle {
  --fa: "\f61c";
  --fa--fa: "\f61c\f61c";
}

.fa-card-club {
  --fa: "\e3e9";
  --fa--fa: "\e3e9\e3e9";
}

.fa-child-dress {
  --fa: "\e59c";
  --fa--fa: "\e59c\e59c";
}

.fa-users-between-lines {
  --fa: "\e591";
  --fa--fa: "\e591\e591";
}

.fa-lungs-virus {
  --fa: "\e067";
  --fa--fa: "\e067\e067";
}

.fa-spinner-third {
  --fa: "\f3f4";
  --fa--fa: "\f3f4\f3f4";
}

.fa-face-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588";
}

.fa-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588";
}

.fa-phone {
  --fa: "\f095";
  --fa--fa: "\f095\f095";
}

.fa-computer-mouse-scrollwheel {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd";
}

.fa-mouse-alt {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd";
}

.fa-calendar-xmark {
  --fa: "\f273";
  --fa--fa: "\f273\f273";
}

.fa-calendar-times {
  --fa: "\f273";
  --fa--fa: "\f273\f273";
}

.fa-child-reaching {
  --fa: "\e59d";
  --fa--fa: "\e59d\e59d";
}

.fa-table-layout {
  --fa: "\e290";
  --fa--fa: "\e290\e290";
}

.fa-narwhal {
  --fa: "\f6fe";
  --fa--fa: "\f6fe\f6fe";
}

.fa-ramp-loading {
  --fa: "\f4d4";
  --fa--fa: "\f4d4\f4d4";
}

.fa-calendar-circle-plus {
  --fa: "\e470";
  --fa--fa: "\e470\e470";
}

.fa-toothbrush {
  --fa: "\f635";
  --fa--fa: "\f635\f635";
}

.fa-border-inner {
  --fa: "\f84e";
  --fa--fa: "\f84e\f84e";
}

.fa-paw-claws {
  --fa: "\f702";
  --fa--fa: "\f702\f702";
}

.fa-kiwi-fruit {
  --fa: "\e30c";
  --fa--fa: "\e30c\e30c";
}

.fa-traffic-light-slow {
  --fa: "\f639";
  --fa--fa: "\f639\f639";
}

.fa-rectangle-code {
  --fa: "\e322";
  --fa--fa: "\e322\e322";
}

.fa-head-side-virus {
  --fa: "\e064";
  --fa--fa: "\e064\e064";
}

.fa-keyboard-brightness {
  --fa: "\e1c0";
  --fa--fa: "\e1c0\e1c0";
}

.fa-books-medical {
  --fa: "\f7e8";
  --fa--fa: "\f7e8\f7e8";
}

.fa-lightbulb-slash {
  --fa: "\f673";
  --fa--fa: "\f673\f673";
}

.fa-house-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487";
}

.fa-home-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487";
}

.fa-square-5 {
  --fa: "\e25a";
  --fa--fa: "\e25a\e25a";
}

.fa-square-heart {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8";
}

.fa-heart-square {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8";
}

.fa-puzzle {
  --fa: "\e443";
  --fa--fa: "\e443\e443";
}

.fa-user-gear {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe";
}

.fa-user-cog {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe";
}

.fa-pipe-circle-check {
  --fa: "\e436";
  --fa--fa: "\e436\e436";
}

.fa-arrow-up-1-9 {
  --fa: "\f163";
  --fa--fa: "\f163\f163";
}

.fa-sort-numeric-up {
  --fa: "\f163";
  --fa--fa: "\f163\f163";
}

.fa-octagon-exclamation {
  --fa: "\e204";
  --fa--fa: "\e204\e204";
}

.fa-dial-low {
  --fa: "\e15d";
  --fa--fa: "\e15d\e15d";
}

.fa-door-closed {
  --fa: "\f52a";
  --fa--fa: "\f52a\f52a";
}

.fa-laptop-mobile {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a";
}

.fa-phone-laptop {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a";
}

.fa-conveyor-belt-boxes {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f";
}

.fa-conveyor-belt-alt {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f";
}

.fa-shield-virus {
  --fa: "\e06c";
  --fa--fa: "\e06c\e06c";
}

.fa-starfighter-twin-ion-engine-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e";
}

.fa-starfighter-alt-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e";
}

.fa-dice-six {
  --fa: "\f526";
  --fa--fa: "\f526\f526";
}

.fa-starfighter-twin-ion-engine {
  --fa: "\e038";
  --fa--fa: "\e038\e038";
}

.fa-starfighter-alt {
  --fa: "\e038";
  --fa--fa: "\e038\e038";
}

.fa-rocket-launch {
  --fa: "\e027";
  --fa--fa: "\e027\e027";
}

.fa-mosquito-net {
  --fa: "\e52c";
  --fa--fa: "\e52c\e52c";
}

.fa-file-fragment {
  --fa: "\e697";
  --fa--fa: "\e697\e697";
}

.fa-vent-damper {
  --fa: "\e465";
  --fa--fa: "\e465\e465";
}

.fa-bridge-water {
  --fa: "\e4ce";
  --fa--fa: "\e4ce\e4ce";
}

.fa-ban-bug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9";
}

.fa-debug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9";
}

.fa-person-booth {
  --fa: "\f756";
  --fa--fa: "\f756\f756";
}

.fa-text-width {
  --fa: "\f035";
  --fa--fa: "\f035\f035";
}

.fa-garage-car {
  --fa: "\e00a";
  --fa--fa: "\e00a\e00a";
}

.fa-square-kanban {
  --fa: "\e488";
  --fa--fa: "\e488\e488";
}

.fa-hat-wizard {
  --fa: "\f6e8";
  --fa--fa: "\f6e8\f6e8";
}

.fa-chart-kanban {
  --fa: "\e64f";
  --fa--fa: "\e64f\e64f";
}

.fa-pen-fancy {
  --fa: "\f5ac";
  --fa--fa: "\f5ac\f5ac";
}

.fa-coffee-pot {
  --fa: "\e002";
  --fa--fa: "\e002\e002";
}

.fa-mouse-field {
  --fa: "\e5a8";
  --fa--fa: "\e5a8\e5a8";
}

.fa-person-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e";
}

.fa-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e";
}

.fa-shower-down {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d";
}

.fa-shower-alt {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d";
}

.fa-box-circle-check {
  --fa: "\e0c4";
  --fa--fa: "\e0c4\e0c4";
}

.fa-brightness {
  --fa: "\e0c9";
  --fa--fa: "\e0c9\e0c9";
}

.fa-car-side-bolt {
  --fa: "\e344";
  --fa--fa: "\e344\e344";
}

.fa-file-xml {
  --fa: "\e654";
  --fa--fa: "\e654\e654";
}

.fa-ornament {
  --fa: "\f7b8";
  --fa--fa: "\f7b8\f7b8";
}

.fa-phone-arrow-down-left {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-phone-arrow-down {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-phone-incoming {
  --fa: "\e223";
  --fa--fa: "\e223\e223";
}

.fa-cloud-word {
  --fa: "\e138";
  --fa--fa: "\e138\e138";
}

.fa-hand-fingers-crossed {
  --fa: "\e1a3";
  --fa--fa: "\e1a3\e1a3";
}

.fa-trash {
  --fa: "\f1f8";
  --fa--fa: "\f1f8\f1f8";
}

.fa-gauge-simple {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-gauge-simple-med {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-tachometer-average {
  --fa: "\f629";
  --fa--fa: "\f629\f629";
}

.fa-arrow-down-small-big {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d";
}

.fa-sort-size-down-alt {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d";
}

.fa-book-medical {
  --fa: "\f7e6";
  --fa--fa: "\f7e6\f7e6";
}

.fa-face-melting {
  --fa: "\e483";
  --fa--fa: "\e483\e483";
}

.fa-poo {
  --fa: "\f2fe";
  --fa--fa: "\f2fe\f2fe";
}

.fa-pen-clip-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f";
}

.fa-pen-alt-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f";
}

.fa-quote-right {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e";
}

.fa-quote-right-alt {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e";
}

.fa-scroll-old {
  --fa: "\f70f";
  --fa--fa: "\f70f\f70f";
}

.fa-guitars {
  --fa: "\f8bf";
  --fa--fa: "\f8bf\f8bf";
}

.fa-phone-xmark {
  --fa: "\e227";
  --fa--fa: "\e227\e227";
}

.fa-hose {
  --fa: "\e419";
  --fa--fa: "\e419\e419";
}

.fa-clock-six {
  --fa: "\e352";
  --fa--fa: "\e352\e352";
}

.fa-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-t-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-tshirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553";
}

.fa-billboard {
  --fa: "\e5cd";
  --fa--fa: "\e5cd\e5cd";
}

.fa-square-r {
  --fa: "\e27c";
  --fa--fa: "\e27c\e27c";
}

.fa-cubes {
  --fa: "\f1b3";
  --fa--fa: "\f1b3\f1b3";
}

.fa-envelope-open-dollar {
  --fa: "\f657";
  --fa--fa: "\f657\f657";
}

.fa-divide {
  --fa: "\f529";
  --fa--fa: "\f529\f529";
}

.fa-sun-cloud {
  --fa: "\f763";
  --fa--fa: "\f763\f763";
}

.fa-lamp-floor {
  --fa: "\e015";
  --fa--fa: "\e015\e015";
}

.fa-square-7 {
  --fa: "\e25c";
  --fa--fa: "\e25c\e25c";
}

.fa-tenge-sign {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7";
}

.fa-tenge {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7";
}

.fa-headphones {
  --fa: "\f025";
  --fa--fa: "\f025\f025";
}

.fa-hands-holding {
  --fa: "\f4c2";
  --fa--fa: "\f4c2\f4c2";
}

.fa-campfire {
  --fa: "\f6ba";
  --fa--fa: "\f6ba\f6ba";
}

.fa-circle-ampersand {
  --fa: "\e0f8";
  --fa--fa: "\e0f8\e0f8";
}

.fa-snowflakes {
  --fa: "\f7cf";
  --fa--fa: "\f7cf\f7cf";
}

.fa-hands-clapping {
  --fa: "\e1a8";
  --fa--fa: "\e1a8\e1a8";
}

.fa-republican {
  --fa: "\f75e";
  --fa--fa: "\f75e\f75e";
}

.fa-leaf-maple {
  --fa: "\f6f6";
  --fa--fa: "\f6f6\f6f6";
}

.fa-arrow-left {
  --fa: "\f060";
  --fa--fa: "\f060\f060";
}

.fa-person-circle-xmark {
  --fa: "\e543";
  --fa--fa: "\e543\e543";
}

.fa-ruler {
  --fa: "\f545";
  --fa--fa: "\f545\f545";
}

.fa-arrow-left-from-bracket {
  --fa: "\e668";
  --fa--fa: "\e668\e668";
}

.fa-cup-straw-swoosh {
  --fa: "\e364";
  --fa--fa: "\e364\e364";
}

.fa-temperature-sun {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a";
}

.fa-temperature-hot {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a";
}

.fa-align-left {
  --fa: "\f036";
  --fa--fa: "\f036\f036";
}

.fa-dice-d6 {
  --fa: "\f6d1";
  --fa--fa: "\f6d1\f6d1";
}

.fa-restroom {
  --fa: "\f7bd";
  --fa--fa: "\f7bd\f7bd";
}

.fa-high-definition {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae";
}

.fa-rectangle-hd {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae";
}

.fa-j {
  --fa: "J";
  --fa--fa: "JJ";
}

.fa-galaxy {
  --fa: "\e008";
  --fa--fa: "\e008\e008";
}

.fa-users-viewfinder {
  --fa: "\e595";
  --fa--fa: "\e595\e595";
}

.fa-file-video {
  --fa: "\f1c8";
  --fa--fa: "\f1c8\f1c8";
}

.fa-cherries {
  --fa: "\e0ec";
  --fa--fa: "\e0ec\e0ec";
}

.fa-up-right-from-square {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d";
}

.fa-external-link-alt {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d";
}

.fa-circle-sort {
  --fa: "\e030";
  --fa--fa: "\e030\e030";
}

.fa-sort-circle {
  --fa: "\e030";
  --fa--fa: "\e030\e030";
}

.fa-table-cells {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a";
}

.fa-th {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a";
}

.fa-bag-shopping-minus {
  --fa: "\e650";
  --fa--fa: "\e650\e650";
}

.fa-file-pdf {
  --fa: "\f1c1";
  --fa--fa: "\f1c1\f1c1";
}

.fa-siren {
  --fa: "\e02d";
  --fa--fa: "\e02d\e02d";
}

.fa-arrow-up-to-dotted-line {
  --fa: "\e0a1";
  --fa--fa: "\e0a1\e0a1";
}

.fa-image-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5";
}

.fa-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5";
}

.fa-tank-water {
  --fa: "\e452";
  --fa--fa: "\e452\e452";
}

.fa-curling-stone {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a";
}

.fa-curling {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a";
}

.fa-gamepad-modern {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2";
}

.fa-gamepad-alt {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2";
}

.fa-messages-question {
  --fa: "\e1e7";
  --fa--fa: "\e1e7\e1e7";
}

.fa-book-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647";
}

.fa-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647";
}

.fa-o {
  --fa: "O";
  --fa--fa: "OO";
}

.fa-suitcase-medical {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa";
}

.fa-medkit {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa";
}

.fa-briefcase-arrow-right {
  --fa: "\e2f2";
  --fa--fa: "\e2f2\e2f2";
}

.fa-expand-wide {
  --fa: "\f320";
  --fa--fa: "\f320\f320";
}

.fa-clock-eleven-thirty {
  --fa: "\e348";
  --fa--fa: "\e348\e348";
}

.fa-rv {
  --fa: "\f7be";
  --fa--fa: "\f7be\f7be";
}

.fa-user-secret {
  --fa: "\f21b";
  --fa--fa: "\f21b\f21b";
}

.fa-otter {
  --fa: "\f700";
  --fa--fa: "\f700\f700";
}

.fa-dreidel {
  --fa: "\f792";
  --fa--fa: "\f792\f792";
}

.fa-person-dress {
  --fa: "\f182";
  --fa--fa: "\f182\f182";
}

.fa-female {
  --fa: "\f182";
  --fa--fa: "\f182\f182";
}

.fa-comment-dollar {
  --fa: "\f651";
  --fa--fa: "\f651\f651";
}

.fa-business-time {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a";
}

.fa-briefcase-clock {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a";
}

.fa-flower-tulip {
  --fa: "\f801";
  --fa--fa: "\f801\f801";
}

.fa-people-pants-simple {
  --fa: "\e21a";
  --fa--fa: "\e21a\e21a";
}

.fa-cloud-drizzle {
  --fa: "\f738";
  --fa--fa: "\f738\f738";
}

.fa-table-cells-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009";
}

.fa-th-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009";
}

.fa-book-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827";
}

.fa-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827";
}

.fa-solar-system {
  --fa: "\e02f";
  --fa--fa: "\e02f\e02f";
}

.fa-seal-question {
  --fa: "\e243";
  --fa--fa: "\e243\e243";
}

.fa-phone-volume {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0";
}

.fa-volume-control-phone {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0";
}

.fa-disc-drive {
  --fa: "\f8b5";
  --fa--fa: "\f8b5\f8b5";
}

.fa-hat-cowboy-side {
  --fa: "\f8c1";
  --fa--fa: "\f8c1\f8c1";
}

.fa-table-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292";
}

.fa-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292";
}

.fa-location-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608";
}

.fa-map-marker-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608";
}

.fa-face-fearful {
  --fa: "\e375";
  --fa--fa: "\e375\e375";
}

.fa-clipboard-user {
  --fa: "\f7f3";
  --fa--fa: "\f7f3\f7f3";
}

.fa-bus-school {
  --fa: "\f5dd";
  --fa--fa: "\f5dd\f5dd";
}

.fa-film-slash {
  --fa: "\e179";
  --fa--fa: "\e179\e179";
}

.fa-square-arrow-down-right {
  --fa: "\e262";
  --fa--fa: "\e262\e262";
}

.fa-book-sparkles {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8";
}

.fa-book-spells {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8";
}

.fa-washing-machine {
  --fa: "\f898";
  --fa--fa: "\f898\f898";
}

.fa-washer {
  --fa: "\f898";
  --fa--fa: "\f898\f898";
}

.fa-child {
  --fa: "\f1ae";
  --fa--fa: "\f1ae\f1ae";
}

.fa-lira-sign {
  --fa: "\f195";
  --fa--fa: "\f195\f195";
}

.fa-user-visor {
  --fa: "\e04c";
  --fa--fa: "\e04c\e04c";
}

.fa-file-plus-minus {
  --fa: "\e177";
  --fa--fa: "\e177\e177";
}

.fa-chess-clock-flip {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e";
}

.fa-chess-clock-alt {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e";
}

.fa-satellite {
  --fa: "\f7bf";
  --fa--fa: "\f7bf\f7bf";
}

.fa-truck-fire {
  --fa: "\e65a";
  --fa--fa: "\e65a\e65a";
}

.fa-plane-lock {
  --fa: "\e558";
  --fa--fa: "\e558\e558";
}

.fa-steering-wheel {
  --fa: "\f622";
  --fa--fa: "\f622\f622";
}

.fa-tag {
  --fa: "\f02b";
  --fa--fa: "\f02b\f02b";
}

.fa-stretcher {
  --fa: "\f825";
  --fa--fa: "\f825\f825";
}

.fa-book-section {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1";
}

.fa-book-law {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1";
}

.fa-inboxes {
  --fa: "\e1bb";
  --fa--fa: "\e1bb\e1bb";
}

.fa-coffee-bean {
  --fa: "\e13e";
  --fa--fa: "\e13e\e13e";
}

.fa-circle-yen {
  --fa: "\e5d0";
  --fa--fa: "\e5d0\e5d0";
}

.fa-brackets-curly {
  --fa: "\f7ea";
  --fa--fa: "\f7ea\f7ea";
}

.fa-ellipsis-stroke-vertical {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c";
}

.fa-ellipsis-v-alt {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c";
}

.fa-comment {
  --fa: "\f075";
  --fa--fa: "\f075\f075";
}

.fa-square-1 {
  --fa: "\e256";
  --fa--fa: "\e256\e256";
}

.fa-cake-candles {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-birthday-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd";
}

.fa-head-side {
  --fa: "\f6e9";
  --fa--fa: "\f6e9\f6e9";
}

.fa-truck-ladder {
  --fa: "\e657";
  --fa--fa: "\e657\e657";
}

.fa-envelope {
  --fa: "\f0e0";
  --fa--fa: "\f0e0\f0e0";
}

.fa-dolly-empty {
  --fa: "\f473";
  --fa--fa: "\f473\f473";
}

.fa-face-tissue {
  --fa: "\e39c";
  --fa--fa: "\e39c\e39c";
}

.fa-angles-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102";
}

.fa-angle-double-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102";
}

.fa-bin-recycle {
  --fa: "\e5f7";
  --fa--fa: "\e5f7\e5f7";
}

.fa-paperclip {
  --fa: "\f0c6";
  --fa--fa: "\f0c6\f0c6";
}

.fa-chart-line-down {
  --fa: "\f64d";
  --fa--fa: "\f64d\f64d";
}

.fa-arrow-right-to-city {
  --fa: "\e4b3";
  --fa--fa: "\e4b3\e4b3";
}

.fa-lock-a {
  --fa: "\e422";
  --fa--fa: "\e422\e422";
}

.fa-ribbon {
  --fa: "\f4d6";
  --fa--fa: "\f4d6\f4d6";
}

.fa-lungs {
  --fa: "\f604";
  --fa--fa: "\f604\f604";
}

.fa-person-pinball {
  --fa: "\e21d";
  --fa--fa: "\e21d\e21d";
}

.fa-arrow-up-9-1 {
  --fa: "\f887";
  --fa--fa: "\f887\f887";
}

.fa-sort-numeric-up-alt {
  --fa: "\f887";
  --fa--fa: "\f887\f887";
}

.fa-apple-core {
  --fa: "\e08f";
  --fa--fa: "\e08f\e08f";
}

.fa-circle-y {
  --fa: "\e12f";
  --fa--fa: "\e12f\e12f";
}

.fa-h6 {
  --fa: "\e413";
  --fa--fa: "\e413\e413";
}

.fa-litecoin-sign {
  --fa: "\e1d3";
  --fa--fa: "\e1d3\e1d3";
}

.fa-bottle-baby {
  --fa: "\e673";
  --fa--fa: "\e673\e673";
}

.fa-circle-small {
  --fa: "\e122";
  --fa--fa: "\e122\e122";
}

.fa-border-none {
  --fa: "\f850";
  --fa--fa: "\f850\f850";
}

.fa-arrow-turn-down-left {
  --fa: "\e2e1";
  --fa--fa: "\e2e1\e2e1";
}

.fa-circle-wifi-circle-wifi {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e";
}

.fa-circle-wifi-group {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e";
}

.fa-circle-nodes {
  --fa: "\e4e2";
  --fa--fa: "\e4e2\e4e2";
}

.fa-parachute-box {
  --fa: "\f4cd";
  --fa--fa: "\f4cd\f4cd";
}

.fa-reflect-horizontal {
  --fa: "\e664";
  --fa--fa: "\e664\e664";
}

.fa-message-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4";
}

.fa-comment-alt-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4";
}

.fa-rugby-ball {
  --fa: "\e3c6";
  --fa--fa: "\e3c6\e3c6";
}

.fa-comment-music {
  --fa: "\f8b0";
  --fa--fa: "\f8b0\f8b0";
}

.fa-indent {
  --fa: "\f03c";
  --fa--fa: "\f03c\f03c";
}

.fa-tree-deciduous {
  --fa: "\f400";
  --fa--fa: "\f400\f400";
}

.fa-tree-alt {
  --fa: "\f400";
  --fa--fa: "\f400\f400";
}

.fa-puzzle-piece-simple {
  --fa: "\e231";
  --fa--fa: "\e231\e231";
}

.fa-puzzle-piece-alt {
  --fa: "\e231";
  --fa--fa: "\e231\e231";
}

.fa-truck-field-un {
  --fa: "\e58e";
  --fa--fa: "\e58e\e58e";
}

.fa-nfc-trash {
  --fa: "\e1fd";
  --fa--fa: "\e1fd\e1fd";
}

.fa-hourglass {
  --fa: "\f254";
  --fa--fa: "\f254\f254";
}

.fa-hourglass-empty {
  --fa: "\f254";
  --fa--fa: "\f254\f254";
}

.fa-mountain {
  --fa: "\f6fc";
  --fa--fa: "\f6fc\f6fc";
}

.fa-file-xmark {
  --fa: "\f317";
  --fa--fa: "\f317\f317";
}

.fa-file-times {
  --fa: "\f317";
  --fa--fa: "\f317\f317";
}

.fa-house-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9";
}

.fa-home-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9";
}

.fa-house-chimney-blank {
  --fa: "\e3b0";
  --fa--fa: "\e3b0\e3b0";
}

.fa-meter-bolt {
  --fa: "\e1e9";
  --fa--fa: "\e1e9\e1e9";
}

.fa-user-doctor {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0";
}

.fa-user-md {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0";
}

.fa-slash-back {
  --fa: "\\";
  --fa--fa: "\\\\";
}

.fa-circle-info {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a";
}

.fa-info-circle {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a";
}

.fa-fishing-rod {
  --fa: "\e3a8";
  --fa--fa: "\e3a8\e3a8";
}

.fa-hammer-crash {
  --fa: "\e414";
  --fa--fa: "\e414\e414";
}

.fa-message-heart {
  --fa: "\e5c9";
  --fa--fa: "\e5c9\e5c9";
}

.fa-cloud-meatball {
  --fa: "\f73b";
  --fa--fa: "\f73b\f73b";
}

.fa-camera-polaroid {
  --fa: "\f8aa";
  --fa--fa: "\f8aa\f8aa";
}

.fa-camera {
  --fa: "\f030";
  --fa--fa: "\f030\f030";
}

.fa-camera-alt {
  --fa: "\f030";
  --fa--fa: "\f030\f030";
}

.fa-square-virus {
  --fa: "\e578";
  --fa--fa: "\e578\e578";
}

.fa-cart-arrow-up {
  --fa: "\e3ee";
  --fa--fa: "\e3ee\e3ee";
}

.fa-meteor {
  --fa: "\f753";
  --fa--fa: "\f753\f753";
}

.fa-car-on {
  --fa: "\e4dd";
  --fa--fa: "\e4dd\e4dd";
}

.fa-sleigh {
  --fa: "\f7cc";
  --fa--fa: "\f7cc\f7cc";
}

.fa-arrow-down-1-9 {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-sort-numeric-asc {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-sort-numeric-down {
  --fa: "\f162";
  --fa--fa: "\f162\f162";
}

.fa-buoy-mooring {
  --fa: "\e5b6";
  --fa--fa: "\e5b6\e5b6";
}

.fa-square-4 {
  --fa: "\e259";
  --fa--fa: "\e259\e259";
}

.fa-hand-holding-droplet {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1";
}

.fa-hand-holding-water {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1";
}

.fa-file-eps {
  --fa: "\e644";
  --fa--fa: "\e644\e644";
}

.fa-tricycle-adult {
  --fa: "\e5c4";
  --fa--fa: "\e5c4\e5c4";
}

.fa-waveform {
  --fa: "\f8f1";
  --fa--fa: "\f8f1\f8f1";
}

.fa-water {
  --fa: "\f773";
  --fa--fa: "\f773\f773";
}

.fa-star-sharp-half-stroke {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d";
}

.fa-star-sharp-half-alt {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d";
}

.fa-nfc-signal {
  --fa: "\e1fb";
  --fa--fa: "\e1fb\e1fb";
}

.fa-plane-prop {
  --fa: "\e22b";
  --fa--fa: "\e22b\e22b";
}

.fa-calendar-check {
  --fa: "\f274";
  --fa--fa: "\f274\f274";
}

.fa-clock-desk {
  --fa: "\e134";
  --fa--fa: "\e134\e134";
}

.fa-calendar-clock {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2";
}

.fa-calendar-time {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2";
}

.fa-braille {
  --fa: "\f2a1";
  --fa--fa: "\f2a1\f2a1";
}

.fa-prescription-bottle-medical {
  --fa: "\f486";
  --fa--fa: "\f486\f486";
}

.fa-prescription-bottle-alt {
  --fa: "\f486";
  --fa--fa: "\f486\f486";
}

.fa-plate-utensils {
  --fa: "\e43b";
  --fa--fa: "\e43b\e43b";
}

.fa-family-pants {
  --fa: "\e302";
  --fa--fa: "\e302\e302";
}

.fa-hose-reel {
  --fa: "\e41a";
  --fa--fa: "\e41a\e41a";
}

.fa-house-window {
  --fa: "\e3b3";
  --fa--fa: "\e3b3\e3b3";
}

.fa-landmark {
  --fa: "\f66f";
  --fa--fa: "\f66f\f66f";
}

.fa-truck {
  --fa: "\f0d1";
  --fa--fa: "\f0d1\f0d1";
}

.fa-music-magnifying-glass {
  --fa: "\e662";
  --fa--fa: "\e662\e662";
}

.fa-crosshairs {
  --fa: "\f05b";
  --fa--fa: "\f05b\f05b";
}

.fa-cloud-rainbow {
  --fa: "\f73e";
  --fa--fa: "\f73e\f73e";
}

.fa-person-cane {
  --fa: "\e53c";
  --fa--fa: "\e53c\e53c";
}

.fa-alien {
  --fa: "\f8f5";
  --fa--fa: "\f8f5\f8f5";
}

.fa-tent {
  --fa: "\e57d";
  --fa--fa: "\e57d\e57d";
}

.fa-laptop-binary {
  --fa: "\e5e7";
  --fa--fa: "\e5e7\e5e7";
}

.fa-vest-patches {
  --fa: "\e086";
  --fa--fa: "\e086\e086";
}

.fa-people-dress-simple {
  --fa: "\e218";
  --fa--fa: "\e218\e218";
}

.fa-check-double {
  --fa: "\f560";
  --fa--fa: "\f560\f560";
}

.fa-arrow-down-a-z {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-sort-alpha-asc {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-sort-alpha-down {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d";
}

.fa-bowling-ball-pin {
  --fa: "\e0c3";
  --fa--fa: "\e0c3\e0c3";
}

.fa-bell-school-slash {
  --fa: "\f5d6";
  --fa--fa: "\f5d6\f5d6";
}

.fa-plus-large {
  --fa: "\e59e";
  --fa--fa: "\e59e\e59e";
}

.fa-money-bill-wheat {
  --fa: "\e52a";
  --fa--fa: "\e52a\e52a";
}

.fa-camera-viewfinder {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da";
}

.fa-screenshot {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da";
}

.fa-message-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af";
}

.fa-comment-alt-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af";
}

.fa-car-building {
  --fa: "\f859";
  --fa--fa: "\f859\f859";
}

.fa-border-bottom-right {
  --fa: "\f854";
  --fa--fa: "\f854\f854";
}

.fa-border-style-alt {
  --fa: "\f854";
  --fa--fa: "\f854\f854";
}

.fa-octagon {
  --fa: "\f306";
  --fa--fa: "\f306\f306";
}

.fa-comment-arrow-up-right {
  --fa: "\e145";
  --fa--fa: "\e145\e145";
}

.fa-octagon-divide {
  --fa: "\e203";
  --fa--fa: "\e203\e203";
}

.fa-cookie {
  --fa: "\f563";
  --fa--fa: "\f563\f563";
}

.fa-arrow-rotate-left {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-left-rotate {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-rotate-back {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-arrow-rotate-backward {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-undo {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2";
}

.fa-tv-music {
  --fa: "\f8e6";
  --fa--fa: "\f8e6\f8e6";
}

.fa-hard-drive {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0";
}

.fa-hdd {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0";
}

.fa-reel {
  --fa: "\e238";
  --fa--fa: "\e238\e238";
}

.fa-face-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586";
}

.fa-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586";
}

.fa-dumbbell {
  --fa: "\f44b";
  --fa--fa: "\f44b\f44b";
}

.fa-rectangle-list {
  --fa: "\f022";
  --fa--fa: "\f022\f022";
}

.fa-list-alt {
  --fa: "\f022";
  --fa--fa: "\f022\f022";
}

.fa-tarp-droplet {
  --fa: "\e57c";
  --fa--fa: "\e57c\e57c";
}

.fa-alarm-exclamation {
  --fa: "\f843";
  --fa--fa: "\f843\f843";
}

.fa-house-medical-circle-check {
  --fa: "\e511";
  --fa--fa: "\e511\e511";
}

.fa-traffic-cone {
  --fa: "\f636";
  --fa--fa: "\f636\f636";
}

.fa-grate {
  --fa: "\e193";
  --fa--fa: "\e193\e193";
}

.fa-arrow-down-right {
  --fa: "\e093";
  --fa--fa: "\e093\e093";
}

.fa-person-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca";
}

.fa-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca";
}

.fa-calendar-plus {
  --fa: "\f271";
  --fa--fa: "\f271\f271";
}

.fa-person-from-portal {
  --fa: "\e023";
  --fa--fa: "\e023\e023";
}

.fa-portal-exit {
  --fa: "\e023";
  --fa--fa: "\e023\e023";
}

.fa-plane-arrival {
  --fa: "\f5af";
  --fa--fa: "\f5af\f5af";
}

.fa-cowbell-circle-plus {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4";
}

.fa-cowbell-more {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4";
}

.fa-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359";
}

.fa-arrow-alt-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359";
}

.fa-distribute-spacing-vertical {
  --fa: "\e366";
  --fa--fa: "\e366\e366";
}

.fa-signal-bars-fair {
  --fa: "\f692";
  --fa--fa: "\f692\f692";
}

.fa-signal-alt-2 {
  --fa: "\f692";
  --fa--fa: "\f692\f692";
}

.fa-sportsball {
  --fa: "\e44b";
  --fa--fa: "\e44b\e44b";
}

.fa-game-console-handheld-crank {
  --fa: "\e5b9";
  --fa--fa: "\e5b9\e5b9";
}

.fa-train-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239";
}

.fa-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239";
}

.fa-chart-gantt {
  --fa: "\e0e4";
  --fa--fa: "\e0e4\e0e4";
}

.fa-face-smile-upside-down {
  --fa: "\e395";
  --fa--fa: "\e395\e395";
}

.fa-ball-pile {
  --fa: "\f77e";
  --fa--fa: "\f77e\f77e";
}

.fa-badge-dollar {
  --fa: "\f645";
  --fa--fa: "\f645\f645";
}

.fa-money-bills-simple {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4";
}

.fa-money-bills-alt {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4";
}

.fa-list-timeline {
  --fa: "\e1d1";
  --fa--fa: "\e1d1\e1d1";
}

.fa-indian-rupee-sign {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-indian-rupee {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-inr {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc";
}

.fa-crop-simple {
  --fa: "\f565";
  --fa--fa: "\f565\f565";
}

.fa-crop-alt {
  --fa: "\f565";
  --fa--fa: "\f565\f565";
}

.fa-money-bill-1 {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1";
}

.fa-money-bill-alt {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1";
}

.fa-left-long {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a";
}

.fa-long-arrow-alt-left {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a";
}

.fa-keyboard-down {
  --fa: "\e1c2";
  --fa--fa: "\e1c2\e1c2";
}

.fa-circle-up-right {
  --fa: "\e129";
  --fa--fa: "\e129\e129";
}

.fa-cloud-bolt-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d";
}

.fa-thunderstorm-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d";
}

.fa-turn-left-up {
  --fa: "\e638";
  --fa--fa: "\e638\e638";
}

.fa-dna {
  --fa: "\f471";
  --fa--fa: "\f471\f471";
}

.fa-virus-slash {
  --fa: "\e075";
  --fa--fa: "\e075\e075";
}

.fa-bracket-round-right {
  --fa: "\)";
  --fa--fa: "\)\)";
}

.fa-circle-sterling {
  --fa: "\e5cf";
  --fa--fa: "\e5cf\e5cf";
}

.fa-circle-5 {
  --fa: "\e0f2";
  --fa--fa: "\e0f2\e0f2";
}

.fa-minus {
  --fa: "\f068";
  --fa--fa: "\f068\f068";
}

.fa-subtract {
  --fa: "\f068";
  --fa--fa: "\f068\f068";
}

.fa-fire-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df";
}

.fa-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df";
}

.fa-right-to-line {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c";
}

.fa-arrow-alt-to-right {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c";
}

.fa-gif {
  --fa: "\e190";
  --fa--fa: "\e190\e190";
}

.fa-chess {
  --fa: "\f439";
  --fa--fa: "\f439\f439";
}

.fa-trash-slash {
  --fa: "\e2b3";
  --fa--fa: "\e2b3\e2b3";
}

.fa-arrow-left-long {
  --fa: "\f177";
  --fa--fa: "\f177\f177";
}

.fa-long-arrow-left {
  --fa: "\f177";
  --fa--fa: "\f177\f177";
}

.fa-plug-circle-check {
  --fa: "\e55c";
  --fa--fa: "\e55c\e55c";
}

.fa-font-case {
  --fa: "\f866";
  --fa--fa: "\f866\f866";
}

.fa-street-view {
  --fa: "\f21d";
  --fa--fa: "\f21d\f21d";
}

.fa-arrow-down-left {
  --fa: "\e091";
  --fa--fa: "\e091\e091";
}

.fa-franc-sign {
  --fa: "\e18f";
  --fa--fa: "\e18f\e18f";
}

.fa-flask-round-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0";
}

.fa-flask-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0";
}

.fa-volume-off {
  --fa: "\f026";
  --fa--fa: "\f026\f026";
}

.fa-book-circle-arrow-right {
  --fa: "\e0bc";
  --fa--fa: "\e0bc\e0bc";
}

.fa-chart-user {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3";
}

.fa-user-chart {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3";
}

.fa-hands-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-hands-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3";
}

.fa-presentation-screen {
  --fa: "\f685";
  --fa--fa: "\f685\f685";
}

.fa-presentation {
  --fa: "\f685";
  --fa--fa: "\f685\f685";
}

.fa-circle-bolt {
  --fa: "\e0fe";
  --fa--fa: "\e0fe\e0fe";
}

.fa-face-smile-halo {
  --fa: "\e38f";
  --fa--fa: "\e38f\e38f";
}

.fa-cart-circle-arrow-down {
  --fa: "\e3ef";
  --fa--fa: "\e3ef\e3ef";
}

.fa-house-person-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-house-person-arrive {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-house-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011";
}

.fa-message-xmark {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-comment-alt-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-message-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab";
}

.fa-file-certificate {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3";
}

.fa-file-award {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3";
}

.fa-user-doctor-hair-long {
  --fa: "\e459";
  --fa--fa: "\e459\e459";
}

.fa-camera-security {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe";
}

.fa-camera-home {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe";
}

.fa-gear {
  --fa: "\f013";
  --fa--fa: "\f013\f013";
}

.fa-cog {
  --fa: "\f013";
  --fa--fa: "\f013\f013";
}

.fa-droplet-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7";
}

.fa-tint-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7";
}

.fa-book-heart {
  --fa: "\f499";
  --fa--fa: "\f499\f499";
}

.fa-mosque {
  --fa: "\f678";
  --fa--fa: "\f678\f678";
}

.fa-duck {
  --fa: "\f6d8";
  --fa--fa: "\f6d8\f6d8";
}

.fa-mosquito {
  --fa: "\e52b";
  --fa--fa: "\e52b\e52b";
}

.fa-star-of-david {
  --fa: "\f69a";
  --fa--fa: "\f69a\f69a";
}

.fa-flag-swallowtail {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c";
}

.fa-flag-alt {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c";
}

.fa-person-military-rifle {
  --fa: "\e54b";
  --fa--fa: "\e54b\e54b";
}

.fa-car-garage {
  --fa: "\f5e2";
  --fa--fa: "\f5e2\f5e2";
}

.fa-cart-shopping {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a";
}

.fa-shopping-cart {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a";
}

.fa-book-font {
  --fa: "\e0bf";
  --fa--fa: "\e0bf\e0bf";
}

.fa-shield-plus {
  --fa: "\e24a";
  --fa--fa: "\e24a\e24a";
}

.fa-vials {
  --fa: "\f493";
  --fa--fa: "\f493\f493";
}

.fa-eye-dropper-full {
  --fa: "\e172";
  --fa--fa: "\e172\e172";
}

.fa-distribute-spacing-horizontal {
  --fa: "\e365";
  --fa--fa: "\e365\e365";
}

.fa-tablet-rugged {
  --fa: "\f48f";
  --fa--fa: "\f48f\f48f";
}

.fa-temperature-snow {
  --fa: "\f768";
  --fa--fa: "\f768\f768";
}

.fa-temperature-frigid {
  --fa: "\f768";
  --fa--fa: "\f768\f768";
}

.fa-moped {
  --fa: "\e3b9";
  --fa--fa: "\e3b9\e3b9";
}

.fa-face-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9";
}

.fa-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9";
}

.fa-radio-tuner {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8";
}

.fa-radio-alt {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8";
}

.fa-face-swear {
  --fa: "\e399";
  --fa--fa: "\e399\e399";
}

.fa-water-arrow-down {
  --fa: "\f774";
  --fa--fa: "\f774\f774";
}

.fa-water-lower {
  --fa: "\f774";
  --fa--fa: "\f774\f774";
}

.fa-scanner-touchscreen {
  --fa: "\f48a";
  --fa--fa: "\f48a\f48a";
}

.fa-circle-7 {
  --fa: "\e0f4";
  --fa--fa: "\e0f4\e0f4";
}

.fa-plug-circle-plus {
  --fa: "\e55f";
  --fa--fa: "\e55f\e55f";
}

.fa-person-ski-jumping {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7";
}

.fa-ski-jump {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7";
}

.fa-place-of-worship {
  --fa: "\f67f";
  --fa--fa: "\f67f\f67f";
}

.fa-water-arrow-up {
  --fa: "\f775";
  --fa--fa: "\f775\f775";
}

.fa-water-rise {
  --fa: "\f775";
  --fa--fa: "\f775\f775";
}

.fa-waveform-lines {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2";
}

.fa-waveform-path {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2";
}

.fa-split {
  --fa: "\e254";
  --fa--fa: "\e254\e254";
}

.fa-film-canister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7";
}

.fa-film-cannister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7";
}

.fa-folder-xmark {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f";
}

.fa-folder-times {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f";
}

.fa-toilet-paper-blank {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f";
}

.fa-toilet-paper-alt {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f";
}

.fa-tablet-screen {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc";
}

.fa-tablet-android-alt {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc";
}

.fa-hexagon-vertical-nft-slanted {
  --fa: "\e506";
  --fa--fa: "\e506\e506";
}

.fa-folder-music {
  --fa: "\e18d";
  --fa--fa: "\e18d\e18d";
}

.fa-display-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166";
}

.fa-desktop-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166";
}

.fa-share-all {
  --fa: "\f367";
  --fa--fa: "\f367\f367";
}

.fa-peapod {
  --fa: "\e31c";
  --fa--fa: "\e31c\e31c";
}

.fa-chess-clock {
  --fa: "\f43d";
  --fa--fa: "\f43d\f43d";
}

.fa-axe {
  --fa: "\f6b2";
  --fa--fa: "\f6b2\f6b2";
}

.fa-square-d {
  --fa: "\e268";
  --fa--fa: "\e268\e268";
}

.fa-grip-vertical {
  --fa: "\f58e";
  --fa--fa: "\f58e\f58e";
}

.fa-mobile-signal-out {
  --fa: "\e1f0";
  --fa--fa: "\e1f0\e1f0";
}

.fa-hexagon-nodes {
  --fa: "\e699";
  --fa--fa: "\e699\e699";
}

.fa-arrow-turn-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148";
}

.fa-level-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148";
}

.fa-u {
  --fa: "U";
  --fa--fa: "UU";
}

.fa-arrow-up-from-dotted-line {
  --fa: "\e09b";
  --fa--fa: "\e09b\e09b";
}

.fa-square-root-variable {
  --fa: "\f698";
  --fa--fa: "\f698\f698";
}

.fa-square-root-alt {
  --fa: "\f698";
  --fa--fa: "\f698\f698";
}

.fa-light-switch-on {
  --fa: "\e019";
  --fa--fa: "\e019\e019";
}

.fa-arrow-down-arrow-up {
  --fa: "\f883";
  --fa--fa: "\f883\f883";
}

.fa-sort-alt {
  --fa: "\f883";
  --fa--fa: "\f883\f883";
}

.fa-raindrops {
  --fa: "\f75c";
  --fa--fa: "\f75c\f75c";
}

.fa-dash {
  --fa: "\e404";
  --fa--fa: "\e404\e404";
}

.fa-minus-large {
  --fa: "\e404";
  --fa--fa: "\e404\e404";
}

.fa-clock {
  --fa: "\f017";
  --fa--fa: "\f017\f017";
}

.fa-clock-four {
  --fa: "\f017";
  --fa--fa: "\f017\f017";
}

.fa-input-numeric {
  --fa: "\e1bd";
  --fa--fa: "\e1bd\e1bd";
}

.fa-truck-tow {
  --fa: "\e2b8";
  --fa--fa: "\e2b8\e2b8";
}

.fa-backward-step {
  --fa: "\f048";
  --fa--fa: "\f048\f048";
}

.fa-step-backward {
  --fa: "\f048";
  --fa--fa: "\f048\f048";
}

.fa-pallet {
  --fa: "\f482";
  --fa--fa: "\f482\f482";
}

.fa-car-bolt {
  --fa: "\e341";
  --fa--fa: "\e341\e341";
}

.fa-arrows-maximize {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d";
}

.fa-expand-arrows {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d";
}

.fa-faucet {
  --fa: "\e005";
  --fa--fa: "\e005\e005";
}

.fa-cloud-sleet {
  --fa: "\f741";
  --fa--fa: "\f741\f741";
}

.fa-lamp-street {
  --fa: "\e1c5";
  --fa--fa: "\e1c5\e1c5";
}

.fa-list-radio {
  --fa: "\e1d0";
  --fa--fa: "\e1d0\e1d0";
}

.fa-pen-nib-slash {
  --fa: "\e4a1";
  --fa--fa: "\e4a1\e4a1";
}

.fa-baseball-bat-ball {
  --fa: "\f432";
  --fa--fa: "\f432\f432";
}

.fa-square-up-left {
  --fa: "\e282";
  --fa--fa: "\e282\e282";
}

.fa-overline {
  --fa: "\f876";
  --fa--fa: "\f876\f876";
}

.fa-s {
  --fa: "S";
  --fa--fa: "SS";
}

.fa-timeline {
  --fa: "\e29c";
  --fa--fa: "\e29c\e29c";
}

.fa-keyboard {
  --fa: "\f11c";
  --fa--fa: "\f11c\f11c";
}

.fa-arrows-from-dotted-line {
  --fa: "\e0a3";
  --fa--fa: "\e0a3\e0a3";
}

.fa-usb-drive {
  --fa: "\f8e9";
  --fa--fa: "\f8e9\f8e9";
}

.fa-ballot {
  --fa: "\f732";
  --fa--fa: "\f732\f732";
}

.fa-caret-down {
  --fa: "\f0d7";
  --fa--fa: "\f0d7\f0d7";
}

.fa-location-dot-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605";
}

.fa-map-marker-alt-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605";
}

.fa-cards {
  --fa: "\e3ed";
  --fa--fa: "\e3ed\e3ed";
}

.fa-house-chimney-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2";
}

.fa-clinic-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2";
}

.fa-boxing-glove {
  --fa: "\f438";
  --fa--fa: "\f438\f438";
}

.fa-glove-boxing {
  --fa: "\f438";
  --fa--fa: "\f438\f438";
}

.fa-temperature-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-temperature-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-thermometer-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-thermometer-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8";
}

.fa-bell-school {
  --fa: "\f5d5";
  --fa--fa: "\f5d5\f5d5";
}

.fa-mobile-screen {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf";
}

.fa-mobile-android-alt {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf";
}

.fa-plane-up {
  --fa: "\e22d";
  --fa--fa: "\e22d\e22d";
}

.fa-folder-heart {
  --fa: "\e189";
  --fa--fa: "\e189\e189";
}

.fa-circle-location-arrow {
  --fa: "\f602";
  --fa--fa: "\f602\f602";
}

.fa-location-circle {
  --fa: "\f602";
  --fa--fa: "\f602\f602";
}

.fa-face-head-bandage {
  --fa: "\e37a";
  --fa--fa: "\e37a\e37a";
}

.fa-sushi-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-maki-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-makizushi {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b";
}

.fa-car-bump {
  --fa: "\f5e0";
  --fa--fa: "\f5e0\f5e0";
}

.fa-piggy-bank {
  --fa: "\f4d3";
  --fa--fa: "\f4d3\f4d3";
}

.fa-racquet {
  --fa: "\f45a";
  --fa--fa: "\f45a\f45a";
}

.fa-car-mirrors {
  --fa: "\e343";
  --fa--fa: "\e343\e343";
}

.fa-industry-windows {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3";
}

.fa-industry-alt {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3";
}

.fa-bolt-auto {
  --fa: "\e0b6";
  --fa--fa: "\e0b6\e0b6";
}

.fa-battery-half {
  --fa: "\f242";
  --fa--fa: "\f242\f242";
}

.fa-battery-3 {
  --fa: "\f242";
  --fa--fa: "\f242\f242";
}

.fa-flux-capacitor {
  --fa: "\f8ba";
  --fa--fa: "\f8ba\f8ba";
}

.fa-mountain-city {
  --fa: "\e52e";
  --fa--fa: "\e52e\e52e";
}

.fa-coins {
  --fa: "\f51e";
  --fa--fa: "\f51e\f51e";
}

.fa-honey-pot {
  --fa: "\e418";
  --fa--fa: "\e418\e418";
}

.fa-olive {
  --fa: "\e316";
  --fa--fa: "\e316\e316";
}

.fa-khanda {
  --fa: "\f66d";
  --fa--fa: "\f66d\f66d";
}

.fa-filter-list {
  --fa: "\e17c";
  --fa--fa: "\e17c\e17c";
}

.fa-outlet {
  --fa: "\e01c";
  --fa--fa: "\e01c\e01c";
}

.fa-sliders {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de";
}

.fa-sliders-h {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de";
}

.fa-cauldron {
  --fa: "\f6bf";
  --fa--fa: "\f6bf\f6bf";
}

.fa-people {
  --fa: "\e216";
  --fa--fa: "\e216\e216";
}

.fa-folder-tree {
  --fa: "\f802";
  --fa--fa: "\f802\f802";
}

.fa-network-wired {
  --fa: "\f6ff";
  --fa--fa: "\f6ff\f6ff";
}

.fa-croissant {
  --fa: "\f7f6";
  --fa--fa: "\f7f6\f7f6";
}

.fa-map-pin {
  --fa: "\f276";
  --fa--fa: "\f276\f276";
}

.fa-hamsa {
  --fa: "\f665";
  --fa--fa: "\f665\f665";
}

.fa-cent-sign {
  --fa: "\e3f5";
  --fa--fa: "\e3f5\e3f5";
}

.fa-swords-laser {
  --fa: "\e03d";
  --fa--fa: "\e03d\e03d";
}

.fa-flask {
  --fa: "\f0c3";
  --fa--fa: "\f0c3\f0c3";
}

.fa-person-pregnant {
  --fa: "\e31e";
  --fa--fa: "\e31e\e31e";
}

.fa-square-u {
  --fa: "\e281";
  --fa--fa: "\e281\e281";
}

.fa-wand-sparkles {
  --fa: "\f72b";
  --fa--fa: "\f72b\f72b";
}

.fa-router {
  --fa: "\f8da";
  --fa--fa: "\f8da\f8da";
}

.fa-ellipsis-vertical {
  --fa: "\f142";
  --fa--fa: "\f142\f142";
}

.fa-ellipsis-v {
  --fa: "\f142";
  --fa--fa: "\f142\f142";
}

.fa-sword-laser-alt {
  --fa: "\e03c";
  --fa--fa: "\e03c\e03c";
}

.fa-ticket {
  --fa: "\f145";
  --fa--fa: "\f145\f145";
}

.fa-power-off {
  --fa: "\f011";
  --fa--fa: "\f011\f011";
}

.fa-coin {
  --fa: "\f85c";
  --fa--fa: "\f85c\f85c";
}

.fa-laptop-slash {
  --fa: "\e1c7";
  --fa--fa: "\e1c7\e1c7";
}

.fa-right-long {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b";
}

.fa-long-arrow-alt-right {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b";
}

.fa-circle-b {
  --fa: "\e0fd";
  --fa--fa: "\e0fd\e0fd";
}

.fa-person-dress-simple {
  --fa: "\e21c";
  --fa--fa: "\e21c\e21c";
}

.fa-pipe-collar {
  --fa: "\e437";
  --fa--fa: "\e437\e437";
}

.fa-lights-holiday {
  --fa: "\f7b2";
  --fa--fa: "\f7b2\f7b2";
}

.fa-citrus {
  --fa: "\e2f4";
  --fa--fa: "\e2f4\e2f4";
}

.fa-flag-usa {
  --fa: "\f74d";
  --fa--fa: "\f74d\f74d";
}

.fa-laptop-file {
  --fa: "\e51d";
  --fa--fa: "\e51d\e51d";
}

.fa-tty {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4";
}

.fa-teletype {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4";
}

.fa-chart-tree-map {
  --fa: "\e0ea";
  --fa--fa: "\e0ea\e0ea";
}

.fa-diagram-next {
  --fa: "\e476";
  --fa--fa: "\e476\e476";
}

.fa-person-rifle {
  --fa: "\e54e";
  --fa--fa: "\e54e\e54e";
}

.fa-clock-five-thirty {
  --fa: "\e34a";
  --fa--fa: "\e34a\e34a";
}

.fa-pipe-valve {
  --fa: "\e439";
  --fa--fa: "\e439\e439";
}

.fa-lightbulb-message {
  --fa: "\e687";
  --fa--fa: "\e687\e687";
}

.fa-arrow-up-from-arc {
  --fa: "\e4b4";
  --fa--fa: "\e4b4\e4b4";
}

.fa-face-spiral-eyes {
  --fa: "\e485";
  --fa--fa: "\e485\e485";
}

.fa-compress-wide {
  --fa: "\f326";
  --fa--fa: "\f326\f326";
}

.fa-circle-phone-hangup {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d";
}

.fa-phone-circle-down {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d";
}

.fa-gear-complex-code {
  --fa: "\e5eb";
  --fa--fa: "\e5eb\e5eb";
}

.fa-house-medical-circle-exclamation {
  --fa: "\e512";
  --fa--fa: "\e512\e512";
}

.fa-badminton {
  --fa: "\e33a";
  --fa--fa: "\e33a\e33a";
}

.fa-closed-captioning {
  --fa: "\f20a";
  --fa--fa: "\f20a\f20a";
}

.fa-person-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec";
}

.fa-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec";
}

.fa-right-from-line {
  --fa: "\f347";
  --fa--fa: "\f347\f347";
}

.fa-arrow-alt-from-left {
  --fa: "\f347";
  --fa--fa: "\f347\f347";
}

.fa-venus-double {
  --fa: "\f226";
  --fa--fa: "\f226\f226";
}

.fa-images {
  --fa: "\f302";
  --fa--fa: "\f302\f302";
}

.fa-calculator {
  --fa: "\f1ec";
  --fa--fa: "\f1ec\f1ec";
}

.fa-shuttlecock {
  --fa: "\f45b";
  --fa--fa: "\f45b\f45b";
}

.fa-user-hair {
  --fa: "\e45a";
  --fa--fa: "\e45a\e45a";
}

.fa-eye-evil {
  --fa: "\f6db";
  --fa--fa: "\f6db\f6db";
}

.fa-people-pulling {
  --fa: "\e535";
  --fa--fa: "\e535\e535";
}

.fa-n {
  --fa: "N";
  --fa--fa: "NN";
}

.fa-swap {
  --fa: "\e609";
  --fa--fa: "\e609\e609";
}

.fa-garage {
  --fa: "\e009";
  --fa--fa: "\e009\e009";
}

.fa-cable-car {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da";
}

.fa-tram {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da";
}

.fa-shovel-snow {
  --fa: "\f7c3";
  --fa--fa: "\f7c3\f7c3";
}

.fa-cloud-rain {
  --fa: "\f73d";
  --fa--fa: "\f73d\f73d";
}

.fa-face-lying {
  --fa: "\e37e";
  --fa--fa: "\e37e\e37e";
}

.fa-sprinkler {
  --fa: "\e035";
  --fa--fa: "\e035\e035";
}

.fa-building-circle-xmark {
  --fa: "\e4d4";
  --fa--fa: "\e4d4\e4d4";
}

.fa-person-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb";
}

.fa-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb";
}

.fa-game-console-handheld {
  --fa: "\f8bb";
  --fa--fa: "\f8bb\f8bb";
}

.fa-ship {
  --fa: "\f21a";
  --fa--fa: "\f21a\f21a";
}

.fa-clock-six-thirty {
  --fa: "\e353";
  --fa--fa: "\e353\e353";
}

.fa-battery-slash {
  --fa: "\f377";
  --fa--fa: "\f377\f377";
}

.fa-tugrik-sign {
  --fa: "\e2ba";
  --fa--fa: "\e2ba\e2ba";
}

.fa-arrows-down-to-line {
  --fa: "\e4b8";
  --fa--fa: "\e4b8\e4b8";
}

.fa-download {
  --fa: "\f019";
  --fa--fa: "\f019\f019";
}

.fa-angles-up-down {
  --fa: "\e60d";
  --fa--fa: "\e60d\e60d";
}

.fa-shelves {
  --fa: "\f480";
  --fa--fa: "\f480\f480";
}

.fa-inventory {
  --fa: "\f480";
  --fa--fa: "\f480\f480";
}

.fa-cloud-snow {
  --fa: "\f742";
  --fa--fa: "\f742\f742";
}

.fa-face-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580";
}

.fa-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580";
}

.fa-delete-left {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a";
}

.fa-backspace {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a";
}

.fa-oven {
  --fa: "\e01d";
  --fa--fa: "\e01d\e01d";
}

.fa-cloud-binary {
  --fa: "\e601";
  --fa--fa: "\e601\e601";
}

.fa-eye-dropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-eye-dropper-empty {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-eyedropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb";
}

.fa-comment-captions {
  --fa: "\e146";
  --fa--fa: "\e146\e146";
}

.fa-comments-question {
  --fa: "\e14e";
  --fa--fa: "\e14e\e14e";
}

.fa-scribble {
  --fa: "\e23f";
  --fa--fa: "\e23f\e23f";
}

.fa-rotate-exclamation {
  --fa: "\e23c";
  --fa--fa: "\e23c\e23c";
}

.fa-file-circle-check {
  --fa: "\e5a0";
  --fa--fa: "\e5a0\e5a0";
}

.fa-glass {
  --fa: "\f804";
  --fa--fa: "\f804\f804";
}

.fa-loader {
  --fa: "\e1d4";
  --fa--fa: "\e1d4\e1d4";
}

.fa-forward {
  --fa: "\f04e";
  --fa--fa: "\f04e\f04e";
}

.fa-user-pilot {
  --fa: "\e2c0";
  --fa--fa: "\e2c0\e2c0";
}

.fa-mobile {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-mobile-android {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-mobile-phone {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce";
}

.fa-code-pull-request-closed {
  --fa: "\e3f9";
  --fa--fa: "\e3f9\e3f9";
}

.fa-face-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a";
}

.fa-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a";
}

.fa-align-center {
  --fa: "\f037";
  --fa--fa: "\f037\f037";
}

.fa-book-skull {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7";
}

.fa-book-dead {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7";
}

.fa-id-card {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2";
}

.fa-drivers-license {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2";
}

.fa-face-dotted {
  --fa: "\e47f";
  --fa--fa: "\e47f\e47f";
}

.fa-face-worried {
  --fa: "\e3a3";
  --fa--fa: "\e3a3\e3a3";
}

.fa-outdent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b";
}

.fa-dedent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b";
}

.fa-court-sport {
  --fa: "\e643";
  --fa--fa: "\e643\e643";
}

.fa-heart-circle-exclamation {
  --fa: "\e4fe";
  --fa--fa: "\e4fe\e4fe";
}

.fa-house {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-home-lg-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015";
}

.fa-vector-circle {
  --fa: "\e2c6";
  --fa--fa: "\e2c6\e2c6";
}

.fa-car-circle-bolt {
  --fa: "\e342";
  --fa--fa: "\e342\e342";
}

.fa-calendar-week {
  --fa: "\f784";
  --fa--fa: "\f784\f784";
}

.fa-flying-disc {
  --fa: "\e3a9";
  --fa--fa: "\e3a9\e3a9";
}

.fa-laptop-medical {
  --fa: "\f812";
  --fa--fa: "\f812\f812";
}

.fa-square-down-right {
  --fa: "\e26c";
  --fa--fa: "\e26c\e26c";
}

.fa-b {
  --fa: "B";
  --fa--fa: "BB";
}

.fa-seat-airline {
  --fa: "\e244";
  --fa--fa: "\e244\e244";
}

.fa-moon-over-sun {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a";
}

.fa-eclipse-alt {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a";
}

.fa-pipe {
  --fa: "\|";
  --fa--fa: "\|\|";
}

.fa-file-medical {
  --fa: "\f477";
  --fa--fa: "\f477\f477";
}

.fa-potato {
  --fa: "\e440";
  --fa--fa: "\e440\e440";
}

.fa-dice-one {
  --fa: "\f525";
  --fa--fa: "\f525\f525";
}

.fa-circle-a {
  --fa: "\e0f7";
  --fa--fa: "\e0f7\e0f7";
}

.fa-helmet-battle {
  --fa: "\f6eb";
  --fa--fa: "\f6eb\f6eb";
}

.fa-butter {
  --fa: "\e3e4";
  --fa--fa: "\e3e4\e3e4";
}

.fa-blanket-fire {
  --fa: "\e3da";
  --fa--fa: "\e3da\e3da";
}

.fa-kiwi-bird {
  --fa: "\f535";
  --fa--fa: "\f535\f535";
}

.fa-castle {
  --fa: "\e0de";
  --fa--fa: "\e0de\e0de";
}

.fa-golf-club {
  --fa: "\f451";
  --fa--fa: "\f451\f451";
}

.fa-arrow-right-arrow-left {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec";
}

.fa-exchange {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec";
}

.fa-rotate-right {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-redo-alt {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-rotate-forward {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9";
}

.fa-utensils {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7";
}

.fa-cutlery {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7";
}

.fa-arrow-up-wide-short {
  --fa: "\f161";
  --fa--fa: "\f161\f161";
}

.fa-sort-amount-up {
  --fa: "\f161";
  --fa--fa: "\f161\f161";
}

.fa-chart-pie-simple-circle-dollar {
  --fa: "\e605";
  --fa--fa: "\e605\e605";
}

.fa-balloons {
  --fa: "\e2e4";
  --fa--fa: "\e2e4\e2e4";
}

.fa-mill-sign {
  --fa: "\e1ed";
  --fa--fa: "\e1ed\e1ed";
}

.fa-bowl-rice {
  --fa: "\e2eb";
  --fa--fa: "\e2eb\e2eb";
}

.fa-timeline-arrow {
  --fa: "\e29d";
  --fa--fa: "\e29d\e29d";
}

.fa-skull {
  --fa: "\f54c";
  --fa--fa: "\f54c\f54c";
}

.fa-game-board-simple {
  --fa: "\f868";
  --fa--fa: "\f868\f868";
}

.fa-game-board-alt {
  --fa: "\f868";
  --fa--fa: "\f868\f868";
}

.fa-circle-video {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b";
}

.fa-video-circle {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b";
}

.fa-chart-scatter-bubble {
  --fa: "\e0e9";
  --fa--fa: "\e0e9\e0e9";
}

.fa-house-turret {
  --fa: "\e1b4";
  --fa--fa: "\e1b4\e1b4";
}

.fa-banana {
  --fa: "\e2e5";
  --fa--fa: "\e2e5\e2e5";
}

.fa-hand-holding-skull {
  --fa: "\e1a4";
  --fa--fa: "\e1a4\e1a4";
}

.fa-people-dress {
  --fa: "\e217";
  --fa--fa: "\e217\e217";
}

.fa-loveseat {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc";
}

.fa-couch-small {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc";
}

.fa-tower-broadcast {
  --fa: "\f519";
  --fa--fa: "\f519\f519";
}

.fa-broadcast-tower {
  --fa: "\f519";
  --fa--fa: "\f519\f519";
}

.fa-truck-pickup {
  --fa: "\f63c";
  --fa--fa: "\f63c\f63c";
}

.fa-block-quote {
  --fa: "\e0b5";
  --fa--fa: "\e0b5\e0b5";
}

.fa-up-long {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c";
}

.fa-long-arrow-alt-up {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c";
}

.fa-stop {
  --fa: "\f04d";
  --fa--fa: "\f04d\f04d";
}

.fa-code-merge {
  --fa: "\f387";
  --fa--fa: "\f387\f387";
}

.fa-money-check-dollar-pen {
  --fa: "\f873";
  --fa--fa: "\f873\f873";
}

.fa-money-check-edit-alt {
  --fa: "\f873";
  --fa--fa: "\f873\f873";
}

.fa-up-from-line {
  --fa: "\f346";
  --fa--fa: "\f346\f346";
}

.fa-arrow-alt-from-bottom {
  --fa: "\f346";
  --fa--fa: "\f346\f346";
}

.fa-upload {
  --fa: "\f093";
  --fa--fa: "\f093\f093";
}

.fa-hurricane {
  --fa: "\f751";
  --fa--fa: "\f751\f751";
}

.fa-grid-round-2-plus {
  --fa: "\e5dc";
  --fa--fa: "\e5dc\e5dc";
}

.fa-people-pants {
  --fa: "\e219";
  --fa--fa: "\e219\e219";
}

.fa-mound {
  --fa: "\e52d";
  --fa--fa: "\e52d\e52d";
}

.fa-windsock {
  --fa: "\f777";
  --fa--fa: "\f777\f777";
}

.fa-circle-half {
  --fa: "\e110";
  --fa--fa: "\e110\e110";
}

.fa-brake-warning {
  --fa: "\e0c7";
  --fa--fa: "\e0c7\e0c7";
}

.fa-toilet-portable {
  --fa: "\e583";
  --fa--fa: "\e583\e583";
}

.fa-compact-disc {
  --fa: "\f51f";
  --fa--fa: "\f51f\f51f";
}

.fa-file-arrow-down {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d";
}

.fa-file-download {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d";
}

.fa-saxophone-fire {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db";
}

.fa-sax-hot {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db";
}

.fa-camera-web-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833";
}

.fa-webcam-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833";
}

.fa-folder-medical {
  --fa: "\e18c";
  --fa--fa: "\e18c\e18c";
}

.fa-folder-gear {
  --fa: "\e187";
  --fa--fa: "\e187\e187";
}

.fa-folder-cog {
  --fa: "\e187";
  --fa--fa: "\e187\e187";
}

.fa-hand-wave {
  --fa: "\e1a7";
  --fa--fa: "\e1a7\e1a7";
}

.fa-arrow-up-arrow-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099";
}

.fa-sort-up-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099";
}

.fa-caravan {
  --fa: "\f8ff";
  --fa--fa: "\f8ff\f8ff";
}

.fa-shield-cat {
  --fa: "\e572";
  --fa--fa: "\e572\e572";
}

.fa-message-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9";
}

.fa-comment-alt-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9";
}

.fa-bolt {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7";
}

.fa-zap {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7";
}

.fa-trash-can-check {
  --fa: "\e2a9";
  --fa--fa: "\e2a9\e2a9";
}

.fa-glass-water {
  --fa: "\e4f4";
  --fa--fa: "\e4f4\e4f4";
}

.fa-oil-well {
  --fa: "\e532";
  --fa--fa: "\e532\e532";
}

.fa-table-cells-column-unlock {
  --fa: "\e690";
  --fa--fa: "\e690\e690";
}

.fa-person-simple {
  --fa: "\e220";
  --fa--fa: "\e220\e220";
}

.fa-arrow-turn-left-up {
  --fa: "\e634";
  --fa--fa: "\e634\e634";
}

.fa-vault {
  --fa: "\e2c5";
  --fa--fa: "\e2c5\e2c5";
}

.fa-mars {
  --fa: "\f222";
  --fa--fa: "\f222\f222";
}

.fa-toilet {
  --fa: "\f7d8";
  --fa--fa: "\f7d8\f7d8";
}

.fa-plane-circle-xmark {
  --fa: "\e557";
  --fa--fa: "\e557\e557";
}

.fa-yen-sign {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-cny {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-jpy {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-rmb {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-yen {
  --fa: "\f157";
  --fa--fa: "\f157\f157";
}

.fa-gear-code {
  --fa: "\e5e8";
  --fa--fa: "\e5e8\e5e8";
}

.fa-notes {
  --fa: "\e202";
  --fa--fa: "\e202\e202";
}

.fa-ruble-sign {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-rouble {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-rub {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-ruble {
  --fa: "\f158";
  --fa--fa: "\f158\f158";
}

.fa-trash-undo {
  --fa: "\f895";
  --fa--fa: "\f895\f895";
}

.fa-trash-arrow-turn-left {
  --fa: "\f895";
  --fa--fa: "\f895\f895";
}

.fa-champagne-glass {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e";
}

.fa-glass-champagne {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e";
}

.fa-objects-align-center-horizontal {
  --fa: "\e3bc";
  --fa--fa: "\e3bc\e3bc";
}

.fa-sun {
  --fa: "\f185";
  --fa--fa: "\f185\f185";
}

.fa-trash-can-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad";
}

.fa-trash-alt-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad";
}

.fa-screen-users {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d";
}

.fa-users-class {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d";
}

.fa-guitar {
  --fa: "\f7a6";
  --fa--fa: "\f7a6\f7a6";
}

.fa-square-arrow-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a";
}

.fa-arrow-square-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a";
}

.fa-square-8 {
  --fa: "\e25d";
  --fa--fa: "\e25d\e25d";
}

.fa-face-smile-hearts {
  --fa: "\e390";
  --fa--fa: "\e390\e390";
}

.fa-brackets-square {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9";
}

.fa-brackets {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9";
}

.fa-laptop-arrow-down {
  --fa: "\e1c6";
  --fa--fa: "\e1c6\e1c6";
}

.fa-hockey-stick-puck {
  --fa: "\e3ae";
  --fa--fa: "\e3ae\e3ae";
}

.fa-house-tree {
  --fa: "\e1b3";
  --fa--fa: "\e1b3\e1b3";
}

.fa-signal-fair {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d";
}

.fa-signal-2 {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d";
}

.fa-face-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c";
}

.fa-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c";
}

.fa-circle-dollar {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-dollar-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-usd-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8";
}

.fa-horse-head {
  --fa: "\f7ab";
  --fa--fa: "\f7ab\f7ab";
}

.fa-arrows-repeat {
  --fa: "\f364";
  --fa--fa: "\f364\f364";
}

.fa-repeat-alt {
  --fa: "\f364";
  --fa--fa: "\f364\f364";
}

.fa-bore-hole {
  --fa: "\e4c3";
  --fa--fa: "\e4c3\e4c3";
}

.fa-industry {
  --fa: "\f275";
  --fa--fa: "\f275\f275";
}

.fa-image-polaroid {
  --fa: "\f8c4";
  --fa--fa: "\f8c4\f8c4";
}

.fa-wave-triangle {
  --fa: "\f89a";
  --fa--fa: "\f89a\f89a";
}

.fa-turn-left-down {
  --fa: "\e637";
  --fa--fa: "\e637\e637";
}

.fa-person-running-fast {
  --fa: "\e5ff";
  --fa--fa: "\e5ff\e5ff";
}

.fa-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358";
}

.fa-arrow-alt-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358";
}

.fa-grill {
  --fa: "\e5a3";
  --fa--fa: "\e5a3\e5a3";
}

.fa-arrows-turn-to-dots {
  --fa: "\e4c1";
  --fa--fa: "\e4c1\e4c1";
}

.fa-chart-mixed {
  --fa: "\f643";
  --fa--fa: "\f643\f643";
}

.fa-analytics {
  --fa: "\f643";
  --fa--fa: "\f643\f643";
}

.fa-florin-sign {
  --fa: "\e184";
  --fa--fa: "\e184\e184";
}

.fa-arrow-down-short-wide {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-sort-amount-desc {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-sort-amount-down-alt {
  --fa: "\f884";
  --fa--fa: "\f884\f884";
}

.fa-less-than {
  --fa: "\<";
  --fa--fa: "\<\<";
}

.fa-display-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165";
}

.fa-desktop-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165";
}

.fa-face-drooling {
  --fa: "\e372";
  --fa--fa: "\e372\e372";
}

.fa-oil-temperature {
  --fa: "\f614";
  --fa--fa: "\f614\f614";
}

.fa-oil-temp {
  --fa: "\f614";
  --fa--fa: "\f614\f614";
}

.fa-square-question {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd";
}

.fa-question-square {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd";
}

.fa-air-conditioner {
  --fa: "\f8f4";
  --fa--fa: "\f8f4\f8f4";
}

.fa-angle-down {
  --fa: "\f107";
  --fa--fa: "\f107\f107";
}

.fa-mountains {
  --fa: "\f6fd";
  --fa--fa: "\f6fd\f6fd";
}

.fa-omega {
  --fa: "\f67a";
  --fa--fa: "\f67a\f67a";
}

.fa-car-tunnel {
  --fa: "\e4de";
  --fa--fa: "\e4de\e4de";
}

.fa-person-dolly-empty {
  --fa: "\f4d1";
  --fa--fa: "\f4d1\f4d1";
}

.fa-pan-food {
  --fa: "\e42b";
  --fa--fa: "\e42b\e42b";
}

.fa-head-side-cough {
  --fa: "\e061";
  --fa--fa: "\e061\e061";
}

.fa-grip-lines {
  --fa: "\f7a4";
  --fa--fa: "\f7a4\f7a4";
}

.fa-thumbs-down {
  --fa: "\f165";
  --fa--fa: "\f165\f165";
}

.fa-user-lock {
  --fa: "\f502";
  --fa--fa: "\f502\f502";
}

.fa-arrow-right-long {
  --fa: "\f178";
  --fa--fa: "\f178\f178";
}

.fa-long-arrow-right {
  --fa: "\f178";
  --fa--fa: "\f178\f178";
}

.fa-tickets-airline {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tickets-perforated-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tickets-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b";
}

.fa-tent-double-peak {
  --fa: "\e627";
  --fa--fa: "\e627\e627";
}

.fa-anchor-circle-xmark {
  --fa: "\e4ac";
  --fa--fa: "\e4ac\e4ac";
}

.fa-ellipsis {
  --fa: "\f141";
  --fa--fa: "\f141\f141";
}

.fa-ellipsis-h {
  --fa: "\f141";
  --fa--fa: "\f141\f141";
}

.fa-nfc-slash {
  --fa: "\e1fc";
  --fa--fa: "\e1fc\e1fc";
}

.fa-chess-pawn {
  --fa: "\f443";
  --fa--fa: "\f443\f443";
}

.fa-kit-medical {
  --fa: "\f479";
  --fa--fa: "\f479\f479";
}

.fa-first-aid {
  --fa: "\f479";
  --fa--fa: "\f479\f479";
}

.fa-grid-2-plus {
  --fa: "\e197";
  --fa--fa: "\e197\e197";
}

.fa-bells {
  --fa: "\f77f";
  --fa--fa: "\f77f\f77f";
}

.fa-person-through-window {
  --fa: "\e5a9";
  --fa--fa: "\e5a9\e5a9";
}

.fa-toolbox {
  --fa: "\f552";
  --fa--fa: "\f552\f552";
}

.fa-globe-wifi {
  --fa: "\e685";
  --fa--fa: "\e685\e685";
}

.fa-envelope-dot {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f";
}

.fa-envelope-badge {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f";
}

.fa-magnifying-glass-waveform {
  --fa: "\e661";
  --fa--fa: "\e661\e661";
}

.fa-hands-holding-circle {
  --fa: "\e4fb";
  --fa--fa: "\e4fb\e4fb";
}

.fa-bug {
  --fa: "\f188";
  --fa--fa: "\f188\f188";
}

.fa-bowl-chopsticks {
  --fa: "\e2e9";
  --fa--fa: "\e2e9\e2e9";
}

.fa-credit-card {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d";
}

.fa-credit-card-alt {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d";
}

.fa-circle-s {
  --fa: "\e121";
  --fa--fa: "\e121\e121";
}

.fa-box-ballot {
  --fa: "\f735";
  --fa--fa: "\f735\f735";
}

.fa-car {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9";
}

.fa-automobile {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9";
}

.fa-hand-holding-hand {
  --fa: "\e4f7";
  --fa--fa: "\e4f7\e4f7";
}

.fa-user-tie-hair {
  --fa: "\e45f";
  --fa--fa: "\e45f\e45f";
}

.fa-podium-star {
  --fa: "\f758";
  --fa--fa: "\f758\f758";
}

.fa-user-hair-mullet {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-business-front {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-party-back {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-trian-balbot {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c";
}

.fa-microphone-stand {
  --fa: "\f8cb";
  --fa--fa: "\f8cb\f8cb";
}

.fa-book-open-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da";
}

.fa-book-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da";
}

.fa-family-dress {
  --fa: "\e301";
  --fa--fa: "\e301\e301";
}

.fa-circle-x {
  --fa: "\e12e";
  --fa--fa: "\e12e\e12e";
}

.fa-cabin {
  --fa: "\e46d";
  --fa--fa: "\e46d\e46d";
}

.fa-mountain-sun {
  --fa: "\e52f";
  --fa--fa: "\e52f\e52f";
}

.fa-chart-simple-horizontal {
  --fa: "\e474";
  --fa--fa: "\e474\e474";
}

.fa-arrows-left-right-to-line {
  --fa: "\e4ba";
  --fa--fa: "\e4ba\e4ba";
}

.fa-hand-back-point-left {
  --fa: "\e19f";
  --fa--fa: "\e19f\e19f";
}

.fa-message-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-comment-alt-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-messaging {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3";
}

.fa-file-heart {
  --fa: "\e176";
  --fa--fa: "\e176\e176";
}

.fa-beer-mug {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3";
}

.fa-beer-foam {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3";
}

.fa-dice-d20 {
  --fa: "\f6cf";
  --fa--fa: "\f6cf\f6cf";
}

.fa-drone {
  --fa: "\f85f";
  --fa--fa: "\f85f\f85f";
}

.fa-truck-droplet {
  --fa: "\e58c";
  --fa--fa: "\e58c\e58c";
}

.fa-file-circle-xmark {
  --fa: "\e5a1";
  --fa--fa: "\e5a1\e5a1";
}

.fa-temperature-arrow-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040";
}

.fa-temperature-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040";
}

.fa-medal {
  --fa: "\f5a2";
  --fa--fa: "\f5a2\f5a2";
}

.fa-person-fairy {
  --fa: "\e608";
  --fa--fa: "\e608\e608";
}

.fa-bed {
  --fa: "\f236";
  --fa--fa: "\f236\f236";
}

.fa-book-copy {
  --fa: "\e0be";
  --fa--fa: "\e0be\e0be";
}

.fa-square-h {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd";
}

.fa-h-square {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd";
}

.fa-square-c {
  --fa: "\e266";
  --fa--fa: "\e266\e266";
}

.fa-clock-two {
  --fa: "\e35a";
  --fa--fa: "\e35a\e35a";
}

.fa-square-ellipsis-vertical {
  --fa: "\e26f";
  --fa--fa: "\e26f\e26f";
}

.fa-calendar-users {
  --fa: "\e5e2";
  --fa--fa: "\e5e2\e5e2";
}

.fa-podcast {
  --fa: "\f2ce";
  --fa--fa: "\f2ce\f2ce";
}

.fa-bee {
  --fa: "\e0b2";
  --fa--fa: "\e0b2\e0b2";
}

.fa-temperature-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-temperature-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-thermometer-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-thermometer-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7";
}

.fa-bell {
  --fa: "\f0f3";
  --fa--fa: "\f0f3\f0f3";
}

.fa-candy-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8";
}

.fa-chocolate-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8";
}

.fa-xmark-large {
  --fa: "\e59b";
  --fa--fa: "\e59b\e59b";
}

.fa-pinata {
  --fa: "\e3c3";
  --fa--fa: "\e3c3\e3c3";
}

.fa-file-ppt {
  --fa: "\e64a";
  --fa--fa: "\e64a\e64a";
}

.fa-arrows-from-line {
  --fa: "\e0a4";
  --fa--fa: "\e0a4\e0a4";
}

.fa-superscript {
  --fa: "\f12b";
  --fa--fa: "\f12b\f12b";
}

.fa-bowl-spoon {
  --fa: "\e3e0";
  --fa--fa: "\e3e0\e3e0";
}

.fa-hexagon-check {
  --fa: "\e416";
  --fa--fa: "\e416\e416";
}

.fa-plug-circle-xmark {
  --fa: "\e560";
  --fa--fa: "\e560\e560";
}

.fa-star-of-life {
  --fa: "\f621";
  --fa--fa: "\f621\f621";
}

.fa-phone-slash {
  --fa: "\f3dd";
  --fa--fa: "\f3dd\f3dd";
}

.fa-traffic-light-stop {
  --fa: "\f63a";
  --fa--fa: "\f63a\f63a";
}

.fa-paint-roller {
  --fa: "\f5aa";
  --fa--fa: "\f5aa\f5aa";
}

.fa-accent-grave {
  --fa: "\`";
  --fa--fa: "\`\`";
}

.fa-handshake-angle {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4";
}

.fa-hands-helping {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4";
}

.fa-circle-0 {
  --fa: "\e0ed";
  --fa--fa: "\e0ed\e0ed";
}

.fa-dial-med-low {
  --fa: "\e160";
  --fa--fa: "\e160\e160";
}

.fa-location-dot {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5";
}

.fa-map-marker-alt {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5";
}

.fa-crab {
  --fa: "\e3ff";
  --fa--fa: "\e3ff\e3ff";
}

.fa-box-open-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c";
}

.fa-box-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c";
}

.fa-file {
  --fa: "\f15b";
  --fa--fa: "\f15b\f15b";
}

.fa-greater-than {
  --fa: "\>";
  --fa--fa: "\>\>";
}

.fa-quotes {
  --fa: "\e234";
  --fa--fa: "\e234\e234";
}

.fa-pretzel {
  --fa: "\e441";
  --fa--fa: "\e441\e441";
}

.fa-t-rex {
  --fa: "\e629";
  --fa--fa: "\e629\e629";
}

.fa-person-swimming {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4";
}

.fa-swimmer {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4";
}

.fa-arrow-down {
  --fa: "\f063";
  --fa--fa: "\f063\f063";
}

.fa-user-robot-xmarks {
  --fa: "\e4a7";
  --fa--fa: "\e4a7\e4a7";
}

.fa-message-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4";
}

.fa-comment-alt-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4";
}

.fa-candy-corn {
  --fa: "\f6bd";
  --fa--fa: "\f6bd\f6bd";
}

.fa-folder-magnifying-glass {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b";
}

.fa-folder-search {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b";
}

.fa-notebook {
  --fa: "\e201";
  --fa--fa: "\e201\e201";
}

.fa-circle-wifi {
  --fa: "\e67d";
  --fa--fa: "\e67d\e67d";
}

.fa-droplet {
  --fa: "\f043";
  --fa--fa: "\f043\f043";
}

.fa-tint {
  --fa: "\f043";
  --fa--fa: "\f043\f043";
}

.fa-bullseye-pointer {
  --fa: "\f649";
  --fa--fa: "\f649\f649";
}

.fa-eraser {
  --fa: "\f12d";
  --fa--fa: "\f12d\f12d";
}

.fa-hexagon-image {
  --fa: "\e504";
  --fa--fa: "\e504\e504";
}

.fa-earth-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-earth {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-earth-america {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-globe-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d";
}

.fa-file-svg {
  --fa: "\e64b";
  --fa--fa: "\e64b\e64b";
}

.fa-crate-apple {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1";
}

.fa-apple-crate {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1";
}

.fa-person-burst {
  --fa: "\e53b";
  --fa--fa: "\e53b\e53b";
}

.fa-game-board {
  --fa: "\f867";
  --fa--fa: "\f867\f867";
}

.fa-hat-chef {
  --fa: "\f86b";
  --fa--fa: "\f86b\f86b";
}

.fa-hand-back-point-right {
  --fa: "\e1a1";
  --fa--fa: "\e1a1\e1a1";
}

.fa-dove {
  --fa: "\f4ba";
  --fa--fa: "\f4ba\f4ba";
}

.fa-snowflake-droplets {
  --fa: "\e5c1";
  --fa--fa: "\e5c1\e5c1";
}

.fa-battery-empty {
  --fa: "\f244";
  --fa--fa: "\f244\f244";
}

.fa-battery-0 {
  --fa: "\f244";
  --fa--fa: "\f244\f244";
}

.fa-grid-4 {
  --fa: "\e198";
  --fa--fa: "\e198\e198";
}

.fa-socks {
  --fa: "\f696";
  --fa--fa: "\f696\f696";
}

.fa-face-sunglasses {
  --fa: "\e398";
  --fa--fa: "\e398\e398";
}

.fa-inbox {
  --fa: "\f01c";
  --fa--fa: "\f01c\f01c";
}

.fa-square-0 {
  --fa: "\e255";
  --fa--fa: "\e255\e255";
}

.fa-section {
  --fa: "\e447";
  --fa--fa: "\e447\e447";
}

.fa-square-this-way-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f";
}

.fa-box-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f";
}

.fa-gauge-high {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-tachometer-alt {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-tachometer-alt-fast {
  --fa: "\f625";
  --fa--fa: "\f625\f625";
}

.fa-square-ampersand {
  --fa: "\e260";
  --fa--fa: "\e260\e260";
}

.fa-envelope-open-text {
  --fa: "\f658";
  --fa--fa: "\f658\f658";
}

.fa-lamp-desk {
  --fa: "\e014";
  --fa--fa: "\e014\e014";
}

.fa-hospital {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-hospital-alt {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-hospital-wide {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8";
}

.fa-poll-people {
  --fa: "\f759";
  --fa--fa: "\f759\f759";
}

.fa-whiskey-glass-ice {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1";
}

.fa-glass-whiskey-rocks {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1";
}

.fa-wine-bottle {
  --fa: "\f72f";
  --fa--fa: "\f72f\f72f";
}

.fa-chess-rook {
  --fa: "\f447";
  --fa--fa: "\f447\f447";
}

.fa-user-bounty-hunter {
  --fa: "\e2bf";
  --fa--fa: "\e2bf\e2bf";
}

.fa-bars-staggered {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-reorder {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-stream {
  --fa: "\f550";
  --fa--fa: "\f550\f550";
}

.fa-diagram-sankey {
  --fa: "\e158";
  --fa--fa: "\e158\e158";
}

.fa-cloud-hail-mixed {
  --fa: "\f73a";
  --fa--fa: "\f73a\f73a";
}

.fa-circle-up-left {
  --fa: "\e128";
  --fa--fa: "\e128\e128";
}

.fa-dharmachakra {
  --fa: "\f655";
  --fa--fa: "\f655\f655";
}

.fa-objects-align-left {
  --fa: "\e3be";
  --fa--fa: "\e3be\e3be";
}

.fa-oil-can-drip {
  --fa: "\e205";
  --fa--fa: "\e205\e205";
}

.fa-face-smiling-hands {
  --fa: "\e396";
  --fa--fa: "\e396\e396";
}

.fa-broccoli {
  --fa: "\e3e2";
  --fa--fa: "\e3e2\e3e2";
}

.fa-route-interstate {
  --fa: "\f61b";
  --fa--fa: "\f61b\f61b";
}

.fa-ear-muffs {
  --fa: "\f795";
  --fa--fa: "\f795\f795";
}

.fa-hotdog {
  --fa: "\f80f";
  --fa--fa: "\f80f\f80f";
}

.fa-transporter-empty {
  --fa: "\e046";
  --fa--fa: "\e046\e046";
}

.fa-person-walking-with-cane {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d";
}

.fa-blind {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d";
}

.fa-angle-90 {
  --fa: "\e08d";
  --fa--fa: "\e08d\e08d";
}

.fa-rectangle-terminal {
  --fa: "\e236";
  --fa--fa: "\e236\e236";
}

.fa-kite {
  --fa: "\f6f4";
  --fa--fa: "\f6f4\f6f4";
}

.fa-drum {
  --fa: "\f569";
  --fa--fa: "\f569\f569";
}

.fa-scrubber {
  --fa: "\f2f8";
  --fa--fa: "\f2f8\f2f8";
}

.fa-ice-cream {
  --fa: "\f810";
  --fa--fa: "\f810\f810";
}

.fa-heart-circle-bolt {
  --fa: "\e4fc";
  --fa--fa: "\e4fc\e4fc";
}

.fa-fish-bones {
  --fa: "\e304";
  --fa--fa: "\e304\e304";
}

.fa-deer-rudolph {
  --fa: "\f78f";
  --fa--fa: "\f78f\f78f";
}

.fa-fax {
  --fa: "\f1ac";
  --fa--fa: "\f1ac\f1ac";
}

.fa-paragraph {
  --fa: "\f1dd";
  --fa--fa: "\f1dd\f1dd";
}

.fa-head-side-heart {
  --fa: "\e1aa";
  --fa--fa: "\e1aa\e1aa";
}

.fa-square-e {
  --fa: "\e26d";
  --fa--fa: "\e26d\e26d";
}

.fa-meter-fire {
  --fa: "\e1eb";
  --fa--fa: "\e1eb\e1eb";
}

.fa-cloud-hail {
  --fa: "\f739";
  --fa--fa: "\f739\f739";
}

.fa-check-to-slot {
  --fa: "\f772";
  --fa--fa: "\f772\f772";
}

.fa-vote-yea {
  --fa: "\f772";
  --fa--fa: "\f772\f772";
}

.fa-money-from-bracket {
  --fa: "\e312";
  --fa--fa: "\e312\e312";
}

.fa-star-half {
  --fa: "\f089";
  --fa--fa: "\f089\f089";
}

.fa-car-bus {
  --fa: "\f85a";
  --fa--fa: "\f85a\f85a";
}

.fa-speaker {
  --fa: "\f8df";
  --fa--fa: "\f8df\f8df";
}

.fa-timer {
  --fa: "\e29e";
  --fa--fa: "\e29e\e29e";
}

.fa-boxes-stacked {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-boxes {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-boxes-alt {
  --fa: "\f468";
  --fa--fa: "\f468\f468";
}

.fa-landmark-magnifying-glass {
  --fa: "\e622";
  --fa--fa: "\e622\e622";
}

.fa-grill-hot {
  --fa: "\e5a5";
  --fa--fa: "\e5a5\e5a5";
}

.fa-ballot-check {
  --fa: "\f733";
  --fa--fa: "\f733\f733";
}

.fa-link {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1";
}

.fa-chain {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1";
}

.fa-ear-listen {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2";
}

.fa-assistive-listening-systems {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2";
}

.fa-file-minus {
  --fa: "\f318";
  --fa--fa: "\f318\f318";
}

.fa-tree-city {
  --fa: "\e587";
  --fa--fa: "\e587\e587";
}

.fa-play {
  --fa: "\f04b";
  --fa--fa: "\f04b\f04b";
}

.fa-font {
  --fa: "\f031";
  --fa--fa: "\f031\f031";
}

.fa-cup-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5";
}

.fa-coffee-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5";
}

.fa-square-down-left {
  --fa: "\e26b";
  --fa--fa: "\e26b\e26b";
}

.fa-burger-lettuce {
  --fa: "\e3e3";
  --fa--fa: "\e3e3\e3e3";
}

.fa-table-cells-row-lock {
  --fa: "\e67a";
  --fa--fa: "\e67a\e67a";
}

.fa-rupiah-sign {
  --fa: "\e23d";
  --fa--fa: "\e23d\e23d";
}

.fa-magnifying-glass {
  --fa: "\f002";
  --fa--fa: "\f002\f002";
}

.fa-search {
  --fa: "\f002";
  --fa--fa: "\f002\f002";
}

.fa-table-tennis-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-ping-pong-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-table-tennis {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d";
}

.fa-person-dots-from-line {
  --fa: "\f470";
  --fa--fa: "\f470\f470";
}

.fa-diagnoses {
  --fa: "\f470";
  --fa--fa: "\f470\f470";
}

.fa-chevrons-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322";
}

.fa-chevron-double-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322";
}

.fa-trash-can-arrow-up {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a";
}

.fa-trash-restore-alt {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a";
}

.fa-signal-good {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e";
}

.fa-signal-3 {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e";
}

.fa-location-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b";
}

.fa-map-marker-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b";
}

.fa-floppy-disk-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-floppy-disk-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-save-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-save-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181";
}

.fa-naira-sign {
  --fa: "\e1f6";
  --fa--fa: "\e1f6\e1f6";
}

.fa-peach {
  --fa: "\e20b";
  --fa--fa: "\e20b\e20b";
}

.fa-circles-overlap-3 {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1";
}

.fa-pronoun {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1";
}

.fa-taxi-bus {
  --fa: "\e298";
  --fa--fa: "\e298\e298";
}

.fa-bracket-curly {
  --fa: "\{";
  --fa--fa: "\{\{";
}

.fa-bracket-curly-left {
  --fa: "\{";
  --fa--fa: "\{\{";
}

.fa-lobster {
  --fa: "\e421";
  --fa--fa: "\e421\e421";
}

.fa-cart-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476";
}

.fa-dolly-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476";
}

.fa-colon {
  --fa: "\:";
  --fa--fa: "\:\:";
}

.fa-cart-arrow-down {
  --fa: "\f218";
  --fa--fa: "\f218\f218";
}

.fa-wand {
  --fa: "\f72a";
  --fa--fa: "\f72a\f72a";
}

.fa-walkie-talkie {
  --fa: "\f8ef";
  --fa--fa: "\f8ef\f8ef";
}

.fa-file-pen {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c";
}

.fa-file-edit {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c";
}

.fa-receipt {
  --fa: "\f543";
  --fa--fa: "\f543\f543";
}

.fa-table-picnic {
  --fa: "\e32d";
  --fa--fa: "\e32d\e32d";
}

.fa-square-pen {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-pen-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-pencil-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b";
}

.fa-circle-microphone-lines {
  --fa: "\e117";
  --fa--fa: "\e117\e117";
}

.fa-microphone-circle-alt {
  --fa: "\e117";
  --fa--fa: "\e117\e117";
}

.fa-display-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa";
}

.fa-desktop-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa";
}

.fa-suitcase-rolling {
  --fa: "\f5c1";
  --fa--fa: "\f5c1\f5c1";
}

.fa-person-circle-exclamation {
  --fa: "\e53f";
  --fa--fa: "\e53f\e53f";
}

.fa-transporter-2 {
  --fa: "\e044";
  --fa--fa: "\e044\e044";
}

.fa-user-hoodie {
  --fa: "\e68a";
  --fa--fa: "\e68a\e68a";
}

.fa-hands-holding-diamond {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c";
}

.fa-hand-receiving {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c";
}

.fa-money-bill-simple-wave {
  --fa: "\e1f2";
  --fa--fa: "\e1f2\e1f2";
}

.fa-chevron-down {
  --fa: "\f078";
  --fa--fa: "\f078\f078";
}

.fa-battery-full {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-battery {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-battery-5 {
  --fa: "\f240";
  --fa--fa: "\f240\f240";
}

.fa-bell-plus {
  --fa: "\f849";
  --fa--fa: "\f849\f849";
}

.fa-book-arrow-right {
  --fa: "\e0b9";
  --fa--fa: "\e0b9\e0b9";
}

.fa-hospitals {
  --fa: "\f80e";
  --fa--fa: "\f80e\f80e";
}

.fa-club {
  --fa: "\f327";
  --fa--fa: "\f327\f327";
}

.fa-skull-crossbones {
  --fa: "\f714";
  --fa--fa: "\f714\f714";
}

.fa-droplet-degree {
  --fa: "\f748";
  --fa--fa: "\f748\f748";
}

.fa-dewpoint {
  --fa: "\f748";
  --fa--fa: "\f748\f748";
}

.fa-code-compare {
  --fa: "\e13a";
  --fa--fa: "\e13a\e13a";
}

.fa-list-ul {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca";
}

.fa-list-dots {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca";
}

.fa-hand-holding-magic {
  --fa: "\f6e5";
  --fa--fa: "\f6e5\f6e5";
}

.fa-watermelon-slice {
  --fa: "\e337";
  --fa--fa: "\e337\e337";
}

.fa-circle-ellipsis {
  --fa: "\e10a";
  --fa--fa: "\e10a\e10a";
}

.fa-school-lock {
  --fa: "\e56f";
  --fa--fa: "\e56f\e56f";
}

.fa-tower-cell {
  --fa: "\e585";
  --fa--fa: "\e585\e585";
}

.fa-sd-cards {
  --fa: "\e240";
  --fa--fa: "\e240\e240";
}

.fa-jug-bottle {
  --fa: "\e5fb";
  --fa--fa: "\e5fb\e5fb";
}

.fa-down-long {
  --fa: "\f309";
  --fa--fa: "\f309\f309";
}

.fa-long-arrow-alt-down {
  --fa: "\f309";
  --fa--fa: "\f309\f309";
}

.fa-envelopes {
  --fa: "\e170";
  --fa--fa: "\e170\e170";
}

.fa-phone-office {
  --fa: "\f67d";
  --fa--fa: "\f67d\f67d";
}

.fa-ranking-star {
  --fa: "\e561";
  --fa--fa: "\e561\e561";
}

.fa-chess-king {
  --fa: "\f43f";
  --fa--fa: "\f43f\f43f";
}

.fa-nfc-pen {
  --fa: "\e1fa";
  --fa--fa: "\e1fa\e1fa";
}

.fa-person-harassing {
  --fa: "\e549";
  --fa--fa: "\e549\e549";
}

.fa-magnifying-glass-play {
  --fa: "\e660";
  --fa--fa: "\e660\e660";
}

.fa-hat-winter {
  --fa: "\f7a8";
  --fa--fa: "\f7a8\f7a8";
}

.fa-brazilian-real-sign {
  --fa: "\e46c";
  --fa--fa: "\e46c\e46c";
}

.fa-landmark-dome {
  --fa: "\f752";
  --fa--fa: "\f752\f752";
}

.fa-landmark-alt {
  --fa: "\f752";
  --fa--fa: "\f752\f752";
}

.fa-bone-break {
  --fa: "\f5d8";
  --fa--fa: "\f5d8\f5d8";
}

.fa-arrow-up {
  --fa: "\f062";
  --fa--fa: "\f062\f062";
}

.fa-down-from-dotted-line {
  --fa: "\e407";
  --fa--fa: "\e407\e407";
}

.fa-tv {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-television {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-tv-alt {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c";
}

.fa-border-left {
  --fa: "\f84f";
  --fa--fa: "\f84f\f84f";
}

.fa-circle-divide {
  --fa: "\e106";
  --fa--fa: "\e106\e106";
}

.fa-shrimp {
  --fa: "\e448";
  --fa--fa: "\e448\e448";
}

.fa-list-check {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae";
}

.fa-tasks {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae";
}

.fa-diagram-subtask {
  --fa: "\e479";
  --fa--fa: "\e479\e479";
}

.fa-jug-detergent {
  --fa: "\e519";
  --fa--fa: "\e519\e519";
}

.fa-circle-user {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd";
}

.fa-user-circle {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd";
}

.fa-square-y {
  --fa: "\e287";
  --fa--fa: "\e287\e287";
}

.fa-user-doctor-hair {
  --fa: "\e458";
  --fa--fa: "\e458\e458";
}

.fa-planet-ringed {
  --fa: "\e020";
  --fa--fa: "\e020\e020";
}

.fa-mushroom {
  --fa: "\e425";
  --fa--fa: "\e425\e425";
}

.fa-user-shield {
  --fa: "\f505";
  --fa--fa: "\f505\f505";
}

.fa-megaphone {
  --fa: "\f675";
  --fa--fa: "\f675\f675";
}

.fa-wreath-laurel {
  --fa: "\e5d2";
  --fa--fa: "\e5d2\e5d2";
}

.fa-circle-exclamation-check {
  --fa: "\e10d";
  --fa--fa: "\e10d\e10d";
}

.fa-wind {
  --fa: "\f72e";
  --fa--fa: "\f72e\f72e";
}

.fa-box-dollar {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0";
}

.fa-box-usd {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0";
}

.fa-car-burst {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1";
}

.fa-car-crash {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1";
}

.fa-y {
  --fa: "Y";
  --fa--fa: "YY";
}

.fa-user-headset {
  --fa: "\f82d";
  --fa--fa: "\f82d\f82d";
}

.fa-arrows-retweet {
  --fa: "\f361";
  --fa--fa: "\f361\f361";
}

.fa-retweet-alt {
  --fa: "\f361";
  --fa--fa: "\f361\f361";
}

.fa-person-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce";
}

.fa-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce";
}

.fa-square-chevron-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b";
}

.fa-chevron-square-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b";
}

.fa-lacrosse-stick-ball {
  --fa: "\e3b6";
  --fa--fa: "\e3b6\e3b6";
}

.fa-truck-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b";
}

.fa-shipping-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b";
}

.fa-user-magnifying-glass {
  --fa: "\e5c5";
  --fa--fa: "\e5c5\e5c5";
}

.fa-star-sharp {
  --fa: "\e28b";
  --fa--fa: "\e28b\e28b";
}

.fa-comment-heart {
  --fa: "\e5c8";
  --fa--fa: "\e5c8\e5c8";
}

.fa-circle-1 {
  --fa: "\e0ee";
  --fa--fa: "\e0ee\e0ee";
}

.fa-circle-star {
  --fa: "\e123";
  --fa--fa: "\e123\e123";
}

.fa-star-circle {
  --fa: "\e123";
  --fa--fa: "\e123\e123";
}

.fa-fish {
  --fa: "\f578";
  --fa--fa: "\f578\f578";
}

.fa-cloud-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e";
}

.fa-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e";
}

.fa-waffle {
  --fa: "\e466";
  --fa--fa: "\e466\e466";
}

.fa-music-note {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf";
}

.fa-music-alt {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf";
}

.fa-hexagon-exclamation {
  --fa: "\e417";
  --fa--fa: "\e417\e417";
}

.fa-cart-shopping-fast {
  --fa: "\e0dc";
  --fa--fa: "\e0dc\e0dc";
}

.fa-object-union {
  --fa: "\e49f";
  --fa--fa: "\e49f\e49f";
}

.fa-user-graduate {
  --fa: "\f501";
  --fa--fa: "\f501\f501";
}

.fa-starfighter {
  --fa: "\e037";
  --fa--fa: "\e037\e037";
}

.fa-circle-half-stroke {
  --fa: "\f042";
  --fa--fa: "\f042\f042";
}

.fa-adjust {
  --fa: "\f042";
  --fa--fa: "\f042\f042";
}

.fa-arrow-right-long-to-line {
  --fa: "\e3d5";
  --fa--fa: "\e3d5\e3d5";
}

.fa-square-arrow-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339";
}

.fa-arrow-square-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339";
}

.fa-diamond-half-stroke {
  --fa: "\e5b8";
  --fa--fa: "\e5b8\e5b8";
}

.fa-clapperboard {
  --fa: "\e131";
  --fa--fa: "\e131\e131";
}

.fa-square-chevron-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a";
}

.fa-chevron-square-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a";
}

.fa-phone-intercom {
  --fa: "\e434";
  --fa--fa: "\e434\e434";
}

.fa-link-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb";
}

.fa-chain-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb";
}

.fa-mango {
  --fa: "\e30f";
  --fa--fa: "\e30f\e30f";
}

.fa-music-note-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0";
}

.fa-music-alt-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0";
}

.fa-circle-radiation {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba";
}

.fa-radiation-alt {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba";
}

.fa-face-tongue-sweat {
  --fa: "\e39e";
  --fa--fa: "\e39e\e39e";
}

.fa-globe-stand {
  --fa: "\f5f6";
  --fa--fa: "\f5f6\f5f6";
}

.fa-baseball {
  --fa: "\f433";
  --fa--fa: "\f433\f433";
}

.fa-baseball-ball {
  --fa: "\f433";
  --fa--fa: "\f433\f433";
}

.fa-circle-p {
  --fa: "\e11a";
  --fa--fa: "\e11a\e11a";
}

.fa-award-simple {
  --fa: "\e0ab";
  --fa--fa: "\e0ab\e0ab";
}

.fa-jet-fighter-up {
  --fa: "\e518";
  --fa--fa: "\e518\e518";
}

.fa-diagram-project {
  --fa: "\f542";
  --fa--fa: "\f542\f542";
}

.fa-project-diagram {
  --fa: "\f542";
  --fa--fa: "\f542\f542";
}

.fa-pedestal {
  --fa: "\e20d";
  --fa--fa: "\e20d\e20d";
}

.fa-chart-pyramid {
  --fa: "\e0e6";
  --fa--fa: "\e0e6\e0e6";
}

.fa-sidebar {
  --fa: "\e24e";
  --fa--fa: "\e24e\e24e";
}

.fa-snowman-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b";
}

.fa-frosty-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b";
}

.fa-copy {
  --fa: "\f0c5";
  --fa--fa: "\f0c5\f0c5";
}

.fa-burger-glass {
  --fa: "\e0ce";
  --fa--fa: "\e0ce\e0ce";
}

.fa-volume-xmark {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-volume-mute {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-volume-times {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9";
}

.fa-hand-sparkles {
  --fa: "\e05d";
  --fa--fa: "\e05d\e05d";
}

.fa-bars-filter {
  --fa: "\e0ad";
  --fa--fa: "\e0ad\e0ad";
}

.fa-paintbrush-pencil {
  --fa: "\e206";
  --fa--fa: "\e206\e206";
}

.fa-party-bell {
  --fa: "\e31a";
  --fa--fa: "\e31a\e31a";
}

.fa-user-vneck-hair {
  --fa: "\e462";
  --fa--fa: "\e462\e462";
}

.fa-jack-o-lantern {
  --fa: "\f30e";
  --fa--fa: "\f30e\f30e";
}

.fa-grip {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d";
}

.fa-grip-horizontal {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d";
}

.fa-share-from-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d";
}

.fa-share-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d";
}

.fa-keynote {
  --fa: "\f66c";
  --fa--fa: "\f66c\f66c";
}

.fa-child-combatant {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0";
}

.fa-child-rifle {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0";
}

.fa-gun {
  --fa: "\e19b";
  --fa--fa: "\e19b\e19b";
}

.fa-square-phone {
  --fa: "\f098";
  --fa--fa: "\f098\f098";
}

.fa-phone-square {
  --fa: "\f098";
  --fa--fa: "\f098\f098";
}

.fa-hat-beach {
  --fa: "\e606";
  --fa--fa: "\e606\e606";
}

.fa-plus {
  --fa: "\+";
  --fa--fa: "\+\+";
}

.fa-add {
  --fa: "\+";
  --fa--fa: "\+\+";
}

.fa-expand {
  --fa: "\f065";
  --fa--fa: "\f065\f065";
}

.fa-computer {
  --fa: "\e4e5";
  --fa--fa: "\e4e5\e4e5";
}

.fa-fort {
  --fa: "\e486";
  --fa--fa: "\e486\e486";
}

.fa-cloud-check {
  --fa: "\e35c";
  --fa--fa: "\e35c\e35c";
}

.fa-xmark {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-close {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-multiply {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-remove {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-times {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d";
}

.fa-face-smirking {
  --fa: "\e397";
  --fa--fa: "\e397\e397";
}

.fa-arrows-up-down-left-right {
  --fa: "\f047";
  --fa--fa: "\f047\f047";
}

.fa-arrows {
  --fa: "\f047";
  --fa--fa: "\f047\f047";
}

.fa-chalkboard-user {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c";
}

.fa-chalkboard-teacher {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c";
}

.fa-rhombus {
  --fa: "\e23b";
  --fa--fa: "\e23b\e23b";
}

.fa-claw-marks {
  --fa: "\f6c2";
  --fa--fa: "\f6c2\f6c2";
}

.fa-peso-sign {
  --fa: "\e222";
  --fa--fa: "\e222\e222";
}

.fa-face-smile-tongue {
  --fa: "\e394";
  --fa--fa: "\e394\e394";
}

.fa-cart-circle-xmark {
  --fa: "\e3f4";
  --fa--fa: "\e3f4\e3f4";
}

.fa-building-shield {
  --fa: "\e4d8";
  --fa--fa: "\e4d8\e4d8";
}

.fa-circle-phone-flip {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c";
}

.fa-phone-circle-alt {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c";
}

.fa-baby {
  --fa: "\f77c";
  --fa--fa: "\f77c\f77c";
}

.fa-users-line {
  --fa: "\e592";
  --fa--fa: "\e592\e592";
}

.fa-quote-left {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d";
}

.fa-quote-left-alt {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d";
}

.fa-tractor {
  --fa: "\f722";
  --fa--fa: "\f722\f722";
}

.fa-down-from-bracket {
  --fa: "\e66b";
  --fa--fa: "\e66b\e66b";
}

.fa-key-skeleton {
  --fa: "\f6f3";
  --fa--fa: "\f6f3\f6f3";
}

.fa-trash-arrow-up {
  --fa: "\f829";
  --fa--fa: "\f829\f829";
}

.fa-trash-restore {
  --fa: "\f829";
  --fa--fa: "\f829\f829";
}

.fa-arrow-down-up-lock {
  --fa: "\e4b0";
  --fa--fa: "\e4b0\e4b0";
}

.fa-arrow-down-to-bracket {
  --fa: "\e094";
  --fa--fa: "\e094\e094";
}

.fa-lines-leaning {
  --fa: "\e51e";
  --fa--fa: "\e51e\e51e";
}

.fa-square-q {
  --fa: "\e27b";
  --fa--fa: "\e27b\e27b";
}

.fa-ruler-combined {
  --fa: "\f546";
  --fa--fa: "\f546\f546";
}

.fa-symbols {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e";
}

.fa-icons-alt {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e";
}

.fa-copyright {
  --fa: "\f1f9";
  --fa--fa: "\f1f9\f1f9";
}

.fa-flask-gear {
  --fa: "\e5f1";
  --fa--fa: "\e5f1\e5f1";
}

.fa-highlighter-line {
  --fa: "\e1af";
  --fa--fa: "\e1af\e1af";
}

.fa-bracket-square {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-bracket {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-bracket-left {
  --fa: "\[";
  --fa--fa: "\[\[";
}

.fa-island-tropical {
  --fa: "\f811";
  --fa--fa: "\f811\f811";
}

.fa-island-tree-palm {
  --fa: "\f811";
  --fa--fa: "\f811\f811";
}

.fa-arrow-right-from-line {
  --fa: "\f343";
  --fa--fa: "\f343\f343";
}

.fa-arrow-from-left {
  --fa: "\f343";
  --fa--fa: "\f343\f343";
}

.fa-h2 {
  --fa: "\f314";
  --fa--fa: "\f314\f314";
}

.fa-equals {
  --fa: "\=";
  --fa--fa: "\=\=";
}

.fa-cake-slice {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5";
}

.fa-shortcake {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5";
}

.fa-building-magnifying-glass {
  --fa: "\e61c";
  --fa--fa: "\e61c\e61c";
}

.fa-peanut {
  --fa: "\e430";
  --fa--fa: "\e430\e430";
}

.fa-wrench-simple {
  --fa: "\e2d1";
  --fa--fa: "\e2d1\e2d1";
}

.fa-blender {
  --fa: "\f517";
  --fa--fa: "\f517\f517";
}

.fa-teeth {
  --fa: "\f62e";
  --fa--fa: "\f62e\f62e";
}

.fa-tally-2 {
  --fa: "\e295";
  --fa--fa: "\e295\e295";
}

.fa-shekel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-ils {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-shekel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-sheqel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-sheqel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b";
}

.fa-cars {
  --fa: "\f85b";
  --fa--fa: "\f85b\f85b";
}

.fa-axe-battle {
  --fa: "\f6b3";
  --fa--fa: "\f6b3\f6b3";
}

.fa-user-hair-long {
  --fa: "\e45b";
  --fa--fa: "\e45b\e45b";
}

.fa-map {
  --fa: "\f279";
  --fa--fa: "\f279\f279";
}

.fa-arrow-left-from-arc {
  --fa: "\e615";
  --fa--fa: "\e615\e615";
}

.fa-file-circle-info {
  --fa: "\e493";
  --fa--fa: "\e493\e493";
}

.fa-face-disappointed {
  --fa: "\e36f";
  --fa--fa: "\e36f\e36f";
}

.fa-lasso-sparkles {
  --fa: "\e1c9";
  --fa--fa: "\e1c9\e1c9";
}

.fa-clock-eleven {
  --fa: "\e347";
  --fa--fa: "\e347\e347";
}

.fa-rocket {
  --fa: "\f135";
  --fa--fa: "\f135\f135";
}

.fa-siren-on {
  --fa: "\e02e";
  --fa--fa: "\e02e\e02e";
}

.fa-clock-ten {
  --fa: "\e354";
  --fa--fa: "\e354\e354";
}

.fa-candle-holder {
  --fa: "\f6bc";
  --fa--fa: "\f6bc\f6bc";
}

.fa-video-arrow-down-left {
  --fa: "\e2c8";
  --fa--fa: "\e2c8\e2c8";
}

.fa-photo-film {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c";
}

.fa-photo-video {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c";
}

.fa-floppy-disk-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180";
}

.fa-save-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180";
}

.fa-folder-minus {
  --fa: "\f65d";
  --fa--fa: "\f65d\f65d";
}

.fa-hexagon-nodes-bolt {
  --fa: "\e69a";
  --fa--fa: "\e69a\e69a";
}

.fa-planet-moon {
  --fa: "\e01f";
  --fa--fa: "\e01f\e01f";
}

.fa-face-eyes-xmarks {
  --fa: "\e374";
  --fa--fa: "\e374\e374";
}

.fa-chart-scatter {
  --fa: "\f7ee";
  --fa--fa: "\f7ee\f7ee";
}

.fa-circle-gf {
  --fa: "\e67f";
  --fa--fa: "\e67f\e67f";
}

.fa-display-arrow-down {
  --fa: "\e164";
  --fa--fa: "\e164\e164";
}

.fa-store {
  --fa: "\f54e";
  --fa--fa: "\f54e\f54e";
}

.fa-arrow-trend-up {
  --fa: "\e098";
  --fa--fa: "\e098\e098";
}

.fa-plug-circle-minus {
  --fa: "\e55e";
  --fa--fa: "\e55e\e55e";
}

.fa-olive-branch {
  --fa: "\e317";
  --fa--fa: "\e317\e317";
}

.fa-angle {
  --fa: "\e08c";
  --fa--fa: "\e08c\e08c";
}

.fa-vacuum-robot {
  --fa: "\e04e";
  --fa--fa: "\e04e\e04e";
}

.fa-sign-hanging {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9";
}

.fa-sign {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9";
}

.fa-square-divide {
  --fa: "\e26a";
  --fa--fa: "\e26a\e26a";
}

.fa-folder-check {
  --fa: "\e64e";
  --fa--fa: "\e64e\e64e";
}

.fa-signal-stream-slash {
  --fa: "\e250";
  --fa--fa: "\e250\e250";
}

.fa-bezier-curve {
  --fa: "\f55b";
  --fa--fa: "\f55b\f55b";
}

.fa-eye-dropper-half {
  --fa: "\e173";
  --fa--fa: "\e173\e173";
}

.fa-store-lock {
  --fa: "\e4a6";
  --fa--fa: "\e4a6\e4a6";
}

.fa-bell-slash {
  --fa: "\f1f6";
  --fa--fa: "\f1f6\f1f6";
}

.fa-cloud-bolt-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e";
}

.fa-thunderstorm-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e";
}

.fa-camera-slash {
  --fa: "\e0d9";
  --fa--fa: "\e0d9\e0d9";
}

.fa-comment-quote {
  --fa: "\e14c";
  --fa--fa: "\e14c\e14c";
}

.fa-tablet {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb";
}

.fa-tablet-android {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb";
}

.fa-school-flag {
  --fa: "\e56e";
  --fa--fa: "\e56e\e56e";
}

.fa-message-code {
  --fa: "\e1df";
  --fa--fa: "\e1df\e1df";
}

.fa-glass-half {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-glass-half-empty {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-glass-half-full {
  --fa: "\e192";
  --fa--fa: "\e192\e192";
}

.fa-fill {
  --fa: "\f575";
  --fa--fa: "\f575\f575";
}

.fa-message-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7";
}

.fa-comment-alt-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7";
}

.fa-angle-up {
  --fa: "\f106";
  --fa--fa: "\f106\f106";
}

.fa-dinosaur {
  --fa: "\e5fe";
  --fa--fa: "\e5fe\e5fe";
}

.fa-drumstick-bite {
  --fa: "\f6d7";
  --fa--fa: "\f6d7\f6d7";
}

.fa-link-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc";
}

.fa-chain-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc";
}

.fa-holly-berry {
  --fa: "\f7aa";
  --fa--fa: "\f7aa\f7aa";
}

.fa-nose {
  --fa: "\e5bd";
  --fa--fa: "\e5bd\e5bd";
}

.fa-arrow-left-to-arc {
  --fa: "\e616";
  --fa--fa: "\e616\e616";
}

.fa-chevron-left {
  --fa: "\f053";
  --fa--fa: "\f053\f053";
}

.fa-bacteria {
  --fa: "\e059";
  --fa--fa: "\e059\e059";
}

.fa-clouds {
  --fa: "\f744";
  --fa--fa: "\f744\f744";
}

.fa-money-bill-simple {
  --fa: "\e1f1";
  --fa--fa: "\e1f1\e1f1";
}

.fa-hand-lizard {
  --fa: "\f258";
  --fa--fa: "\f258\f258";
}

.fa-table-pivot {
  --fa: "\e291";
  --fa--fa: "\e291\e291";
}

.fa-filter-slash {
  --fa: "\e17d";
  --fa--fa: "\e17d\e17d";
}

.fa-trash-can-undo {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-trash-can-arrow-turn-left {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-trash-undo-alt {
  --fa: "\f896";
  --fa--fa: "\f896\f896";
}

.fa-notdef {
  --fa: "\e1fe";
  --fa--fa: "\e1fe\e1fe";
}

.fa-disease {
  --fa: "\f7fa";
  --fa--fa: "\f7fa\f7fa";
}

.fa-person-to-door {
  --fa: "\e433";
  --fa--fa: "\e433\e433";
}

.fa-turntable {
  --fa: "\f8e4";
  --fa--fa: "\f8e4\f8e4";
}

.fa-briefcase-medical {
  --fa: "\f469";
  --fa--fa: "\f469\f469";
}

.fa-genderless {
  --fa: "\f22d";
  --fa--fa: "\f22d\f22d";
}

.fa-chevron-right {
  --fa: "\f054";
  --fa--fa: "\f054\f054";
}

.fa-signal-weak {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c";
}

.fa-signal-1 {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c";
}

.fa-clock-five {
  --fa: "\e349";
  --fa--fa: "\e349\e349";
}

.fa-retweet {
  --fa: "\f079";
  --fa--fa: "\f079\f079";
}

.fa-car-rear {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de";
}

.fa-car-alt {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de";
}

.fa-pump-soap {
  --fa: "\e06b";
  --fa--fa: "\e06b\e06b";
}

.fa-computer-classic {
  --fa: "\f8b1";
  --fa--fa: "\f8b1\f8b1";
}

.fa-frame {
  --fa: "\e495";
  --fa--fa: "\e495\e495";
}

.fa-video-slash {
  --fa: "\f4e2";
  --fa--fa: "\f4e2\f4e2";
}

.fa-battery-quarter {
  --fa: "\f243";
  --fa--fa: "\f243\f243";
}

.fa-battery-2 {
  --fa: "\f243";
  --fa--fa: "\f243\f243";
}

.fa-ellipsis-stroke {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b";
}

.fa-ellipsis-h-alt {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b";
}

.fa-radio {
  --fa: "\f8d7";
  --fa--fa: "\f8d7\f8d7";
}

.fa-baby-carriage {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d";
}

.fa-carriage-baby {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d";
}

.fa-face-expressionless {
  --fa: "\e373";
  --fa--fa: "\e373\e373";
}

.fa-down-to-dotted-line {
  --fa: "\e408";
  --fa--fa: "\e408\e408";
}

.fa-cloud-music {
  --fa: "\f8ae";
  --fa--fa: "\f8ae\f8ae";
}

.fa-traffic-light {
  --fa: "\f637";
  --fa--fa: "\f637\f637";
}

.fa-cloud-minus {
  --fa: "\e35d";
  --fa--fa: "\e35d\e35d";
}

.fa-thermometer {
  --fa: "\f491";
  --fa--fa: "\f491\f491";
}

.fa-shield-minus {
  --fa: "\e249";
  --fa--fa: "\e249\e249";
}

.fa-vr-cardboard {
  --fa: "\f729";
  --fa--fa: "\f729\f729";
}

.fa-car-tilt {
  --fa: "\f5e5";
  --fa--fa: "\f5e5\f5e5";
}

.fa-gauge-circle-minus {
  --fa: "\e497";
  --fa--fa: "\e497\e497";
}

.fa-brightness-low {
  --fa: "\e0ca";
  --fa--fa: "\e0ca\e0ca";
}

.fa-hand-middle-finger {
  --fa: "\f806";
  --fa--fa: "\f806\f806";
}

.fa-percent {
  --fa: "\%";
  --fa--fa: "\%\%";
}

.fa-percentage {
  --fa: "\%";
  --fa--fa: "\%\%";
}

.fa-truck-moving {
  --fa: "\f4df";
  --fa--fa: "\f4df\f4df";
}

.fa-glass-water-droplet {
  --fa: "\e4f5";
  --fa--fa: "\e4f5\e4f5";
}

.fa-conveyor-belt {
  --fa: "\f46e";
  --fa--fa: "\f46e\f46e";
}

.fa-location-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606";
}

.fa-map-marker-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606";
}

.fa-coin-vertical {
  --fa: "\e3fd";
  --fa--fa: "\e3fd\e3fd";
}

.fa-display {
  --fa: "\e163";
  --fa--fa: "\e163\e163";
}

.fa-person-sign {
  --fa: "\f757";
  --fa--fa: "\f757\f757";
}

.fa-face-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118";
}

.fa-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118";
}

.fa-phone-hangup {
  --fa: "\e225";
  --fa--fa: "\e225\e225";
}

.fa-signature-slash {
  --fa: "\e3cb";
  --fa--fa: "\e3cb\e3cb";
}

.fa-thumbtack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d";
}

.fa-thumb-tack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d";
}

.fa-wheat-slash {
  --fa: "\e339";
  --fa--fa: "\e339\e339";
}

.fa-trophy {
  --fa: "\f091";
  --fa--fa: "\f091\f091";
}

.fa-clouds-sun {
  --fa: "\f746";
  --fa--fa: "\f746\f746";
}

.fa-person-praying {
  --fa: "\f683";
  --fa--fa: "\f683\f683";
}

.fa-pray {
  --fa: "\f683";
  --fa--fa: "\f683\f683";
}

.fa-hammer {
  --fa: "\f6e3";
  --fa--fa: "\f6e3\f6e3";
}

.fa-face-vomit {
  --fa: "\e3a0";
  --fa--fa: "\e3a0\e3a0";
}

.fa-speakers {
  --fa: "\f8e0";
  --fa--fa: "\f8e0\f8e0";
}

.fa-tty-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9";
}

.fa-teletype-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9";
}

.fa-mug-tea-saucer {
  --fa: "\e1f5";
  --fa--fa: "\e1f5\e1f5";
}

.fa-diagram-lean-canvas {
  --fa: "\e156";
  --fa--fa: "\e156\e156";
}

.fa-alt {
  --fa: "\e08a";
  --fa--fa: "\e08a\e08a";
}

.fa-dial {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b";
}

.fa-dial-med-high {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b";
}

.fa-hand-peace {
  --fa: "\f25b";
  --fa--fa: "\f25b\f25b";
}

.fa-circle-trash {
  --fa: "\e126";
  --fa--fa: "\e126\e126";
}

.fa-trash-circle {
  --fa: "\e126";
  --fa--fa: "\e126\e126";
}

.fa-rotate {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1";
}

.fa-sync-alt {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1";
}

.fa-circle-quarters {
  --fa: "\e3f8";
  --fa--fa: "\e3f8\e3f8";
}

.fa-spinner {
  --fa: "\f110";
  --fa--fa: "\f110\f110";
}

.fa-tower-control {
  --fa: "\e2a2";
  --fa--fa: "\e2a2\e2a2";
}

.fa-arrow-up-triangle-square {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a";
}

.fa-sort-shapes-up {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a";
}

.fa-whale {
  --fa: "\f72c";
  --fa--fa: "\f72c\f72c";
}

.fa-robot {
  --fa: "\f544";
  --fa--fa: "\f544\f544";
}

.fa-peace {
  --fa: "\f67c";
  --fa--fa: "\f67c\f67c";
}

.fa-party-horn {
  --fa: "\e31b";
  --fa--fa: "\e31b\e31b";
}

.fa-gears {
  --fa: "\f085";
  --fa--fa: "\f085\f085";
}

.fa-cogs {
  --fa: "\f085";
  --fa--fa: "\f085\f085";
}

.fa-sun-bright {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f";
}

.fa-sun-alt {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f";
}

.fa-warehouse {
  --fa: "\f494";
  --fa--fa: "\f494\f494";
}

.fa-conveyor-belt-arm {
  --fa: "\e5f8";
  --fa--fa: "\e5f8\e5f8";
}

.fa-lock-keyhole-open {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2";
}

.fa-lock-open-alt {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2";
}

.fa-square-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-box-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-square-wine-glass-crack {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b";
}

.fa-arrow-up-right-dots {
  --fa: "\e4b7";
  --fa--fa: "\e4b7\e4b7";
}

.fa-square-n {
  --fa: "\e277";
  --fa--fa: "\e277\e277";
}

.fa-splotch {
  --fa: "\f5bc";
  --fa--fa: "\f5bc\f5bc";
}

.fa-face-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584";
}

.fa-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584";
}

.fa-meter {
  --fa: "\e1e8";
  --fa--fa: "\e1e8\e1e8";
}

.fa-mandolin {
  --fa: "\f6f9";
  --fa--fa: "\f6f9\f6f9";
}

.fa-dice-four {
  --fa: "\f524";
  --fa--fa: "\f524\f524";
}

.fa-sim-card {
  --fa: "\f7c4";
  --fa--fa: "\f7c4\f7c4";
}

.fa-transgender {
  --fa: "\f225";
  --fa--fa: "\f225\f225";
}

.fa-transgender-alt {
  --fa: "\f225";
  --fa--fa: "\f225\f225";
}

.fa-mercury {
  --fa: "\f223";
  --fa--fa: "\f223\f223";
}

.fa-up-from-bracket {
  --fa: "\e590";
  --fa--fa: "\e590\e590";
}

.fa-knife-kitchen {
  --fa: "\f6f5";
  --fa--fa: "\f6f5\f6f5";
}

.fa-border-right {
  --fa: "\f852";
  --fa--fa: "\f852\f852";
}

.fa-arrow-turn-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149";
}

.fa-level-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149";
}

.fa-spade {
  --fa: "\f2f4";
  --fa--fa: "\f2f4\f2f4";
}

.fa-card-spade {
  --fa: "\e3ec";
  --fa--fa: "\e3ec\e3ec";
}

.fa-line-columns {
  --fa: "\f870";
  --fa--fa: "\f870\f870";
}

.fa-ant {
  --fa: "\e680";
  --fa--fa: "\e680\e680";
}

.fa-arrow-right-to-line {
  --fa: "\f340";
  --fa--fa: "\f340\f340";
}

.fa-arrow-to-right {
  --fa: "\f340";
  --fa--fa: "\f340\f340";
}

.fa-person-falling-burst {
  --fa: "\e547";
  --fa--fa: "\e547\e547";
}

.fa-flag-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456";
}

.fa-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456";
}

.fa-conveyor-belt-empty {
  --fa: "\e150";
  --fa--fa: "\e150\e150";
}

.fa-user-group-simple {
  --fa: "\e603";
  --fa--fa: "\e603\e603";
}

.fa-award {
  --fa: "\f559";
  --fa--fa: "\f559\f559";
}

.fa-ticket-simple {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff";
}

.fa-ticket-alt {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff";
}

.fa-building {
  --fa: "\f1ad";
  --fa--fa: "\f1ad\f1ad";
}

.fa-angles-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100";
}

.fa-angle-double-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100";
}

.fa-camcorder {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8";
}

.fa-video-handheld {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8";
}

.fa-pancakes {
  --fa: "\e42d";
  --fa--fa: "\e42d\e42d";
}

.fa-album-circle-user {
  --fa: "\e48d";
  --fa--fa: "\e48d\e48d";
}

.fa-subtitles-slash {
  --fa: "\e610";
  --fa--fa: "\e610\e610";
}

.fa-qrcode {
  --fa: "\f029";
  --fa--fa: "\f029\f029";
}

.fa-dice-d10 {
  --fa: "\f6cd";
  --fa--fa: "\f6cd\f6cd";
}

.fa-fireplace {
  --fa: "\f79a";
  --fa--fa: "\f79a\f79a";
}

.fa-browser {
  --fa: "\f37e";
  --fa--fa: "\f37e\f37e";
}

.fa-pen-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618";
}

.fa-pencil-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618";
}

.fa-fish-cooked {
  --fa: "\f7fe";
  --fa--fa: "\f7fe\f7fe";
}

.fa-chair-office {
  --fa: "\f6c1";
  --fa--fa: "\f6c1\f6c1";
}

.fa-magnifying-glass-music {
  --fa: "\e65f";
  --fa--fa: "\e65f\e65f";
}

.fa-nesting-dolls {
  --fa: "\e3ba";
  --fa--fa: "\e3ba\e3ba";
}

.fa-clock-rotate-left {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da";
}

.fa-history {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da";
}

.fa-trumpet {
  --fa: "\f8e3";
  --fa--fa: "\f8e3\f8e3";
}

.fa-face-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583";
}

.fa-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583";
}

.fa-fire-smoke {
  --fa: "\f74b";
  --fa--fa: "\f74b\f74b";
}

.fa-phone-missed {
  --fa: "\e226";
  --fa--fa: "\e226\e226";
}

.fa-file-export {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e";
}

.fa-arrow-right-from-file {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e";
}

.fa-shield {
  --fa: "\f132";
  --fa--fa: "\f132\f132";
}

.fa-shield-blank {
  --fa: "\f132";
  --fa--fa: "\f132\f132";
}

.fa-arrow-up-short-wide {
  --fa: "\f885";
  --fa--fa: "\f885\f885";
}

.fa-sort-amount-up-alt {
  --fa: "\f885";
  --fa--fa: "\f885\f885";
}

.fa-arrows-repeat-1 {
  --fa: "\f366";
  --fa--fa: "\f366\f366";
}

.fa-repeat-1-alt {
  --fa: "\f366";
  --fa--fa: "\f366\f366";
}

.fa-gun-slash {
  --fa: "\e19c";
  --fa--fa: "\e19c\e19c";
}

.fa-avocado {
  --fa: "\e0aa";
  --fa--fa: "\e0aa\e0aa";
}

.fa-binary {
  --fa: "\e33b";
  --fa--fa: "\e33b\e33b";
}

.fa-comment-nodes {
  --fa: "\e696";
  --fa--fa: "\e696\e696";
}

.fa-glasses-round {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5";
}

.fa-glasses-alt {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5";
}

.fa-phone-plus {
  --fa: "\f4d2";
  --fa--fa: "\f4d2\f4d2";
}

.fa-ditto {
  --fa: "\"";
  --fa--fa: "\"\"";
}

.fa-person-seat {
  --fa: "\e21e";
  --fa--fa: "\e21e\e21e";
}

.fa-house-medical {
  --fa: "\e3b2";
  --fa--fa: "\e3b2\e3b2";
}

.fa-golf-ball-tee {
  --fa: "\f450";
  --fa--fa: "\f450\f450";
}

.fa-golf-ball {
  --fa: "\f450";
  --fa--fa: "\f450\f450";
}

.fa-circle-chevron-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137";
}

.fa-chevron-circle-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137";
}

.fa-house-chimney-window {
  --fa: "\e00d";
  --fa--fa: "\e00d\e00d";
}

.fa-scythe {
  --fa: "\f710";
  --fa--fa: "\f710\f710";
}

.fa-pen-nib {
  --fa: "\f5ad";
  --fa--fa: "\f5ad\f5ad";
}

.fa-ban-parking {
  --fa: "\f616";
  --fa--fa: "\f616\f616";
}

.fa-parking-circle-slash {
  --fa: "\f616";
  --fa--fa: "\f616\f616";
}

.fa-tent-arrow-turn-left {
  --fa: "\e580";
  --fa--fa: "\e580\e580";
}

.fa-face-diagonal-mouth {
  --fa: "\e47e";
  --fa--fa: "\e47e\e47e";
}

.fa-diagram-cells {
  --fa: "\e475";
  --fa--fa: "\e475\e475";
}

.fa-cricket-bat-ball {
  --fa: "\f449";
  --fa--fa: "\f449\f449";
}

.fa-cricket {
  --fa: "\f449";
  --fa--fa: "\f449\f449";
}

.fa-tents {
  --fa: "\e582";
  --fa--fa: "\e582\e582";
}

.fa-wand-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0";
}

.fa-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0";
}

.fa-dog {
  --fa: "\f6d3";
  --fa--fa: "\f6d3\f6d3";
}

.fa-pen-line {
  --fa: "\e212";
  --fa--fa: "\e212\e212";
}

.fa-atom-simple {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3";
}

.fa-atom-alt {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3";
}

.fa-ampersand {
  --fa: "\&";
  --fa--fa: "\&\&";
}

.fa-carrot {
  --fa: "\f787";
  --fa--fa: "\f787\f787";
}

.fa-arrow-up-from-line {
  --fa: "\f342";
  --fa--fa: "\f342\f342";
}

.fa-arrow-from-bottom {
  --fa: "\f342";
  --fa--fa: "\f342\f342";
}

.fa-moon {
  --fa: "\f186";
  --fa--fa: "\f186\f186";
}

.fa-pen-slash {
  --fa: "\e213";
  --fa--fa: "\e213\e213";
}

.fa-wine-glass-empty {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce";
}

.fa-wine-glass-alt {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce";
}

.fa-square-star {
  --fa: "\e27f";
  --fa--fa: "\e27f\e27f";
}

.fa-cheese {
  --fa: "\f7ef";
  --fa--fa: "\f7ef\f7ef";
}

.fa-send-backward {
  --fa: "\f87f";
  --fa--fa: "\f87f\f87f";
}

.fa-yin-yang {
  --fa: "\f6ad";
  --fa--fa: "\f6ad\f6ad";
}

.fa-music {
  --fa: "\f001";
  --fa--fa: "\f001\f001";
}

.fa-compass-slash {
  --fa: "\f5e9";
  --fa--fa: "\f5e9\f5e9";
}

.fa-clock-one {
  --fa: "\e34e";
  --fa--fa: "\e34e\e34e";
}

.fa-file-music {
  --fa: "\f8b6";
  --fa--fa: "\f8b6\f8b6";
}

.fa-code-commit {
  --fa: "\f386";
  --fa--fa: "\f386\f386";
}

.fa-temperature-low {
  --fa: "\f76b";
  --fa--fa: "\f76b\f76b";
}

.fa-person-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a";
}

.fa-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a";
}

.fa-display-chart-up-circle-currency {
  --fa: "\e5e5";
  --fa--fa: "\e5e5\e5e5";
}

.fa-skeleton {
  --fa: "\f620";
  --fa--fa: "\f620\f620";
}

.fa-circle-g {
  --fa: "\e10f";
  --fa--fa: "\e10f\e10f";
}

.fa-circle-arrow-up-left {
  --fa: "\e0fb";
  --fa--fa: "\e0fb\e0fb";
}

.fa-coin-blank {
  --fa: "\e3fb";
  --fa--fa: "\e3fb\e3fb";
}

.fa-broom {
  --fa: "\f51a";
  --fa--fa: "\f51a\f51a";
}

.fa-vacuum {
  --fa: "\e04d";
  --fa--fa: "\e04d\e04d";
}

.fa-shield-heart {
  --fa: "\e574";
  --fa--fa: "\e574\e574";
}

.fa-card-heart {
  --fa: "\e3eb";
  --fa--fa: "\e3eb\e3eb";
}

.fa-lightbulb-cfl-on {
  --fa: "\e5a7";
  --fa--fa: "\e5a7\e5a7";
}

.fa-melon {
  --fa: "\e310";
  --fa--fa: "\e310\e310";
}

.fa-gopuram {
  --fa: "\f664";
  --fa--fa: "\f664\f664";
}

.fa-earth-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b";
}

.fa-globe-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b";
}

.fa-container-storage {
  --fa: "\f4b7";
  --fa--fa: "\f4b7\f4b7";
}

.fa-face-pouting {
  --fa: "\e387";
  --fa--fa: "\e387\e387";
}

.fa-square-xmark {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-times-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-xmark-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3";
}

.fa-face-explode {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe";
}

.fa-exploding-head {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe";
}

.fa-hashtag {
  --fa: "\#";
  --fa--fa: "\#\#";
}

.fa-up-right-and-down-left-from-center {
  --fa: "\f424";
  --fa--fa: "\f424\f424";
}

.fa-expand-alt {
  --fa: "\f424";
  --fa--fa: "\f424\f424";
}

.fa-oil-can {
  --fa: "\f613";
  --fa--fa: "\f613\f613";
}

.fa-t {
  --fa: "T";
  --fa--fa: "TT";
}

.fa-transformer-bolt {
  --fa: "\e2a4";
  --fa--fa: "\e2a4\e2a4";
}

.fa-hippo {
  --fa: "\f6ed";
  --fa--fa: "\f6ed\f6ed";
}

.fa-chart-column {
  --fa: "\e0e3";
  --fa--fa: "\e0e3\e0e3";
}

.fa-cassette-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec";
}

.fa-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec";
}

.fa-infinity {
  --fa: "\f534";
  --fa--fa: "\f534\f534";
}

.fa-vial-circle-check {
  --fa: "\e596";
  --fa--fa: "\e596\e596";
}

.fa-chimney {
  --fa: "\f78b";
  --fa--fa: "\f78b\f78b";
}

.fa-object-intersect {
  --fa: "\e49d";
  --fa--fa: "\e49d\e49d";
}

.fa-person-arrow-down-to-line {
  --fa: "\e538";
  --fa--fa: "\e538\e538";
}

.fa-voicemail {
  --fa: "\f897";
  --fa--fa: "\f897\f897";
}

.fa-block-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db";
}

.fa-wall-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db";
}

.fa-fan {
  --fa: "\f863";
  --fa--fa: "\f863\f863";
}

.fa-bags-shopping {
  --fa: "\f847";
  --fa--fa: "\f847\f847";
}

.fa-paragraph-left {
  --fa: "\f878";
  --fa--fa: "\f878\f878";
}

.fa-paragraph-rtl {
  --fa: "\f878";
  --fa--fa: "\f878\f878";
}

.fa-person-walking-luggage {
  --fa: "\e554";
  --fa--fa: "\e554\e554";
}

.fa-caravan-simple {
  --fa: "\e000";
  --fa--fa: "\e000\e000";
}

.fa-caravan-alt {
  --fa: "\e000";
  --fa--fa: "\e000\e000";
}

.fa-turtle {
  --fa: "\f726";
  --fa--fa: "\f726\f726";
}

.fa-pencil-mechanical {
  --fa: "\e5ca";
  --fa--fa: "\e5ca\e5ca";
}

.fa-up-down {
  --fa: "\f338";
  --fa--fa: "\f338\f338";
}

.fa-arrows-alt-v {
  --fa: "\f338";
  --fa--fa: "\f338\f338";
}

.fa-cloud-moon-rain {
  --fa: "\f73c";
  --fa--fa: "\f73c\f73c";
}

.fa-booth-curtain {
  --fa: "\f734";
  --fa--fa: "\f734\f734";
}

.fa-calendar {
  --fa: "\f133";
  --fa--fa: "\f133\f133";
}

.fa-box-heart {
  --fa: "\f49d";
  --fa--fa: "\f49d\f49d";
}

.fa-trailer {
  --fa: "\e041";
  --fa--fa: "\e041\e041";
}

.fa-user-doctor-message {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e";
}

.fa-user-md-chat {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e";
}

.fa-bahai {
  --fa: "\f666";
  --fa--fa: "\f666\f666";
}

.fa-haykal {
  --fa: "\f666";
  --fa--fa: "\f666\f666";
}

.fa-lighthouse {
  --fa: "\e612";
  --fa--fa: "\e612\e612";
}

.fa-amp-guitar {
  --fa: "\f8a1";
  --fa--fa: "\f8a1\f8a1";
}

.fa-sd-card {
  --fa: "\f7c2";
  --fa--fa: "\f7c2\f7c2";
}

.fa-volume-slash {
  --fa: "\f2e2";
  --fa--fa: "\f2e2\f2e2";
}

.fa-border-bottom {
  --fa: "\f84d";
  --fa--fa: "\f84d\f84d";
}

.fa-wifi-weak {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa";
}

.fa-wifi-1 {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa";
}

.fa-dragon {
  --fa: "\f6d5";
  --fa--fa: "\f6d5\f6d5";
}

.fa-shoe-prints {
  --fa: "\f54b";
  --fa--fa: "\f54b\f54b";
}

.fa-circle-plus {
  --fa: "\f055";
  --fa--fa: "\f055\f055";
}

.fa-plus-circle {
  --fa: "\f055";
  --fa--fa: "\f055\f055";
}

.fa-face-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b";
}

.fa-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b";
}

.fa-hand-holding {
  --fa: "\f4bd";
  --fa--fa: "\f4bd\f4bd";
}

.fa-plug-circle-exclamation {
  --fa: "\e55d";
  --fa--fa: "\e55d\e55d";
}

.fa-link-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-chain-broken {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-chain-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-unlink {
  --fa: "\f127";
  --fa--fa: "\f127\f127";
}

.fa-clone {
  --fa: "\f24d";
  --fa--fa: "\f24d\f24d";
}

.fa-person-walking-arrow-loop-left {
  --fa: "\e551";
  --fa--fa: "\e551\e551";
}

.fa-arrow-up-z-a {
  --fa: "\f882";
  --fa--fa: "\f882\f882";
}

.fa-sort-alpha-up-alt {
  --fa: "\f882";
  --fa--fa: "\f882\f882";
}

.fa-fire-flame-curved {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4";
}

.fa-fire-alt {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4";
}

.fa-tornado {
  --fa: "\f76f";
  --fa--fa: "\f76f\f76f";
}

.fa-file-circle-plus {
  --fa: "\e494";
  --fa--fa: "\e494\e494";
}

.fa-delete-right {
  --fa: "\e154";
  --fa--fa: "\e154\e154";
}

.fa-book-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687";
}

.fa-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687";
}

.fa-circle-quarter {
  --fa: "\e11f";
  --fa--fa: "\e11f\e11f";
}

.fa-anchor {
  --fa: "\f13d";
  --fa--fa: "\f13d\f13d";
}

.fa-border-all {
  --fa: "\f84c";
  --fa--fa: "\f84c\f84c";
}

.fa-function {
  --fa: "\f661";
  --fa--fa: "\f661\f661";
}

.fa-face-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556";
}

.fa-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556";
}

.fa-people-simple {
  --fa: "\e21b";
  --fa--fa: "\e21b\e21b";
}

.fa-cookie-bite {
  --fa: "\f564";
  --fa--fa: "\f564\f564";
}

.fa-arrow-trend-down {
  --fa: "\e097";
  --fa--fa: "\e097\e097";
}

.fa-rss {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e";
}

.fa-feed {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e";
}

.fa-face-monocle {
  --fa: "\e380";
  --fa--fa: "\e380\e380";
}

.fa-draw-polygon {
  --fa: "\f5ee";
  --fa--fa: "\f5ee\f5ee";
}

.fa-scale-balanced {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e";
}

.fa-balance-scale {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e";
}

.fa-calendar-lines {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5";
}

.fa-calendar-note {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5";
}

.fa-arrow-down-big-small {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c";
}

.fa-sort-size-down {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c";
}

.fa-gauge-simple-high {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-tachometer {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-tachometer-fast {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a";
}

.fa-do-not-enter {
  --fa: "\f5ec";
  --fa--fa: "\f5ec\f5ec";
}

.fa-shower {
  --fa: "\f2cc";
  --fa--fa: "\f2cc\f2cc";
}

.fa-dice-d8 {
  --fa: "\f6d2";
  --fa--fa: "\f6d2\f6d2";
}

.fa-desktop {
  --fa: "\f390";
  --fa--fa: "\f390\f390";
}

.fa-desktop-alt {
  --fa: "\f390";
  --fa--fa: "\f390\f390";
}

.fa-m {
  --fa: "M";
  --fa--fa: "MM";
}

.fa-spinner-scale {
  --fa: "\e62a";
  --fa--fa: "\e62a\e62a";
}

.fa-grip-dots-vertical {
  --fa: "\e411";
  --fa--fa: "\e411\e411";
}

.fa-face-viewfinder {
  --fa: "\e2ff";
  --fa--fa: "\e2ff\e2ff";
}

.fa-soft-serve {
  --fa: "\e400";
  --fa--fa: "\e400\e400";
}

.fa-creemee {
  --fa: "\e400";
  --fa--fa: "\e400\e400";
}

.fa-h5 {
  --fa: "\e412";
  --fa--fa: "\e412\e412";
}

.fa-hand-back-point-down {
  --fa: "\e19e";
  --fa--fa: "\e19e\e19e";
}

.fa-table-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b";
}

.fa-th-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b";
}

.fa-basket-shopping-minus {
  --fa: "\e652";
  --fa--fa: "\e652\e652";
}

.fa-comment-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd";
}

.fa-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd";
}

.fa-rectangle {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa";
}

.fa-rectangle-landscape {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa";
}

.fa-clipboard-list-check {
  --fa: "\f737";
  --fa--fa: "\f737\f737";
}

.fa-turkey {
  --fa: "\f725";
  --fa--fa: "\f725\f725";
}

.fa-book {
  --fa: "\f02d";
  --fa--fa: "\f02d\f02d";
}

.fa-user-plus {
  --fa: "\f234";
  --fa--fa: "\f234\f234";
}

.fa-ice-skate {
  --fa: "\f7ac";
  --fa--fa: "\f7ac\f7ac";
}

.fa-check {
  --fa: "\f00c";
  --fa--fa: "\f00c\f00c";
}

.fa-battery-three-quarters {
  --fa: "\f241";
  --fa--fa: "\f241\f241";
}

.fa-battery-4 {
  --fa: "\f241";
  --fa--fa: "\f241\f241";
}

.fa-tomato {
  --fa: "\e330";
  --fa--fa: "\e330\e330";
}

.fa-sword-laser {
  --fa: "\e03b";
  --fa--fa: "\e03b\e03b";
}

.fa-house-circle-check {
  --fa: "\e509";
  --fa--fa: "\e509\e509";
}

.fa-buildings {
  --fa: "\e0cc";
  --fa--fa: "\e0cc\e0cc";
}

.fa-angle-left {
  --fa: "\f104";
  --fa--fa: "\f104\f104";
}

.fa-cart-flatbed-boxes {
  --fa: "\f475";
  --fa--fa: "\f475\f475";
}

.fa-dolly-flatbed-alt {
  --fa: "\f475";
  --fa--fa: "\f475\f475";
}

.fa-diagram-successor {
  --fa: "\e47a";
  --fa--fa: "\e47a\e47a";
}

.fa-truck-arrow-right {
  --fa: "\e58b";
  --fa--fa: "\e58b\e58b";
}

.fa-square-w {
  --fa: "\e285";
  --fa--fa: "\e285\e285";
}

.fa-arrows-split-up-and-left {
  --fa: "\e4bc";
  --fa--fa: "\e4bc\e4bc";
}

.fa-lamp {
  --fa: "\f4ca";
  --fa--fa: "\f4ca\f4ca";
}

.fa-airplay {
  --fa: "\e089";
  --fa--fa: "\e089\e089";
}

.fa-hand-fist {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de";
}

.fa-fist-raised {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de";
}

.fa-shield-quartered {
  --fa: "\e575";
  --fa--fa: "\e575\e575";
}

.fa-slash-forward {
  --fa: "\/";
  --fa--fa: "\/\/";
}

.fa-location-pen {
  --fa: "\f607";
  --fa--fa: "\f607\f607";
}

.fa-map-marker-edit {
  --fa: "\f607";
  --fa--fa: "\f607\f607";
}

.fa-cloud-moon {
  --fa: "\f6c3";
  --fa--fa: "\f6c3\f6c3";
}

.fa-pot-food {
  --fa: "\e43f";
  --fa--fa: "\e43f\e43f";
}

.fa-briefcase {
  --fa: "\f0b1";
  --fa--fa: "\f0b1\f0b1";
}

.fa-person-falling {
  --fa: "\e546";
  --fa--fa: "\e546\e546";
}

.fa-image-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0";
}

.fa-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0";
}

.fa-user-tag {
  --fa: "\f507";
  --fa--fa: "\f507\f507";
}

.fa-rug {
  --fa: "\e569";
  --fa--fa: "\e569\e569";
}

.fa-print-slash {
  --fa: "\f686";
  --fa--fa: "\f686\f686";
}

.fa-earth-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2";
}

.fa-globe-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2";
}

.fa-cart-flatbed-suitcase {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d";
}

.fa-luggage-cart {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d";
}

.fa-hand-back-point-ribbon {
  --fa: "\e1a0";
  --fa--fa: "\e1a0\e1a0";
}

.fa-rectangle-xmark {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-rectangle-times {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-times-rectangle {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-window-close {
  --fa: "\f410";
  --fa--fa: "\f410\f410";
}

.fa-tire-rugged {
  --fa: "\f634";
  --fa--fa: "\f634\f634";
}

.fa-lightbulb-dollar {
  --fa: "\f670";
  --fa--fa: "\f670\f670";
}

.fa-cowbell {
  --fa: "\f8b3";
  --fa--fa: "\f8b3\f8b3";
}

.fa-baht-sign {
  --fa: "\e0ac";
  --fa--fa: "\e0ac\e0ac";
}

.fa-corner {
  --fa: "\e3fe";
  --fa--fa: "\e3fe\e3fe";
}

.fa-chevrons-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324";
}

.fa-chevron-double-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324";
}

.fa-book-open {
  --fa: "\f518";
  --fa--fa: "\f518\f518";
}

.fa-book-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a";
}

.fa-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a";
}

.fa-inhaler {
  --fa: "\f5f9";
  --fa--fa: "\f5f9\f5f9";
}

.fa-handcuffs {
  --fa: "\e4f8";
  --fa--fa: "\e4f8\e4f8";
}

.fa-snake {
  --fa: "\f716";
  --fa--fa: "\f716\f716";
}

.fa-triangle-exclamation {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-exclamation-triangle {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-warning {
  --fa: "\f071";
  --fa--fa: "\f071\f071";
}

.fa-note-medical {
  --fa: "\e200";
  --fa--fa: "\e200\e200";
}

.fa-database {
  --fa: "\f1c0";
  --fa--fa: "\f1c0\f1c0";
}

.fa-down-left {
  --fa: "\e16a";
  --fa--fa: "\e16a\e16a";
}

.fa-share {
  --fa: "\f064";
  --fa--fa: "\f064\f064";
}

.fa-mail-forward {
  --fa: "\f064";
  --fa--fa: "\f064\f064";
}

.fa-face-thinking {
  --fa: "\e39b";
  --fa--fa: "\e39b\e39b";
}

.fa-turn-down-right {
  --fa: "\e455";
  --fa--fa: "\e455\e455";
}

.fa-bottle-droplet {
  --fa: "\e4c4";
  --fa--fa: "\e4c4\e4c4";
}

.fa-mask-face {
  --fa: "\e1d7";
  --fa--fa: "\e1d7\e1d7";
}

.fa-hill-rockslide {
  --fa: "\e508";
  --fa--fa: "\e508\e508";
}

.fa-scanner-keyboard {
  --fa: "\f489";
  --fa--fa: "\f489\f489";
}

.fa-circle-o {
  --fa: "\e119";
  --fa--fa: "\e119\e119";
}

.fa-grid-horizontal {
  --fa: "\e307";
  --fa--fa: "\e307\e307";
}

.fa-message-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650";
}

.fa-comment-alt-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650";
}

.fa-right-left {
  --fa: "\f362";
  --fa--fa: "\f362\f362";
}

.fa-exchange-alt {
  --fa: "\f362";
  --fa--fa: "\f362\f362";
}

.fa-columns-3 {
  --fa: "\e361";
  --fa--fa: "\e361\e361";
}

.fa-paper-plane {
  --fa: "\f1d8";
  --fa--fa: "\f1d8\f1d8";
}

.fa-road-circle-exclamation {
  --fa: "\e565";
  --fa--fa: "\e565\e565";
}

.fa-dungeon {
  --fa: "\f6d9";
  --fa--fa: "\f6d9\f6d9";
}

.fa-hand-holding-box {
  --fa: "\f47b";
  --fa--fa: "\f47b\f47b";
}

.fa-input-text {
  --fa: "\e1bf";
  --fa--fa: "\e1bf\e1bf";
}

.fa-window-flip {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f";
}

.fa-window-alt {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f";
}

.fa-align-right {
  --fa: "\f038";
  --fa--fa: "\f038\f038";
}

.fa-scanner-gun {
  --fa: "\f488";
  --fa--fa: "\f488\f488";
}

.fa-scanner {
  --fa: "\f488";
  --fa--fa: "\f488\f488";
}

.fa-tire {
  --fa: "\f631";
  --fa--fa: "\f631\f631";
}

.fa-engine {
  --fa: "\e16e";
  --fa--fa: "\e16e\e16e";
}

.fa-money-bill-1-wave {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b";
}

.fa-money-bill-wave-alt {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b";
}

.fa-life-ring {
  --fa: "\f1cd";
  --fa--fa: "\f1cd\f1cd";
}

.fa-hands {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-sign-language {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-signing {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7";
}

.fa-circle-caret-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330";
}

.fa-caret-circle-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330";
}

.fa-turn-left {
  --fa: "\e636";
  --fa--fa: "\e636\e636";
}

.fa-wheat {
  --fa: "\f72d";
  --fa--fa: "\f72d\f72d";
}

.fa-file-spreadsheet {
  --fa: "\f65b";
  --fa--fa: "\f65b\f65b";
}

.fa-audio-description-slash {
  --fa: "\e0a8";
  --fa--fa: "\e0a8\e0a8";
}

.fa-bell-ring {
  --fa: "\e62c";
  --fa--fa: "\e62c\e62c";
}

.fa-calendar-day {
  --fa: "\f783";
  --fa--fa: "\f783\f783";
}

.fa-water-ladder {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-ladder-water {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-swimming-pool {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5";
}

.fa-arrows-up-down {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d";
}

.fa-arrows-v {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d";
}

.fa-chess-pawn-piece {
  --fa: "\f444";
  --fa--fa: "\f444\f444";
}

.fa-chess-pawn-alt {
  --fa: "\f444";
  --fa--fa: "\f444\f444";
}

.fa-face-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f";
}

.fa-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f";
}

.fa-wheelchair-move {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce";
}

.fa-wheelchair-alt {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce";
}

.fa-turn-down {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be";
}

.fa-level-down-alt {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be";
}

.fa-square-s {
  --fa: "\e27d";
  --fa--fa: "\e27d\e27d";
}

.fa-rectangle-barcode {
  --fa: "\f463";
  --fa--fa: "\f463\f463";
}

.fa-barcode-alt {
  --fa: "\f463";
  --fa--fa: "\f463\f463";
}

.fa-person-walking-arrow-right {
  --fa: "\e552";
  --fa--fa: "\e552\e552";
}

.fa-square-envelope {
  --fa: "\f199";
  --fa--fa: "\f199\f199";
}

.fa-envelope-square {
  --fa: "\f199";
  --fa--fa: "\f199\f199";
}

.fa-dice {
  --fa: "\f522";
  --fa--fa: "\f522\f522";
}

.fa-unicorn {
  --fa: "\f727";
  --fa--fa: "\f727\f727";
}

.fa-bowling-ball {
  --fa: "\f436";
  --fa--fa: "\f436\f436";
}

.fa-pompebled {
  --fa: "\e43d";
  --fa--fa: "\e43d\e43d";
}

.fa-brain {
  --fa: "\f5dc";
  --fa--fa: "\f5dc\f5dc";
}

.fa-watch-smart {
  --fa: "\e2cc";
  --fa--fa: "\e2cc\e2cc";
}

.fa-book-user {
  --fa: "\f7e7";
  --fa--fa: "\f7e7\f7e7";
}

.fa-sensor-cloud {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c";
}

.fa-sensor-smoke {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c";
}

.fa-clapperboard-play {
  --fa: "\e132";
  --fa--fa: "\e132\e132";
}

.fa-bandage {
  --fa: "\f462";
  --fa--fa: "\f462\f462";
}

.fa-band-aid {
  --fa: "\f462";
  --fa--fa: "\f462\f462";
}

.fa-calendar-minus {
  --fa: "\f272";
  --fa--fa: "\f272\f272";
}

.fa-circle-xmark {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-times-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-xmark-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057";
}

.fa-circle-4 {
  --fa: "\e0f1";
  --fa--fa: "\e0f1\e0f1";
}

.fa-gifts {
  --fa: "\f79c";
  --fa--fa: "\f79c\f79c";
}

.fa-album-collection {
  --fa: "\f8a0";
  --fa--fa: "\f8a0\f8a0";
}

.fa-hotel {
  --fa: "\f594";
  --fa--fa: "\f594\f594";
}

.fa-earth-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e";
}

.fa-globe-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e";
}

.fa-id-card-clip {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f";
}

.fa-id-card-alt {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f";
}

.fa-magnifying-glass-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e";
}

.fa-search-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e";
}

.fa-thumbs-up {
  --fa: "\f164";
  --fa--fa: "\f164\f164";
}

.fa-cloud-showers {
  --fa: "\f73f";
  --fa--fa: "\f73f\f73f";
}

.fa-user-clock {
  --fa: "\f4fd";
  --fa--fa: "\f4fd\f4fd";
}

.fa-onion {
  --fa: "\e427";
  --fa--fa: "\e427\e427";
}

.fa-clock-twelve-thirty {
  --fa: "\e359";
  --fa--fa: "\e359\e359";
}

.fa-arrow-down-to-dotted-line {
  --fa: "\e095";
  --fa--fa: "\e095\e095";
}

.fa-hand-dots {
  --fa: "\f461";
  --fa--fa: "\f461\f461";
}

.fa-allergies {
  --fa: "\f461";
  --fa--fa: "\f461\f461";
}

.fa-file-invoice {
  --fa: "\f570";
  --fa--fa: "\f570\f570";
}

.fa-window-minimize {
  --fa: "\f2d1";
  --fa--fa: "\f2d1\f2d1";
}

.fa-rectangle-wide {
  --fa: "\f2fc";
  --fa--fa: "\f2fc\f2fc";
}

.fa-comment-arrow-up {
  --fa: "\e144";
  --fa--fa: "\e144\e144";
}

.fa-garlic {
  --fa: "\e40e";
  --fa--fa: "\e40e\e40e";
}

.fa-mug-saucer {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4";
}

.fa-coffee {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4";
}

.fa-brush {
  --fa: "\f55d";
  --fa--fa: "\f55d\f55d";
}

.fa-file-half-dashed {
  --fa: "\e698";
  --fa--fa: "\e698\e698";
}

.fa-tree-decorated {
  --fa: "\f7dc";
  --fa--fa: "\f7dc\f7dc";
}

.fa-mask {
  --fa: "\f6fa";
  --fa--fa: "\f6fa\f6fa";
}

.fa-calendar-heart {
  --fa: "\e0d3";
  --fa--fa: "\e0d3\e0d3";
}

.fa-magnifying-glass-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010";
}

.fa-search-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010";
}

.fa-flower {
  --fa: "\f7ff";
  --fa--fa: "\f7ff\f7ff";
}

.fa-arrow-down-from-arc {
  --fa: "\e614";
  --fa--fa: "\e614\e614";
}

.fa-right-left-large {
  --fa: "\e5e1";
  --fa--fa: "\e5e1\e5e1";
}

.fa-ruler-vertical {
  --fa: "\f548";
  --fa--fa: "\f548\f548";
}

.fa-circles-overlap {
  --fa: "\e600";
  --fa--fa: "\e600\e600";
}

.fa-user-large {
  --fa: "\f406";
  --fa--fa: "\f406\f406";
}

.fa-user-alt {
  --fa: "\f406";
  --fa--fa: "\f406\f406";
}

.fa-starship-freighter {
  --fa: "\e03a";
  --fa--fa: "\e03a\e03a";
}

.fa-train-tram {
  --fa: "\e5b4";
  --fa--fa: "\e5b4\e5b4";
}

.fa-bridge-suspension {
  --fa: "\e4cd";
  --fa--fa: "\e4cd\e4cd";
}

.fa-trash-check {
  --fa: "\e2af";
  --fa--fa: "\e2af\e2af";
}

.fa-user-nurse {
  --fa: "\f82f";
  --fa--fa: "\f82f\f82f";
}

.fa-boombox {
  --fa: "\f8a5";
  --fa--fa: "\f8a5\f8a5";
}

.fa-syringe {
  --fa: "\f48e";
  --fa--fa: "\f48e\f48e";
}

.fa-cloud-sun {
  --fa: "\f6c4";
  --fa--fa: "\f6c4\f6c4";
}

.fa-shield-exclamation {
  --fa: "\e247";
  --fa--fa: "\e247\e247";
}

.fa-stopwatch-20 {
  --fa: "\e06f";
  --fa--fa: "\e06f\e06f";
}

.fa-square-full {
  --fa: "\f45c";
  --fa--fa: "\f45c\f45c";
}

.fa-grip-dots {
  --fa: "\e410";
  --fa--fa: "\e410\e410";
}

.fa-comment-exclamation {
  --fa: "\f4af";
  --fa--fa: "\f4af\f4af";
}

.fa-pen-swirl {
  --fa: "\e214";
  --fa--fa: "\e214\e214";
}

.fa-falafel {
  --fa: "\e40a";
  --fa--fa: "\e40a\e40a";
}

.fa-circle-2 {
  --fa: "\e0ef";
  --fa--fa: "\e0ef\e0ef";
}

.fa-magnet {
  --fa: "\f076";
  --fa--fa: "\f076\f076";
}

.fa-jar {
  --fa: "\e516";
  --fa--fa: "\e516\e516";
}

.fa-gramophone {
  --fa: "\f8bd";
  --fa--fa: "\f8bd\f8bd";
}

.fa-dice-d12 {
  --fa: "\f6ce";
  --fa--fa: "\f6ce\f6ce";
}

.fa-note-sticky {
  --fa: "\f249";
  --fa--fa: "\f249\f249";
}

.fa-sticky-note {
  --fa: "\f249";
  --fa--fa: "\f249\f249";
}

.fa-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354";
}

.fa-arrow-alt-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354";
}

.fa-hundred-points {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c";
}

.fa-100 {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c";
}

.fa-paperclip-vertical {
  --fa: "\e3c2";
  --fa--fa: "\e3c2\e3c2";
}

.fa-wind-warning {
  --fa: "\f776";
  --fa--fa: "\f776\f776";
}

.fa-wind-circle-exclamation {
  --fa: "\f776";
  --fa--fa: "\f776\f776";
}

.fa-location-pin-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c";
}

.fa-map-marker-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c";
}

.fa-face-sad-sweat {
  --fa: "\e38a";
  --fa--fa: "\e38a\e38a";
}

.fa-bug-slash {
  --fa: "\e490";
  --fa--fa: "\e490\e490";
}

.fa-cupcake {
  --fa: "\e402";
  --fa--fa: "\e402\e402";
}

.fa-light-switch-off {
  --fa: "\e018";
  --fa--fa: "\e018\e018";
}

.fa-toggle-large-off {
  --fa: "\e5b0";
  --fa--fa: "\e5b0\e5b0";
}

.fa-pen-fancy-slash {
  --fa: "\e210";
  --fa--fa: "\e210\e210";
}

.fa-truck-container {
  --fa: "\f4dc";
  --fa--fa: "\f4dc\f4dc";
}

.fa-boot {
  --fa: "\f782";
  --fa--fa: "\f782\f782";
}

.fa-arrow-up-from-water-pump {
  --fa: "\e4b6";
  --fa--fa: "\e4b6\e4b6";
}

.fa-file-check {
  --fa: "\f316";
  --fa--fa: "\f316\f316";
}

.fa-bone {
  --fa: "\f5d7";
  --fa--fa: "\f5d7\f5d7";
}

.fa-cards-blank {
  --fa: "\e4df";
  --fa--fa: "\e4df\e4df";
}

.fa-circle-3 {
  --fa: "\e0f0";
  --fa--fa: "\e0f0\e0f0";
}

.fa-bench-tree {
  --fa: "\e2e7";
  --fa--fa: "\e2e7\e2e7";
}

.fa-keyboard-brightness-low {
  --fa: "\e1c1";
  --fa--fa: "\e1c1\e1c1";
}

.fa-ski-boot-ski {
  --fa: "\e3cd";
  --fa--fa: "\e3cd\e3cd";
}

.fa-brain-circuit {
  --fa: "\e0c6";
  --fa--fa: "\e0c6\e0c6";
}

.fa-table-cells-row-unlock {
  --fa: "\e691";
  --fa--fa: "\e691\e691";
}

.fa-user-injured {
  --fa: "\f728";
  --fa--fa: "\f728\f728";
}

.fa-block-brick-fire {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc";
}

.fa-firewall {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc";
}

.fa-face-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4";
}

.fa-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4";
}

.fa-plane {
  --fa: "\f072";
  --fa--fa: "\f072\f072";
}

.fa-tent-arrows-down {
  --fa: "\e581";
  --fa--fa: "\e581\e581";
}

.fa-exclamation {
  --fa: "\!";
  --fa--fa: "\!\!";
}

.fa-arrows-spin {
  --fa: "\e4bb";
  --fa--fa: "\e4bb\e4bb";
}

.fa-face-smile-relaxed {
  --fa: "\e392";
  --fa--fa: "\e392\e392";
}

.fa-comment-xmark {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5";
}

.fa-comment-times {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5";
}

.fa-print {
  --fa: "\f02f";
  --fa--fa: "\f02f\f02f";
}

.fa-turkish-lira-sign {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-try {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-turkish-lira {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb";
}

.fa-face-nose-steam {
  --fa: "\e382";
  --fa--fa: "\e382\e382";
}

.fa-circle-waveform-lines {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d";
}

.fa-waveform-circle {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d";
}

.fa-dollar-sign {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-dollar {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-usd {
  --fa: "\$";
  --fa--fa: "\$\$";
}

.fa-ferris-wheel {
  --fa: "\e174";
  --fa--fa: "\e174\e174";
}

.fa-computer-speaker {
  --fa: "\f8b2";
  --fa--fa: "\f8b2\f8b2";
}

.fa-skull-cow {
  --fa: "\f8de";
  --fa--fa: "\f8de\f8de";
}

.fa-x {
  --fa: "X";
  --fa--fa: "XX";
}

.fa-magnifying-glass-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688";
}

.fa-search-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688";
}

.fa-users-gear {
  --fa: "\f509";
  --fa--fa: "\f509\f509";
}

.fa-users-cog {
  --fa: "\f509";
  --fa--fa: "\f509\f509";
}

.fa-person-military-pointing {
  --fa: "\e54a";
  --fa--fa: "\e54a\e54a";
}

.fa-building-columns {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-bank {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-institution {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-museum {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-university {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c";
}

.fa-circle-t {
  --fa: "\e124";
  --fa--fa: "\e124\e124";
}

.fa-sack {
  --fa: "\f81c";
  --fa--fa: "\f81c\f81c";
}

.fa-grid-2 {
  --fa: "\e196";
  --fa--fa: "\e196\e196";
}

.fa-camera-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac";
}

.fa-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac";
}

.fa-umbrella {
  --fa: "\f0e9";
  --fa--fa: "\f0e9\f0e9";
}

.fa-trowel {
  --fa: "\e589";
  --fa--fa: "\e589\e589";
}

.fa-horizontal-rule {
  --fa: "\f86c";
  --fa--fa: "\f86c\f86c";
}

.fa-bed-front {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7";
}

.fa-bed-alt {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7";
}

.fa-d {
  --fa: "D";
  --fa--fa: "DD";
}

.fa-stapler {
  --fa: "\e5af";
  --fa--fa: "\e5af\e5af";
}

.fa-masks-theater {
  --fa: "\f630";
  --fa--fa: "\f630\f630";
}

.fa-theater-masks {
  --fa: "\f630";
  --fa--fa: "\f630\f630";
}

.fa-file-gif {
  --fa: "\e645";
  --fa--fa: "\e645\e645";
}

.fa-kip-sign {
  --fa: "\e1c4";
  --fa--fa: "\e1c4\e1c4";
}

.fa-face-woozy {
  --fa: "\e3a2";
  --fa--fa: "\e3a2\e3a2";
}

.fa-cloud-question {
  --fa: "\e492";
  --fa--fa: "\e492\e492";
}

.fa-pineapple {
  --fa: "\e31f";
  --fa--fa: "\e31f\e31f";
}

.fa-hand-point-left {
  --fa: "\f0a5";
  --fa--fa: "\f0a5\f0a5";
}

.fa-gallery-thumbnails {
  --fa: "\e3aa";
  --fa--fa: "\e3aa\e3aa";
}

.fa-circle-j {
  --fa: "\e112";
  --fa--fa: "\e112\e112";
}

.fa-eyes {
  --fa: "\e367";
  --fa--fa: "\e367\e367";
}

.fa-handshake-simple {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6";
}

.fa-handshake-alt {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6";
}

.fa-page-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a";
}

.fa-file-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a";
}

.fa-jet-fighter {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb";
}

.fa-fighter-jet {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb";
}

.fa-comet {
  --fa: "\e003";
  --fa--fa: "\e003\e003";
}

.fa-square-share-nodes {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1";
}

.fa-share-alt-square {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1";
}

.fa-reflect-vertical {
  --fa: "\e665";
  --fa--fa: "\e665\e665";
}

.fa-shield-keyhole {
  --fa: "\e248";
  --fa--fa: "\e248\e248";
}

.fa-file-mp4 {
  --fa: "\e649";
  --fa--fa: "\e649\e649";
}

.fa-barcode {
  --fa: "\f02a";
  --fa--fa: "\f02a\f02a";
}

.fa-bulldozer {
  --fa: "\e655";
  --fa--fa: "\e655\e655";
}

.fa-plus-minus {
  --fa: "\e43c";
  --fa--fa: "\e43c\e43c";
}

.fa-square-sliders-vertical {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2";
}

.fa-sliders-v-square {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2";
}

.fa-video {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d";
}

.fa-video-camera {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d";
}

.fa-message-middle {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1";
}

.fa-comment-middle-alt {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1";
}

.fa-graduation-cap {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d";
}

.fa-mortar-board {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d";
}

.fa-hand-holding-medical {
  --fa: "\e05c";
  --fa--fa: "\e05c\e05c";
}

.fa-person-circle-check {
  --fa: "\e53e";
  --fa--fa: "\e53e\e53e";
}

.fa-square-z {
  --fa: "\e288";
  --fa--fa: "\e288\e288";
}

.fa-message-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6";
}

.fa-comment-alt-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6";
}

.fa-turn-up {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf";
}

.fa-level-up-alt {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
.fa.fa-glass {
  --fa: "\f000";
}

.fa.fa-envelope-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-envelope-o {
  --fa: "\f0e0";
}

.fa.fa-star-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-star-o {
  --fa: "\f005";
}

.fa.fa-remove {
  --fa: "\f00d";
}

.fa.fa-close {
  --fa: "\f00d";
}

.fa.fa-gear {
  --fa: "\f013";
}

.fa.fa-trash-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-trash-o {
  --fa: "\f2ed";
}

.fa.fa-home {
  --fa: "\f015";
}

.fa.fa-file-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-o {
  --fa: "\f15b";
}

.fa.fa-clock-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-clock-o {
  --fa: "\f017";
}

.fa.fa-arrow-circle-o-down {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-arrow-circle-o-down {
  --fa: "\f358";
}

.fa.fa-arrow-circle-o-up {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-arrow-circle-o-up {
  --fa: "\f35b";
}

.fa.fa-play-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-play-circle-o {
  --fa: "\f144";
}

.fa.fa-repeat {
  --fa: "\f01e";
}

.fa.fa-rotate-right {
  --fa: "\f01e";
}

.fa.fa-refresh {
  --fa: "\f021";
}

.fa.fa-list-alt {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-list-alt {
  --fa: "\f022";
}

.fa.fa-dedent {
  --fa: "\f03b";
}

.fa.fa-video-camera {
  --fa: "\f03d";
}

.fa.fa-picture-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-picture-o {
  --fa: "\f03e";
}

.fa.fa-photo {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-photo {
  --fa: "\f03e";
}

.fa.fa-image {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-image {
  --fa: "\f03e";
}

.fa.fa-map-marker {
  --fa: "\f3c5";
}

.fa.fa-pencil-square-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-pencil-square-o {
  --fa: "\f044";
}

.fa.fa-edit {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-edit {
  --fa: "\f044";
}

.fa.fa-share-square-o {
  --fa: "\f14d";
}

.fa.fa-check-square-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-check-square-o {
  --fa: "\f14a";
}

.fa.fa-arrows {
  --fa: "\f0b2";
}

.fa.fa-times-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-times-circle-o {
  --fa: "\f057";
}

.fa.fa-check-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-check-circle-o {
  --fa: "\f058";
}

.fa.fa-mail-forward {
  --fa: "\f064";
}

.fa.fa-expand {
  --fa: "\f424";
}

.fa.fa-compress {
  --fa: "\f422";
}

.fa.fa-eye {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-eye-slash {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-warning {
  --fa: "\f071";
}

.fa.fa-calendar {
  --fa: "\f073";
}

.fa.fa-arrows-v {
  --fa: "\f338";
}

.fa.fa-arrows-h {
  --fa: "\f337";
}

.fa.fa-bar-chart {
  --fa: "\e0e3";
}

.fa.fa-bar-chart-o {
  --fa: "\e0e3";
}

.fa.fa-twitter-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-twitter-square {
  --fa: "\f081";
}

.fa.fa-facebook-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-facebook-square {
  --fa: "\f082";
}

.fa.fa-gears {
  --fa: "\f085";
}

.fa.fa-thumbs-o-up {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-thumbs-o-up {
  --fa: "\f164";
}

.fa.fa-thumbs-o-down {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-thumbs-o-down {
  --fa: "\f165";
}

.fa.fa-heart-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-heart-o {
  --fa: "\f004";
}

.fa.fa-sign-out {
  --fa: "\f2f5";
}

.fa.fa-linkedin-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-linkedin-square {
  --fa: "\f08c";
}

.fa.fa-thumb-tack {
  --fa: "\f08d";
}

.fa.fa-external-link {
  --fa: "\f35d";
}

.fa.fa-sign-in {
  --fa: "\f2f6";
}

.fa.fa-github-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-github-square {
  --fa: "\f092";
}

.fa.fa-lemon-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-lemon-o {
  --fa: "\f094";
}

.fa.fa-square-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-square-o {
  --fa: "\f0c8";
}

.fa.fa-bookmark-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-bookmark-o {
  --fa: "\f02e";
}

.fa.fa-twitter {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-facebook {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-facebook {
  --fa: "\f39e";
}

.fa.fa-facebook-f {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-facebook-f {
  --fa: "\f39e";
}

.fa.fa-github {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-credit-card {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-feed {
  --fa: "\f09e";
}

.fa.fa-hdd-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hdd-o {
  --fa: "\f0a0";
}

.fa.fa-hand-o-right {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-o-right {
  --fa: "\f0a4";
}

.fa.fa-hand-o-left {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-o-left {
  --fa: "\f0a5";
}

.fa.fa-hand-o-up {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-o-up {
  --fa: "\f0a6";
}

.fa.fa-hand-o-down {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-o-down {
  --fa: "\f0a7";
}

.fa.fa-globe {
  --fa: "\f57d";
}

.fa.fa-tasks {
  --fa: "\f828";
}

.fa.fa-arrows-alt {
  --fa: "\f31e";
}

.fa.fa-group {
  --fa: "\f0c0";
}

.fa.fa-chain {
  --fa: "\f0c1";
}

.fa.fa-cut {
  --fa: "\f0c4";
}

.fa.fa-files-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-files-o {
  --fa: "\f0c5";
}

.fa.fa-floppy-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-floppy-o {
  --fa: "\f0c7";
}

.fa.fa-save {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-save {
  --fa: "\f0c7";
}

.fa.fa-navicon {
  --fa: "\f0c9";
}

.fa.fa-reorder {
  --fa: "\f0c9";
}

.fa.fa-magic {
  --fa: "\e2ca";
}

.fa.fa-pinterest {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pinterest-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pinterest-square {
  --fa: "\f0d3";
}

.fa.fa-google-plus-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-plus-square {
  --fa: "\f0d4";
}

.fa.fa-google-plus {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-plus {
  --fa: "\f0d5";
}

.fa.fa-money {
  --fa: "\f3d1";
}

.fa.fa-unsorted {
  --fa: "\f0dc";
}

.fa.fa-sort-desc {
  --fa: "\f0dd";
}

.fa.fa-sort-asc {
  --fa: "\f0de";
}

.fa.fa-linkedin {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-linkedin {
  --fa: "\f0e1";
}

.fa.fa-rotate-left {
  --fa: "\f0e2";
}

.fa.fa-legal {
  --fa: "\f0e3";
}

.fa.fa-tachometer {
  --fa: "\f625";
}

.fa.fa-dashboard {
  --fa: "\f625";
}

.fa.fa-comment-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-comment-o {
  --fa: "\f075";
}

.fa.fa-comments-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-comments-o {
  --fa: "\f086";
}

.fa.fa-flash {
  --fa: "\f0e7";
}

.fa.fa-clipboard {
  --fa: "\f0ea";
}

.fa.fa-lightbulb-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-lightbulb-o {
  --fa: "\f0eb";
}

.fa.fa-exchange {
  --fa: "\f362";
}

.fa.fa-cloud-download {
  --fa: "\f0ed";
}

.fa.fa-cloud-upload {
  --fa: "\f0ee";
}

.fa.fa-bell-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-bell-o {
  --fa: "\f0f3";
}

.fa.fa-cutlery {
  --fa: "\f2e7";
}

.fa.fa-file-text-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-text-o {
  --fa: "\f15c";
}

.fa.fa-building-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-building-o {
  --fa: "\f1ad";
}

.fa.fa-hospital-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hospital-o {
  --fa: "\f0f8";
}

.fa.fa-tablet {
  --fa: "\f3fa";
}

.fa.fa-mobile {
  --fa: "\f3cd";
}

.fa.fa-mobile-phone {
  --fa: "\f3cd";
}

.fa.fa-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-circle-o {
  --fa: "\f111";
}

.fa.fa-mail-reply {
  --fa: "\f3e5";
}

.fa.fa-github-alt {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-folder-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-folder-o {
  --fa: "\f07b";
}

.fa.fa-folder-open-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-folder-open-o {
  --fa: "\f07c";
}

.fa.fa-smile-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-smile-o {
  --fa: "\f118";
}

.fa.fa-frown-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-frown-o {
  --fa: "\f119";
}

.fa.fa-meh-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-meh-o {
  --fa: "\f11a";
}

.fa.fa-keyboard-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-keyboard-o {
  --fa: "\f11c";
}

.fa.fa-flag-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-flag-o {
  --fa: "\f024";
}

.fa.fa-mail-reply-all {
  --fa: "\f122";
}

.fa.fa-star-half-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-star-half-o {
  --fa: "\f5c0";
}

.fa.fa-star-half-empty {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-star-half-empty {
  --fa: "\f5c0";
}

.fa.fa-star-half-full {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-star-half-full {
  --fa: "\f5c0";
}

.fa.fa-code-fork {
  --fa: "\f126";
}

.fa.fa-chain-broken {
  --fa: "\f127";
}

.fa.fa-unlink {
  --fa: "\f127";
}

.fa.fa-calendar-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-calendar-o {
  --fa: "\f133";
}

.fa.fa-maxcdn {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-html5 {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-css3 {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-unlock-alt {
  --fa: "\f09c";
}

.fa.fa-minus-square-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-minus-square-o {
  --fa: "\f146";
}

.fa.fa-level-up {
  --fa: "\f3bf";
}

.fa.fa-level-down {
  --fa: "\f3be";
}

.fa.fa-pencil-square {
  --fa: "\f14b";
}

.fa.fa-external-link-square {
  --fa: "\f360";
}

.fa.fa-compass {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-caret-square-o-down {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-caret-square-o-down {
  --fa: "\f150";
}

.fa.fa-toggle-down {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-toggle-down {
  --fa: "\f150";
}

.fa.fa-caret-square-o-up {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-caret-square-o-up {
  --fa: "\f151";
}

.fa.fa-toggle-up {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-toggle-up {
  --fa: "\f151";
}

.fa.fa-caret-square-o-right {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-caret-square-o-right {
  --fa: "\f152";
}

.fa.fa-toggle-right {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-toggle-right {
  --fa: "\f152";
}

.fa.fa-eur {
  --fa: "\f153";
}

.fa.fa-euro {
  --fa: "\f153";
}

.fa.fa-gbp {
  --fa: "\f154";
}

.fa.fa-usd {
  --fa: "\$";
}

.fa.fa-dollar {
  --fa: "\$";
}

.fa.fa-inr {
  --fa: "\e1bc";
}

.fa.fa-rupee {
  --fa: "\e1bc";
}

.fa.fa-jpy {
  --fa: "\f157";
}

.fa.fa-cny {
  --fa: "\f157";
}

.fa.fa-rmb {
  --fa: "\f157";
}

.fa.fa-yen {
  --fa: "\f157";
}

.fa.fa-rub {
  --fa: "\f158";
}

.fa.fa-ruble {
  --fa: "\f158";
}

.fa.fa-rouble {
  --fa: "\f158";
}

.fa.fa-krw {
  --fa: "\f159";
}

.fa.fa-won {
  --fa: "\f159";
}

.fa.fa-btc {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bitcoin {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bitcoin {
  --fa: "\f15a";
}

.fa.fa-file-text {
  --fa: "\f15c";
}

.fa.fa-sort-alpha-asc {
  --fa: "\f15d";
}

.fa.fa-sort-alpha-desc {
  --fa: "\f881";
}

.fa.fa-sort-amount-asc {
  --fa: "\f884";
}

.fa.fa-sort-amount-desc {
  --fa: "\f160";
}

.fa.fa-sort-numeric-asc {
  --fa: "\f162";
}

.fa.fa-sort-numeric-desc {
  --fa: "\f886";
}

.fa.fa-youtube-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-youtube-square {
  --fa: "\f431";
}

.fa.fa-youtube {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-xing {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-xing-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-xing-square {
  --fa: "\f169";
}

.fa.fa-youtube-play {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-youtube-play {
  --fa: "\f167";
}

.fa.fa-dropbox {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-stack-overflow {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-instagram {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-flickr {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-adn {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bitbucket {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bitbucket-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bitbucket-square {
  --fa: "\f171";
}

.fa.fa-tumblr {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-tumblr-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-tumblr-square {
  --fa: "\f174";
}

.fa.fa-long-arrow-down {
  --fa: "\f309";
}

.fa.fa-long-arrow-up {
  --fa: "\f30c";
}

.fa.fa-long-arrow-left {
  --fa: "\f30a";
}

.fa.fa-long-arrow-right {
  --fa: "\f30b";
}

.fa.fa-apple {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-windows {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-android {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-linux {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-dribbble {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-skype {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-foursquare {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-trello {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gratipay {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gittip {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gittip {
  --fa: "\f184";
}

.fa.fa-sun-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-sun-o {
  --fa: "\f185";
}

.fa.fa-moon-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-moon-o {
  --fa: "\f186";
}

.fa.fa-vk {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-weibo {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-renren {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pagelines {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-stack-exchange {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right {
  --fa: "\f35a";
}

.fa.fa-arrow-circle-o-left {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-arrow-circle-o-left {
  --fa: "\f359";
}

.fa.fa-caret-square-o-left {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-caret-square-o-left {
  --fa: "\f191";
}

.fa.fa-toggle-left {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-toggle-left {
  --fa: "\f191";
}

.fa.fa-dot-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-dot-circle-o {
  --fa: "\f192";
}

.fa.fa-vimeo-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-vimeo-square {
  --fa: "\f194";
}

.fa.fa-try {
  --fa: "\e2bb";
}

.fa.fa-turkish-lira {
  --fa: "\e2bb";
}

.fa.fa-plus-square-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-plus-square-o {
  --fa: "\f0fe";
}

.fa.fa-slack {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wordpress {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-openid {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-institution {
  --fa: "\f19c";
}

.fa.fa-bank {
  --fa: "\f19c";
}

.fa.fa-mortar-board {
  --fa: "\f19d";
}

.fa.fa-yahoo {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-reddit {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-reddit-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-reddit-square {
  --fa: "\f1a2";
}

.fa.fa-stumbleupon-circle {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-stumbleupon {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-delicious {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-digg {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pied-piper-pp {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pied-piper-alt {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-drupal {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-joomla {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-behance {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-behance-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-behance-square {
  --fa: "\f1b5";
}

.fa.fa-steam {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-steam-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-steam-square {
  --fa: "\f1b7";
}

.fa.fa-automobile {
  --fa: "\f1b9";
}

.fa.fa-cab {
  --fa: "\f1ba";
}

.fa.fa-spotify {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-deviantart {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-soundcloud {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-file-pdf-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-pdf-o {
  --fa: "\f1c1";
}

.fa.fa-file-word-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-word-o {
  --fa: "\f1c2";
}

.fa.fa-file-excel-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-excel-o {
  --fa: "\f1c3";
}

.fa.fa-file-powerpoint-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-powerpoint-o {
  --fa: "\f1c4";
}

.fa.fa-file-image-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-image-o {
  --fa: "\f1c5";
}

.fa.fa-file-photo-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-photo-o {
  --fa: "\f1c5";
}

.fa.fa-file-picture-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-picture-o {
  --fa: "\f1c5";
}

.fa.fa-file-archive-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-archive-o {
  --fa: "\f1c6";
}

.fa.fa-file-zip-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-zip-o {
  --fa: "\f1c6";
}

.fa.fa-file-audio-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-audio-o {
  --fa: "\f1c7";
}

.fa.fa-file-sound-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-sound-o {
  --fa: "\f1c7";
}

.fa.fa-file-video-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-video-o {
  --fa: "\f1c8";
}

.fa.fa-file-movie-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-movie-o {
  --fa: "\f1c8";
}

.fa.fa-file-code-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-file-code-o {
  --fa: "\f1c9";
}

.fa.fa-vine {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-codepen {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-jsfiddle {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-life-bouy {
  --fa: "\f1cd";
}

.fa.fa-life-buoy {
  --fa: "\f1cd";
}

.fa.fa-life-saver {
  --fa: "\f1cd";
}

.fa.fa-support {
  --fa: "\f1cd";
}

.fa.fa-circle-o-notch {
  --fa: "\f1ce";
}

.fa.fa-rebel {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-ra {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-ra {
  --fa: "\f1d0";
}

.fa.fa-resistance {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-resistance {
  --fa: "\f1d0";
}

.fa.fa-empire {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-ge {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-ge {
  --fa: "\f1d1";
}

.fa.fa-git-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-git-square {
  --fa: "\f1d2";
}

.fa.fa-git {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-hacker-news {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-y-combinator-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-y-combinator-square {
  --fa: "\f1d4";
}

.fa.fa-yc-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-yc-square {
  --fa: "\f1d4";
}

.fa.fa-tencent-weibo {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-qq {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-weixin {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wechat {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wechat {
  --fa: "\f1d7";
}

.fa.fa-send {
  --fa: "\f1d8";
}

.fa.fa-paper-plane-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-paper-plane-o {
  --fa: "\f1d8";
}

.fa.fa-send-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-send-o {
  --fa: "\f1d8";
}

.fa.fa-circle-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-circle-thin {
  --fa: "\f111";
}

.fa.fa-header {
  --fa: "\f1dc";
}

.fa.fa-futbol-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-futbol-o {
  --fa: "\f1e3";
}

.fa.fa-soccer-ball-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-soccer-ball-o {
  --fa: "\f1e3";
}

.fa.fa-slideshare {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-twitch {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-yelp {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-newspaper-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-newspaper-o {
  --fa: "\f1ea";
}

.fa.fa-paypal {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-wallet {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-visa {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-mastercard {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-discover {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-amex {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-paypal {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-stripe {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bell-slash-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-bell-slash-o {
  --fa: "\f1f6";
}

.fa.fa-trash {
  --fa: "\f2ed";
}

.fa.fa-copyright {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-eyedropper {
  --fa: "\f1fb";
}

.fa.fa-area-chart {
  --fa: "\f1fe";
}

.fa.fa-pie-chart {
  --fa: "\f200";
}

.fa.fa-line-chart {
  --fa: "\f201";
}

.fa.fa-lastfm {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-lastfm-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-lastfm-square {
  --fa: "\f203";
}

.fa.fa-ioxhost {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-angellist {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-cc {
  --fa: "\f20a";
}

.fa.fa-ils {
  --fa: "\f20b";
}

.fa.fa-shekel {
  --fa: "\f20b";
}

.fa.fa-sheqel {
  --fa: "\f20b";
}

.fa.fa-buysellads {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-connectdevelop {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-dashcube {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-forumbee {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-leanpub {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-sellsy {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-shirtsinbulk {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-simplybuilt {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-skyatlas {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-diamond {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-diamond {
  --fa: "\f3a5";
}

.fa.fa-transgender {
  --fa: "\f224";
}

.fa.fa-intersex {
  --fa: "\f224";
}

.fa.fa-transgender-alt {
  --fa: "\f225";
}

.fa.fa-facebook-official {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-facebook-official {
  --fa: "\f09a";
}

.fa.fa-pinterest-p {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-whatsapp {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-hotel {
  --fa: "\f236";
}

.fa.fa-viacoin {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-medium {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-y-combinator {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-yc {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-yc {
  --fa: "\f23b";
}

.fa.fa-optin-monster {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-opencart {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-expeditedssl {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-battery-4 {
  --fa: "\f240";
}

.fa.fa-battery {
  --fa: "\f240";
}

.fa.fa-battery-3 {
  --fa: "\f241";
}

.fa.fa-battery-2 {
  --fa: "\f242";
}

.fa.fa-battery-1 {
  --fa: "\f243";
}

.fa.fa-battery-0 {
  --fa: "\f244";
}

.fa.fa-object-group {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-object-ungroup {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-sticky-note-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-sticky-note-o {
  --fa: "\f249";
}

.fa.fa-cc-jcb {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-cc-diners-club {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-clone {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hourglass-o {
  --fa: "\f254";
}

.fa.fa-hourglass-1 {
  --fa: "\f251";
}

.fa.fa-hourglass-2 {
  --fa: "\f252";
}

.fa.fa-hourglass-3 {
  --fa: "\f253";
}

.fa.fa-hand-rock-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-rock-o {
  --fa: "\f255";
}

.fa.fa-hand-grab-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-grab-o {
  --fa: "\f255";
}

.fa.fa-hand-paper-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-paper-o {
  --fa: "\f256";
}

.fa.fa-hand-stop-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-stop-o {
  --fa: "\f256";
}

.fa.fa-hand-scissors-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-scissors-o {
  --fa: "\f257";
}

.fa.fa-hand-lizard-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-lizard-o {
  --fa: "\f258";
}

.fa.fa-hand-spock-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-spock-o {
  --fa: "\f259";
}

.fa.fa-hand-pointer-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-pointer-o {
  --fa: "\f25a";
}

.fa.fa-hand-peace-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-hand-peace-o {
  --fa: "\f25b";
}

.fa.fa-registered {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-creative-commons {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gg {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gg-circle {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-odnoklassniki {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-odnoklassniki-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-odnoklassniki-square {
  --fa: "\f264";
}

.fa.fa-get-pocket {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wikipedia-w {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-safari {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-chrome {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-firefox {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-opera {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-internet-explorer {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-television {
  --fa: "\f26c";
}

.fa.fa-contao {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-500px {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-amazon {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-calendar-plus-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-calendar-plus-o {
  --fa: "\f271";
}

.fa.fa-calendar-minus-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-calendar-minus-o {
  --fa: "\f272";
}

.fa.fa-calendar-times-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-calendar-times-o {
  --fa: "\f273";
}

.fa.fa-calendar-check-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-calendar-check-o {
  --fa: "\f274";
}

.fa.fa-map-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-map-o {
  --fa: "\f279";
}

.fa.fa-commenting {
  --fa: "\f4ad";
}

.fa.fa-commenting-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-commenting-o {
  --fa: "\f4ad";
}

.fa.fa-houzz {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-vimeo {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-vimeo {
  --fa: "\f27d";
}

.fa.fa-black-tie {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-fonticons {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-reddit-alien {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-edge {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-credit-card-alt {
  --fa: "\f09d";
}

.fa.fa-codiepie {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-modx {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-fort-awesome {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-usb {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-product-hunt {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-mixcloud {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-scribd {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-pause-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-pause-circle-o {
  --fa: "\f28b";
}

.fa.fa-stop-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-stop-circle-o {
  --fa: "\f28d";
}

.fa.fa-bluetooth {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-bluetooth-b {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-gitlab {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wpbeginner {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wpforms {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-envira {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wheelchair-alt {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wheelchair-alt {
  --fa: "\f368";
}

.fa.fa-question-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-question-circle-o {
  --fa: "\f059";
}

.fa.fa-volume-control-phone {
  --fa: "\f2a0";
}

.fa.fa-asl-interpreting {
  --fa: "\f2a3";
}

.fa.fa-deafness {
  --fa: "\f2a4";
}

.fa.fa-hard-of-hearing {
  --fa: "\f2a4";
}

.fa.fa-glide {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-glide-g {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-signing {
  --fa: "\f2a7";
}

.fa.fa-viadeo {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-viadeo-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-viadeo-square {
  --fa: "\f2aa";
}

.fa.fa-snapchat {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-snapchat-ghost {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-snapchat-ghost {
  --fa: "\f2ab";
}

.fa.fa-snapchat-square {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-snapchat-square {
  --fa: "\f2ad";
}

.fa.fa-pied-piper {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-first-order {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-yoast {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-themeisle {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-plus-official {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-plus-official {
  --fa: "\f2b3";
}

.fa.fa-google-plus-circle {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-google-plus-circle {
  --fa: "\f2b3";
}

.fa.fa-font-awesome {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-fa {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-fa {
  --fa: "\f2b4";
}

.fa.fa-handshake-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-handshake-o {
  --fa: "\f2b5";
}

.fa.fa-envelope-open-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-envelope-open-o {
  --fa: "\f2b6";
}

.fa.fa-linode {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-address-book-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-address-book-o {
  --fa: "\f2b9";
}

.fa.fa-vcard {
  --fa: "\f2bb";
}

.fa.fa-address-card-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-address-card-o {
  --fa: "\f2bb";
}

.fa.fa-vcard-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-vcard-o {
  --fa: "\f2bb";
}

.fa.fa-user-circle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-user-circle-o {
  --fa: "\f2bd";
}

.fa.fa-user-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-user-o {
  --fa: "\f007";
}

.fa.fa-id-badge {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-drivers-license {
  --fa: "\f2c2";
}

.fa.fa-id-card-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-id-card-o {
  --fa: "\f2c2";
}

.fa.fa-drivers-license-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-drivers-license-o {
  --fa: "\f2c2";
}

.fa.fa-quora {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-free-code-camp {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-telegram {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-thermometer-4 {
  --fa: "\f2c7";
}

.fa.fa-thermometer {
  --fa: "\f2c7";
}

.fa.fa-thermometer-3 {
  --fa: "\f2c8";
}

.fa.fa-thermometer-2 {
  --fa: "\f2c9";
}

.fa.fa-thermometer-1 {
  --fa: "\f2ca";
}

.fa.fa-thermometer-0 {
  --fa: "\f2cb";
}

.fa.fa-bathtub {
  --fa: "\f2cd";
}

.fa.fa-s15 {
  --fa: "\f2cd";
}

.fa.fa-window-maximize {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-window-restore {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-times-rectangle {
  --fa: "\f410";
}

.fa.fa-window-close-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-window-close-o {
  --fa: "\f410";
}

.fa.fa-times-rectangle-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-times-rectangle-o {
  --fa: "\f410";
}

.fa.fa-bandcamp {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-grav {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-etsy {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-imdb {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-ravelry {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-eercast {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-eercast {
  --fa: "\f2da";
}

.fa.fa-snowflake-o {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa.fa-snowflake-o {
  --fa: "\f2dc";
}

.fa.fa-superpowers {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-wpexplorer {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa.fa-meetup {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* open-sans-500 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/open-sans/open-sans-v40-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* remove user agent styles */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

html {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-size: 12px;
}

.linkable {
  color: #939393;
  text-decoration: underline;
}
.linkable:hover {
  color: #0d5f41;
}

.go-modal-content .mat-dialog-container {
  padding: 0 !important;
  box-shadow: none;
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.cdk-overlay-backdrop-showing {
  background-color: rgba(0, 0, 0, 0.5);
}

.mat-expansion-panel-header {
  padding: 1rem !important;
  font-size: 1rem !important;
}

.mat-expansion-panel-body {
  padding: 0.5rem 1rem 1rem !important;
}

.hover:hover {
  z-index: 999;
  box-shadow: none !important;
}
.hover:hover.blue {
  background-color: #f7f9fc !important;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.timepicker header {
  background-color: #1f5b30 !important;
}

.timepicker button {
  color: #1f5b30 !important;
}

.timepicker .clock-face__number > span.active {
  background-color: #1f5b30 !important;
}

.timepicker .clock-face__clock-hand {
  background-color: #1f5b30 !important;
}
.timepicker .clock-face__clock-hand::after {
  background-color: #1f5b30 !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.timepicker .clock-face__clock-hand_minute::before {
  border: 0.25rem solid #1f5b30 !important;
}

.wr-create-stepper .ngx-timepicker {
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.wr-create-stepper .mat-stepper-horizontal-line {
  flex: auto;
  min-width: 2rem;
  height: 0;
  margin: 0;
  border-top: 3px solid !important;
}
.wr-create-stepper .mat-step-icon-selected {
  background-color: #8bb2ec;
}
.wr-create-stepper .green-border {
  border-color: #39a858 !important;
}

.disable-tab {
  pointer-events: none;
}

.disable-tab .mat-step-icon {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.54);
}

.transparent-background {
  background-color: transparent !important;
}

.insights-scrollbar {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  transition: scrollbar-color 0.5s;
}
.insights-scrollbar:hover {
  scrollbar-color: #b6b6b6 transparent;
}
.insights-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 2px;
}
.insights-scrollbar:not(:hover)::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.insights-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}
.insights-scrollbar::-webkit-scrollbar {
  width: 0.35vw;
  background-color: transparent;
}

.sidenav-container .modal {
  margin-top: 3.125rem;
}

:not(.mobile).sidenav-container.wide-sidenav .modal {
  margin-left: 16rem !important;
}
:not(.mobile).sidenav-container.slim-sidenav .modal {
  margin-left: 3.125rem !important;
}