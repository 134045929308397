/// Typography sizes for different elements in the application.
/// The values in the lists are in order:
/// 1. Font size
/// 2. Font weight
/// 3. Line height
/// 4. Letter spacing

$h1: (34px, 600, 40px, 0.25px);
$h2: (24px, 600, 32px, 0);
$h3: (20px, 600, 28px, 0.15px);
$h4: (16px, 600, 24px, 0.15px);
$h5: (14px, 600, 22px, 0.15px);
$h6: (14px, 600, 22px, 0.15px);
$avatar: (20px, 400, 20px, 0.14px);
$badge: (12px, 600, 16px, 0.14px);
$body-1: (16px, 400, 21px, 0.15px);
$body-2: (14px, 400, 21px, 0.15px);
$button-l: (15px, 600, 26px, 0.4px);
$button-m: (14px, 600, 24px, 0.4px);
$button-s: (13px, 600, 22px, 0.4px);
$caption: (12px, 400, 20px, 0.4px);
$chip: (13px, 400, 26px, 0.16px);
$helper-text: (12px, 400, 18px, 0.4px);
$input-label: (12px, 400, 12px, 0.15px);
$input-text: (16px, 400, 24px, 0.15px);
$link: (16px, 600, 24px, 0.15px);
$overline: (12px, 400, 24px, 1px);
$tooltip: (10px, 600, 14px, 0);
