@use 'sass:list';
@use 'variables/colors';
@use 'variables/typography-levels';

@mixin _typography($font-size, $font-weight, $line-height, $letter-spacing) {
	font-family: 'Open Sans', sans-serif;
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
}

@mixin _typography-with-colors($font-size, $font-weight, $line-height, $letter-spacing) {
	color: var(--qit-text-primary);

	@include _typography($font-size, $font-weight, $line-height, $letter-spacing);

	&--secondary {
		color: var(--qit-text-secondary);

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--main {
		color: colors.$primary-main-700;

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--disabled {
		color: colors.$text-disabled;

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--error {
		color: colors.$text-error;

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--warning {
		color: colors.$text-warning;

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--info {
		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);

		color: colors.$text-info;
	}

	&--success {
		color: colors.$text-success;

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}

	&--contrast {
		color: var(--qit-text-contrast);

		@include _typography($font-size, $font-weight, $line-height, $letter-spacing);
	}
}

h1,
.q--typography-h1 {
	// override normalize/import-now
	font-size: list.nth(typography-levels.$h1, 1) !important;

	@include _typography-with-colors(typography-levels.$h1...);
}

h2,
.q--typography-h2 {
	@include _typography-with-colors(typography-levels.$h2...);
}

h3,
.q--typography-h3 {
	@include _typography-with-colors(typography-levels.$h3...);
}

h4,
.q--typography-h4 {
	@include _typography-with-colors(typography-levels.$h4...);
}

h5,
.q--typography-h5 {
	@include _typography-with-colors(typography-levels.$h5...);
}

h6,
.q--typography-h6 {
	@include _typography-with-colors(typography-levels.$h6...);
}

.q--typography-avatar {
	@include _typography-with-colors(typography-levels.$avatar...);
}

.q--typography-badge {
	@include _typography-with-colors(typography-levels.$badge...);
}

.q--typography-body1 {
	@include _typography-with-colors(typography-levels.$body-1...);
}

.q--typography-body2 {
	@include _typography-with-colors(typography-levels.$body-2...);
}

.q--typography-button-l {
	@include _typography-with-colors(typography-levels.$button-l...);
}

.q--typography-button-m {
	@include _typography-with-colors(typography-levels.$button-m...);
}

.q--typography-button-s {
	@include _typography-with-colors(typography-levels.$button-s...);
}

.q--typography-caption {
	@include _typography-with-colors(typography-levels.$caption...);
}

.q--typography-chip {
	@include _typography-with-colors(typography-levels.$chip...);
}

.q--typography-helper-text {
	@include _typography-with-colors(typography-levels.$helper-text...);
}

.q--typography-input-label {
	@include _typography-with-colors(typography-levels.$input-label...);
}

.q--typography-input-text {
	@include _typography-with-colors(typography-levels.$input-text...);
}

// TODO: link is not present in Figma; consider removing
// or updating it during the M3 migration and/or Bootstrap deprecation
.q--typography-link {
	text-decoration: underline;
	cursor: pointer;

	@include _typography-with-colors(typography-levels.$link...);
}

.q--typography-overline {
	@include _typography-with-colors(typography-levels.$overline...);
}

.q--typography-tooltip {
	@include _typography-with-colors(typography-levels.$tooltip...);
}
