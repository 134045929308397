// Remove Arrows and Spinners
// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	margin: 0;
	appearance: none;
}

input[type='number'] {
	appearance: textfield;
}
